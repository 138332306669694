import { FunctionComponent } from "react";
import { ExtensionBySelfData } from "../../../../../../types/visa/extensionBySelf/data";
import { ChangeBySelfData } from "../../../../../../types/visa/changeBySelf/data";
import {
  formatDate,
  getLabelFromCurrentStatusOfResidence
} from "../../../../../../utils/visaFormDataHelpers/commonVisaFormDataHelper";
import { getLabelFromPeriodOfStayCodeForDependent } from "../../../../../../utils/visaFormDataHelpers/dependentVisaformDataHelper";
import { useTranslation } from "react-i18next";
import { SectionHeading } from "../../previewBodyCommonStyle";
import { VisaApplicationType } from "../../../../../../types/visa/applicationType";
import {
  isChangeBySelfData,
  isExtensionBySelfData,
  isThisVisaChange,
  isThisVisaExtension,
} from "../../../../../../utils/visaApplicationHelper";
import PreviewList from "../../../PreviewList";

interface StatusOfResidenceSectionProps {
  visaApplicationType: VisaApplicationType;
  data: ExtensionBySelfData | ChangeBySelfData;
}

export const StatusOfResidenceSection: FunctionComponent<
  StatusOfResidenceSectionProps
> = ({ visaApplicationType, data }) => {
  const { t } = useTranslation();
  const isVisaExtension = isThisVisaExtension(visaApplicationType);
  const isVisaChange = isThisVisaChange(visaApplicationType);
  const getPeriodOfStayLabel = (code: string) => {
    switch (visaApplicationType) {
      case VisaApplicationType.ExtendDependentVisaBySelf:
      case VisaApplicationType.ChangeToDependentVisaBySelf:
        return getLabelFromPeriodOfStayCodeForDependent(code);
      case VisaApplicationType.ExtendGijinkokuVisaBySelf:
        return getLabelFromPeriodOfStayCodeForDependent(code);
      default:
        return "";
    }
  };

  return (
    <section>
      <SectionHeading>{t("statusOfResidenceSection.title")}</SectionHeading>
      <PreviewList
        items={[
          {
            label: t("statusOfResidenceSection.currentStatusOfResidence"),
            value: (() => {
              let statusOfResidenceCode = "";

              if (isVisaExtension && isExtensionBySelfData(data)) 
                statusOfResidenceCode = data["WCICS010Dto:selCurrentZirySkk"] ?? '';
              

              if (isVisaChange && isChangeBySelfData(data))
                statusOfResidenceCode = data["WCICS020Dto:selCurrentZirySkk"] ?? '';

              return t(`${getLabelFromCurrentStatusOfResidence(statusOfResidenceCode)}`);
            })(),
          },
          {
            label: t("statusOfResidenceSection.periodOfStay"),
            value: (() => {
              let code = "";

              if (isVisaExtension && isExtensionBySelfData(data))
                code = data["WCICS010Dto:selZiryPeriod"] ?? '';

              if (isVisaChange && isChangeBySelfData(data))
                code = data["WCICS020Dto:selZiryPeriod"] ?? '';

              return t(`${getPeriodOfStayLabel(code)}`);
            })(),
          },
          {
            label: t("statusOfResidenceSection.dateOfExpiration"),
            value: (() => {
              if (isVisaExtension && isExtensionBySelfData(data)) {
                return formatDate(
                  data["WCICS010Dto:selExpirationDateYear"],
                  data["WCICS010Dto:selExpirationDateMonth"],
                  data["WCICS010Dto:selExpirationDateDay"]
                );
              }

              if (isVisaChange && isChangeBySelfData(data)) {
                return formatDate(
                  data["WCICS020Dto:selExpirationDateYear"],
                  data["WCICS020Dto:selExpirationDateMonth"],
                  data["WCICS020Dto:selExpirationDateDay"]
                );
              }

              return "";
            })(),
          },
          {
            label: t("statusOfResidenceSection.residenceCardNumber"),
            value: (() => {
              if (isVisaExtension && isExtensionBySelfData(data)) 
                return data["WCICS010Dto:txtZiryCardNum"];

              if (isVisaChange && isChangeBySelfData(data)) 
                return data["WCICS020Dto:txtZiryCardNum"];
              

              return "";
            })(),
          },
        ]}
      />
    </section>
  );
};

export default StatusOfResidenceSection;
