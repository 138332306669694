import { FunctionComponent, HTMLAttributes } from 'react';
import styled from 'styled-components';

interface FieldGroupNumberProps extends HTMLAttributes<HTMLDivElement> {
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: 1px solid #C2C2C2;
  border-radius: 50%;
  color: #626161;
  background: #F7F7F7;
  font-weight: 500;
  font-size: 1rem;
  line-height: 19px;
  overflow: hidden;
`;

const FieldGroupNumber: FunctionComponent<FieldGroupNumberProps> = ({
    children,
    ...props
}) => {
    return (
        <Container {...props}>
            {children}
        </Container>
    );
};

export default FieldGroupNumber;