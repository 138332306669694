import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { SectionHeading } from "../../previewBodyCommonStyle";

import PreviewList from "../../../PreviewList";
import { FormR } from "../../../../../../types/visa/uncommonFormParts/formR/data";
import { parseDateWithJaUnits } from "../../../../../../utils/visaFormDataHelpers/commonVisaFormDataHelper";

interface PlaceAndDateOfNotificationSectionProps {
  data: FormR;
}

export const PlaceAndDateOfNotificationSection: FunctionComponent<PlaceAndDateOfNotificationSectionProps> = ({
  data
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "placeAndDateOfNotificationSection" });

  return (
    <section>
      <SectionHeading>{t("title")}</SectionHeading>
      <PreviewList
        items={[
          {
            label: t("japaneseAuthorities"),
            value: data["place_and_date_japanese_authorities"]
          },
          {
            label: t("jaAuthDateOfRegistration"),
            value: parseDateWithJaUnits(
              data["place_and_date_japanese_authorities_date_of_registration"],
              '/'
            ).yearMonthDay
          },
          {
            label: t("foreignAuthorities"),
            value: data["place_and_date_foreign_authorities"]
          },
          {
            label: t("foreignAuthDateOfRegistration"),
            value: parseDateWithJaUnits(
              data["place_and_date_foreign_authorities_date_of_registration"],
              '/'
            ).yearMonthDay
          }
        ]}
      />
    </section>
  );
};

export default PlaceAndDateOfNotificationSection;
