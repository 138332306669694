import { ChangeEvent, FunctionComponent, InputHTMLAttributes } from 'react';
import styled from 'styled-components';

import { ValidationFunction } from '../../types/validation';

interface MultiTextInputProps extends InputHTMLAttributes<HTMLTextAreaElement> {
    invalid?: boolean;
    onValueChange?: (value: string) => void;
    // DEPRECATED
    validators?: ValidationFunction[];
    error?: string;
    onTextChange?: (value: string) => void;
    onValidationFail?: (value: string) => void;
}

const TextArea = styled.textarea<MultiTextInputProps>`
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: 85px;
  max-height: 300px;
  min-height: 40px;
  padding: 10px;
  border: 1px solid ${(props: MultiTextInputProps) => props.invalid ? '#E93232' : '#8A8E94'};
  border-radius: 5px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 1rem;
  line-height: 20px;
  color: #444444;
  background: ${(props: MultiTextInputProps) => props.invalid ? '#FFF0F0' : '#F5F9FF'};
  outline: none;

  &:disabled {
    border-color: #8A8E94;
    background: #D5D5D5;
  }

  &:focus {
    border: 2px solid #1F82FF;
    background: #F5F9FF;
  }

  &::placeholder {
    color: #999999;
  }
`;

const MultiTextInput: FunctionComponent<MultiTextInputProps> = ({
    onChange,
    onValueChange,
    ...props
}) => {
    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        onChange?.(event);
        onValueChange?.(event.target.value);
    };

    return (
        <TextArea
            onChange={handleChange}
            {...props}
        />
    );
};

export default MultiTextInput;