//The suffix, "CB19" is a parent key for all the codes.
//You won't have to know what it means exactly. you might see validation errors
//from backend that mention "CB19."
//It's confusing but added to distinguish the codes from other similar names
//such as "Status of Residence"

export enum StatusOfResidenceCode_CB19 {
  Dependent = 'T44',
  SpouseOrChildOfJapaneseNational = 'T61',
  SpouseOrChildOfPermanentResident = 'T62',
  LongTermResident = 'T63',
  Student = 'T41',
  Gijinkoku = 'T26',
  DesignatedActivities = 'T51',
  Diplomat = 'T01',
  Official = 'T02',
  Professor = 'T03',
  Artist = 'T04',
  ReligiousActivities = 'T05',
  Journalist = 'T06',
  LegalAccountingServices = 'T12',
  MedicalServices = 'T13',
  Researcher = 'T14',
  Instructor = 'T15',
  IntraCompanyTransferee = 'T18',
  Entertainer = 'T19',
  SkilledLabor = 'T20',
  TechnicalInternTraining_i_a = 'T21',
  TechnicalInternTraining_i_b = 'T22',
  TechnicalInternTraining_ii_a = 'T23',
  TechnicalInternTraining_ii_b = 'T24',
  BusinessManager = 'T25',
  EngineerSpecialistInHumanitiesInternationalServices = 'T26',
  TechnicalInternTraining_iii_a = 'T27',
  TechnicalInternTraining_iii_b = 'T28',
  NursingCare = 'T29',
  CulturalActivities = 'T31',
  TemporaryVisitor = 'T32',
  Trainee = 'T43',
  SpecialPermanentResidents = 'T60',
  SpecifiedSkilledWorker_i = 'T71',
  SpecifiedSkilledWorker_ii = 'T72',
  HighlySkilledProfessional_i_a = 'T81',
  HighlySkilledProfessional_i_b = 'T82',
  HighlySkilledProfessional_i_c = 'T83',
  HighlySkilledProfessional_ii = 'T90',
  PermanentResident = 'X14',
}


export enum StatusOfResidenceInJapanese {
  Dependent = '家族滞在',
  SpouseOrChildOfJapaneseNational = '日本人の配偶者等',
  SpouseOrChildOfPermanentResident = '永住者の配偶者等',
  LongTermResident = '定住者',
  Student = '留学',
  DesignatedActivities = '特定活動',
  Diplomat = '外交',
  Official = '公用',
  Professor = '教授',
  Artist = '芸術',
  ReligiousActivities = '宗教',
  Journalist = '報道',
  LegalAccountingServices = '法律・会計業務',
  MedicalServices = '医療',
  Researcher = '研究',
  Instructor = '教育',
  IntraCompanyTransferee = '企業内転勤',
  Entertainer = '興行',
  SkilledLabor = '技能',
  TechnicalInternTraining_i_a = '技能実習1号イ',
  TechnicalInternTraining_i_b = '技能実習1号ロ',
  TechnicalInternTraining_ii_a = '技能実習2号イ',
  TechnicalInternTraining_ii_b = '技能実習2号ロ',
  BusinessManager = '経営・管理',
  EngineerSpecialistInHumanitiesInternationalServices = '技術・人文知識・国際業務',
  TechnicalInternTraining_iii_a = '技能実習3号イ',
  TechnicalInternTraining_iii_b = '技能実習3号ロ',
  NursingCare = '介護',
  CulturalActivities = '文化活動',
  TemporaryVisitor = '短期滞在',
  Trainee = '研修',
  SpecialPermanentResidents = '特別永住者',
  SpecifiedSkilledWorker_i = '特定技能1号',
  SpecifiedSkilledWorker_ii = '特定技能2号',
  HighlySkilledProfessional_i_a = '高度専門職1号イ',
  HighlySkilledProfessional_i_b = '高度専門職1号ロ',
  HighlySkilledProfessional_i_c = '高度専門職1号ハ',
  HighlySkilledProfessional_ii = '高度専門職2号',
  PermanentResident = '永住者',
}
