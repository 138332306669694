import { User } from "../types/user/data";
import { axiosInstance } from "./common";

export const signUp = async (email: string, password: string) => {
  const response = await axiosInstance.request({
      method: 'POST',
      url: 'users',
      data: {
          user: {
              email,
              password
          },
      },
  });

  return { status: 'ok' };
};

export const signIn = async (email: string, password: string) => {
  const response = await axiosInstance.request({
      method: 'POST',
      url: 'users/sign_in',
      data: {
          user: {
              email,
              password,
          },
      },
  });

  return response.data as User;
};

export const signOut = async () => {
  await axiosInstance.request({
      method: 'DELETE',
      url: 'users/sign_out'
  });

  return { status: 'ok' };
};
