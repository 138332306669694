import { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import Page from "../../components/layout/Page";
import Text from "../../components/text/Text";
import { Trans, useTranslation } from "react-i18next";
import PageHeading from "../../components/text/PageHeading";
import Column from "../../components/layout/Column";
import DisplayLabel from "../../components/form/DisplayLabel";
import { useDispatcher } from "../../hooks";
import { getLatestUserRegistrationApplication } from "../../apis/userRegistration";
import { UserRegistration } from "../../types/userRegistration/data";
import { getCurrentUser } from "../../apis/user";
import { getLabelsFromAddressCode } from "../../utils/visaFormDataHelpers/commonVisaFormDataHelper";
import InlineMessage from "../../components/form/InlineMessage";
import { Gender } from "../../types/userRegistration/value";
import { getApprovalDate, isUserRegistrationApplicationApproved } from "../../utils/userRegistrationHelper";
import { StatusOfResidenceCode_CB19 } from "../../types/visa/statusOfResidence";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/values/query";

interface ProfilePageProps {}

const LocalPage = styled(Page)`
  background-color: #ffffff;
  padding: 0 0 30px 0;
`;

const HeadingInfoContainer = styled(Column)`
  padding: 20px;
  gap: 20px;
`;


const DataContainer = styled.div`
  width: 100%;
`;

const GovAccountSecContainer = styled(Column)`
`;

const ItemRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 10px 20px 10px 20px;

  &:nth-child(odd) {
    background-color: #f7f7f7;
  }
`;

const LocalDisplayLabel = styled(DisplayLabel)`
  word-break: break-all;
`;

const LocalText = styled(Text)`
  word-break: break-all;
`;


const ProfilePage: FunctionComponent<ProfilePageProps> = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'profilePage' });
  const { t: tWithoutPrefix } = useTranslation();
  const { dispatcher } = useDispatcher();

  const [registrationDate, setRegistrationDate] = useState("-");
  const [currentStatusOfResidence, setCurrentStatusOfResidence] = useState<StatusOfResidenceCode_CB19>();
  const [emailForContact, setEmailForContact] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [givenName, setGivenName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [gender, setGender] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [prefecture, setPrefecture] = useState("");
  const [municipality, setMunicipality] = useState("");
  const [townStreetBuilding, setTownStreetBuilding] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [residenceCardNumber, setResidenceCardNumber] = useState("");

  const { isPending: isUserDataPending, data: userData } = useQuery({
    queryKey: [QUERY_KEYS.USER],
    queryFn: getCurrentUser,
  });

  const { isPending: isUserRegAppPending, data: userRegAppData } = useQuery({
    queryKey: [QUERY_KEYS.USER_REGISTRATION_APPLICATION],
    queryFn: getLatestUserRegistrationApplication,
  });

  const isReady = !isUserDataPending && !isUserRegAppPending;

  useEffect(() => {
    if (!isReady) {
      dispatcher.startLoading();
      return;
    }

    if (!userData || !userRegAppData) {
      return;
    }

    const user = userData;
    const userRegistrationApplication = userRegAppData;
    const result: UserRegistration = userRegistrationApplication.riyousha_touroku;

    const [familyName, givenName, ...middleNameArr ] = 
      result["WCFDS010Dto:hdnUserInfoName"]?.split(" ") ?? [];
    const address = getLabelsFromAddressCode(
      result["WCFDS010Dto:hdnSearchedAddress"]
    );

    if (isUserRegistrationApplicationApproved(userRegistrationApplication))  
      setRegistrationDate(getApprovalDate(userRegistrationApplication) ?? '-');
    

    setCurrentStatusOfResidence(user.status_of_residence); 
    setEmailForContact(result["WCFDS010Dto:txtMailAddress"] ?? '');
    setFamilyName(familyName);
    setGivenName(givenName);
    setMiddleName(middleNameArr.join(" "));
    setGender(result["WCFDS010Dto:hdnSex"] ?? '');
    
    const birthdateValues = [
      result["WCFDS010Dto:hdnDateOfBirthYear"],
      result["WCFDS010Dto:hdnDateOfBirthMonth"],
      result["WCFDS010Dto:hdnDateOfBirthDay"],
    ];

    if (birthdateValues.every(val => !!val)) 
      setBirthdate(birthdateValues.join("/"));
    
    setPhoneNumber(result["WCFDS010Dto:txtUserTelNo"] ?? '');
    setPrefecture(address.prefecture
      ? tWithoutPrefix(address.prefecture)
      : ""
    );
    setMunicipality(address.municipality
      ? tWithoutPrefix(address.municipality)
      : ""
    );
    setTownStreetBuilding(result["WCFDS010Dto:txtDetailHomeAddress"] ?? '');
    setResidenceCardNumber(result["WCFDS010Dto:txtZiryCardNum"] ?? '');

    dispatcher.stopLoading();
  }, [isUserDataPending, isUserRegAppPending, userData, userRegAppData]);


  return (
    <LocalPage>
      <HeadingInfoContainer>
        <PageHeading>{t("profile")}</PageHeading>
      </HeadingInfoContainer>
    
      <GovAccountSecContainer>
        { isReady &&
          <DataContainer>
            <ItemRow>
              <LocalDisplayLabel>
                {t("currentStatusOfResidence")}
              </LocalDisplayLabel>
              <LocalText>
                { currentStatusOfResidence &&
                  tWithoutPrefix(`statusOfResidence_CB19.${currentStatusOfResidence}`)
                }
              </LocalText>
            </ItemRow>
            <ItemRow>
              <LocalDisplayLabel>{t("emailForContact")}</LocalDisplayLabel>
              <LocalText>{emailForContact}</LocalText>
            </ItemRow>
            <ItemRow>
              <LocalDisplayLabel>{t("familyName")}</LocalDisplayLabel>
              <LocalText>{familyName}</LocalText>
            </ItemRow>
            <ItemRow>
              <LocalDisplayLabel>{t("givenName")}</LocalDisplayLabel>
              <LocalText>{givenName}</LocalText>
            </ItemRow>
            <ItemRow>
              <LocalDisplayLabel>{t("middleName")}</LocalDisplayLabel>
              <LocalText>{middleName}</LocalText>
            </ItemRow>
            <ItemRow>
              <LocalDisplayLabel>{t("gender")}</LocalDisplayLabel>
              <LocalText>
                { gender === Gender.Male && t("male") }
                { gender === Gender.Female && t("female") }
              </LocalText>
            </ItemRow>
            <ItemRow>
              <LocalDisplayLabel>{t("birthdate")}</LocalDisplayLabel>
              <LocalText>{birthdate}</LocalText>
            </ItemRow>
            <ItemRow>
              <LocalDisplayLabel>{t("telephoneNumber")}</LocalDisplayLabel>
              <LocalText>{phoneNumber}</LocalText>
            </ItemRow>
            <ItemRow>
              <LocalDisplayLabel>
                {t("currentResidencePrefecture")}
              </LocalDisplayLabel>
              <LocalText>{prefecture}</LocalText>
            </ItemRow>
            <ItemRow>
              <LocalDisplayLabel>
                {t("currentResidenceMunicipality")}
              </LocalDisplayLabel>
              <LocalText>{municipality}</LocalText>
            </ItemRow>
            <ItemRow>
              <LocalDisplayLabel>
                {t("currentResidenceTownStreetBuilding")}
              </LocalDisplayLabel>
              <LocalText>{townStreetBuilding}</LocalText>
            </ItemRow>
            <ItemRow>
              <LocalDisplayLabel>
                {t("residenceCardNumber")}
              </LocalDisplayLabel>
              <LocalText>{residenceCardNumber}</LocalText>
            </ItemRow>
          </DataContainer>
        }
      </GovAccountSecContainer>
    </LocalPage>
  );
};

export default ProfilePage;
