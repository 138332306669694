import { FunctionComponent, HTMLAttributes } from "react";
import styled from "styled-components";

interface PromptCardProps extends HTMLAttributes<HTMLDivElement> {
  variant?: "info" | "warning";
}

const Container = styled.div<PromptCardProps>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 280px;
  width: 100%;
  padding: 20px;
  border-radius: 5px;

  border: 2px dashed
    ${({ variant }) => {
      switch (variant) {
        case "info":
          return "#1f82ff";
        case "warning":
          return "#E93232";
        default:
          return "#1f82ff";
      }
    }};

  background-color: ${({ variant }) => {
    switch (variant) {
      case "info":
        return "#f5f9ff";
      case "warning":
        return "#fff0f0";
      default:
        return "#f5f9ff";
    }
  }};
`;

const PromptCard: FunctionComponent<PromptCardProps> = ({
  children,
  ...props
}) => {
  return <Container {...props}>{children}</Container>;
};

export default PromptCard;
