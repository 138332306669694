import { FunctionComponent } from "react";
import styled from "styled-components";
import Column from "../layout/Column";
import { ReactComponent as TrashIcon } from "../../assets/icon-trash-can-3.svg";
import Text from "../text/Text";
import { convertBytesToMB } from "../../utils/visaFormDataHelpers/commonVisaFormDataHelper";

interface AttachmentPreviewProps {
  onClickDelete: () => void;
  file:
    | File
    | {
        size: number;
        url: string;
        name: string;
      };
}

const Container = styled(Column)`
  max-width: 320px;
  align-items: center;
  gap: 10px;
  margin: 0 auto;
`;

const DeleteButton = styled.button.attrs({
  type: "button",
})`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  background-color: transparent;
  border-radius: 100px;
  border: 1px solid #e93232;
  cursor: pointer;
  flex-shrink: 0;
`;

const DeleteIcon = styled(TrashIcon)`
  & * {
    fill: #e93232;
  }
`;

const FileInfoDeleteBtnContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 40px;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const FileNameSizeContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  gap: 5px;
`;

const FileNameText = styled(Text)`
  color: #626161;
  font-weight: 700;
  line-height: 16px;
  word-break: break-all;
`;

const LinkAnchor = styled.a`
  text-decoration: none;
  color: #0069ec;
  font-family: inherit;
`;

const Image = styled.img`
  width: 100%;
  max-width: 320px;
  height: auto;
`;

const AttachmentPreview: FunctionComponent<AttachmentPreviewProps> = ({
  onClickDelete,
  file,
}) => {
  const fileExtension = file.name?.split(".").pop()?.toLowerCase() || "";
  const isImageFile = ["jpg", "jpeg", "png", "gif", "webp", "bmp", "tiff"]
    .includes(fileExtension);
  const srcUrl = file instanceof File ? URL.createObjectURL(file) : file.url;

  return (
    <Container>
      <FileInfoDeleteBtnContainer>
        <FileNameText>
          {isImageFile ? (
             <>
                {`${file.name} (${convertBytesToMB(file.size)}MB)`}
             </>
          ) : (
            <FileNameSizeContainer>
              <LinkAnchor href={srcUrl} target="_blank" rel="noreferrer">
                {file.name}
              </LinkAnchor>
              <span>
              {`(${convertBytesToMB(file.size)}MB)`}
              </span>
            </FileNameSizeContainer>
          )}
        </FileNameText>
        <DeleteButton onClick={() => onClickDelete()}>
          <DeleteIcon />
        </DeleteButton>
      </FileInfoDeleteBtnContainer>

      {isImageFile && <Image src={srcUrl} />}
    </Container>
  );
};

export default AttachmentPreview;
