
import { SupplementaryInfo } from "../types/visa/supplementaryInfo";
import { axiosInstance } from "./common";

export const updateSupplementaryInfo = async (supplementaryInfoId: number | string, data: Partial<SupplementaryInfo>) => {
  const response = await axiosInstance.request({
      method: 'PUT',
      url: `supplementary_infos/${supplementaryInfoId}`,
      data
  });

  return response.data as SupplementaryInfo;
};

export const updateSupplementaryInfoAsInvitee = async (supplementaryInfoId: number | string, data: Partial<SupplementaryInfo>, invitationToken: string) => {
  const response = await axiosInstance.request({
      method: 'PUT',
      url: `supplementary_infos/${supplementaryInfoId}/invitee_update`,
      data: {
        ...data,
        invitation_token: invitationToken
      }
  });

  return response.data as SupplementaryInfo;
};