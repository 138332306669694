import { FunctionComponent, useState } from "react";
import styled from "styled-components";
import Label from "../form/Label";
import UploadButton from "../form/UploadButton";
import { useDispatcher } from "../../hooks";
import { useTranslation } from "react-i18next";
import { generateRandomString } from "../../utils/utils";
import UploadingMessage from "../display/UploadingMessage";
import AttachmentPreview from "../display/AttachmentPreview";
import CameraButton from "../form/CameraButton";
import Column from "../layout/Column";

interface UserDocUploadFieldProps {
  required?: boolean;
  label?: string;
  attachment: File | File [] | null;
  hideButtonsIfFileExists?: boolean;
  onAttachmentUpload?: (file: File) => void;
  onAttachmentDelete?: (index?: number) => void;
}

const Container = styled(Column)`
  gap: 20px;
`;

const PreviewContainer = styled(Column)`
  gap: 20px;
`;

const ActionButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  width: 100%;
`;


const UserDocUploadField: FunctionComponent<UserDocUploadFieldProps> = ({
  attachment,
  required,
  label,
  hideButtonsIfFileExists,
  onAttachmentUpload,
  onAttachmentDelete,
}) => {
  const sizeLimitInMB = 5;
  const { state, dispatcher } = useDispatcher();
  const { t } = useTranslation();
  const [id] = useState(generateRandomString());
  const isFileBeingUploaded =
    state.isUploading &&
    state.uploaderIds.some((uploaderId) => uploaderId === id);
  const uploadedAttachments = attachment 
    ? Array.isArray(attachment) ? attachment : [attachment] 
    : [];
  const showActionButtons = !(hideButtonsIfFileExists && uploadedAttachments.length > 0);

  return (
    <Container>
      {label && (
        <Label required={required}>{label}</Label>
      )}
      
      { uploadedAttachments.length > 0 && (
        <PreviewContainer>
        {uploadedAttachments.map((file, i) => (
          <AttachmentPreview
            key={`file-preview-${i}`}
            onClickDelete={() => onAttachmentDelete?.(i)}
            file={file}
          />
        ))}
        </PreviewContainer>
      )}
      {isFileBeingUploaded && (
        <UploadingMessage />
      )}

      { showActionButtons && (
        <ActionButtonsContainer>
          <CameraButton
            disabled={isFileBeingUploaded}
            onFileChange={file => onAttachmentUpload?.(file)}
            capture="environment"
          />
          <UploadButton
            uploadLimitInMB={sizeLimitInMB}
            disabled={isFileBeingUploaded}
            onFileSizeExceeded={() => {
              dispatcher.showWarningOnSnackbar(t("snackbar.under5MB"));
            }}
            onFileChange={file => onAttachmentUpload?.(file)}
          />
        </ActionButtonsContainer>
      )}
    </Container>
  );
};

export default UserDocUploadField;
