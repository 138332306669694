import { FunctionComponent, useEffect, useState } from 'react';
import { VisaFormProps } from '../../../types/visa/formProp';
import Accordion from '../../../components/navigation/Accordion';
import BasicInformationSection from '../ApplicantBlock/BasicInformationSection';
import StatusOfResidenceSection from '../ApplicantBlock/StatusOfResidenceSection';
import { useTranslation } from 'react-i18next';
import DetailsOfExtensionSection from '../ApplicantBlock/DetailsOfExtensionSection';
import FamilyCoresidentsSection from '../ApplicantBlock/FamilyCoresidentsSection';
import OtherSection from '../ApplicantBlock/OtherSection';
import AttachmentsSection from '../ApplicantBlock/AttachmentSection';
import { ReactComponent as ClipIcon } from '../../../assets/icon-clip.svg';
import PlaceAndDateOfNotificationSection from '../ApplicantBlock/PlaceAndDateOfNotificationSection';
import { getVisaApplicationStatus, isThisVisaChange, isThisVisaExtension } from '../../../utils/visaApplicationHelper';
import { FormR } from '../../../types/visa/uncommonFormParts/formR/data';
import DetailsOfChangeSection from '../ApplicantBlock/DetailsOfChangeSection';
import MethodOfSupportEtcSection from '../ApplicantBlock/MethodOfSupportEtcSection';
import SupporterSection from '../ApplicantBlock/SupporterSection';
import { SECTION_NAMES } from '../../../constants/values/sectionNames';
import { VisaApplicationStatus } from '../../../types/visa/applicationStatus';

interface DependentVisaFormProps extends VisaFormProps {
  formR: FormR | null;
  onChangeFormR: (data: Partial<FormR>) => void;
}

const DependentVisaForm: FunctionComponent<DependentVisaFormProps>  = ({
  visaApplication,
  visaApplicationType,
  formR,
  extensionBySelfData,
  changeBySelfData,
  supplementaryInfo,
  visaAttachments,
  onChangeFormR,
  onChangeExtensionBySelfData,
  onChangeBySelfDataChange,
  onChangeSupplementaryInfo,
  onChangeApplicationReadiness,
  onUploadVisaAttachment,
  onDeleteVisaAttachment,
  showErrorAlerts
}) => {
  const { t } = useTranslation();
  const isNoDataGiven = (!extensionBySelfData && !changeBySelfData);
  const [activeSection, setActiveSection] = useState<string>('');
  const toggleActiveSection = (key: string) => setActiveSection(activeSection === key ? '' : key);
  const isVisaExtension = isThisVisaExtension(visaApplicationType);
  const isVisaChange = isThisVisaChange(visaApplicationType);

  const [isBasicInfoSecReady, setIsBasicInfoSecReady] = useState<boolean>(false);
  const [isStatusOfResidenceSecReady, setIsStatusOfResidenceSecReady] = useState<boolean>(false);
  const [isDetailsOfExtensionSecReady, setIsDetailsOfExtensionSecReady] = useState<boolean>(false);
  const [isDetailsOfChangeSecReady, setIsDetailsOfChangeSecReady] = useState<boolean>(false);
  const [isFamilyCohabitantsSecReady, setIsFamilyCohabitantsSecReady] = useState<boolean>(false);
  const [isPlaceAndDateOfNotificationSecReady, setIsPlaceAndDateOfNotificationSecReady] = useState<boolean>(false);
  const [isMethodOfSupportEtcSecReady, setIsMethodOfSupportEtcSecReady] = useState<boolean>(false);
  const [isSupporterSecReady, setIsSupporterSecReady] = useState<boolean>(false);
  const [isOtherSecReady, setIsOtherSecReady] = useState<boolean>(false);
  const [isAttachmentsSecReady, setIsAttachmentsSecReady] = useState<boolean>(false);
  const isVisaInInsufficientStatus = visaApplication
    ? getVisaApplicationStatus(visaApplication) === VisaApplicationStatus.Insufficient
    : false;


  useEffect (() => {
    const conditionalSecsReady: boolean[] = [];

    if (isVisaExtension) {
      conditionalSecsReady.push(isDetailsOfExtensionSecReady);
    }

    if (isVisaChange) {
      conditionalSecsReady.push(isDetailsOfChangeSecReady);
    }

    onChangeApplicationReadiness([
      isBasicInfoSecReady,
      isStatusOfResidenceSecReady,
      isFamilyCohabitantsSecReady,
      isOtherSecReady,
      isAttachmentsSecReady,

      ...conditionalSecsReady
    ].every(isSectionReady => isSectionReady));
  }, [
    isBasicInfoSecReady,
    isStatusOfResidenceSecReady,
    isDetailsOfExtensionSecReady,
    isDetailsOfChangeSecReady,
    isFamilyCohabitantsSecReady,
    isPlaceAndDateOfNotificationSecReady,
    isMethodOfSupportEtcSecReady,
    isSupporterSecReady,
    isOtherSecReady,
    isAttachmentsSecReady
  ])

  if (isNoDataGiven)
    return null;

  return (
    <>
      {/* Basic Information */}
      <Accordion
        active={activeSection === SECTION_NAMES.basicInfomation}
        label={t("basicInformationSection.title")}
        onClick={() => toggleActiveSection(SECTION_NAMES.basicInfomation)}
        isError={showErrorAlerts && !isBasicInfoSecReady}
      >
        <BasicInformationSection
          readOnly={isVisaInInsufficientStatus}
          isActive={activeSection === SECTION_NAMES.basicInfomation}
          visaApplicationType={visaApplicationType}
          extensionBySelfData={extensionBySelfData}
          changeBySelfData={changeBySelfData}
          onChangeExtensionBySelfData={onChangeExtensionBySelfData}
          onChangeChangeBySelfData={onChangeBySelfDataChange}
          onChangeFormR={onChangeFormR}
          onChangeSectionReadiness={setIsBasicInfoSecReady}
          showEmptyRequiredValueError={showErrorAlerts}
        />
      </Accordion>

      {/* Status of Residence */}
      <Accordion
        active={activeSection === SECTION_NAMES.statusOfResidence}
        label={t("statusOfResidenceSection.title")}
        onClick={() => toggleActiveSection(SECTION_NAMES.statusOfResidence)}
        isError={showErrorAlerts && !isStatusOfResidenceSecReady}
      >
        <StatusOfResidenceSection
          isActive={activeSection === SECTION_NAMES.statusOfResidence}
          readOnly={isVisaInInsufficientStatus}
          visaApplicationType={visaApplicationType}
          extensionBySelfData={extensionBySelfData}
          changeBySelfData={changeBySelfData}
          onChangeExtensionBySelfData={onChangeExtensionBySelfData}
          onChangeChangeBySelfData={onChangeBySelfDataChange}
          onChangeFormR={onChangeFormR}
          onChangeSectionReadiness={setIsStatusOfResidenceSecReady}
          showEmptyRequiredValueError={showErrorAlerts}
        />
      </Accordion>

      {/* Details of Extension */}
      {isVisaExtension && (
        <Accordion
          active={activeSection === SECTION_NAMES.detailsOfExtension}
          label={t("detailsOfExtensionSection.title")}
          onClick={() => toggleActiveSection(SECTION_NAMES.detailsOfExtension)}
          isError={showErrorAlerts && !isDetailsOfExtensionSecReady}
        >
          <DetailsOfExtensionSection
            isActive={activeSection === SECTION_NAMES.detailsOfExtension}
            readOnly={isVisaInInsufficientStatus}
            visaApplicationType={visaApplicationType}
            extensionBySelfData={extensionBySelfData}
            onChangeExtensionBySelfData={onChangeExtensionBySelfData}
            onChangeSectionReadiness={setIsDetailsOfExtensionSecReady}
            showEmptyRequiredValueError={showErrorAlerts}
          />
        </Accordion>
      )}

      {/* Details of Change */}
      {isVisaChange && (
        <Accordion
          active={activeSection === SECTION_NAMES.detailsOfChange}
          label={t("detailsOfChangeSection.title")}
          onClick={() => toggleActiveSection(SECTION_NAMES.detailsOfChange)}
          isError={showErrorAlerts && !isDetailsOfChangeSecReady}
        >
          <DetailsOfChangeSection
            isActive={activeSection === SECTION_NAMES.detailsOfChange}
            readOnly={isVisaInInsufficientStatus}
            visaApplicationType={visaApplicationType}
            changeBySelfData={changeBySelfData}
            onChangeChangeBySelfData={onChangeBySelfDataChange}
            onChangeSectionReadiness={setIsDetailsOfChangeSecReady}
            showEmptyRequiredValueError={showErrorAlerts}
          />
        </Accordion>
      )}

      {/* Family/Co-residents */}
      <Accordion
        active={activeSection === SECTION_NAMES.familyCoResidents}
        label={t("familyCoresidentsSection.title")}
        onClick={() => toggleActiveSection(SECTION_NAMES.familyCoResidents)}
        isError={showErrorAlerts && !isFamilyCohabitantsSecReady}
      >
        <FamilyCoresidentsSection
          isActive={activeSection === SECTION_NAMES.familyCoResidents}
          readOnly={isVisaInInsufficientStatus}
          visaApplicationType={visaApplicationType}
          extensionBySelfData={extensionBySelfData}
          changeBySelfData={changeBySelfData}
          onChangeExtensionBySelfData={onChangeExtensionBySelfData}
          onChangeChangeBySelfData={onChangeBySelfDataChange}
          onChangeSectionReadiness={setIsFamilyCohabitantsSecReady}
          showEmptyRequiredValueError={showErrorAlerts}
        />
      </Accordion>

      {/* The place and date of notification */}
      <Accordion
        active={activeSection === SECTION_NAMES.placeAndDateOfNotification}
        label={t('placeAndDateOfNotificationSection.title')}
        onClick={() => toggleActiveSection(SECTION_NAMES.placeAndDateOfNotification)}
        isError={showErrorAlerts && !isPlaceAndDateOfNotificationSecReady}
      >
        <PlaceAndDateOfNotificationSection
          isActive={activeSection === SECTION_NAMES.placeAndDateOfNotification}
          readOnly={isVisaInInsufficientStatus}
          visaApplicationType={visaApplicationType}
          onChangeSectionReadiness={setIsPlaceAndDateOfNotificationSecReady}
          showEmptyRequiredValueError={showErrorAlerts}
          formR={formR}
          onChangeFormR={onChangeFormR}
        />
        </Accordion>

      {/* Method of support, etc. */}
      <Accordion
        active={activeSection === SECTION_NAMES.methodOfSupportEtc}
        label={t("methodOfSupportEtcSection.title")}
        onClick={() => toggleActiveSection(SECTION_NAMES.methodOfSupportEtc)}
        isError={showErrorAlerts && !isMethodOfSupportEtcSecReady}
      >
        <MethodOfSupportEtcSection
          isActive={activeSection === SECTION_NAMES.methodOfSupportEtc}
          readOnly={isVisaInInsufficientStatus}
          visaApplicationType={visaApplicationType}
          formR={formR}
          onChangeFormR={onChangeFormR}
          onChangeSectionReadiness={setIsMethodOfSupportEtcSecReady}
          supplementaryInfo={supplementaryInfo}
          onChangeSupplementaryInfo={onChangeSupplementaryInfo}
          showEmptyRequiredValueError={showErrorAlerts}
        />
      </Accordion>

      {/* Supporter */}
      <Accordion
        active={activeSection === SECTION_NAMES.supporter}
        label={t("supporterSection.title")}
        onClick={() => toggleActiveSection(SECTION_NAMES.supporter)}
        isError={showErrorAlerts && !isSupporterSecReady}
      >
        <SupporterSection
          isActive={activeSection === SECTION_NAMES.supporter}
          readOnly={isVisaInInsufficientStatus}
          visaApplicationType={visaApplicationType}
          formR={formR}
          onChangeFormR={onChangeFormR}
          onChangeSectionReadiness={setIsSupporterSecReady}
          showEmptyRequiredValueError={showErrorAlerts}
        />
      </Accordion>

      {/* Other */}
      <Accordion
        active={activeSection === SECTION_NAMES.other}
        label={t("otherSection.title")}
        onClick={() => toggleActiveSection(SECTION_NAMES.other)}
        isError={showErrorAlerts && !isOtherSecReady}
      >
        <OtherSection
          isActive={activeSection === SECTION_NAMES.other}
          readOnly={isVisaInInsufficientStatus}
          visaApplicationType={visaApplicationType}
          extensionBySelfData={extensionBySelfData}
          changeBySelfData={changeBySelfData}
          onChangeExtensionBySelfData={onChangeExtensionBySelfData}
          onChangeChangeBySelfData={onChangeBySelfDataChange}
          onChangeSectionReadiness={setIsOtherSecReady}
          showEmptyRequiredValueError={showErrorAlerts}
        />
      </Accordion>

      {/* Attachments */}
      <Accordion
        active={activeSection === SECTION_NAMES.attachments}
        label={t("attachmentsSection.title")}
        onClick={() => toggleActiveSection(SECTION_NAMES.attachments)}
        iconComponent={<ClipIcon />}
        isError={showErrorAlerts && !isAttachmentsSecReady}
      >
        <AttachmentsSection
          isActive={activeSection === SECTION_NAMES.attachments}
          visaApplication={visaApplication}
          visaApplicationType={visaApplicationType}
          visaAttachments={visaAttachments}
          onChangeFormR={onChangeFormR}
          onUploadVisaAttachment={onUploadVisaAttachment}
          onDeleteVisaAttachment={onDeleteVisaAttachment}
          onChangeSectionReadiness={setIsAttachmentsSecReady}
          showUnattachedAlert={showErrorAlerts}
        />
      </Accordion>
    </>
  );
}

export default DependentVisaForm;
