import { SelectOption } from "../../types/option";

//Be careful. This includes Japan as a nationality option
//If it should be excluded, use FOREIGNER_NATIONALITY_OPTIONS instead
export const ALL_NATIONALITY_OPTIONS: SelectOption[] = [
    {
        "label": "nationality.afghanistan",
        "value": "001"
    },
    {
        "label": "nationality.albania",
        "value": "002"
    },
    {
        "label": "nationality.algeria",
        "value": "003"
    },
    {
        "label": "nationality.andorra",
        "value": "505"
    },
    {
        "label": "nationality.angola",
        "value": "501"
    },
    {
        "label": "nationality.antigua_and_barbuda",
        "value": "502"
    },
    {
        "label": "nationality.argentina",
        "value": "004"
    },
    {
        "label": "nationality.armenia",
        "value": "503"
    },
    {
        "label": "nationality.australia",
        "value": "005"
    },
    {
        "label": "nationality.austria",
        "value": "006"
    },
    {
        "label": "nationality.azerbaijan",
        "value": "504"
    },
    {
        "label": "nationality.the_bahamas",
        "value": "022"
    },
    {
        "label": "nationality.bahrain",
        "value": "015"
    },
    {
        "label": "nationality.bangladesh",
        "value": "021"
    },
    {
        "label": "nationality.barbados",
        "value": "018"
    },
    {
        "label": "nationality.belarus",
        "value": "025"
    },
    {
        "label": "nationality.belgium",
        "value": "010"
    },
    {
        "label": "nationality.belize",
        "value": "023"
    },
    {
        "label": "nationality.benin",
        "value": "060"
    },
    {
        "label": "nationality.bhutan",
        "value": "016"
    },
    {
        "label": "nationality.bolivia",
        "value": "011"
    },
    {
        "label": "nationality.bosnia_and_herzegovina",
        "value": "602"
    },
    {
        "label": "nationality.botswana",
        "value": "019"
    },
    {
        "label": "nationality.brazil",
        "value": "012"
    },
    {
        "label": "nationality.brunei",
        "value": "024"
    },
    {
        "label": "nationality.bulgaria",
        "value": "013"
    },
    {
        "label": "nationality.burkina_faso",
        "value": "266"
    },
    {
        "label": "nationality.burundi",
        "value": "017"
    },
    {
        "label": "nationality.cambodia",
        "value": "030"
    },
    {
        "label": "nationality.cameroon",
        "value": "032"
    },
    {
        "label": "nationality.canada",
        "value": "033"
    },
    {
        "label": "nationality.cape_verde",
        "value": "050"
    },
    {
        "label": "nationality.central_africa",
        "value": "034"
    },
    {
        "label": "nationality.chad",
        "value": "036"
    },
    {
        "label": "nationality.chile",
        "value": "037"
    },
    {
        "label": "nationality.people's_republic_of_china",
        "value": "039"
    },
    {
        "label": "nationality.colombia",
        "value": "043"
    },
    {
        "label": "nationality.comoros",
        "value": "051"
    },
    {
        "label": "nationality.democratic_republic_of_the_congo",
        "value": "045"
    },
    {
        "label": "nationality.republic_of_congo",
        "value": "044"
    },
    {
        "label": "nationality.costa_rica",
        "value": "046"
    },
    {
        "label": "nationality.cote_d'ivoire",
        "value": "118"
    },
    {
        "label": "nationality.croatia",
        "value": "052"
    },
    {
        "label": "nationality.cuba",
        "value": "047"
    },
    {
        "label": "nationality.cyprus",
        "value": "048"
    },
    {
        "label": "nationality.czech_republic",
        "value": "053"
    },
    {
        "label": "nationality.denmark",
        "value": "061"
    },
    {
        "label": "nationality.djibouti",
        "value": "063"
    },
    {
        "label": "nationality.dominica",
        "value": "064"
    },
    {
        "label": "nationality.dominican_republic",
        "value": "062"
    },
    {
        "label": "nationality.ecuador",
        "value": "070"
    },
    {
        "label": "nationality.egypt",
        "value": "263"
    },
    {
        "label": "nationality.el_salvador",
        "value": "071"
    },
    {
        "label": "nationality.equatorial_guinea",
        "value": "073"
    },
    {
        "label": "nationality.eritrea",
        "value": "075"
    },
    {
        "label": "nationality.estonia",
        "value": "074"
    },
    {
        "label": "nationality.ethiopia",
        "value": "072"
    },
    {
        "label": "nationality.fiji",
        "value": "083"
    },
    {
        "label": "nationality.finland",
        "value": "080"
    },
    {
        "label": "nationality.france",
        "value": "081"
    },
    {
        "label": "nationality.gabon",
        "value": "090"
    },
    {
        "label": "nationality.the_gambia",
        "value": "097"
    },
    {
        "label": "nationality.georgia",
        "value": "550"
    },
    {
        "label": "nationality.germany",
        "value": "091"
    },
    {
        "label": "nationality.ghana",
        "value": "093"
    },
    {
        "label": "nationality.greece",
        "value": "094"
    },
    {
        "label": "nationality.grenada",
        "value": "500"
    },
    {
        "label": "nationality.guatemala",
        "value": "095"
    },
    {
        "label": "nationality.guinea",
        "value": "096"
    },
    {
        "label": "nationality.guinea-bissau",
        "value": "099"
    },
    {
        "label": "nationality.guyana",
        "value": "098"
    },
    {
        "label": "nationality.haiti",
        "value": "100"
    },
    {
        "label": "nationality.honduras",
        "value": "101"
    },
    {
        "label": "nationality.hungary",
        "value": "102"
    },
    {
        "label": "nationality.iceland",
        "value": "110"
    },
    {
        "label": "nationality.india",
        "value": "111"
    },
    {
        "label": "nationality.indonesia",
        "value": "112"
    },
    {
        "label": "nationality.iran",
        "value": "113"
    },
    {
        "label": "nationality.iraq",
        "value": "114"
    },
    {
        "label": "nationality.ireland",
        "value": "115"
    },
    {
        "label": "nationality.israel",
        "value": "116"
    },
    {
        "label": "nationality.italy",
        "value": "117"
    },
    {
        "label": "nationality.jamaica",
        "value": "130"
    },
    {
        "label": "nationality.japan",
        "value": "900"
    },
    {
        "label": "nationality.jordan",
        "value": "131"
    },
    {
        "label": "nationality.kazakhstan",
        "value": "146"
    },
    {
        "label": "nationality.kenya",
        "value": "143"
    },
    {
        "label": "nationality.kiribati",
        "value": "144"
    },
    {
        "label": "nationality.republic_of_korea",
        "value": "140"
    },
    {
        "label": "nationality.kosovo",
        "value": "607"
    },
    {
        "label": "nationality.kuwait",
        "value": "142"
    },
    {
        "label": "nationality.kyrgyz_republic",
        "value": "145"
    },
    {
        "label": "nationality.laos",
        "value": "150"
    },
    {
        "label": "nationality.latvia",
        "value": "157"
    },
    {
        "label": "nationality.lebanon",
        "value": "151"
    },
    {
        "label": "nationality.lesotho",
        "value": "156"
    },
    {
        "label": "nationality.liberia",
        "value": "152"
    },
    {
        "label": "nationality.libya",
        "value": "153"
    },
    {
        "label": "nationality.liechtenstein",
        "value": "154"
    },
    {
        "label": "nationality.lithuania",
        "value": "158"
    },
    {
        "label": "nationality.luxembourg",
        "value": "155"
    },
    {
        "label": "nationality.north_macedonia",
        "value": "177"
    },
    {
        "label": "nationality.madagascar",
        "value": "160"
    },
    {
        "label": "nationality.malawi",
        "value": "169"
    },
    {
        "label": "nationality.malaysia",
        "value": "161"
    },
    {
        "label": "nationality.maldives",
        "value": "171"
    },
    {
        "label": "nationality.mali",
        "value": "162"
    },
    {
        "label": "nationality.malta",
        "value": "170"
    },
    {
        "label": "nationality.marshall",
        "value": "174"
    },
    {
        "label": "nationality.mauritania",
        "value": "163"
    },
    {
        "label": "nationality.mauritius",
        "value": "172"
    },
    {
        "label": "nationality.mexico",
        "value": "164"
    },
    {
        "label": "nationality.micronesia",
        "value": "175"
    },
    {
        "label": "nationality.moldova",
        "value": "176"
    },
    {
        "label": "nationality.monaco",
        "value": "165"
    },
    {
        "label": "nationality.mongolia",
        "value": "166"
    },
    {
        "label": "nationality.montenegro",
        "value": "605"
    },
    {
        "label": "nationality.morocco",
        "value": "167"
    },
    {
        "label": "nationality.mozambique",
        "value": "173"
    },
    {
        "label": "nationality.myanmar",
        "value": "014"
    },
    {
        "label": "nationality.namibia",
        "value": "188"
    },
    {
        "label": "nationality.nauru",
        "value": "187"
    },
    {
        "label": "nationality.nepal",
        "value": "180"
    },
    {
        "label": "nationality.netherlands",
        "value": "181"
    },
    {
        "label": "nationality.new_zealand",
        "value": "182"
    },
    {
        "label": "nationality.nicaragua",
        "value": "183"
    },
    {
        "label": "nationality.niger",
        "value": "184"
    },
    {
        "label": "nationality.nigeria",
        "value": "185"
    },
    {
        "label": "nationality.norway",
        "value": "186"
    },
    {
        "label": "nationality.oman",
        "value": "168"
    },
    {
        "label": "nationality.pakistan",
        "value": "200"
    },
    {
        "label": "nationality.palau",
        "value": "208"
    },
    {
        "label": "nationality.palestine",
        "value": "606"
    },
    {
        "label": "nationality.panama",
        "value": "201"
    },
    {
        "label": "nationality.papua_new_guinea",
        "value": "207"
    },
    {
        "label": "nationality.paraguay",
        "value": "202"
    },
    {
        "label": "nationality.peru",
        "value": "203"
    },
    {
        "label": "nationality.philippines",
        "value": "204"
    },
    {
        "label": "nationality.poland",
        "value": "205"
    },
    {
        "label": "nationality.portugal",
        "value": "206"
    },
    {
        "label": "nationality.qatar",
        "value": "210"
    },
    {
        "label": "nationality.romania",
        "value": "220"
    },
    {
        "label": "nationality.russia",
        "value": "222"
    },
    {
        "label": "nationality.rwanda",
        "value": "221"
    },
    {
        "label": "nationality.saint_christopher_and_nevis",
        "value": "249"
    },
    {
        "label": "nationality.saint_lucia",
        "value": "247"
    },
    {
        "label": "nationality.saint_vincent",
        "value": "248"
    },
    {
        "label": "nationality.samoa",
        "value": "290"
    },
    {
        "label": "nationality.san_marino",
        "value": "230"
    },
    {
        "label": "nationality.sao_tome_and_principe",
        "value": "243"
    },
    {
        "label": "nationality.saudi_arabia",
        "value": "231"
    },
    {
        "label": "nationality.senegal",
        "value": "232"
    },
    {
        "label": "nationality.serbia",
        "value": "604"
    },
    {
        "label": "nationality.seychelles",
        "value": "245"
    },
    {
        "label": "nationality.sierra_leone",
        "value": "233"
    },
    {
        "label": "nationality.singapore",
        "value": "240"
    },
    {
        "label": "nationality.slovakia",
        "value": "601"
    },
    {
        "label": "nationality.slovenia",
        "value": "600"
    },
    {
        "label": "nationality.solomon",
        "value": "246"
    },
    {
        "label": "nationality.somalia",
        "value": "234"
    },
    {
        "label": "nationality.republic_of_south_africa",
        "value": "261"
    },
    {
        "label": "nationality.south_sudan",
        "value": "608"
    },
    {
        "label": "nationality.spain",
        "value": "235"
    },
    {
        "label": "nationality.sri_lanka",
        "value": "035"
    },
    {
        "label": "nationality.sudan",
        "value": "236"
    },
    {
        "label": "nationality.suriname",
        "value": "244"
    },
    {
        "label": "nationality.eswatini",
        "value": "241"
    },
    {
        "label": "nationality.sweden",
        "value": "237"
    },
    {
        "label": "nationality.switzerland",
        "value": "238"
    },
    {
        "label": "nationality.syria",
        "value": "239"
    },
    {
        "label": "nationality.taiwan",
        "value": "042"
    },
    {
        "label": "nationality.tajikistan",
        "value": "259"
    },
    {
        "label": "nationality.tanzania",
        "value": "251"
    },
    {
        "label": "nationality.thailand",
        "value": "250"
    },
    {
        "label": "nationality.timor-leste",
        "value": "076"
    },
    {
        "label": "nationality.togo",
        "value": "252"
    },
    {
        "label": "nationality.tonga",
        "value": "256"
    },
    {
        "label": "nationality.trinidad_and_tobago",
        "value": "253"
    },
    {
        "label": "nationality.tunisia",
        "value": "254"
    },
    {
        "label": "nationality.turkey",
        "value": "255"
    },
    {
        "label": "nationality.turkmenistan",
        "value": "258"
    },
    {
        "label": "nationality.tuvalu",
        "value": "257"
    },
    {
        "label": "nationality.uganda",
        "value": "260"
    },
    {
        "label": "nationality.ukraine",
        "value": "269"
    },
    {
        "label": "nationality.united_arab_emirates",
        "value": "009"
    },
    {
        "label": "nationality.united_kingdom",
        "value": "264"
    },
    {
        "label": "nationality.united_states_of_america",
        "value": "265"
    },
    {
        "label": "nationality.uruguay",
        "value": "267"
    },
    {
        "label": "nationality.uzbekistan",
        "value": "270"
    },
    {
        "label": "nationality.vanuatu",
        "value": "284"
    },
    {
        "label": "nationality.vatican",
        "value": "280"
    },
    {
        "label": "nationality.venezuela",
        "value": "281"
    },
    {
        "label": "nationality.viet_nam",
        "value": "283"
    },
    {
        "label": "nationality.yemen",
        "value": "300"
    },
    {
        "label": "nationality.zambia",
        "value": "310"
    },
    {
        "label": "nationality.zimbabwe",
        "value": "311"
    },
    {
        "label": "nationality.korea",
        "value": "139"
    },
    {
        "label": "nationality.statelessness",
        "value": "401"
    }
];


export const ALL_NATIONALITY_WITH_JA_VALUE_OPTIONS: SelectOption[] = [
    {
      "label": "nationality.afghanistan",
      "value": "アフガニスタン"
    },
    {
      "label": "nationality.albania",
      "value": "アルバニア"
    },
    {
      "label": "nationality.algeria",
      "value": "アルジェリア"
    },
    {
      "label": "nationality.andorra",
      "value": "アンドラ"
    },
    {
      "label": "nationality.angola",
      "value": "アンゴラ"
    },
    {
      "label": "nationality.antigua_and_barbuda",
      "value": "アンティグア・バーブーダ"
    },
    {
      "label": "nationality.argentina",
      "value": "アルゼンチン"
    },
    {
      "label": "nationality.armenia",
      "value": "アルメニア"
    },
    {
      "label": "nationality.australia",
      "value": "オーストラリア"
    },
    {
      "label": "nationality.austria",
      "value": "オーストリア"
    },
    {
      "label": "nationality.azerbaijan",
      "value": "アゼルバイジャン"
    },
    {
      "label": "nationality.the_bahamas",
      "value": "バハマ"
    },
    {
      "label": "nationality.bahrain",
      "value": "バーレーン"
    },
    {
      "label": "nationality.bangladesh",
      "value": "バングラデシュ"
    },
    {
      "label": "nationality.barbados",
      "value": "バルバドス"
    },
    {
      "label": "nationality.belarus",
      "value": "ベラルーシ"
    },
    {
      "label": "nationality.belgium",
      "value": "ベルギー"
    },
    {
      "label": "nationality.belize",
      "value": "ベリーズ"
    },
    {
      "label": "nationality.benin",
      "value": "ベナン"
    },
    {
      "label": "nationality.bhutan",
      "value": "ブータン"
    },
    {
      "label": "nationality.bolivia",
      "value": "ボリビア"
    },
    {
      "label": "nationality.bosnia_and_herzegovina",
      "value": "ボスニア・ヘルツェゴビナ"
    },
    {
      "label": "nationality.botswana",
      "value": "ボツワナ"
    },
    {
      "label": "nationality.brazil",
      "value": "ブラジル"
    },
    {
      "label": "nationality.brunei",
      "value": "ブルネイ"
    },
    {
      "label": "nationality.bulgaria",
      "value": "ブルガリア"
    },
    {
      "label": "nationality.burkina_faso",
      "value": "ブルキナファソ"
    },
    {
      "label": "nationality.burundi",
      "value": "ブルンジ"
    },
    {
      "label": "nationality.cambodia",
      "value": "カンボジア"
    },
    {
      "label": "nationality.cameroon",
      "value": "カメルーン"
    },
    {
      "label": "nationality.canada",
      "value": "カナダ"
    },
    {
      "label": "nationality.cape_verde",
      "value": "カーボベルデ"
    },
    {
      "label": "nationality.central_africa",
      "value": "中央アフリカ"
    },
    {
      "label": "nationality.chad",
      "value": "チャド"
    },
    {
      "label": "nationality.chile",
      "value": "チリ"
    },
    {
      "label": "nationality.people's_republic_of_china",
      "value": "中国"
    },
    {
      "label": "nationality.colombia",
      "value": "コロンビア"
    },
    {
      "label": "nationality.comoros",
      "value": "コモロ"
    },
    {
      "label": "nationality.democratic_republic_of_the_congo",
      "value": "コンゴ民主共和国"
    },
    {
      "label": "nationality.republic_of_congo",
      "value": "コンゴ共和国"
    },
    {
      "label": "nationality.costa_rica",
      "value": "コスタリカ"
    },
    {
      "label": "nationality.cote_d'ivoire",
      "value": "コートジボワール"
    },
    {
      "label": "nationality.croatia",
      "value": "クロアチア"
    },
    {
      "label": "nationality.cuba",
      "value": "キューバ"
    },
    {
      "label": "nationality.cyprus",
      "value": "キプロス"
    },
    {
      "label": "nationality.czech_republic",
      "value": "チェコ"
    },
    {
      "label": "nationality.denmark",
      "value": "デンマーク"
    },
    {
      "label": "nationality.djibouti",
      "value": "ジブチ"
    },
    {
      "label": "nationality.dominica",
      "value": "ドミニカ"
    },
    {
      "label": "nationality.dominican_republic",
      "value": "ドミニカ共和国"
    },
    {
      "label": "nationality.ecuador",
      "value": "エクアドル"
    },
    {
      "label": "nationality.egypt",
      "value": "エジプト"
    },
    {
      "label": "nationality.el_salvador",
      "value": "エルサルバドル"
    },
    {
      "label": "nationality.equatorial_guinea",
      "value": "赤道ギニア"
    },
    {
      "label": "nationality.eritrea",
      "value": "エリトリア"
    },
    {
      "label": "nationality.estonia",
      "value": "エストニア"
    },
    {
      "label": "nationality.ethiopia",
      "value": "エチオピア"
    },
    {
      "label": "nationality.fiji",
      "value": "フィジー"
    },
    {
      "label": "nationality.finland",
      "value": "フィンランド"
    },
    {
      "label": "nationality.france",
      "value": "フランス"
    },
    {
      "label": "nationality.gabon",
      "value": "ガボン"
    },
    {
      "label": "nationality.the_gambia",
      "value": "ガンビア"
    },
    {
      "label": "nationality.georgia",
      "value": "ジョージア"
    },
    {
      "label": "nationality.germany",
      "value": "ドイツ"
    },
    {
      "label": "nationality.ghana",
      "value": "ガーナ"
    },
    {
      "label": "nationality.greece",
      "value": "ギリシャ"
    },
    {
      "label": "nationality.grenada",
      "value": "グレナダ"
    },
    {
      "label": "nationality.guatemala",
      "value": "グアテマラ"
    },
    {
      "label": "nationality.guinea",
      "value": "ギニア"
    },
    {
      "label": "nationality.guinea-bissau",
      "value": "ギニアビサウ"
    },
    {
      "label": "nationality.guyana",
      "value": "ガイアナ"
    },
    {
      "label": "nationality.haiti",
      "value": "ハイチ"
    },
    {
      "label": "nationality.honduras",
      "value": "ホンジュラス"
    },
    {
      "label": "nationality.hungary",
      "value": "ハンガリー"
    },
    {
      "label": "nationality.iceland",
      "value": "アイスランド"
    },
    {
      "label": "nationality.india",
      "value": "インド"
    },
    {
      "label": "nationality.indonesia",
      "value": "インドネシア"
    },
    {
      "label": "nationality.iran",
      "value": "イラン"
    },
    {
      "label": "nationality.iraq",
      "value": "イラク"
    },
    {
      "label": "nationality.ireland",
      "value": "アイルランド"
    },
    {
      "label": "nationality.israel",
      "value": "イスラエル"
    },
    {
      "label": "nationality.italy",
      "value": "イタリア"
    },
    {
      "label": "nationality.jamaica",
      "value": "ジャマイカ"
    },
    {
      "label": "nationality.japan",
      "value": "日本"
    },
    {
      "label": "nationality.jordan",
      "value": "ヨルダン"
    },
    {
      "label": "nationality.kazakhstan",
      "value": "カザフスタン"
    },
    {
      "label": "nationality.kenya",
      "value": "ケニア"
    },
    {
      "label": "nationality.kiribati",
      "value": "キリバス"
    },
    {
      "label": "nationality.republic_of_korea",
      "value": "韓国"
    },
    {
      "label": "nationality.kosovo",
      "value": "コソボ"
    },
    {
      "label": "nationality.kuwait",
      "value": "クウェート"
    },
    {
      "label": "nationality.kyrgyz_republic",
      "value": "キルギス"
    },
    {
      "label": "nationality.laos",
      "value": "ラオス"
    },
    {
      "label": "nationality.latvia",
      "value": "ラトビア"
    },
    {
      "label": "nationality.lebanon",
      "value": "レバノン"
    },
    {
      "label": "nationality.lesotho",
      "value": "レソト"
    },
    {
      "label": "nationality.liberia",
      "value": "リベリア"
    },
    {
      "label": "nationality.libya",
      "value": "リビア"
    },
    {
      "label": "nationality.liechtenstein",
      "value": "リヒテンシュタイン"
    },
    {
      "label": "nationality.lithuania",
      "value": "リトアニア"
    },
    {
      "label": "nationality.luxembourg",
      "value": "ルクセンブルク"
    },
    {
      "label": "nationality.north_macedonia",
      "value": "北マケドニア"
    },
    {
      "label": "nationality.madagascar",
      "value": "マダガスカル"
    },
    {
      "label": "nationality.malawi",
      "value": "マラウイ"
    },
    {
      "label": "nationality.malaysia",
      "value": "マレーシア"
    },
    {
      "label": "nationality.maldives",
      "value": "モルディブ"
    },
    {
      "label": "nationality.mali",
      "value": "マリ"
    },
    {
      "label": "nationality.malta",
      "value": "マルタ"
    },
    {
      "label": "nationality.marshall",
      "value": "マーシャル"
    },
    {
      "label": "nationality.mauritania",
      "value": "モーリタニア"
    },
    {
      "label": "nationality.mauritius",
      "value": "モーリシャス"
    },
    {
      "label": "nationality.mexico",
      "value": "メキシコ"
    },
    {
      "label": "nationality.micronesia",
      "value": "ミクロネシア"
    },
    {
      "label": "nationality.moldova",
      "value": "モルドバ"
    },
    {
      "label": "nationality.monaco",
      "value": "モナコ"
    },
    {
      "label": "nationality.mongolia",
      "value": "モンゴル"
    },
    {
      "label": "nationality.montenegro",
      "value": "モンテネグロ"
    },
    {
      "label": "nationality.morocco",
      "value": "モロッコ"
    },
    {
      "label": "nationality.mozambique",
      "value": "モザンビーク"
    },
    {
      "label": "nationality.myanmar",
      "value": "ミャンマー"
    },
    {
      "label": "nationality.namibia",
      "value": "ナミビア"
    },
    {
      "label": "nationality.nauru",
      "value": "ナウル"
    },
    {
      "label": "nationality.nepal",
      "value": "ネパール"
    },
    {
      "label": "nationality.netherlands",
      "value": "オランダ"
    },
    {
      "label": "nationality.new_zealand",
      "value": "ニュージーランド"
    },
    {
      "label": "nationality.nicaragua",
      "value": "ニカラグア"
    },
    {
      "label": "nationality.niger",
      "value": "ニジェール"
    },
    {
      "label": "nationality.nigeria",
      "value": "ナイジェリア"
    },
    {
      "label": "nationality.norway",
      "value": "ノルウェー"
    },
    {
      "label": "nationality.oman",
      "value": "オマーン"
    },
    {
      "label": "nationality.pakistan",
      "value": "パキスタン"
    },
    {
      "label": "nationality.palau",
      "value": "パラオ"
    },
    {
      "label": "nationality.palestine",
      "value": "パレスチナ"
    },
    {
      "label": "nationality.panama",
      "value": "パナマ"
    },
    {
      "label": "nationality.papua_new_guinea",
      "value": "パプアニューギニア"
    },
    {
      "label": "nationality.paraguay",
      "value": "パラグアイ"
    },
    {
      "label": "nationality.peru",
      "value": "ペルー"
    },
    {
      "label": "nationality.philippines",
      "value": "フィリピン"
    },
    {
      "label": "nationality.poland",
      "value": "ポーランド"
    },
    {
      "label": "nationality.portugal",
      "value": "ポルトガル"
    },
    {
      "label": "nationality.qatar",
      "value": "カタール"
    },
    {
      "label": "nationality.romania",
      "value": "ルーマニア"
    },
    {
      "label": "nationality.russia",
      "value": "ロシア"
    },
    {
      "label": "nationality.rwanda",
      "value": "ルワンダ"
    },
    {
      "label": "nationality.saint_christopher_and_nevis",
      "value": "セントクリストファー・ネービス"
    },
    {
      "label": "nationality.saint_lucia",
      "value": "セントルシア"
    },
    {
      "label": "nationality.saint_vincent",
      "value": "セントビンセント"
    },
    {
      "label": "nationality.samoa",
      "value": "サモア"
    },
    {
      "label": "nationality.san_marino",
      "value": "サンマリノ"
    },
    {
      "label": "nationality.sao_tome_and_principe",
      "value": "サントメ・プリンシペ"
    },
    {
      "label": "nationality.saudi_arabia",
      "value": "サウジアラビア"
    },
    {
      "label": "nationality.senegal",
      "value": "セネガル"
    },
    {
      "label": "nationality.serbia",
      "value": "セルビア"
    },
    {
      "label": "nationality.seychelles",
      "value": "セーシェル"
    },
    {
      "label": "nationality.sierra_leone",
      "value": "シエラレオネ"
    },
    {
      "label": "nationality.singapore",
      "value": "シンガポール"
    },
    {
      "label": "nationality.slovakia",
      "value": "スロバキア"
    },
    {
      "label": "nationality.slovenia",
      "value": "スロベニア"
    },
    {
      "label": "nationality.solomon",
      "value": "ソロモン"
    },
    {
      "label": "nationality.somalia",
      "value": "ソマリア"
    },
    {
      "label": "nationality.republic_of_south_africa",
      "value": "南アフリカ共和国"
    },
    {
      "label": "nationality.south_sudan",
      "value": "南スーダン共和国"
    },
    {
      "label": "nationality.spain",
      "value": "スペイン"
    },
    {
      "label": "nationality.sri_lanka",
      "value": "スリランカ"
    },
    {
      "label": "nationality.sudan",
      "value": "スーダン"
    },
    {
      "label": "nationality.suriname",
      "value": "スリナム"
    },
    {
      "label": "nationality.eswatini",
      "value": "エスワティニ"
    },
    {
      "label": "nationality.sweden",
      "value": "スウェーデン"
    },
    {
      "label": "nationality.switzerland",
      "value": "スイス"
    },
    {
      "label": "nationality.syria",
      "value": "シリア"
    },
    {
      "label": "nationality.taiwan",
      "value": "台湾"
    },
    {
      "label": "nationality.tajikistan",
      "value": "タジキスタン"
    },
    {
      "label": "nationality.tanzania",
      "value": "タンザニア"
    },
    {
      "label": "nationality.thailand",
      "value": "タイ"
    },
    {
      "label": "nationality.timor-leste",
      "value": "東ティモール"
    },
    {
      "label": "nationality.togo",
      "value": "トーゴ"
    },
    {
      "label": "nationality.tonga",
      "value": "トンガ"
    },
    {
      "label": "nationality.trinidad_and_tobago",
      "value": "トリニダード・トバゴ"
    },
    {
      "label": "nationality.tunisia",
      "value": "チュニジア"
    },
    {
      "label": "nationality.turkey",
      "value": "トルコ"
    },
    {
      "label": "nationality.turkmenistan",
      "value": "トルクメニスタン"
    },
    {
      "label": "nationality.tuvalu",
      "value": "ツバル"
    },
    {
      "label": "nationality.uganda",
      "value": "ウガンダ"
    },
    {
      "label": "nationality.ukraine",
      "value": "ウクライナ"
    },
    {
      "label": "nationality.united_arab_emirates",
      "value": "アラブ首長国連邦"
    },
    {
      "label": "nationality.united_kingdom",
      "value": "英国"
    },
    {
      "label": "nationality.united_states_of_america",
      "value": "米国"
    },
    {
      "label": "nationality.uruguay",
      "value": "ウルグアイ"
    },
    {
      "label": "nationality.uzbekistan",
      "value": "ウズベキスタン"
    },
    {
      "label": "nationality.vanuatu",
      "value": "バヌアツ"
    },
    {
      "label": "nationality.vatican",
      "value": "バチカン"
    },
    {
      "label": "nationality.venezuela",
      "value": "ベネズエラ"
    },
    {
      "label": "nationality.viet_nam",
      "value": "ベトナム"
    },
    {
      "label": "nationality.yemen",
      "value": "イエメン"
    },
    {
      "label": "nationality.zambia",
      "value": "ザンビア"
    },
    {
      "label": "nationality.zimbabwe",
      "value": "ジンバブエ"
    },
    {
      "label": "nationality.korea",
      "value": "朝鮮"
    },
    {
      "label": "nationality.statelessness",
      "value": "無国籍"
    }
  ]


//Exclude Japan from the list of nationalities because it shouldn't be an option for foreigners
export const FOREIGNER_NATIONALITY_OPTIONS: SelectOption[] = ALL_NATIONALITY_OPTIONS.filter(v => v.label !== 'nationality.japan');
export const FOREIGNER_NATIONALITY_WITH_JA_VALUE_OPTIONS: SelectOption[] = ALL_NATIONALITY_WITH_JA_VALUE_OPTIONS.filter(v => v.label !== 'nationality.japan');