import styled from "styled-components";
import Text from "../../../text/Text";
import Column from "../../../layout/Column";

export const LocalColumn = styled(Column)`
  align-items: normal;
  gap: 25px;
`;

export const VisaCategoryHeading = styled(Text)`
  color: #444;

  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const SectionHeading = styled(Text)`
  font-size: 1rem;
  line-height: 23px;
  font-weight: 700;
  color: #444444;
  margin: 10px 0 10px 0;
`;

export const SectionSubHeading = styled(Text)`
  font-weight: 500;
  margin: 10px 0 10px 0;
`;

export const UserTypeBlockHeading = styled(Text)`
  margin-left: 20px;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 29px;
`;

export const Group = styled.div`
  padding-left: 20px;
  width: 100%;

  padding-top: 10px;
  padding-bottom: 10px;
`;

export const VariableItem = styled.div`
  margin-top: 10px;
  gap: 10px;
`;

export const CheckItemsList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding-left: 5px;
`;

export const CheckItem = styled.li`
  &::before {
    content: "•";
    margin-right: 10px;
  }
`;