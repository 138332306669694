export type NextAction = {
  identifier: string;
};

export enum OnboardingNextAction {
  Request2ndMynaURLToRead = "cfmusrath",
  RequestToSubmitUserAuth = "reqsbmtusrath"
}

export enum VisaSubmissionModalNextAction {
  ReadMynaCard2ndTime = "rdmncd2dtm",
  SubmitApplicationData = "sbmtapdt",
}

export enum UserAuthenticationModalNextAction {
  RequestToConfirmAuthStatus = "usrthrqsttcfrmathstts",
}

export enum VisaMessageModalNextAction {
  RequestToConfirmMessages = "rqesttcnfrmmssgs",
}

export enum VisaStatusConfirmationModalNextAction {
  RequestToConfirmStatus = "rqesttcnfrmstts",
}