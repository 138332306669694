import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatcher } from "../../hooks";
import styled from "styled-components";
import Button from "../../components/form/Button";
import Label from "../../components/form/Label";
import TextInput from "../../components/form/TextInput";
import Column from "../../components/layout/Column";
import Page from "../../components/layout/Page";
import PageHeading from "../../components/text/PageHeading";
import Text from "../../components/text/Text";
import { signIn } from "../../apis/auth";
import { User } from "../../types/user/data";
import BottomAlignedContainer from "../../components/layout/BottomAlignedContainer";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/values/query";
import { isRequiredToCompleteOnboarding, isUserSetupCompleted } from "../../utils/userHelper";
import { getLatestUserRegistrationApplication } from "../../apis/userRegistration";

interface LoginPageProps {}

const LocalPage = styled(Page)`
  padding: 20px;
  gap: 30px;
`;

const LocalLabel = styled(Label)`
  margin-bottom: 5px;
`;

const LinkText = styled(Text)`
  margin: 0 auto;
`;


const Anchor = styled.a`
  text-decoration: none;
  color: #0069EC;
`;

const ButtonContainer = styled(BottomAlignedContainer)`
  padding-top: 30px;
`;

const LoginPage: FunctionComponent<LoginPageProps> = () => {
  const { dispatcher } = useDispatcher();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  const handleLogin = async () => {
    dispatcher.startLoading();
    try {
      const user: User = await signIn(email, password);
      let userRegistrationApplication =
          await getLatestUserRegistrationApplication();

      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER] });
      dispatcher.login();

      const preferredLang = user.user_setting.language?.code || i18n.language;
      i18n.changeLanguage(preferredLang);
      localStorage.setItem("lng", preferredLang);
      
      dispatcher.setIsOnboardingRequired(
        !!isRequiredToCompleteOnboarding(user, userRegistrationApplication)
      )

      if (isUserSetupCompleted(user)) {
        dispatcher.setSetupComplete(true);
      } else {
        dispatcher.setSetupComplete(false);
        navigate("/setup");
        return;
      }
      dispatcher.showSuccessOnSnackbar(t("snackbar.loginSuccess"));
      navigate("/");
    } catch (e) {
      dispatcher.treatAsLoggedOut();
      dispatcher.showWarningOnSnackbar(t("snackbar.loginFailed"));
    } finally {
      dispatcher.stopLoading();
    }

  };

  return (
    <LocalPage>
      <PageHeading>{t("loginPage.title")}</PageHeading>

      <Column>
        <LocalLabel>{t("loginPage.email")}</LocalLabel>
        <TextInput value={email} onValueChange={setEmail} />
      </Column>

      <Column>
        <LocalLabel>{t("loginPage.password")}</LocalLabel>
        <TextInput
          type="password"
          value={password}
          onValueChange={setPassword}
        />
      </Column>

      <LinkText>
        <Anchor href="/reset-password">
          {t("loginPage.forgotYourPassword")}
        </Anchor>
      </LinkText>


      <ButtonContainer>
        <Column style={{ gap: 10 }}>
          <Button
            variant="primary"
            disabled={!email || !password}
            onClick={handleLogin}
          >
            {t("loginPage.login")}
          </Button>

          <LinkText>
            <Anchor href="/signup">
              {t("loginPage.createAccount")}
            </Anchor>
          </LinkText>
        </Column>
      </ButtonContainer>
    </LocalPage>
  );
};

export default LoginPage;
