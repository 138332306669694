import { FunctionComponent } from "react";
import styled from "styled-components";
import Row from "../layout/Row";
import Spinner from "./Spinner";
import Text from "../text/Text";
import { useTranslation } from "react-i18next";

interface UploadingMessageProps {}

const UploadingMessageContainer = styled(Row)`
  background-color: #f2f2f2;
  padding: 10px;
  gap: 10px;
  justify-content: center;
`;

const UploadingMessage: FunctionComponent<UploadingMessageProps> = () => {
  const { t } = useTranslation();

  return (
    <UploadingMessageContainer>
      <Spinner />
      <Text>{t("upload.uploading")}</Text>
    </UploadingMessageContainer>
  )
}

export default UploadingMessage;