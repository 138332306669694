export enum LanguageCode {
  Japanese = 'ja',
  English = 'en',
}

export type Language = {
  id: number;
  created_at: string;
  updated_at: string;

  code: string; //Language code: e.g. 'en', 'ja'
  name: string; //Language name: e.g. 'English'
  tkey: string; //Translation key: e.g. 'japanese'
}