import { useTranslation } from "react-i18next";
import { isValueEmpty } from "../../utils/utils";

export const useSectionErrorHandler = () => {
  const { t } = useTranslation();

  const createGetEmptyFieldError = (config: { additionalCondition?: boolean, errorMessage?: string }) => 
    (val: any) => ((config.additionalCondition ?? true) && isValueEmpty(val))
      ? (config.errorMessage || t('inputAlert.field')) 
      : '';

  const createGetUnattachedFieldError = (config: { additionalCondition?: boolean | undefined | null, errorMessage?: string }) =>
    (val: any) => ((config.additionalCondition ?? true) && isValueEmpty(val))
      ? (config.errorMessage || t('inputAlert.attachment'))
      : '';

  return {
    createGetEmptyFieldError,
    createGetUnattachedFieldError
  };
}
