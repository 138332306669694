import { FunctionComponent } from "react";
import { VisaApplicationType } from "../../../../types/visa/applicationType";
import DependentVisaAttachments from "./SectionBodies/DendentVisaAttachments";
import { ApplicationAttachmentSectionProps } from "../../type";
import { FormR } from "../../../../types/visa/uncommonFormParts/formR/data";

interface AttachmentsSectionProps extends ApplicationAttachmentSectionProps {
  onChangeFormR: (formR: FormR) => void;
}

const AttachmentsSection: FunctionComponent<AttachmentsSectionProps> = ({
  isActive,
  visaApplication,
  visaAttachments,
  visaApplicationType,
  onChangeFormR,
  onUploadVisaAttachment,
  onDeleteVisaAttachment,
  onChangeSectionReadiness,
  showUnattachedAlert,
}) => {

  switch (visaApplicationType) {
    case VisaApplicationType.ExtendDependentVisaBySelf:
    case VisaApplicationType.ChangeToDependentVisaBySelf:
      return (
        <DependentVisaAttachments
          isActive={isActive}
          formR={visaApplication.form_r}
          onChangeFormR={onChangeFormR}
          visaAttachments ={visaAttachments}
          visaApplicationType={visaApplicationType}
          onUploadVisaAttachment={onUploadVisaAttachment}
          onDeleteVisaAttachment={onDeleteVisaAttachment}
          onChangeSectionReadiness={onChangeSectionReadiness}
          showUnattachedAlert={showUnattachedAlert}
        />
      );

    default:
      return null;
  }
};

export default AttachmentsSection;
