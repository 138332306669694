import { VisaAttachment, VisaAttachmentKind } from "../types/visa/attachment";
import { axiosInstance } from "./common";

export const uploadVisaAttachment = async (visaApplicationId: number, file: File, kind: VisaAttachmentKind) => {
  const data = new FormData();
  data.append('visa_application_id', visaApplicationId.toString());
  data.append('kind', kind);
  data.append('file', file);

  const response = await axiosInstance.request({
      method: 'POST',
      url: `visa_attachments`,
      data,
  });

  return response.data as VisaAttachment;
};

export const uploadVisaAttachmentAsInvitee = async (visaApplicationId: number, file: File, kind: VisaAttachmentKind, invitationToken: string) => {
  const data = new FormData();
  data.append('visa_application_id', visaApplicationId.toString());
  data.append('kind', kind);
  data.append('file', file);
  data.append('invitation_token', invitationToken);

  const response = await axiosInstance.request({
      method: 'POST',
      url: `visa_attachments/invitee_create`,
      data,
  });

  return response.data as VisaAttachment;
};

export const getVisaAttachment = async (id: number) => {
  const response = await axiosInstance.request({
      method: 'GET',
      url: `visa_attachments/${id}`
  });

  return response.data as VisaAttachment;
};

export const updateVisaAttachment = async (visaAttachmentId: number, file: File, kind: VisaAttachmentKind) => {
  const data = new FormData();
  data.append('kind', kind);
  data.append('file', file);

  const response = await axiosInstance.request({
      method: 'PUT',
      url: `visa_attachments/${visaAttachmentId}`,
      data,
  });

  return response.data as VisaAttachment;
};

export const deleteVisaAttachment = async (visaAttachmentId: number) => {
  const response = await axiosInstance.request({
      method: 'DELETE',
      url: `visa_attachments/${visaAttachmentId}`,
  });

  return response.data as VisaAttachment;
};

export const deleteVisaAttachments = async (visaAttachmentIds: number []) => {
  await axiosInstance.request({
      method: 'DELETE',
      url: `visa_attachments/destroy_some`,
      data: {
        ids: visaAttachmentIds
      }
  });

  return { status : 'ok' };
};


export const deleteVisaAttachmentAsInvitee = async (visaAttachmentId: number, invitationToken: string) => {
  const response = await axiosInstance.request({
      method: 'DELETE',
      url: `visa_attachments/${visaAttachmentId}/invitee_destroy`,
      data: {
        invitation_token: invitationToken
      }
  });

  return response.data as VisaAttachment;
};

export const deleteVisaAttachmentsAsInvitee = async (visaAttachmentIds: number [], invitationToken: string) => {
  await axiosInstance.request({
      method: 'DELETE',
      url: `visa_attachments/invitee_destroy_some`,
      data: {
        ids: visaAttachmentIds,
        invitation_token: invitationToken
      }
  });

  return { status: 'ok' };
};