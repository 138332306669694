import { SelectOption } from "../../../types/option";
import { PeriodOfStayCode_CB20, PeriodOfStayInJapanese } from "../../../types/visa/periodOfStay";
import { StatusOfResidenceCode_CB19, StatusOfResidenceInJapanese } from "../../../types/visa/statusOfResidence";
import { PERIOD_OF_STAY_CB20_OPTIONS_DIC } from "./periodOfStayCommon";

const getPeriodOfStayTKey = (key: string) => `periodOfStay_CB20.${key}`;

export const PERIOD_OF_STAY_CB20_OPTIONS_FOR_DEPENDENT: SelectOption[] = 
  PERIOD_OF_STAY_CB20_OPTIONS_DIC[StatusOfResidenceCode_CB19.Dependent];

//For FormR (i.e. uncommmon part)
export const SUPPORTER_PERIOD_OF_STAY_WITH_JA_VALUE_OPTIONS_DIC_FOR_DEPENDENT: Record<
  string,
  SelectOption[]
> = {
  [StatusOfResidenceInJapanese.Professor]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeMonths),
      value: PeriodOfStayInJapanese.ThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayInJapanese.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayInJapanese.ThreeYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayInJapanese.FiveYears,
    },
  ],
  [StatusOfResidenceInJapanese.Artist]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeMonths),
      value: PeriodOfStayInJapanese.ThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayInJapanese.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayInJapanese.ThreeYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayInJapanese.FiveYears,
    },
  ],
  [StatusOfResidenceInJapanese.ReligiousActivities]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeMonths),
      value: PeriodOfStayInJapanese.ThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayInJapanese.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayInJapanese.ThreeYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayInJapanese.FiveYears,
    },
  ],
  [StatusOfResidenceInJapanese.Journalist]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeMonths),
      value: PeriodOfStayInJapanese.ThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayInJapanese.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayInJapanese.ThreeYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayInJapanese.FiveYears,
    },
  ],
  [StatusOfResidenceInJapanese.LegalAccountingServices]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeMonths),
      value: PeriodOfStayInJapanese.ThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayInJapanese.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayInJapanese.ThreeYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayInJapanese.FiveYears,
    },
  ],
  [StatusOfResidenceInJapanese.MedicalServices]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeMonths),
      value: PeriodOfStayInJapanese.ThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayInJapanese.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayInJapanese.ThreeYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayInJapanese.FiveYears,
    },
  ],
  [StatusOfResidenceInJapanese.Researcher]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeMonths),
      value: PeriodOfStayInJapanese.ThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayInJapanese.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayInJapanese.ThreeYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayInJapanese.FiveYears,
    },
  ],
  [StatusOfResidenceInJapanese.Instructor]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeMonths),
      value: PeriodOfStayInJapanese.ThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayInJapanese.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayInJapanese.ThreeYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayInJapanese.FiveYears,
    },
  ],
  [StatusOfResidenceInJapanese.IntraCompanyTransferee]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeMonths),
      value: PeriodOfStayInJapanese.ThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayInJapanese.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayInJapanese.ThreeYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayInJapanese.FiveYears,
    },
  ],
  [StatusOfResidenceInJapanese.Entertainer]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FifteenDays),
      value: PeriodOfStayInJapanese.FifteenDays,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThirtyDays),
      value: PeriodOfStayInJapanese.ThirtyDays,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeMonths),
      value: PeriodOfStayInJapanese.ThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.SixMonths),
      value: PeriodOfStayInJapanese.SixMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayInJapanese.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayInJapanese.ThreeYears,
    },
  ],
  [StatusOfResidenceInJapanese.SkilledLabor]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeMonths),
      value: PeriodOfStayInJapanese.ThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayInJapanese.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayInJapanese.ThreeYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayInJapanese.FiveYears,
    },
  ],
  [StatusOfResidenceInJapanese.BusinessManager]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeMonths),
      value: PeriodOfStayInJapanese.ThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FourMonths),
      value: PeriodOfStayInJapanese.FourMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayInJapanese.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayInJapanese.ThreeYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayInJapanese.FiveYears,
    },
  ],
  [StatusOfResidenceInJapanese.EngineerSpecialistInHumanitiesInternationalServices]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeMonths),
      value: PeriodOfStayInJapanese.ThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayInJapanese.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayInJapanese.ThreeYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayInJapanese.FiveYears,
    },
  ],
  [StatusOfResidenceInJapanese.NursingCare]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeMonths),
      value: PeriodOfStayInJapanese.ThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayInJapanese.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayInJapanese.ThreeYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayInJapanese.FiveYears,
    },
  ],
  [StatusOfResidenceInJapanese.CulturalActivities]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeMonths),
      value: PeriodOfStayInJapanese.ThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.SixMonths),
      value: PeriodOfStayInJapanese.SixMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayInJapanese.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayInJapanese.ThreeYears,
    },
  ],
  [StatusOfResidenceInJapanese.Student]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeMonths),
      value: PeriodOfStayInJapanese.ThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.SixMonths),
      value: PeriodOfStayInJapanese.SixMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayInJapanese.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYearThreeMonths),
      value: PeriodOfStayInJapanese.OneYearThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.TwoYears),
      value: PeriodOfStayInJapanese.TwoYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.TwoYearsThreeMonths),
      value: PeriodOfStayInJapanese.TwoYearsThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayInJapanese.ThreeYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYearsThreeMonths),
      value: PeriodOfStayInJapanese.ThreeYearsThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FourYears),
      value: PeriodOfStayInJapanese.FourYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FourYearsThreeMonths),
      value: PeriodOfStayInJapanese.FourYearsThreeMonths,
    },
  ],
  [StatusOfResidenceInJapanese.SpouseOrChildOfJapaneseNational]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.SixMonths),
      value: PeriodOfStayInJapanese.SixMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayInJapanese.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayInJapanese.ThreeYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayInJapanese.FiveYears,
    },
  ],
  [StatusOfResidenceInJapanese.SpouseOrChildOfPermanentResident]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.SixMonths),
      value: PeriodOfStayInJapanese.SixMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayInJapanese.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayInJapanese.ThreeYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayInJapanese.FiveYears,
    },
  ],
  [StatusOfResidenceInJapanese.SpecifiedSkilledWorker_ii]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.SixMonths),
      value: PeriodOfStayInJapanese.SixMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayInJapanese.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayInJapanese.ThreeYears,
    },
  ],
  [StatusOfResidenceInJapanese.HighlySkilledProfessional_i_a]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayInJapanese.FiveYears,
    },
  ],
  [StatusOfResidenceInJapanese.HighlySkilledProfessional_i_b]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayInJapanese.FiveYears,
    },
  ],
  [StatusOfResidenceInJapanese.HighlySkilledProfessional_i_c]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayInJapanese.FiveYears,
    },
  ],
  [StatusOfResidenceInJapanese.HighlySkilledProfessional_ii]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.Unlimited),
      value: PeriodOfStayInJapanese.Unlimited,
    },
  ],
  [StatusOfResidenceInJapanese.PermanentResident]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.Unlimited),
      value: PeriodOfStayInJapanese.Unlimited,
    }
  ],
};
