import React, { FunctionComponent, HTMLAttributes, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as TrashCanIcon } from "../../assets/icon-trash-can-3.svg";
import { ReactComponent as RedoIcon } from "../../assets/icon-rotate-left.svg";
import { ReactComponent as EraserIcon } from "../../assets/icon-eraser.svg";
import { ReactComponent as PenIcon } from "../../assets/icon-pen.svg";
import { ReactComponent as PenActiveIcon } from "../../assets/icon-pen-filled.svg";
import { ReactComponent as EraserActiveIcon } from "../../assets/icon-eraser-filled.svg";
import { use } from "i18next";

type SignatureCanvasProps = HTMLAttributes<HTMLDivElement> & {
  fileName?: string;
  onUpdateSignature?: (file: File) => void;
};

enum Tool {
  Draw = 'draw',
  Erase = 'erase',
}

/* Since we need to keep the component aspect ratio 16:9,
   the use of a CSS property, aspect-ratio is avoided here */
const Container = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%;
`;

const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #EDEDED;
  border-radius: 4px;
  border: none;
`;

const IconGroup = css`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 10px;
  gap: 16px;
`;

const LeftIconGroup = styled.div`
  ${IconGroup}
  left: 10px;
`;

const RightIconGroup = styled.div`
  ${IconGroup}
  right: 10px;
`;

const ClickableIcon = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;


const SignatureCanvas: FunctionComponent<SignatureCanvasProps> = ({
  fileName,
  onUpdateSignature,
  ...props
}) => {
  const [isDrawing, setIsDrawing] = useState(false);
  const [currentTool, setCurrentTool] = useState<Tool>(Tool.Draw);
  const [history, setHistory] = useState<string[]>([]);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const ctxRef = useRef<CanvasRenderingContext2D | null>(null);
  const signatureFileName = fileName || 'signature.png';

  const getResizedImageDataURL = (canvas: HTMLCanvasElement) => {
    const resizedCanvas = document.createElement('canvas');
    const resizedContext = resizedCanvas.getContext('2d');
    const displayWidth = canvasRef.current?.clientWidth || 0;
    const displayHeight = canvasRef.current?.clientHeight || 0;
  
    if (!resizedContext) return '';
  
    resizedCanvas.width = displayWidth;
    resizedCanvas.height = displayHeight;
  
    const scaleX = displayWidth / canvas.width;
    const scaleY = displayHeight / canvas.height;
  
    resizedContext.scale(scaleX, scaleY);
    resizedContext.drawImage(canvas, 0, 0);
  
    return resizedCanvas.toDataURL('image/png');
  };

  const dataURLtoFile = (dataURL: string, filename: string = signatureFileName): File => {
    const [header, base64Data] = dataURL.split(',');
    const mimeString = header.match(/:(.*?);/)?.[1];
    const byteString = window.atob(base64Data);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    
    for (let i = 0; i < byteString.length; i++) 
      ia[i] = byteString.charCodeAt(i);
    
    const blob = new Blob([ab], { type: mimeString });
    
    return new File([blob], filename, { type: mimeString });
  }

  const getRelativePosition = (event: 
    React.MouseEvent<HTMLCanvasElement, MouseEvent> | 
    React.TouchEvent<HTMLCanvasElement>
  ) => {
    const canvas = canvasRef.current;

    if (!canvas) 
      return { x: 0, y: 0 };

    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;

    let x: number;
    let y: number;

    if ('touches' in event) {
      const touch = event.touches[0];
      x = (touch.clientX - rect.left) * scaleX;
      y = (touch.clientY - rect.top) * scaleY;
    } else {
      x = (event.clientX - rect.left) * scaleX;
      y = (event.clientY - rect.top) * scaleY;
    }

    return { x, y };
  };
  
  const startUpdating = () => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');
    if (!canvas || !context) return;

    setIsDrawing(true);
    ctxRef.current = context;
    context.beginPath();

    setHistory(prev => [...prev, canvas.toDataURL()]);
  };

  const update = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent> | React.TouchEvent<HTMLCanvasElement>) => {
    const { x, y } = getRelativePosition(e);
    const canvas = canvasRef.current;
    const context = ctxRef.current;

    if (!isDrawing || !canvas || !context) 
      return;

    context.lineWidth = 2;
    context.lineCap = 'round';
    context.strokeStyle = currentTool === Tool.Erase ? 'transparent' : 'black'; 
    
    if (currentTool === Tool.Draw) {
      context.lineTo(x, y);
      context.stroke();
    } 
    
    if (currentTool === Tool.Erase) {
      context.clearRect(x - 10, y - 10, 20, 20);
    }
  };

  const stopUpdating = () => {
    const canvas = canvasRef.current;
    const context = ctxRef.current;

    if (!isDrawing || !canvas || !context) 
      return;

    context.closePath();

    const resizedDataURL = getResizedImageDataURL(canvas);
    const signatureFile = dataURLtoFile(resizedDataURL);
    onUpdateSignature?.(signatureFile);
    setIsDrawing(false);
  };

  const changeTool = (tool: Tool) => setCurrentTool(tool);

  const undo = () => {
    const canvas = canvasRef.current;
    const lastState = history[history.length - 1];
    
    if (!canvas || history.length === 0 || !lastState) 
      return;

    setHistory(prev => prev.slice(0, prev.length - 1));

    const img = new Image();
    img.src = lastState;
    img.onload = () => {
      const context = canvas.getContext('2d');
      if (context) {
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(img, 0, 0);
        const resizedDataURL = getResizedImageDataURL(canvas);
        const signatureFile = dataURLtoFile(resizedDataURL);
        onUpdateSignature?.(signatureFile);
      }
    };
    
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');

    if (!canvas || !context) return;
      
    setHistory(prev => [...prev, canvas.toDataURL()]);
    context.clearRect(0, 0, canvas.width, canvas.height);  
  };

  useEffect(() => {
    const canvas = canvasRef.current;

    if (!canvas) 
      return;

    canvas.addEventListener('touchmove', (e) => e.preventDefault(), { passive: false });

  }, []);

  return (
    <Container {...props}>
      <Canvas
        ref={canvasRef}
        onMouseDown={startUpdating}
        onMouseMove={update}
        onMouseUp={stopUpdating}
        onMouseOut={stopUpdating}
        onTouchStart={startUpdating}
        onTouchMove={update}
        onTouchEnd={stopUpdating}
      /> 

      <LeftIconGroup>
        <ClickableIcon onClick={clearCanvas}>
          <TrashCanIcon />
        </ClickableIcon>

        <ClickableIcon onClick={undo}>
          <RedoIcon />
        </ClickableIcon>
      </LeftIconGroup>

      <RightIconGroup>
        <ClickableIcon onClick={() => changeTool(Tool.Draw)}>
          {currentTool === Tool.Draw ? <PenActiveIcon /> : <PenIcon />}
        </ClickableIcon>

        <ClickableIcon onClick={() => changeTool(Tool.Erase)}>
          {currentTool === Tool.Erase ? <EraserActiveIcon /> : <EraserIcon />}
        </ClickableIcon>
      </RightIconGroup>
    </Container>
  );
};

export default SignatureCanvas;
