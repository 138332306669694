import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckItem, CheckItemsList, Group, SectionHeading, VariableItem } from "../../previewBodyCommonStyle";
import PreviewList from "../../../PreviewList";
import { FormR } from "../../../../../../types/visa/uncommonFormParts/formR/data";
import { SupplementaryInfo } from "../../../../../../types/visa/supplementaryInfo";
import { PreviewItem } from "../../../../../../types/modal/preview";
import { getEmploymentKeyOf, getLengthOfEmployments } from "../../../../../../utils/visaFormDataHelpers/dependentVisaformDataHelper";
import FieldGroupNumber from "../../../../../display/FieldGroupNumber";
import { convertKValToFullAmount, getLabelFromMunicipalityInJapanese, getLabelFromPrefectureInJapanese } from "../../../../../../utils/visaFormDataHelpers/commonVisaFormDataHelper";
import { EngagingInActivitiesOtherThanPermitted } from "../../../../../../types/visa/uncommonFormParts/formR/value";

interface MethodOfSupportEtcSectionProps {
  data: FormR;
  supplementaryInfo: SupplementaryInfo;
}

export const MethodOfSupportEtcSection: FunctionComponent<MethodOfSupportEtcSectionProps> = ({
  data,
  supplementaryInfo
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "methodOfSupportEtcSection" });
  const { t: tMet } = useTranslation("translation", { keyPrefix: "methodOfSupportEtcSection.methodOfSupport" });
  const { t: tAct } = useTranslation("translation", { keyPrefix: "methodOfSupportEtcSection.activitiesOtherThanPermitted" });
  const { t: tRep } = useTranslation("translation", { keyPrefix: "methodOfSupportEtcSection.legalRepresentative" });
  const { t: tCommon } = useTranslation("translation", { keyPrefix: "common" });
  const { t: tWithoutPrefix } = useTranslation();
  const empLength = getLengthOfEmployments(data);
  const [empItemsList, setEmpItemsList] = useState<PreviewItem[][]>([]);
  const [methodsOfSupport, setMethodsOfSupport] = useState<string[]>([]);
  
  useEffect(() => {
    const checkedMethods: string[] = [];

    if (data["method_of_support_relatives_exists"]) 
      checkedMethods.push(tMet("relatives"));

    if (data["method_of_support_remittances_from_abroad_exists"])
      checkedMethods.push(tMet("remittancesFromAbroad"));

    if (data["method_of_support_guarantor_exists"])
      checkedMethods.push(tMet("guarantor"));

    if (data["method_of_support_others_exists"]) 
      checkedMethods.push(`
        ${tMet("others")} (${data["method_of_support_others_detail"] ?? ''})
      `);
    
    setMethodsOfSupport(checkedMethods);


    const itemsList: PreviewItem[][] = [];

    for (let i = 1; i <= empLength; i++) {
      const items: PreviewItem[] = [];

      items.push(
        {
          label: tAct("typeOfWork"),
          value: data[getEmploymentKeyOf.typeOfWork(i)]
        },
        {
          label: tAct("placeOfEmployment"),
          value: data[getEmploymentKeyOf.placeOfEmployment(i)]
        },
        {
          label: tAct("nameOfBranch"),
          value: data[getEmploymentKeyOf.nameOfBranch(i)]
        },
        {
          label: tAct("telephoneNumber"),
          value: data[getEmploymentKeyOf.telephoneNumber(i)]
        },
        {
          label: tAct("workTimePerWeek"),
          value: data[getEmploymentKeyOf.workTimePerWeek(i)]
        },
        {
          label: tAct("salary"),
          value: data[getEmploymentKeyOf.salaryType(i)]
        },
        {
          label: tAct("salaryYen"),
          value: convertKValToFullAmount(data[getEmploymentKeyOf.salaryYen(i)] as string, true)
        }
      );

      itemsList.push(items);
    }

    setEmpItemsList(itemsList);
  }, [data]);

  return (
    <section>
      <SectionHeading>{t("title")}</SectionHeading>
      <PreviewList
        items={[
          {
            label: tMet("title"),
            value: (
              <CheckItemsList>
                {methodsOfSupport.map((method, index) => (
                  <CheckItem key={index}>{method}</CheckItem>
                ))}
              </CheckItemsList>)
          },
          {
            label: tAct("areYouEngagingInActivities"),
            value: (() => {
              switch (data["other_activities_yes_or_no"]) {
                case EngagingInActivitiesOtherThanPermitted.Yes: return tCommon("yes");
                case EngagingInActivitiesOtherThanPermitted.No: return tCommon("no");
              }
            })()
          }
        ]}
      />

      {empItemsList.length > 0 && (
        <Group>
          {empItemsList.map((items, i) => (
            <VariableItem key={`emp-${i}`}>
              <FieldGroupNumber>{i + 1}</FieldGroupNumber>
              <PreviewList items={items}/>
            </VariableItem>
          ))}
        </Group>
      )}

      <PreviewList
        items={[
          {
            label: tRep("madeByLegalRepresentative"),
            value: (() => {
              switch (supplementaryInfo["is_this_by_representative"]) {
                case true: return tCommon("yes");
                case false: return tCommon("no");
              }
            })()
          },
          {
            label: tRep("name"),
            value: data["representative_name"],
            skip: !supplementaryInfo["is_this_by_representative"]
          },
          {
            label: tRep("relationship"),
            value: data["representative_relationship"],
            skip: !supplementaryInfo["is_this_by_representative"]
          },
          {
            label: tCommon("prefecture"),
            value: tWithoutPrefix(
              getLabelFromPrefectureInJapanese(
                data["representative_address_prefecture"]
              )
            ),
          },
          {
            label: tCommon("municipality"),
            value: tWithoutPrefix(
              getLabelFromMunicipalityInJapanese(
                data["representative_address_prefecture"],
                data["representative_address_municipality"]
              )
            ),
          },
          {
            label: tCommon("townStreetApartment"),
            value: data["representative_address_street"],
          },
          {
            label: tCommon("cellphoneNumber"),
            value: data["representative_cellular_phone_no"],
          },
          {
            label: tCommon("telephoneNumber"),
            value: data["representative_telephone_no"],
          },
         
        ]}
      />
    </section>
  );
};

export default MethodOfSupportEtcSection;
