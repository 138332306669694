import { FormKind, VisaApplication } from "../../types/visa/applicationData";
import { ChangeBySelfData } from "../../types/visa/changeBySelf/data";
import { ExtensionBySelfData } from "../../types/visa/extensionBySelf/data";
import { SupplementaryInfo } from "../../types/visa/supplementaryInfo";
import { isValueEmpty } from "../../utils/utils";
import { isVisaChangeApplication, isVisaExtensionApplication } from "../../utils/visaApplicationHelper";

export const extractReusableExtensionBySelfData = (
  visaAppToCopyFrom: VisaApplication,
): Partial<ExtensionBySelfData> | null => {
  if (isVisaExtensionApplication(visaAppToCopyFrom)) {
    const data: Partial<ExtensionBySelfData> = { ...visaAppToCopyFrom.koushin_honnin };
    
    delete data["id"];
    delete data["updated_at"];
    delete data["created_at"];
    delete data["visa_application_id"];
    delete data["No."];
    delete data["WCICS010Dto:selCurrentZirySkk"] //Current status of residence
    delete data["WZAAS190Dto:chkHkeDui"] //Keep a copy of the application content
    delete data["selSnsiShbt"] //Application category
    delete data["selZirySkk"] //Status of residence

    return data;
  }

  return null;
};

export const extractReusableChangeBySelfData = (
  visaAppToCopyFrom: VisaApplication,
): Partial<ChangeBySelfData> | null => {
  if (isVisaChangeApplication(visaAppToCopyFrom)) {
    const data: Partial<ChangeBySelfData> = { ...visaAppToCopyFrom.henkou_honnin };

    delete data["id"];
    delete data["updated_at"];
    delete data["created_at"];
    delete data["visa_application_id"];
    delete data["No."];
    delete data["WCICS020Dto:selCurrentZirySkk"] //Current status of residence
    delete data["WZAAS200Dto:chkHkeDui"] //Keep a copy of the application content
    delete data["selSnsiShbt"] //Application category
    delete data["selZirySkk"] //Status of residence

    return data;
  }

  return null;
}

export const extractReusableSupplementaryData = (
  visaAppToCopyFrom: VisaApplication,
  formKind: FormKind
): Partial<SupplementaryInfo> | null => {
  if (!visaAppToCopyFrom.supplementary_info) 
    return null;

  const data: Partial<SupplementaryInfo> = {};

  switch (formKind) {
    case FormKind.R:
      data["is_this_by_representative"] = 
        visaAppToCopyFrom.supplementary_info.is_this_by_representative;
        break;
        
    //TODO: Add the rest of the cases
  }

  return isValueEmpty(data) ? null : data;
}