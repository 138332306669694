export enum SnackbarType {
  Success = 'success',
  Warning = 'warning',
  Neutral = 'neutral'
}

export type Snackbar = {
  message: string;
  type: SnackbarType;
  isShowing: boolean;
}