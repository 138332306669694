import { SelectOption } from "../../../types/option";
import { PeriodOfStayCode_CB20 } from "../../../types/visa/periodOfStay";
import { StatusOfResidenceCode_CB19 } from "../../../types/visa/statusOfResidence";

const getPeriodOfStayTKey = (key: string) => `periodOfStay_CB20.${key}`;

export const PERIOD_OF_STAY_CB20_OPTIONS_DIC: Record<
  string,
  SelectOption[]
> = {
  [StatusOfResidenceCode_CB19.Dependent]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeMonths),
      value: PeriodOfStayCode_CB20.ThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.SixMonths),
      value: PeriodOfStayCode_CB20.SixMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayCode_CB20.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYearThreeMonths),
      value: PeriodOfStayCode_CB20.OneYearThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.TwoYears),
      value: PeriodOfStayCode_CB20.TwoYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.TwoYearsThreeMonths),
      value: PeriodOfStayCode_CB20.TwoYearsThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayCode_CB20.ThreeYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYearsThreeMonths),
      value: PeriodOfStayCode_CB20.ThreeYearsThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FourYears),
      value: PeriodOfStayCode_CB20.FourYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FourYearsThreeMonths),
      value: PeriodOfStayCode_CB20.FourYearsThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayCode_CB20.FiveYears,
    },
  ],
  [StatusOfResidenceCode_CB19.Professor]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeMonths),
      value: PeriodOfStayCode_CB20.ThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayCode_CB20.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayCode_CB20.ThreeYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayCode_CB20.FiveYears,
    },
  ],
  [StatusOfResidenceCode_CB19.Artist]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeMonths),
      value: PeriodOfStayCode_CB20.ThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayCode_CB20.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayCode_CB20.ThreeYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayCode_CB20.FiveYears,
    },
  ],
  [StatusOfResidenceCode_CB19.ReligiousActivities]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeMonths),
      value: PeriodOfStayCode_CB20.ThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayCode_CB20.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayCode_CB20.ThreeYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayCode_CB20.FiveYears,
    },
  ],
  [StatusOfResidenceCode_CB19.Journalist]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeMonths),
      value: PeriodOfStayCode_CB20.ThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayCode_CB20.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayCode_CB20.ThreeYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayCode_CB20.FiveYears,
    },
  ],
  [StatusOfResidenceCode_CB19.LegalAccountingServices]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeMonths),
      value: PeriodOfStayCode_CB20.ThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayCode_CB20.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayCode_CB20.ThreeYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayCode_CB20.FiveYears,
    },
  ],
  [StatusOfResidenceCode_CB19.MedicalServices]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeMonths),
      value: PeriodOfStayCode_CB20.ThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayCode_CB20.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayCode_CB20.ThreeYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayCode_CB20.FiveYears,
    },
  ],
  [StatusOfResidenceCode_CB19.Researcher]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeMonths),
      value: PeriodOfStayCode_CB20.ThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayCode_CB20.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayCode_CB20.ThreeYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayCode_CB20.FiveYears,
    },
  ],
  [StatusOfResidenceCode_CB19.Instructor]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeMonths),
      value: PeriodOfStayCode_CB20.ThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayCode_CB20.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayCode_CB20.ThreeYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayCode_CB20.FiveYears,
    },
  ],
  [StatusOfResidenceCode_CB19.IntraCompanyTransferee]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeMonths),
      value: PeriodOfStayCode_CB20.ThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayCode_CB20.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayCode_CB20.ThreeYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayCode_CB20.FiveYears,
    },
  ],
  [StatusOfResidenceCode_CB19.Entertainer]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FifteenDays),
      value: PeriodOfStayCode_CB20.FifteenDays,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThirtyDays),
      value: PeriodOfStayCode_CB20.ThirtyDays,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeMonths),
      value: PeriodOfStayCode_CB20.ThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.SixMonths),
      value: PeriodOfStayCode_CB20.SixMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayCode_CB20.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayCode_CB20.ThreeYears,
    },
  ],
  [StatusOfResidenceCode_CB19.SkilledLabor]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeMonths),
      value: PeriodOfStayCode_CB20.ThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayCode_CB20.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayCode_CB20.ThreeYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayCode_CB20.FiveYears,
    },
  ],
  [StatusOfResidenceCode_CB19.BusinessManager]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeMonths),
      value: PeriodOfStayCode_CB20.ThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FourMonths),
      value: PeriodOfStayCode_CB20.FourMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayCode_CB20.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayCode_CB20.ThreeYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayCode_CB20.FiveYears,
    },
  ],
  [StatusOfResidenceCode_CB19.EngineerSpecialistInHumanitiesInternationalServices]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeMonths),
      value: PeriodOfStayCode_CB20.ThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayCode_CB20.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayCode_CB20.ThreeYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayCode_CB20.FiveYears,
    },
  ],
  [StatusOfResidenceCode_CB19.NursingCare]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeMonths),
      value: PeriodOfStayCode_CB20.ThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayCode_CB20.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayCode_CB20.ThreeYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayCode_CB20.FiveYears,
    },
  ],
  [StatusOfResidenceCode_CB19.CulturalActivities]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeMonths),
      value: PeriodOfStayCode_CB20.ThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.SixMonths),
      value: PeriodOfStayCode_CB20.SixMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayCode_CB20.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayCode_CB20.ThreeYears,
    },
  ],
  [StatusOfResidenceCode_CB19.Student]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeMonths),
      value: PeriodOfStayCode_CB20.ThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.SixMonths),
      value: PeriodOfStayCode_CB20.SixMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayCode_CB20.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYearThreeMonths),
      value: PeriodOfStayCode_CB20.OneYearThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.TwoYears),
      value: PeriodOfStayCode_CB20.TwoYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.TwoYearsThreeMonths),
      value: PeriodOfStayCode_CB20.TwoYearsThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayCode_CB20.ThreeYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYearsThreeMonths),
      value: PeriodOfStayCode_CB20.ThreeYearsThreeMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FourYears),
      value: PeriodOfStayCode_CB20.FourYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FourYearsThreeMonths),
      value: PeriodOfStayCode_CB20.FourYearsThreeMonths,
    },
  ],
  [StatusOfResidenceCode_CB19.SpouseOrChildOfJapaneseNational]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.SixMonths),
      value: PeriodOfStayCode_CB20.SixMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayCode_CB20.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayCode_CB20.ThreeYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayCode_CB20.FiveYears,
    },
  ],
  [StatusOfResidenceCode_CB19.SpouseOrChildOfPermanentResident]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.SixMonths),
      value: PeriodOfStayCode_CB20.SixMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayCode_CB20.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayCode_CB20.ThreeYears,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayCode_CB20.FiveYears,
    },
  ],
  [StatusOfResidenceCode_CB19.SpecifiedSkilledWorker_ii]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.SixMonths),
      value: PeriodOfStayCode_CB20.SixMonths,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.OneYear),
      value: PeriodOfStayCode_CB20.OneYear,
    },
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.ThreeYears),
      value: PeriodOfStayCode_CB20.ThreeYears,
    },
  ],
  [StatusOfResidenceCode_CB19.HighlySkilledProfessional_i_a]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayCode_CB20.FiveYears,
    },
  ],
  [StatusOfResidenceCode_CB19.HighlySkilledProfessional_i_b]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayCode_CB20.FiveYears,
    },
  ],
  [StatusOfResidenceCode_CB19.HighlySkilledProfessional_i_c]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.FiveYears),
      value: PeriodOfStayCode_CB20.FiveYears,
    },
  ],
  [StatusOfResidenceCode_CB19.HighlySkilledProfessional_ii]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.Unlimited),
      value: PeriodOfStayCode_CB20.Unlimited,
    },
  ],
  [StatusOfResidenceCode_CB19.PermanentResident]: [
    {
      label: getPeriodOfStayTKey(PeriodOfStayCode_CB20.Unlimited),
      value: PeriodOfStayCode_CB20.Unlimited,
    },
  ],
};