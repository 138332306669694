import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Section from '../../../components/layout/Section';
import MultiTextField from '../../../components/compound/MultiTextField';
import { useFieldInputNotes, useValidation } from '../../../hooks';
import FieldGroup from '../../../components/layout/FieldGroup';
import RadioField from '../../../components/compound/RadioField';
import InlineMessage from '../../../components/form/InlineMessage';
import styled from 'styled-components';
import TextField from '../../../components/compound/TextField';
import Button from '../../../components/form/Button';
import SelectField from '../../../components/compound/SelectField';
import { MAX_EMPLOYMENTS } from '../../../constants/values/studentVisaValues';
import FieldGroupNumber from '../../../components/display/FieldGroupNumber';
import { ReactComponent as PlusIcon } from '../../../assets/icon-plus.svg';
import { ReactComponent as TrashCanIcon } from '../../../assets/icon-trash-can-2.svg';
import { MUNICIPALITY_WITH_JA_VALUE_OPTIONS, PREFECTURE_WITH_JA_VALUE_OPTIONS } from '../../../constants/options';
import { SupplementaryInfo } from '../../../types/visa/supplementaryInfo';
import { FormR } from '../../../types/visa/uncommonFormParts/formR/data';
import Checkbox from '../../../components/form/Checkbox';
import { ApplicationFormSectionProps } from '../type';
import { useSectionErrorHandler } from '../hook';
import { getEmploymentKeyOf, getLengthOfEmployments } from '../../../utils/visaFormDataHelpers/dependentVisaformDataHelper';
import { ActivitySalaryType, EngagingInActivitiesOtherThanPermitted } from '../../../types/visa/uncommonFormParts/formR/value';
import { isNoFieldError } from '../../../utils/visaFormDataHelpers/commonVisaFormDataHelper';
import Text from '../../../components/text/Text';
import { read } from 'fs';

interface MethodOfSupportEtcSectionProps extends ApplicationFormSectionProps {
  formR: FormR | null;
  supplementaryInfo: SupplementaryInfo | null;
  onChangeSupplementaryInfo: (data: Partial<SupplementaryInfo>) => void;
  onChangeFormR: (data: Partial<FormR>) => void;
}

type Employment = {
  typeOfWork: string | null;
  placeOfEmployment: string | null;
  nameOfBranch: string | null;
  telephoneNumber: string | null;
  workTimePerWeek: string | null;
  salaryType: string | null;
  salaryYen: string | null;
}

type EmploymentError = {
  typeOfWork: string;
  placeOfEmployment: string;
  nameOfBranch: string;
  telephoneNumber: string;
  workTimePerWeek: string;
  salaryYen: string;
}

const MethodOfSupportContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Indent = styled.div`
  padding: 10px 0 0 20px;
`;

const EmploymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 20px;
  background-color: #EDEDED;
`;

const EmploymentHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const ActionButton = styled(Button).attrs({ variant: 'inline' })`
  width: auto;
  height: 40px;
  gap: 10px;
  margin: 0 auto;
  border-color: #ACACAC;
`;

const EmploymentDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;


const MethodOfSupportEtcSection: FunctionComponent<MethodOfSupportEtcSectionProps> = ({
  isActive,
  readOnly,
  formR,
  supplementaryInfo,
  onChangeFormR,
  onChangeSupplementaryInfo,
  onChangeSectionReadiness,
  showEmptyRequiredValueError
}) => {
  const { t: tMet } = useTranslation('translation', { keyPrefix: 'methodOfSupportEtcSection.methodOfSupport' });
  const { t: tAct } = useTranslation('translation', { keyPrefix: 'methodOfSupportEtcSection.activitiesOtherThanPermitted' });
  const { t: tRep } = useTranslation('translation', { keyPrefix: 'methodOfSupportEtcSection.legalRepresentative' });
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });
  const { t: tWithoutPrefix } = useTranslation();
  const { inputNotes, conditionalInputNotes } = useFieldInputNotes();
  const { createGetEmptyFieldError } = useSectionErrorHandler();
  const getEmptyError = createGetEmptyFieldError({ additionalCondition: showEmptyRequiredValueError });

  const getEmptyEmployment = (): Employment => ({
    typeOfWork: '',
    placeOfEmployment: '',
    nameOfBranch: '',
    telephoneNumber: '',
    workTimePerWeek: '',
    salaryType: '',
    salaryYen: ''
  });

  const getEmptyEmploymentError = (): EmploymentError => ({
    typeOfWork: '',
    placeOfEmployment: '',
    telephoneNumber: '',
    workTimePerWeek: '',
    salaryYen: '',
    nameOfBranch: ''
  });

  //Method of support
  const [isRelatives, setIsRelatives] = useState<boolean | null>();
  const [isRemittancesFromAbroad, setIsRemittancesFromAbroad] = useState<boolean | null>();
  const [isGuarantor, setIsGuarantor] = useState<boolean | null>();
  const [isOthers, setIsOthers] = useState<boolean | null>();
  const [others, setOthers] = useState<string>('');
  const [othersError, setOthersError] = useState<string>('');

  //Activities other than permitted
  const [engagingInActivities, setEngagingInActivities] = useState<string>('');
  const [employments, setEmployments] = useState<Employment[]>([]);
  const [employmentErrors, setEmploymentErrors] = useState<EmploymentError[]>(
    Array.from({ length: MAX_EMPLOYMENTS }, () => getEmptyEmploymentError())
  );

  //Legal representative
  const [isMadeByLegalRepresentative, setIsMadeByLegalRepresentative] = useState<boolean | null>();
  const [nameOfRepresentative, setNameOfRepresentative] = useState<string>('');
  const [repRelWithApplicant, setRepRelWithApplicant] = useState<string>('');
  const [repPrefecture, setRepPrefecture] = useState<string>('');
  const [repMunicipality, setRepMunicipality] = useState<string>('');
  const [repTownStreetApartment, setRepTownStreetApartment] = useState<string>('');
  const [repTelephoneNumber, setRepTelephoneNumber] = useState<string>('');
  const [repCellphoneNumber, setRepCellphoneNumber] = useState<string>('');
  const [nameOfRepresentativeError, setNameOfRepresentativeError] = useState<string>('');
  const [repRelWithApplicantError, setRepRelWithApplicantError] = useState<string>('');
  const [repTownStreetApartmentError, setRepTownStreetApartmentError] = useState<string>('');
  const [repCellphoneNumberError, setRepCellphoneNumberError] = useState<string>('');
  const [repTelephoneNumberError, setRepTelephoneNumberError] = useState<string>('');

  const validation = useValidation();
  const showMethodOfSupportError = [
    isRelatives,
    isRemittancesFromAbroad,
    isGuarantor,
    isOthers
  ].every(val => !val) && showEmptyRequiredValueError;

  //Currently, the item names for this section are common between visa extension and change,
  const saveData = (data: Partial<FormR>) => {
    onChangeFormR(data);
  }
  
  const updateEmployment = (index: number, fieldName: keyof Employment, val: string | null
  ) => {
    setEmployments(prev =>
      prev.map((emp, i) => i === index 
        ? { ...emp, [fieldName]: val } 
        : emp)
    );
  };

  const updateEmploymentError = (index: number, fieldName: keyof EmploymentError, val: string) => {
    setEmploymentErrors(prev =>
      prev.map((err, i) => i === index 
        ? { ...err, [fieldName]: val } 
        : err)
    );
  };

  const onClickAddEmployment = () => {
    const nextIndex = employments.length + 1;

    setEmployments(prev => [...prev, getEmptyEmployment()]);

    saveData({
      [getEmploymentKeyOf.typeOfWork(nextIndex)]: '',
      [getEmploymentKeyOf.placeOfEmployment(nextIndex)]: '',
      [getEmploymentKeyOf.nameOfBranch(nextIndex)]: '',
      [getEmploymentKeyOf.telephoneNumber(nextIndex)]: '',
      [getEmploymentKeyOf.workTimePerWeek(nextIndex)]: '',
      [getEmploymentKeyOf.salaryType(nextIndex)]: '',
      [getEmploymentKeyOf.salaryYen(nextIndex)]: ''
    })
  }

  const onClickRemoveEmployment = (curEmpItemIndex: number) => {
    let data: Partial<FormR> = {};

    for (let i = curEmpItemIndex; i < MAX_EMPLOYMENTS; i++) {
      const nextEmpDataInLocalState: Employment = employments[i + 1] ?? {
        typeOfWork: null,
        placeOfEmployment: null,
        nameOfBranch: null,
        telephoneNumber: null,
        workTimePerWeek: null,
        salaryType: null,
        salaryYen: null
      };
      const nextEmpDataInDB: Employment = {
        typeOfWork: formR?.[getEmploymentKeyOf.typeOfWork(i + 1)] as string ?? null,
        placeOfEmployment: formR?.[getEmploymentKeyOf.placeOfEmployment(i + 1)] as string ?? null,
        nameOfBranch: formR?.[getEmploymentKeyOf.nameOfBranch(i + 1)] as string ?? null,
        telephoneNumber: formR?.[getEmploymentKeyOf.telephoneNumber(i + 1)] as string ?? null,
        workTimePerWeek: formR?.[getEmploymentKeyOf.workTimePerWeek(i + 1)] as string ?? null,
        salaryType: formR?.[getEmploymentKeyOf.salaryType(i + 1)] as string ?? null,
        salaryYen: formR?.[getEmploymentKeyOf.salaryYen(i + 1)] as string ?? null
      };
      const nextEmploymentError: EmploymentError = 
        employmentErrors[i + 1] ?? getEmptyEmploymentError();

      updateEmployment(i, 'typeOfWork', nextEmpDataInLocalState.typeOfWork);
      updateEmployment(i, 'placeOfEmployment', nextEmpDataInLocalState.placeOfEmployment);
      updateEmployment(i, 'nameOfBranch', nextEmpDataInLocalState.nameOfBranch);
      updateEmployment(i, 'workTimePerWeek', nextEmpDataInLocalState.workTimePerWeek);
      updateEmployment(i, 'telephoneNumber', nextEmpDataInLocalState.telephoneNumber);
      updateEmployment(i, 'salaryType', nextEmpDataInLocalState.salaryType);
      updateEmployment(i, 'salaryYen', nextEmpDataInLocalState.salaryYen);

      updateEmploymentError(i, 'typeOfWork', nextEmploymentError.typeOfWork);
      updateEmploymentError(i, 'placeOfEmployment', nextEmploymentError.placeOfEmployment);
      updateEmploymentError(i, 'nameOfBranch', nextEmploymentError.nameOfBranch);
      updateEmploymentError(i, 'telephoneNumber', nextEmploymentError.telephoneNumber);
      updateEmploymentError(i, 'workTimePerWeek', nextEmploymentError.workTimePerWeek);
      updateEmploymentError(i, 'salaryYen', nextEmploymentError.salaryYen);

      data = {
        ...data,
        [getEmploymentKeyOf.typeOfWork(i + 1)]: 
          nextEmploymentError.typeOfWork
            ? nextEmpDataInDB.typeOfWork
            : nextEmpDataInLocalState.typeOfWork,

        [getEmploymentKeyOf.placeOfEmployment(i + 1)]: 
          nextEmploymentError.placeOfEmployment
            ? nextEmpDataInDB.placeOfEmployment
            : nextEmpDataInLocalState.placeOfEmployment,

        [getEmploymentKeyOf.nameOfBranch(i + 1)]: 
          nextEmploymentError.nameOfBranch
            ? nextEmpDataInDB.nameOfBranch
            : nextEmpDataInLocalState.nameOfBranch,

        [getEmploymentKeyOf.telephoneNumber(i + 1)]: 
          nextEmploymentError.telephoneNumber
            ? nextEmpDataInDB.telephoneNumber
            : nextEmpDataInLocalState.telephoneNumber,

        [getEmploymentKeyOf.workTimePerWeek(i + 1)]: 
          nextEmploymentError.workTimePerWeek
            ? nextEmpDataInDB.workTimePerWeek
            : nextEmpDataInLocalState.workTimePerWeek,

        [getEmploymentKeyOf.salaryType(i + 1)]: 
          nextEmpDataInLocalState.salaryType,

        [getEmploymentKeyOf.salaryYen(i + 1)]: 
          nextEmploymentError.salaryYen
            ? nextEmpDataInDB.salaryYen
            : nextEmpDataInLocalState.salaryYen
      }
    }

    setEmployments(prev => prev.slice(0, prev.length - 1));
    setEmploymentErrors(prev => [...prev.slice(0, prev.length - 1), getEmptyEmploymentError()]);

    saveData(data);
  }

  useEffect(() => {
    if (!formR)
      return;

    setEngagingInActivities(formR.other_activities_yes_or_no ?? '');
    setIsRelatives(formR.method_of_support_relatives_exists);
    setIsRemittancesFromAbroad(formR.method_of_support_remittances_from_abroad_exists);
    setIsGuarantor(formR.method_of_support_guarantor_exists);
    setIsOthers(formR.method_of_support_others_exists);

    if (isNoFieldError(othersError))
      setOthers(formR.method_of_support_others_detail ?? '');

    const emps: Employment[] = [];
    const empsLen = getLengthOfEmployments(formR);
    for (let i = 1; i <= empsLen; i++) {
      emps.push({
        typeOfWork: isNoFieldError(employmentErrors[i - 1]?.typeOfWork)
          ? formR[getEmploymentKeyOf.typeOfWork(i)] as string ?? ''
          : employments[i - 1]?.typeOfWork ?? '',

        placeOfEmployment: isNoFieldError(employmentErrors[i - 1]?.placeOfEmployment)
          ? formR[getEmploymentKeyOf.placeOfEmployment(i)] as string ?? ''
          : employments[i - 1]?.placeOfEmployment ?? '',

        nameOfBranch: isNoFieldError(employmentErrors[i - 1]?.nameOfBranch)
          ? formR[getEmploymentKeyOf.nameOfBranch(i)] as string ?? ''
          : employments[i - 1]?.nameOfBranch ?? '',

        telephoneNumber: isNoFieldError(employmentErrors[i - 1]?.telephoneNumber)
          ? formR[getEmploymentKeyOf.telephoneNumber(i)] as string ?? '':
          employments[i - 1]?.telephoneNumber ?? '',

        workTimePerWeek: isNoFieldError(employmentErrors[i - 1]?.workTimePerWeek)
          ? formR[getEmploymentKeyOf.workTimePerWeek(i)] as string ?? ''
          : employments[i - 1]?.workTimePerWeek ?? '',

        salaryType: formR[getEmploymentKeyOf.salaryType(i)] as string ?? '',

        salaryYen: isNoFieldError(employmentErrors[i - 1]?.salaryYen)
          ? formR[getEmploymentKeyOf.salaryYen(i)] as string ?? ''
          : employments[i - 1]?.salaryYen ?? ''
      });
    }
    setEmployments(emps);

    if (isNoFieldError(nameOfRepresentativeError))
      setNameOfRepresentative(formR.representative_name ?? '');

    if (isNoFieldError(repRelWithApplicantError))
      setRepRelWithApplicant(formR.representative_relationship ?? '');

    setRepPrefecture(formR.representative_address_prefecture ?? '');
    setRepMunicipality(formR.representative_address_municipality ?? '');

    if (isNoFieldError(repTownStreetApartmentError))
      setRepTownStreetApartment(formR.representative_address_street ?? '');

    if (isNoFieldError(repCellphoneNumberError))
      setRepCellphoneNumber(formR.representative_cellular_phone_no ?? '');

    if (isNoFieldError(repTelephoneNumberError))
      setRepTelephoneNumber(formR.representative_telephone_no ?? '');    
  }, [formR]);

  useEffect(() => {
    if (!supplementaryInfo)
      return;

    setIsMadeByLegalRepresentative(supplementaryInfo.is_this_by_representative);
  }, [supplementaryInfo?.is_this_by_representative]);

  useEffect(() => {
    const isMethodOfSupportSelected = [
      isRelatives,
      isRemittancesFromAbroad,
      isGuarantor,
      isOthers
    ].some(val => val);

    const isOthersValueOk = isOthers ? !!others : true;

    const areAllRequiredFieldsFilled = [
      engagingInActivities,
      isMadeByLegalRepresentative,
    ].every(val => val != null);

    const areThereNoErrors = [
      othersError,
      ...employmentErrors.flatMap(err => Object.values(err)),
      nameOfRepresentativeError,
      repRelWithApplicantError,
      repTownStreetApartmentError,
      repCellphoneNumberError,
      repTelephoneNumberError,
    ].every(val => !val);

    onChangeSectionReadiness(
      isMethodOfSupportSelected &&
      isOthersValueOk &&
      areThereNoErrors &&
      areAllRequiredFieldsFilled 
    );
  }, [
    isRelatives,
    isRemittancesFromAbroad,
    isGuarantor,
    isOthers,
    others,
    engagingInActivities,
    isMadeByLegalRepresentative,
    othersError,
    employmentErrors,
    nameOfRepresentativeError,
    repRelWithApplicantError,
    repTownStreetApartmentError,
    repCellphoneNumberError,
    repTelephoneNumberError,
  ]);

  if (isActive === false)
    return null;

  return (
    <Section>
      {/* Method of support */}
      <FieldGroup
        required
        title={tMet("title")}
        theme="light"
        error={
          getEmptyError([
            isRelatives,
            isRemittancesFromAbroad,
            isGuarantor,
            isOthers
          ].filter(val => val === true)) 
        }
      >
        <MethodOfSupportContainer>
          {/* Relatives */}
          <Checkbox 
            disabled={readOnly}
            checked={!!isRelatives}
            error={showMethodOfSupportError}
            onValueChange={val => {
              setIsRelatives(val);
              saveData({ method_of_support_relatives_exists: val });
            }}
          >
            {tMet("relatives")}
          </Checkbox>

          {/* Remittances from abroad */}
          <Checkbox 
            disabled={readOnly}
            checked={!!isRemittancesFromAbroad}
            error={showMethodOfSupportError}
            onValueChange={val => {
              setIsRemittancesFromAbroad(val);
              saveData({ method_of_support_remittances_from_abroad_exists: val });
            }}
          >
            {tMet("remittancesFromAbroad")}
          </Checkbox>

          {/* Guarantor */}
          <Checkbox 
            disabled={readOnly}
            checked={!!isGuarantor}
            error={showMethodOfSupportError}
            onValueChange={val => {
              setIsGuarantor(val);
              saveData({ method_of_support_guarantor_exists: val });
            }}
          >
            {tMet("guarantor")}
          </Checkbox>

          {/* Others */}
          <div>
            <Checkbox 
              disabled={readOnly}
              checked={!!isOthers}
              error={showMethodOfSupportError}
              onValueChange={val => {
                const data: Partial<FormR> = {
                  method_of_support_others_exists: val
                };

                if (!val) {
                  setOthers('');
                  data.method_of_support_others_detail = '';
                }

                setIsOthers(val);
                saveData(data);
              }}
            >
              {tMet("others")}
            </Checkbox>

            { isOthers &&
              <Indent>
                <MultiTextField
                  disabled={readOnly}
                  maxLength={40}
                  validators={[validation.createLengthValidator(40)]}
                  value={others}
                  error={getEmptyError(others)}
                  onValueChange={setOthers}
                  onErrorChange={setOthersError}
                  onBlur={() => {
                    saveData({ method_of_support_others_detail: others });
                  }}
                />
              </Indent>
            }
          </div>
        </MethodOfSupportContainer>
      </FieldGroup>


      {/* Activities other than permitted */}
      <FieldGroup
        required
        title={tAct("title")}
        theme="light"
      >
        <RadioField
          disabled={readOnly}
          label={tAct("areYouEngagingInActivities")}
          value={engagingInActivities}
          error={getEmptyError(engagingInActivities)}
          onValueChange={val => {
            let data: Partial<FormR> = {
              other_activities_yes_or_no: val
            };
            
            if (val === EngagingInActivitiesOtherThanPermitted.No) {
              setEmployments([]);
              setEmploymentErrors(
                Array.from({ length: MAX_EMPLOYMENTS }, () => getEmptyEmploymentError())
              );

              for (let i = 1; i <= MAX_EMPLOYMENTS; i++) {
                data = {
                  ...data,
                  [getEmploymentKeyOf.typeOfWork(i)]: null,
                  [getEmploymentKeyOf.placeOfEmployment(i)]: null,
                  [getEmploymentKeyOf.nameOfBranch(i)]: null,
                  [getEmploymentKeyOf.telephoneNumber(i)]: null,
                  [getEmploymentKeyOf.workTimePerWeek(i)]: null,
                  [getEmploymentKeyOf.salaryType(i)]: null,
                  [getEmploymentKeyOf.salaryYen(i)]: null
                }
              }
            }
            
            setEngagingInActivities(val);
            saveData(data);
          }}
          options={[
            { 
              label: tCommon("yes"), 
              value: EngagingInActivitiesOtherThanPermitted.Yes 
            },
            { 
              label: tCommon("no"), 
              value: EngagingInActivitiesOtherThanPermitted.No 
            }
          ]}
        />

        { engagingInActivities === EngagingInActivitiesOtherThanPermitted.Yes && (
          <>       
            <InlineMessage>
              {tAct("noteOnSegment")}
            </InlineMessage>

            { employments.map((emp, index) => (
              <EmploymentContainer key={`employment-${index + 1}`}>
                <EmploymentHeader>
                  <FieldGroupNumber>
                    {index + 1}
                  </FieldGroupNumber>
                  
                  { !readOnly &&
                    <ActionButton 
                      onClick={() => onClickRemoveEmployment(index)}
                    >
                      <TrashCanIcon />
                      {tAct("remove")}
                    </ActionButton>
                  }
                </EmploymentHeader>

                <EmploymentDetail>
                  {/* Type of work */}
                  <MultiTextField
                    disabled={readOnly}
                    label={tAct("typeOfWork")}
                    value={emp.typeOfWork ?? ''}
                    onValueChange={val => updateEmployment(index, 'typeOfWork', val)}
                    error={employmentErrors[index].typeOfWork}
                    onErrorChange={newErr => updateEmploymentError(index, 'typeOfWork', newErr)}
                    maxLength={40}
                    validators={[validation.createLengthValidator(40)]}
                    onBlur={() => {
                      saveData({
                        [getEmploymentKeyOf.typeOfWork(index + 1)]: emp.typeOfWork
                      });
                    }}
                  />

                  {/* Place of employment */}
                  <MultiTextField
                    compact
                      disabled={readOnly}
                    label={tAct("placeOfEmployment")}
                    placeholder='Tokuty Inc.'
                    value={emp.placeOfEmployment ?? ''}
                    onValueChange={val => {
                      updateEmployment(index, 'placeOfEmployment', val);
                    }}
                    error={employmentErrors[index].placeOfEmployment}
                    onErrorChange={newErr => {
                      updateEmploymentError(index, 'placeOfEmployment', newErr);
                    }}
                    maxLength={60}
                    validators={[validation.createLengthValidator(60)]}
                    onBlur={() => {
                      saveData({
                        [getEmploymentKeyOf.placeOfEmployment(index + 1)]: emp.placeOfEmployment
                      });
                    }}
                  />

                  {/* Name of branch */}
                  <MultiTextField
                    compact
                    disabled={readOnly}
                    label={tAct("nameOfBranch")}
                    placeholder="Tokuty Inc."
                    value={emp.nameOfBranch ?? ''}
                    onValueChange={val => {
                      updateEmployment(index, 'nameOfBranch', val);
                    }}
                    error={employmentErrors[index].nameOfBranch}
                    onErrorChange={newErr => {
                      updateEmploymentError(index, 'nameOfBranch', newErr);
                    }}
                    maxLength={60}
                    validators={[validation.createLengthValidator(60)]}
                    onBlur={() => {
                      saveData({
                        [getEmploymentKeyOf.nameOfBranch(index + 1)]: emp.nameOfBranch
                      });
                    }}
                  />

                  {/* Telephone number */}
                  <TextField
                    disabled={readOnly}
                    label={tAct("telephoneNumber")}
                    placeholder="012345678901"
                    value={emp.telephoneNumber ?? ''}
                    onValueChange={val => {
                      updateEmployment(index, 'telephoneNumber', val);
                    }}
                    error={employmentErrors[index].telephoneNumber}
                    onErrorChange={newErr => {
                      updateEmploymentError(index, 'telephoneNumber', newErr);
                    }}
                    maxLength={12}
                    validators={[validation.createLengthValidator(12), validation.isNumeric]}
                    restriction={inputNotes.halfwidthNumberNoHyphens}
                    onBlur={() => {
                      saveData({
                        [getEmploymentKeyOf.telephoneNumber(index + 1)]: emp.telephoneNumber
                      });
                    }}
                  />

                  {/* Work time per week (Hours) */}
                  <TextField
                    disabled={readOnly}
                    label={tAct("workTimePerWeek")}
                    placeholder="12"
                    value={emp.workTimePerWeek ?? ''}
                    onValueChange={val => {
                      updateEmployment(index, 'workTimePerWeek', val);
                    }}
                    error={employmentErrors[index].workTimePerWeek}
                    onErrorChange={newErr => {
                      updateEmploymentError(index, 'workTimePerWeek', newErr);
                    }}
                    maxLength={4}
                    validators={[validation.createLengthValidator(4), validation.isNumeric]}
                    restriction={conditionalInputNotes.halfwidthNumber}
                    onBlur={() => {
                      saveData({
                        [getEmploymentKeyOf.workTimePerWeek(index + 1 )]: emp.workTimePerWeek
                      });
                    }}
                  />

                  {/* Salary */}
                  <RadioField
                    disabled={readOnly}
                    label={tAct("salary")}
                    options={[
                      { 
                        label: tAct("monthly"), 
                        value: ActivitySalaryType.Monthly
                      },
                      { 
                        label: tAct("daily"), 
                        value: ActivitySalaryType.Daily
                      }
                    ]}
                    value={emp.salaryType}
                    onValueChange={val => {
                      updateEmployment(index, 'salaryType', val);
                      saveData({
                        [getEmploymentKeyOf.salaryType(index + 1)]: val
                      });
                    }}
                  />

                  {/* Salary (Yen) */}
                  { !!emp.salaryType &&
                    <TextField
                      disabled={readOnly}
                      label={tAct("salaryYen")}
                      placeholder="0"
                      value={emp.salaryYen ?? ''}
                      onValueChange={val => {
                        updateEmployment(index, 'salaryYen', val);
                      }}
                      error={employmentErrors[index].salaryYen}
                      onErrorChange={newErr => {
                        updateEmploymentError(index, 'salaryYen', newErr);
                      }}
                      maxLength={5}
                      validators={[validation.createLengthValidator(5), validation.isNumeric]}
                      restriction={conditionalInputNotes.halfwidthNumber}
                      onBlur={() => {
                        saveData({
                          [getEmploymentKeyOf.salaryYen(index + 1)]: emp.salaryYen
                        });
                      }}
                      suffixComponent={<Text><strong>,000</strong></Text>}
                      note={tAct("salaryYenNote")}
                    />
                  }

                </EmploymentDetail>
              </EmploymentContainer>
            ))}

            { employments.length < MAX_EMPLOYMENTS &&
              !readOnly &&
              <ActionButton onClick={() => onClickAddEmployment()}>
                <PlusIcon />
                {tAct("addEmployment")}
              </ActionButton>
            }
          </>
        )}
      </FieldGroup>


      {/* Legal representative */}
      <FieldGroup
        required
        title={tRep("title")}
        theme="light"
      >
        {/* Is this application being made by a legal representative */}
        <RadioField
          disabled={readOnly}
          label={tRep("madeByLegalRepresentative")}
          options={[
            { 
              label: tCommon("yes"), 
              value: true
            },
            { 
              label: tCommon("no"), 
              value: false
            }
          ]}
          value={isMadeByLegalRepresentative}
          error={getEmptyError(isMadeByLegalRepresentative)}
          onValueChange={val => {
            const isMadeByLegalRep = val === 'true';

            if (!isMadeByLegalRep) {
              setNameOfRepresentativeError('');
              setRepRelWithApplicantError('');
              setRepTownStreetApartmentError('');
              setRepCellphoneNumberError('');
              setRepTelephoneNumberError('');
              
              saveData({
                representative_name: null,
                representative_relationship: null,
                representative_address_prefecture: null,
                representative_address_municipality: null,
                representative_address_street: null,
                representative_telephone_no: null,
                representative_cellular_phone_no: null
              })
            }
            
            setIsMadeByLegalRepresentative(isMadeByLegalRep);
            onChangeSupplementaryInfo({ is_this_by_representative: isMadeByLegalRep });
          }}
        />

        { isMadeByLegalRepresentative && (
          <>
            {/* Name of representative */}
            <MultiTextField
              compact
              disabled={readOnly}
              label={tRep("name")}
              value={nameOfRepresentative}
              onValueChange={setNameOfRepresentative}
              error={nameOfRepresentativeError}
              onErrorChange={setNameOfRepresentativeError}
              maxLength={26}
              validators={[validation.createLengthValidator(26)]}
              onBlur={() => {
                saveData({ representative_name: nameOfRepresentative });
              }}
            />

            {/* Relationship with the applicant */}
            <MultiTextField
              disabled={readOnly}
              label={tRep("relationship")}
              value={repRelWithApplicant}
              onValueChange={setRepRelWithApplicant}
              error={repRelWithApplicantError}
              onErrorChange={setRepRelWithApplicantError}
              maxLength={40}
              restriction={inputNotes.fullwidthJapanese}
              validators={[validation.createLengthValidator(40), validation.isFullwidth]}
              onBlur={() => {
                saveData({ 
                  representative_relationship: repRelWithApplicant 
                });
              }}
            />

            {/* Prefecture */}
            <SelectField
              disabled={readOnly}
              label={tWithoutPrefix('common.prefecture')}
              placeholder={tWithoutPrefix("placeholder.select")}
              options={PREFECTURE_WITH_JA_VALUE_OPTIONS}
              value={repPrefecture}
              onValueChange={val => {
                setRepPrefecture(val);
                setRepMunicipality('');
                saveData({
                  representative_address_prefecture: val,
                  representative_address_municipality: null
                });
              }}
            />

            {/* Municipality */}
            <SelectField
              disabled={!repPrefecture || readOnly}
              label={tWithoutPrefix('common.municipality')}
              options={MUNICIPALITY_WITH_JA_VALUE_OPTIONS[repPrefecture] ?? []}
              placeholder={
                repPrefecture 
                  ? tWithoutPrefix('placeholder.select')
                  : ''
              }
              value={repMunicipality}
              onValueChange={val => {
                setRepMunicipality(val);
                saveData({ representative_address_municipality: val });
              }}
              error={(() => {
                if (readOnly)
                  return '';

                if (!repPrefecture)
                  return tRep("enterPrefectureFirst");

                return '';
              })()}
            />

            {/* Town/street/apartment */}
            <MultiTextField
              disabled={readOnly}
              label={tWithoutPrefix('common.townStreetApartment')}
              placeholder="品川1-2-3ビザダスビル3F"
              value={repTownStreetApartment}
              onValueChange={setRepTownStreetApartment}
              error={repTownStreetApartmentError}
              onErrorChange={setRepTownStreetApartmentError}
              maxLength={80}
              validators={[validation.createLengthValidator(80)]}
              onBlur={() => {
                saveData({ representative_address_street: repTownStreetApartment });
              }}
            />

            {/* Telephone number */}
            <TextField
              disabled={readOnly}
              label={tWithoutPrefix('common.telephoneNumber')}
              placeholder="01234567901"
              value={repTelephoneNumber}
              onValueChange={setRepTelephoneNumber}
              error={repTelephoneNumberError}
              onErrorChange={setRepTelephoneNumberError}
              maxLength={12}
              validators={[validation.createLengthValidator(12), validation.isNumeric]}
              restriction={inputNotes.halfwidthNumberNoHyphens}
              onBlur={() => {
                saveData({ representative_telephone_no: repTelephoneNumber });
              }}
            />

            {/* Cellphone number */}
            <TextField
              disabled={readOnly}
              label={tWithoutPrefix('common.cellphoneNumber')}
              placeholder="1234567891234"
              value={repCellphoneNumber}
              onValueChange={setRepCellphoneNumber}
              error={repCellphoneNumberError}
              onErrorChange={setRepCellphoneNumberError}
              maxLength={12}
              validators={[validation.createLengthValidator(12), validation.isNumeric]}
              restriction={inputNotes.halfwidthNumberNoHyphens}
              onBlur={() => {
                saveData({ representative_cellular_phone_no: repCellphoneNumber });
              }}
            />
          </>
        )}
      </FieldGroup>
    </Section>
  );
};

export default MethodOfSupportEtcSection;
