import { SelectOption } from "../../../types/option";
const getTkey = (key: string) => `desiredPeriodOfStay_C970.${key}`;

export const DESIRED_PERIOD_OF_STAY_C970_OPTIONS_FOR_GIJINKOKU: SelectOption[] = [
    {
        label: getTkey("T260003000"),
        value: "T260003000"
    },
    {
        label: getTkey("T260100000"),
        value: "T260100000"
    },
    {
        label: getTkey("T260300000"),
        value: "T260300000"
    },
    {
        label: getTkey("T260500000"),
        value: "T260500000"
    }
];
