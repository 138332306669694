import { FunctionComponent } from "react";
import { LocalColumn, VisaCategoryHeading } from "./previewBodyCommonStyle";
import { PreviewModalBodyProps } from "../../../../types/modal/preview";
import {
  isChangeBySelfData,
  isExtensionBySelfData,
  isThisVisaChange,
  isThisVisaExtension,
} from "../../../../utils/visaApplicationHelper";
import BasicInformationSection from "./previewBodySections/ApplicantBlock/BasicInformationSection";
import StatusOfResidenceSection from "./previewBodySections/ApplicantBlock/StatusOfResidenceSection";
import AttachmentsSection from "./previewBodySections/ApplicantBlock/AttachmentsSection/AttachmentsSection";
import OtherSection from "./previewBodySections/ApplicantBlock/OtherSection";
import DetailsOfExtensionSection from "./previewBodySections/ApplicantBlock/DetailsOfExtensionSection";
import FamilyCoresidentsSection from "./previewBodySections/ApplicantBlock/FamilyCoresidentsSection";
import DetailsOfChangeSection from "./previewBodySections/ApplicantBlock/DetailsOfChangeSection";
import PlaceAndDateOfNotificationSection from "./previewBodySections/ApplicantBlock/PlaceAndDateOfNotificationSection";
import MethodOfSupportEtcSection from "./previewBodySections/ApplicantBlock/MethodOfSupportEtcSection";
import SupporterSection from "./previewBodySections/ApplicantBlock/SupporterSection";
import { useTranslation } from "react-i18next";

interface DependentVisaPreviewBodyProps extends PreviewModalBodyProps {}

const DependentVisaPreviewBody: FunctionComponent<DependentVisaPreviewBodyProps> = ({
  visaApplication,
  visaApplicationType,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'modalPreview.applicationType' });
  const isVisaExtension = isThisVisaExtension(visaApplicationType);
  const isVisaChange = isThisVisaChange(visaApplicationType);
  const commonData = isVisaExtension
    ? visaApplication.koushin_honnin
    : visaApplication.henkou_honnin;
  const unCommonData = visaApplication.form_r;
  const supplementaryInfo = visaApplication.supplementary_info;
  
  if (!commonData || !unCommonData || !supplementaryInfo)
    return null;


  return (
    <LocalColumn>
      <VisaCategoryHeading>
        {isVisaExtension && t('dependentExtension')}
        {isVisaChange && t('dependentChange')}
      </VisaCategoryHeading>

      <BasicInformationSection 
        visaApplication={visaApplication}
        data={commonData} 
      />

      <StatusOfResidenceSection
        visaApplicationType={visaApplicationType}
        data={commonData}
      />

      {isVisaExtension && isExtensionBySelfData(commonData) && (
        <DetailsOfExtensionSection
          data={commonData}
          visaApplicationType={visaApplicationType}
        />
      )}

      {isVisaChange && isChangeBySelfData(commonData) && (
        <DetailsOfChangeSection
          data={commonData}
          visaApplicationType={visaApplicationType}
        />
      )}

      <FamilyCoresidentsSection data={commonData} />

      <PlaceAndDateOfNotificationSection data={unCommonData} />

      <MethodOfSupportEtcSection 
        data={unCommonData} 
        supplementaryInfo={supplementaryInfo}
      />

      <SupporterSection data={unCommonData} />

      <OtherSection visaApplicationType={visaApplicationType} data={commonData} />

      <AttachmentsSection
        visaApplication={visaApplication}
        visaApplicationType={visaApplicationType}
        visaAttachments={visaApplication.visa_attachments}
      />
    </LocalColumn>
  );
};

export default DependentVisaPreviewBody;
