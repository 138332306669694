import React, { FunctionComponent, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as InfomationIcon } from '../../assets/icon-info.svg';
import { ReactComponent as WarningIcon } from '../../assets/icon-warning-red.svg';

interface InlineMessageProps extends HTMLAttributes<HTMLDivElement> {
    variant?: 'info' | 'caution' | 'alert' | 'success';
    size?: 'small' | 'base';
    customIcon?: React.ReactNode;
}

const Container = styled.div<InlineMessageProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: ${(props: InlineMessageProps) => props.size === 'small' ? '5px 10px' : '10px'};
  border-radius: 2px;
  box-sizing: border-box;
  color: ${(props: InlineMessageProps) => {
    switch (props.variant) {
      case 'success':
        return '#1FA44F';
      default:
        return '#444444';
    }
  }};
  background: ${(props: InlineMessageProps) => {
    switch (props.variant) {
      case 'info':
        return props.size  === 'small'? '#F7F7F7' : '#EEEEEE';
      case 'caution':
        return '#FFEDB2';
      case 'alert':
        return '#FFE0E0';
      case 'success':
        return '#DBF0E3';
      default:
        return '#EEEEEE';
    }
  }};
  font-weight: 400;
  font-size: ${(props: InlineMessageProps) => props.size === 'small' ? '0.8rem' : '0.9rem'};
  line-height: ${(props: InlineMessageProps) => props.size === 'small' ? '1.063rem' : '1.3rem'};
`;

const Icon = css`
  max-width: 20px;
  max-height: 20px;
  min-width: 20px;
  min-height: 20px;
`;

const InfoIcon = styled(InfomationIcon)`
  ${Icon}
`;

const SuccessIcon = styled(InfoIcon)`
  ${Icon}
  & * {
    fill: #1FA44F;
  }
`;

const AlertIcon = styled(WarningIcon)`
  ${Icon}
`;

const InlineMessage: FunctionComponent<InlineMessageProps> = ({
    children,
    customIcon,
    ...props
}) => {
    const Icon = (() => {
      if (customIcon) 
        return customIcon;
      
      switch (props.variant) {
        case 'alert':
          return <AlertIcon />;
        case 'success':
          return <SuccessIcon />;
        default:
          return <InfoIcon />;
      }
    })();

    return (
        <Container {...props}>
            {Icon}
            {children}
        </Container>
    );
};

export default InlineMessage;