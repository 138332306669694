import { SelectOption } from "../../../types/option";

const getTKey = (key: string) => `periodOfStay_CB20.${key}`;

export const APPLICANT_PERIOD_OF_STAY_OPTIONS_FOR_GIJINKOKU: SelectOption[] = [
  {
      label: getTKey("0003000"),
      value: "0003000"
  },
  {
      label: getTKey("0100000"),
      value: "0100000"
  },
  {
      label: getTKey("0300000"),
      value: "0300000"
  },
  {
      label: getTKey("0500000"),
      value: "0500000"
  }
];
