import {
  ALL_NATIONALITY_OPTIONS,
  FOREIGNER_NATIONALITY_OPTIONS,
  MUNICIPALITY_OPTIONS,
  MUNICIPALITY_WITH_JA_VALUE_OPTIONS,
  PREFECTURE_OPTIONS,
  PREFECTURE_WITH_JA_VALUE_OPTIONS,
  RELATIONSHIP_CB06_OPTIONS_WITHOUT_SELF,
  RELATIONSHIP_WITH_JA_VALUE_OPTIONS_WITHOUT_SELF,
} from "../../constants/options";
import { AIReadingData, DependentAIReadingData } from "../../types/visa/aIReadingData";
import { ChangeBySelfData } from "../../types/visa/changeBySelf/data";
import { ExtensionBySelfData } from "../../types/visa/extensionBySelf/data";
import { FamilyInJapanOrCoresidents, Gender, LivingTogetherWithThisFamilyOrCoresident, MaritalStatus } from "../../types/visa/formCommonValues";
import { StatusOfResidenceCode_CB19, StatusOfResidenceInJapanese } from "../../types/visa/statusOfResidence";
import { FormR } from "../../types/visa/uncommonFormParts/formR/data";
import { formatDate } from "../../utils/utils";

type ZincSnzk = {
  selNationalityAndRegion: number | null;
  txtName: string | null;
  selDateOfBirthYear: string | null;
  selDateOfBirthMonth: string | null;
  selDateOfBirthDay: string | null;
  selZkgr: string | null;
  radDukyUm: string | null;
  txtWorkPlaceOrTugkskName: string | null;
  txtZiryCardNumOrTkeiNum: string | null;
};

export type CommonFormData = ChangeBySelfData & ExtensionBySelfData & Partial<{
  "WCICS020Dto:txtSyst": string | null;
  "WCICS020Dto:selCurrentZirySkk": string | null;
  "WCICS010Dto:selExpirationDateYear": string | null;
  "WCICS010Dto:selExpirationDateMonth": string | null;
  "WCICS010Dto:selExpirationDateDay": string | null;
  "WCICS020Dto:selExpirationDateYear": string | null;
  "WCICS020Dto:selExpirationDateMonth": string | null;
  "WCICS020Dto:selExpirationDateDay": string | null;
  "WCIBS010Dto:txtZincSnzkName": string | null;
  "WCIBS010Dto:zincSnzkList": ZincSnzk[];
}>

export const getUpdatedCommonFormWithAIValues = (
  currentValues: CommonFormData,
  aiValues: AIReadingData
) => {
  const updatedValues = { ...currentValues }; // NOTE: Deep copy is intentional to trigger the useEffect hook

  const {
    familyName,
    middleName,
    givenName,
    nationality,
    birthplace,
    birthdate,
    gender,
    residentTaxPaid,
    criminalRecord,
    criminalRecordDetails,
    married,
    occupation,
    residenceInHomeCountry,
    prefecture,
    municipality,
    townStreetApartment,
    cellphoneNumber,
    telephoneNumber,
    email,
    passportNumber,
    passportExpirationDate,
    statusOfResidence,
    dateOfExpiration,
    residenceCardNumber,
    reasonForExtention,
    desiredStatusOfResidence,
    reasonForChange,
    supporterName,
    supporterRelationship,
    supporterDateOfBirth,
    supporterNationality,
    supporterPlaceOfEmployment,
    supporterResidenceCardNumber,
  } = aiValues;

  if (familyName && !updatedValues["WCIBS010Dto:txtName"]) {
    updatedValues["WCIBS010Dto:txtName"] = [
      familyName || "",
      givenName || "",
      middleName || "",
    ].join(",");
  }

  if (nationality && !currentValues["WCIBS010Dto:selNationalityAndRegion"]) {
    const found = FOREIGNER_NATIONALITY_OPTIONS.find((option) =>
      option.label.endsWith(nationality.toLowerCase())
    );

    if (found) {
      updatedValues["WCIBS010Dto:selNationalityAndRegion"] = found.value;
    }
  }

  if (birthplace && !currentValues?.["WCICS020Dto:txtSyst"]) {
    updatedValues["WCICS020Dto:txtSyst"] = birthplace;
  }

  if (
    birthdate &&
    !currentValues["WCIBS010Dto:selPassportExpirationYear"] &&
    !currentValues["WCIBS010Dto:selPassportExpirationMonth"] &&
    !currentValues["WCIBS010Dto:selPassportExpirationDay"]
  ) {
    const parsedDate = new Date(birthdate);
    if (!isNaN(parsedDate.getTime())) {
      const [year, month, day] = birthdate.split("/");
      if (year && month && day) {
        updatedValues["WCIBS010Dto:selPassportExpirationYear"] = year;
        updatedValues["WCIBS010Dto:selPassportExpirationMonth"] = month;
        updatedValues["WCIBS010Dto:selPassportExpirationDay"] = day;
      }
    }
  }

  if (residentTaxPaid && !currentValues["WCIBS010Dto:txtTaxPay"]) {
    updatedValues["WCIBS010Dto:txtTaxPay"] = residentTaxPaid;
  }

  if (criminalRecord && !currentValues["WCIBS010Dto:radHnziUm1"]) {
    updatedValues["WCIBS010Dto:radHnziUm1"] = criminalRecord;
  }

  if (criminalRecordDetails && !currentValues["WCIBS010Dto:txtHnziNiyu"]) {
    updatedValues["WCIBS010Dto:txtHnziNiyu"] = criminalRecordDetails;
  }

  if (gender && !currentValues["WCIBS010Dto:radSex"]) {
    if (gender === "male") {
      updatedValues["WCIBS010Dto:radSex"] = Gender.Male;
    }

    if (gender === "female") {
      updatedValues["WCIBS010Dto:radSex"] = Gender.Female;
    }
  }

  if (married === true && !currentValues["WCIBS010Dto:radSpouse"]) {
    updatedValues["WCIBS010Dto:radSpouse"] = MaritalStatus.Married;
  }

  if (occupation && !currentValues["WCIBS010Dto:txtJob"]) {
    updatedValues["WCIBS010Dto:txtJob"] = occupation;
  }

  if (
    residenceInHomeCountry &&
    !currentValues["WCIBS010Dto:txtHomeCountryAddress"]
  ) {
    updatedValues["WCIBS010Dto:txtHomeCountryAddress"] = residenceInHomeCountry;
  }

  if (!currentValues["WCIBS010Dto:hdnSearchedAddress"]) {
    if (prefecture) {
      const found = PREFECTURE_OPTIONS.find((option) =>
        option.label.endsWith(prefecture.toLocaleLowerCase())
      );
      if (found) {
        updatedValues["WCIBS010Dto:hdnSearchedAddress"] = found.value;
      }
    }

    if (municipality && updatedValues["WCIBS010Dto:hdnSearchedAddress"]) {
      const found = MUNICIPALITY_OPTIONS[
        updatedValues["WCIBS010Dto:hdnSearchedAddress"]
      ].find((option) =>
        option.label.endsWith(municipality.toLocaleLowerCase())
      );
      if (found) {
        updatedValues["WCIBS010Dto:hdnSearchedAddress"] += found.value;
      }
    }
  }

  if (
    townStreetApartment &&
    !currentValues["WCIBS010Dto:txtDetailHomeAddress"]
  ) {
    updatedValues["WCIBS010Dto:txtDetailHomeAddress"] = townStreetApartment;
  }

  if (cellphoneNumber && !currentValues["WCIBS010Dto:txtCelPhoneNum"]) {
    updatedValues["WCIBS010Dto:txtCelPhoneNum"] = cellphoneNumber;
  }

  if (telephoneNumber && !currentValues["WCIBS010Dto:txtTelNum"]) {
    updatedValues["WCIBS010Dto:txtTelNum"] = telephoneNumber;
  }

  if (email && !currentValues["WCIBS010Dto:txtMailAddress"]) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) {
      updatedValues["WCIBS010Dto:txtMailAddress"] = email;
    }
  }

  if (passportNumber && !currentValues["WCIBS010Dto:txtPassportNum"]) {
    updatedValues["WCIBS010Dto:txtPassportNum"] = passportNumber;
  }

  if (
    passportExpirationDate &&
    !currentValues["WCIBS010Dto:selPassportExpirationYear"] &&
    !currentValues["WCIBS010Dto:selPassportExpirationMonth"] &&
    !currentValues["WCIBS010Dto:selPassportExpirationDay"]
  ) {
    const parsedDate = new Date(passportExpirationDate);
    if (!isNaN(parsedDate.getTime())) {
      const [year, month, day] = passportExpirationDate.split("/");
      if (year && month && day) {
        updatedValues["WCIBS010Dto:selPassportExpirationYear"] = year;
        updatedValues["WCIBS010Dto:selPassportExpirationMonth"] = month;
        updatedValues["WCIBS010Dto:selPassportExpirationDay"] = day;
      }
    }
  }

  if (statusOfResidence && !currentValues["WCICS020Dto:selCurrentZirySkk"]) {
    const residenceCode = retriveResidenceCode(statusOfResidence);
    if (residenceCode) {
      updatedValues["WCICS020Dto:selCurrentZirySkk"] = residenceCode;
    }
  }

  if (dateOfExpiration) {
    const parsedDate = new Date(dateOfExpiration);
    if (!isNaN(parsedDate.getTime())) {
      const [year, month, day] = dateOfExpiration.split("/");
      if (year && month && day) {
        if (
          !currentValues["WCICS010Dto:selExpirationDateYear"] &&
          !currentValues["WCICS020Dto:selExpirationDateYear"]
        ) {
          updatedValues["WCICS010Dto:selExpirationDateYear"] = year;
          updatedValues["WCICS020Dto:selExpirationDateYear"] = year;
        }

        if (
          !currentValues["WCICS010Dto:selExpirationDateMonth"] &&
          !currentValues["WCICS020Dto:selExpirationDateMonth"]
        ) {
          updatedValues["WCICS010Dto:selExpirationDateMonth"] = month;
          updatedValues["WCICS020Dto:selExpirationDateMonth"] = month;
        }

        if (
          !currentValues["WCICS010Dto:selExpirationDateDay"] &&
          !currentValues["WCICS020Dto:selExpirationDateDay"]
        ) {
          updatedValues["WCICS010Dto:selExpirationDateDay"] = day;
          updatedValues["WCICS020Dto:selExpirationDateDay"] = day;
        }
      }
    }
  }

  if (residenceCardNumber && !currentValues["WCICS010Dto:txtZiryCardNum"]) {
    updatedValues["WCICS010Dto:txtZiryCardNum"] = residenceCardNumber;
  }

  if (reasonForExtention && !currentValues["WCICS010Dto:txtKusnReason"]) {
    updatedValues["WCICS010Dto:txtKusnReason"] = reasonForExtention;
  }

  if (desiredStatusOfResidence && !currentValues["WCICS020Dto:selNewZirySkk"]) {
    const residenceCode = retriveResidenceCode(desiredStatusOfResidence);
    if (residenceCode) {
      updatedValues["WCICS020Dto:selNewZirySkk"] = residenceCode;
    }
  }

  if (!currentValues["WCICS020Dto:txtKusnReason"]) {
    updatedValues["WCICS020Dto:txtKusnReason"] = reasonForChange;
  }

  if (!currentValues["WCIBS010Dto:txtZincSnzkName"]) {
    updatedValues["WCIBS010Dto:txtZincSnzkName"] =
      FamilyInJapanOrCoresidents.Present;
  }

  if (
    !currentValues["WCIBS010Dto:zincSnzkList"] ||
    currentValues["WCIBS010Dto:zincSnzkList"]?.length === 0
  ) {
    const zincSnzk: ZincSnzk = {
      selNationalityAndRegion: null,
      selZkgr: null,
      txtName: supporterName,
      selDateOfBirthYear: null,
      selDateOfBirthMonth: null,
      selDateOfBirthDay: null,
      radDukyUm: LivingTogetherWithThisFamilyOrCoresident.Yes,
      txtWorkPlaceOrTugkskName: supporterPlaceOfEmployment,
      txtZiryCardNumOrTkeiNum: supporterResidenceCardNumber,
    };

    if (supporterNationality) {
      const found =
        ALL_NATIONALITY_OPTIONS.find((option) =>
          option.label.endsWith(supporterNationality.toLocaleLowerCase())
        )?.value || null;

      if (found) {
        zincSnzk["selNationalityAndRegion"] = Number(found.value);
      }
    }

    if (supporterRelationship) {
      const relationName = RELATIONSHIP_WITH_JA_VALUE_OPTIONS_WITHOUT_SELF.find(
        (relationship) => relationship.value === supporterRelationship
      );
      const found =
        RELATIONSHIP_CB06_OPTIONS_WITHOUT_SELF.find(
          (relationship) => relationship.label === relationName?.label
        )?.value || null;

      if (found) {
        zincSnzk["selZkgr"] = found.value;
      }
    }

    if (supporterDateOfBirth) {
      const parsedDate = new Date(supporterDateOfBirth);
      if (!isNaN(parsedDate.getTime())) {
        const [year, month, day] = supporterDateOfBirth.split("/");
        if (year && month && day) {
          zincSnzk["selDateOfBirthYear"] = year;
          zincSnzk["selDateOfBirthMonth"] = month;
          zincSnzk["selDateOfBirthDay"] = day;
        }
      }
    }

    if (supporterName) {
      updatedValues["WCIBS010Dto:zincSnzkList"] = [zincSnzk];
    }
  }

  return updatedValues;
};

export const getUpdatedFormRWithAIValues = (
  currentR: FormR,
  aiValues: DependentAIReadingData
) => {
  const updatedR = { ...currentR }; // NOTE: Deep copy is intentional to trigger the useEffect hook

  const {
    placeAndDateJapaneseAuthorities,
    placeAndDateJapaneseAuthoritiesDateOfRegistration,
    placeAndDateForeignAuthorities,
    placeAndDateForeignAuthoritiesDateOfRegistration,
    supporterName,
    supporterDateOfBirth,
    supporterNationality,
    supporterResidenceCardNumber,
    supporterStatusOfResidence,
    supporterPeriodOfStay,
    supporterDateOfExpiration,
    supporterRelationship,
    supporterPlaceOfEmployment,
    supporterCorporationNo,
    supporterNameOfBranch,
    supporterPrefecture,
    supporterMunicipality,
    supporterStreet,
    supporterPhoneNumber,
    supporterAnnualIncome,
  } = aiValues;

  if (placeAndDateJapaneseAuthorities && !currentR.place_and_date_japanese_authorities) {
    updatedR.place_and_date_japanese_authorities = placeAndDateJapaneseAuthorities;
  }

  if (placeAndDateJapaneseAuthoritiesDateOfRegistration && !currentR.place_and_date_japanese_authorities_date_of_registration) {
    const parsedDate = new Date(placeAndDateJapaneseAuthoritiesDateOfRegistration);
    if (!isNaN(parsedDate.getTime())) {
      updatedR.place_and_date_japanese_authorities_date_of_registration = formatDate(parsedDate, "yyyy年MM月dd日");
    }
  }

  if (placeAndDateForeignAuthorities && !currentR.place_and_date_foreign_authorities) {
    updatedR.place_and_date_foreign_authorities = placeAndDateForeignAuthorities;
  }

  if (placeAndDateForeignAuthoritiesDateOfRegistration && !currentR.place_and_date_foreign_authorities_date_of_registration) {
    const parsedDate = new Date(placeAndDateForeignAuthoritiesDateOfRegistration);
    if (!isNaN(parsedDate.getTime())) {
      updatedR.place_and_date_foreign_authorities_date_of_registration = formatDate(parsedDate, "yyyy年MM月dd日");
    }
  }

  if (supporterName && !currentR.supporter_name) {
    updatedR.supporter_name = supporterName;
  }

  if (supporterDateOfBirth && !currentR.supporter_date_of_birth) {
    const parsedDate = new Date(supporterDateOfBirth);
    if (!isNaN(parsedDate.getTime())) {
      updatedR.supporter_date_of_birth = formatDate(parsedDate, "yyyy年MM月dd日");
    }
  }

  if (supporterNationality && !currentR.supporter_nationality_region) {
    const found = FOREIGNER_NATIONALITY_OPTIONS.find((option) => option.label.endsWith(supporterNationality.toLowerCase()));
    if (found) {
      updatedR.supporter_nationality_region = found.value;
    }
  }

  if (supporterResidenceCardNumber && !currentR.supporter_residence_card_number) {
    updatedR.supporter_residence_card_number = supporterResidenceCardNumber;
  }

  if (supporterStatusOfResidence && !currentR.supporter_status_of_residence) {
    updatedR.supporter_status_of_residence = supporterStatusOfResidence;
  }

  if (supporterPeriodOfStay && !currentR.supporter_period_of_stay) {
    updatedR.supporter_period_of_stay = supporterPeriodOfStay;
  }

  if (supporterDateOfExpiration && !currentR.supporter_date_of_expiration) {
    const parsedDate = new Date(supporterDateOfExpiration);
    if (!isNaN(parsedDate.getTime())) {
      updatedR.supporter_date_of_expiration = formatDate(parsedDate, "yyyy年MM月dd日");
    }
  }

  if (supporterRelationship && !currentR.supporter_relationship) {
    const found = RELATIONSHIP_WITH_JA_VALUE_OPTIONS_WITHOUT_SELF.find(
      (option) => option.value === supporterRelationship
    );
    if (found) {
      updatedR.supporter_relationship = found.value;
    }
  }

  if (supporterPlaceOfEmployment && !currentR.supporter_place_of_employment) {
    updatedR.supporter_place_of_employment = supporterPlaceOfEmployment;
  }

  if (supporterCorporationNo && !currentR.supporter_corporation_no) {
    updatedR.supporter_corporation_no = supporterCorporationNo;
  }

  if (supporterNameOfBranch && !currentR.supporter_name_of_branch) {
    updatedR.supporter_name_of_branch = supporterNameOfBranch;
  }

  if (supporterPrefecture && !currentR.supporter_employment_prefecture) {
    const found = PREFECTURE_WITH_JA_VALUE_OPTIONS.find((option) => option.label.endsWith(supporterPrefecture));
    if (found) {
      updatedR.supporter_employment_prefecture = found.value;
    }
  }

  if (
    supporterMunicipality &&
    updatedR.supporter_employment_prefecture &&
    !currentR.supporter_employment_municipality
  ) {
    const options = MUNICIPALITY_WITH_JA_VALUE_OPTIONS[updatedR.supporter_employment_prefecture];
    const found = options?.find((option) => option.label.endsWith(supporterMunicipality));
    if (found) {
      updatedR.supporter_employment_municipality = found.value;
    }
  }

  if (supporterStreet && !currentR.supporter_employment_street) {
    updatedR.supporter_employment_street = supporterStreet;
  }

  if (supporterPhoneNumber && !currentR.supporter_employment_telephone) {
    updatedR.supporter_employment_telephone = supporterPhoneNumber.toString();
  }

  if (supporterAnnualIncome && !currentR.supporter_annual_income) {
    updatedR.supporter_annual_income = supporterAnnualIncome.toString();
  }

  return updatedR;
};

// TODO: Move these logics to the server-side
const retriveResidenceCode = (jaStatusName: string): string | undefined => {
  const mapping: { [key: string]: string } = {};

  for (const key in StatusOfResidenceInJapanese) {
    const jaValue =
      StatusOfResidenceInJapanese[
        key as keyof typeof StatusOfResidenceInJapanese
      ];
    const codeValue =
      StatusOfResidenceCode_CB19[
        key as keyof typeof StatusOfResidenceCode_CB19
      ];

    if (jaValue && codeValue) {
      mapping[jaValue] = codeValue;
    }
  }

  return mapping[jaStatusName];
}
