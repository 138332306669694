import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Section from '../../../../components/layout/Section';
import MultiTextField from '../../../../components/compound/MultiTextField';
import { useFieldInputNotes, useValidation } from '../../../../hooks';
import FieldGroup from '../../../../components/layout/FieldGroup';
import RadioField from '../../../../components/compound/RadioField';
import InlineMessage from '../../../../components/form/InlineMessage';
import styled from 'styled-components';
import TextField from '../../../../components/compound/TextField';
import Button from '../../../../components/form/Button';
import SelectField from '../../../../components/compound/SelectField';
import { FormP } from '../../../../types/visa/uncommonFormParts/formP/data';
import { getLengthOfEmployments, getEmploymentKeyOf } from '../../../../utils/visaFormDataHelpers/studyAbroadVisaFormDataHelper';
import { MAX_EMPLOYMENTS } from '../../../../constants/values/studentVisaValues';
import { ActivitySalaryType, EngagingInActivitiesOtherThanPermitted, PLAN_AFTER_GRADUATION_OTHER_VALUE } from '../../../../types/visa/uncommonFormParts/formP/value';
import FieldGroupNumber from '../../../../components/display/FieldGroupNumber';
import { ReactComponent as PlusIcon } from '../../../../assets/icon-plus.svg';
import { ReactComponent as TrashCanIcon } from '../../../../assets/icon-trash-can-2.svg' 
import { MUNICIPALITY_WITH_JA_VALUE_OPTIONS, PREFECTURE_WITH_JA_VALUE_OPTIONS } from '../../../../constants/options';
import { PLAN_AFTER_GRADUATION_OPTIONS_FOR_STUDY_ABROAD } from '../../../../constants/options/education';
import { SupplementaryInfo } from '../../../../types/visa/supplementaryInfo';
import { ApplicationFormSectionProps } from '../../type';

interface StudentVisaOtherSectionProps extends ApplicationFormSectionProps {
  formP: FormP | null;
  supplementaryInfo: SupplementaryInfo;
  onChangeSupplementaryInfo: (data: Partial<SupplementaryInfo>) => void;
  onChangeFormP: (data: Partial<FormP>) => void;
}

type Employment = {
  typeOfWork: string;
  placeOfEmployment: string;
  empTelephoneNumber: string;
  workTimePerWeek: string;
  salaryType: string;
  salaryYen: string;
}

type EmploymentError = {
  typeOfWork: string;
  placeOfEmployment: string;
  empTelephoneNumber: string;
  workTimePerWeek: string;
  salaryYen: string;
}

const EmploymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 20px;
  background-color: #EDEDED;
`;

const EmploymentHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const ActionButton = styled(Button).attrs({ variant: 'inline' })`
  width: auto;
  height: 40px;
  gap: 10px;
  margin: 0 auto;
`;

const EmploymentDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;


const StudentVisaOthersSection: FunctionComponent<StudentVisaOtherSectionProps> = ({
  visaApplicationType,
  formP,
  supplementaryInfo,
  onChangeFormP,
  onChangeSupplementaryInfo,
  onChangeSectionReadiness
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'othersSection' });
  const { t: tAct } = useTranslation('translation', { keyPrefix: 'othersSection.activitiesOtherThanPermitted' });
  const { t: tGrd } = useTranslation('translation', { keyPrefix: 'othersSection.actualGuardianInJapan' });
  const { t: tRep } = useTranslation('translation', { keyPrefix: 'othersSection.legalRepresentative' });
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });
  const { t: tWithoutPrefix } = useTranslation();
  const { inputNotes } = useFieldInputNotes();

  const getEmptyEmployment = (): Employment => ({
    typeOfWork: '',
    placeOfEmployment: '',
    empTelephoneNumber: '',
    workTimePerWeek: '',
    salaryType: '',
    salaryYen: ''
  });

  const getEmptyEmploymentError = (): EmploymentError => ({
    typeOfWork: '',
    placeOfEmployment: '',
    empTelephoneNumber: '',
    workTimePerWeek: '',
    salaryYen: ''
  });

  const [engagingInActivities, setEngagingInActivities] = useState<string>('');
  const [employments, setEmployments] = useState<Employment[]>([]);
  const [employmentErrors, setEmploymentErrors] = useState<EmploymentError[]>(
    Array.from({ length: MAX_EMPLOYMENTS }, () => getEmptyEmploymentError())
  );

  const [planAfterGraduation, setPlanAfterGraduation] = useState<string>('');
  const [planAfterGraduationOther, setPlanAfterGraduationOther] = useState<string>('');
  const [planAfterGraduationOtherError, setPlanAfterGraduationOtherError] = useState<string>('');

  const [isApplicantToStudyAtSchool, setIsApplicantToStudyAtSchool] = useState<boolean | null>();
  const [nameOfGuardian, setNameOfGuardian] = useState<string>('');
  const [grdRelWithApplicant, setGrdRelWithApplicant] = useState<string>('');
  const [grdPrefecture, setGrdPrefecture] = useState<string>('');
  const [grdMunicipality, setGrdMunicipality] = useState<string>('');
  const [grdTownStreetApartment, setGrdTownStreetApartment] = useState<string>('');
  const [grdCellphoneNumber, setGrdCellphoneNumber] = useState<string>('');
  const [grdTelephoneNumber, setGrdTelephoneNumber] = useState<string>('');
  const [nameOfGuardianError, setNameOfGuardianError] = useState<string>('');
  const [grdRelWithApplicantError, setGrdRelWithApplicantError] = useState<string>('');
  const [grdTownStreetApartmentError, setGrdTownStreetApartmentError] = useState<string>('');
  const [grdCellphoneNumberError, setGrdCellphoneNumberError] = useState<string>('');
  const [grdTelephoneNumberError, setGrdTelephoneNumberError] = useState<string>('');

  const [isMadeByLegalRepresentative, setIsMadeByLegalRepresentative] = useState<boolean | null>();
  const [nameOfRepresentative, setNameOfRepresentative] = useState<string>('');
  const [repRelWithApplicant, setRepRelWithApplicant] = useState<string>('');
  const [repPrefecture, setRepPrefecture] = useState<string>('');
  const [repMunicipality, setRepMunicipality] = useState<string>('');
  const [repTownStreetApartment, setRepTownStreetApartment] = useState<string>('');
  const [repCellphoneNumber, setRepCellphoneNumber] = useState<string>('');
  const [repTelephoneNumber, setRepTelephoneNumber] = useState<string>('');
  const [nameOfRepresentativeError, setNameOfRepresentativeError] = useState<string>('');
  const [repRelWithApplicantError, setRepRelWithApplicantError] = useState<string>('');
  const [repTownStreetApartmentError, setRepTownStreetApartmentError] = useState<string>('');
  const [repCellphoneNumberError, setRepCellphoneNumberError] = useState<string>('');
  const [repTelephoneNumberError, setRepTelephoneNumberError] = useState<string>('');

  const validation = useValidation();

  //Currently, the item names for this section are common between visa extension and change,
  const saveData = (data: Partial<FormP>) => {
    onChangeFormP(data);
  }
  
  const updateEmployment = (index: number, fieldName: keyof Employment, val: string) => {
    setEmployments(prevState =>
      prevState.map((emp, i) => i === index 
        ? { ...emp, [fieldName]: val } 
        : emp)
    );
  };

  const updateEmploymentError = (index: number, fieldName: keyof EmploymentError, val: string) => {
    setEmploymentErrors(prevState =>
      prevState.map((err, i) => i === index 
        ? { ...err, [fieldName]: val } 
        : err)
    );
  };

  const onClickAddEmployment = () => {
    setEmployments([...employments, getEmptyEmployment()]);
  }

  const onClickRemoveEmployment = (index: number) => {
    let data: Partial<FormP> = {};

    for (let i = index; i < MAX_EMPLOYMENTS; i++) {
      const nextEmployment = employments[i + 1] ?? getEmptyEmployment();
      const nextEmploymentError = employmentErrors[i + 1] ?? getEmptyEmploymentError();

      updateEmployment(i, 'typeOfWork', nextEmployment.typeOfWork);
      updateEmployment(i, 'placeOfEmployment', nextEmployment.placeOfEmployment);
      updateEmployment(i, 'empTelephoneNumber', nextEmployment.empTelephoneNumber);
      updateEmployment(i, 'workTimePerWeek', nextEmployment.workTimePerWeek);
      updateEmployment(i, 'salaryType', nextEmployment.salaryType);
      updateEmployment(i, 'salaryYen', nextEmployment.salaryYen);

      updateEmploymentError(i, 'typeOfWork', nextEmploymentError.typeOfWork);
      updateEmploymentError(i, 'placeOfEmployment', nextEmploymentError.placeOfEmployment);
      updateEmploymentError(i, 'empTelephoneNumber', nextEmploymentError.empTelephoneNumber);
      updateEmploymentError(i, 'workTimePerWeek', nextEmploymentError.workTimePerWeek);
      updateEmploymentError(i, 'salaryYen', nextEmploymentError.salaryYen);
      
      data = {
        ...data,
        [getEmploymentKeyOf.typeOfWork(i)]: nextEmployment.typeOfWork,
        [getEmploymentKeyOf.placeOfEmployment(i)]: nextEmployment.placeOfEmployment,
        [getEmploymentKeyOf.telephoneNumber(i)]: nextEmployment.empTelephoneNumber,
        [getEmploymentKeyOf.workTimePerWeek(i)]: nextEmployment.workTimePerWeek,
        [getEmploymentKeyOf.salaryType(i)]: nextEmployment.salaryType,
        [getEmploymentKeyOf.salaryYen(i)]: nextEmployment.salaryYen
      }
    }

    setEmployments(employments.slice(0, employments.length - 1));
    setEmploymentErrors([...employmentErrors.slice(0, employmentErrors.length - 1), getEmptyEmploymentError()]);

    saveData(data);
  }


  useEffect(() => {
    if (!formP)
      return;

    setEngagingInActivities(formP["other_activities_yes_or_no"] );

    //TODO: define a local state which tracks how many supporter items to show
    const emps: Employment[] = [];
    const empsLen = getLengthOfEmployments(formP);
    for (let i = 1; i <= empsLen; i++) {
      emps.push({
        typeOfWork: formP[getEmploymentKeyOf.typeOfWork(i)] as string ?? '',
        placeOfEmployment: formP[getEmploymentKeyOf.placeOfEmployment(i)] as string ?? '',
        empTelephoneNumber: formP[getEmploymentKeyOf.telephoneNumber(i)] as string ?? '',
        workTimePerWeek: formP[getEmploymentKeyOf.workTimePerWeek(i)] as string ?? '',
        salaryType: formP[getEmploymentKeyOf.salaryType(i)] as string ?? '',
        salaryYen: formP[getEmploymentKeyOf.salaryYen(i)] as string ?? ''
      });
    }
    setEmployments(emps);

    setPlanAfterGraduation(formP["plan_after_graduation"] ?? '');
    setPlanAfterGraduationOther(formP["plan_after_graduation_others"] ?? '');

    
    setNameOfGuardian(formP["actual_guardian_name"] ?? '');
    setGrdRelWithApplicant(formP["actual_guardian_relationship_with_the_applicant"] ?? '');
    setGrdPrefecture(formP["actual_guardian_address_prefecture"] ?? '');
    setGrdMunicipality(formP["actual_guardian_address_municipality"] ?? '');
    setGrdTownStreetApartment(formP["actual_guardian_address_street"] ?? '');
    setGrdCellphoneNumber(formP["actual_guardian_cellular_phone_no"] ?? '');
    setGrdTelephoneNumber(formP["actual_guardian_telephone_no"] ?? '');
    
    
    setNameOfRepresentative(formP["legal_representative_name"] ?? '');
    setRepRelWithApplicant(formP["legal_representative_relationship_with_the_applicant"] ?? '');
    setRepPrefecture(formP["legal_representative_address_prefecture"] ?? '');
    setRepMunicipality(formP["legal_representative_address_municipality"] ?? '');
    setRepTownStreetApartment(formP["legal_representative_address_street"] ?? '');
    setRepCellphoneNumber(formP["legal_representative_cellular_phone_no"] ?? '');
    setRepTelephoneNumber(formP["legal_representative_telephone_no"] ?? '');
    
  }, [formP]);

  useEffect(() => {
    if (!supplementaryInfo)
      return;

    setIsApplicantToStudyAtSchool(supplementaryInfo.is_applicant_at_junior_high_school_or_elementary_school);
    setIsMadeByLegalRepresentative(supplementaryInfo.is_this_by_representative);
  }, [supplementaryInfo]);

  useEffect(() => {
    const areThereNoErrors = [
      ...employmentErrors.flatMap(err => Object.values(err)),
      nameOfGuardianError,
      grdRelWithApplicantError,
      grdTownStreetApartmentError,
      grdCellphoneNumberError,
      grdTelephoneNumberError,
      nameOfRepresentativeError,
      repRelWithApplicantError,
      repTownStreetApartmentError,
      repCellphoneNumberError,
      repTelephoneNumberError,
      planAfterGraduationOtherError
    ].every(val => !val);

    onChangeSectionReadiness(areThereNoErrors);
  }, [
    employmentErrors,
    nameOfGuardianError,
    grdRelWithApplicantError,
    grdTownStreetApartmentError,
    grdCellphoneNumberError,
    grdTelephoneNumberError,
    nameOfRepresentativeError,
    repRelWithApplicantError,
    repTownStreetApartmentError,
    repCellphoneNumberError,
    repTelephoneNumberError,
    planAfterGraduationOtherError
  ]);

  return (
    <Section>
      {/* Activities other than permitted */}
      <FieldGroup
        title={tAct("title")}
        theme="light"
      >
        <RadioField
          label={tAct("areYouEngagingInActivities")}
          value={engagingInActivities}
          onValueChange={val => {
            let data: Partial<FormP> = { other_activities_yes_or_no: val };
            
            if (val === EngagingInActivitiesOtherThanPermitted.No) {
              setEmployments([]);
              setEmploymentErrors(
                Array.from({ length: MAX_EMPLOYMENTS }, () => getEmptyEmploymentError())
              );

              for (let i = 1; i <= MAX_EMPLOYMENTS; i++) {
                data = {
                  ...data,
                  [getEmploymentKeyOf.typeOfWork(i)]: '',
                  [getEmploymentKeyOf.placeOfEmployment(i)]: '',
                  [getEmploymentKeyOf.telephoneNumber(i)]: '',
                  [getEmploymentKeyOf.workTimePerWeek(i)]: '',
                  [getEmploymentKeyOf.salaryType(i)]: '',
                  [getEmploymentKeyOf.salaryYen(i)]: ''
                }
              }
            }
            
            setEngagingInActivities(val);
            saveData(data);
          }}
          options={[
            { 
              label: tCommon("yes"), 
              value: EngagingInActivitiesOtherThanPermitted.Yes 
            },
            { 
              label: tCommon("no"), 
              value: EngagingInActivitiesOtherThanPermitted.No 
            }
          ]}
        />

        { engagingInActivities === EngagingInActivitiesOtherThanPermitted.Yes && (
          <>       
            <InlineMessage>
              {tAct("noteOnSegment")}
            </InlineMessage>

            { employments.map((emp, index) => (
              <EmploymentContainer key={`employment-${index + 1}`}>
                <EmploymentHeader>
                  <FieldGroupNumber>
                    {index + 1}
                  </FieldGroupNumber>
                  
                  <ActionButton onClick={() => onClickRemoveEmployment(index)}>
                    <TrashCanIcon />
                    {tAct("remove")}
                  </ActionButton>
                </EmploymentHeader>

                <EmploymentDetail>
                  {/* Type of work */}
                  <MultiTextField
                    label={tAct("typeOfWork")}
                    value={emp.typeOfWork}
                    onValueChange={val => updateEmployment(index, 'typeOfWork', val)}
                    error={employmentErrors[index].typeOfWork}
                    onErrorChange={newErr => updateEmploymentError(index, 'typeOfWork', newErr)}
                    maxLength={172}
                    validators={[validation.createLengthValidator(172)]}
                    onBlur={() => {
                      saveData({
                        [getEmploymentKeyOf.typeOfWork(index + 1)]: emp.typeOfWork
                      });
                    }}
                  />

                  {/* Place of employment */}
                  <TextField
                    label={tAct("placeOfEmployment")}
                    placeholder='Tokuty Inc.'
                    value={emp.placeOfEmployment}
                    onValueChange={val => {
                      updateEmployment(index, 'placeOfEmployment', val);
                    }}
                    error={employmentErrors[index].placeOfEmployment}
                    onErrorChange={newErr => {
                      updateEmploymentError(index, 'placeOfEmployment', newErr);
                    }}
                    maxLength={172}
                    validators={[validation.createLengthValidator(172)]}
                    onBlur={() => {
                      saveData({
                        [getEmploymentKeyOf.placeOfEmployment(index + 1)]: emp.placeOfEmployment
                      });
                    }}
                  />

                  {/* Phone number. */}
                  <TextField
                    label={tWithoutPrefix("common.phoneNumber")}
                    placeholder="1234567891234"
                    value={emp.empTelephoneNumber}
                    onValueChange={val => {
                      updateEmployment(index, 'empTelephoneNumber', val);
                    }}
                    error={employmentErrors[index].empTelephoneNumber}
                    onErrorChange={newErr => {
                      updateEmploymentError(index, 'empTelephoneNumber', newErr);
                    }}
                    maxLength={13}
                    validators={[validation.createLengthValidator(13), validation.isNumeric]}
                    restriction={inputNotes.halfwidthNumber}
                    onBlur={() => {
                      saveData({
                        [getEmploymentKeyOf.telephoneNumber(index + 1)]: emp.empTelephoneNumber
                      });
                    }}
                  />

                  {/* Work time per week (Hours) */}
                  <TextField
                    label={tAct("workTimePerWeek")}
                    placeholder="12"
                    value={emp.workTimePerWeek}
                    onValueChange={val => {
                      updateEmployment(index, 'workTimePerWeek', val);
                    }}
                    error={employmentErrors[index].workTimePerWeek}
                    onErrorChange={newErr => {
                      updateEmploymentError(index, 'workTimePerWeek', newErr);
                    }}
                    maxLength={2}
                    validators={[validation.createLengthValidator(2), validation.isNumeric]}
                    restriction={inputNotes.halfwidthNumber}
                    onBlur={() => {
                      saveData({
                        [getEmploymentKeyOf.workTimePerWeek(index + 1)]: emp.workTimePerWeek
                      });
                    }}
                  />

                  {/* Salary */}
                  <RadioField
                    label={tAct("salary")}
                    options={[
                      { 
                        label: tAct("monthly"), 
                        value: ActivitySalaryType.Monthly
                      },
                      { 
                        label: tAct("daily"), 
                        value: ActivitySalaryType.Daily
                      }
                    ]}
                    value={emp.salaryType}
                    onValueChange={val => {
                      updateEmployment(index, 'salaryType', val);
                      saveData({
                        [getEmploymentKeyOf.salaryType(index + 1)]: val
                      });
                    }}
                  />

                  {/* Salary (Yen) */}
                  { !!emp.salaryType &&
                    <TextField
                      label={tAct("salaryYen")}
                      placeholder="0"
                      value={emp.salaryYen}
                      onValueChange={val => {
                        updateEmployment(index, 'salaryYen', val);
                      }}
                      error={employmentErrors[index].salaryYen}
                      onErrorChange={newErr => {
                        updateEmploymentError(index, 'salaryYen', newErr);
                      }}
                      maxLength={8}
                      validators={[validation.createLengthValidator(10), validation.isNumeric]}
                      restriction={inputNotes.halfwidthNumber}
                      onBlur={() => {
                        saveData({
                          [getEmploymentKeyOf.salaryYen(index + 1)]: emp.salaryYen
                        });
                      }}
                    />
                  }

                </EmploymentDetail>
              </EmploymentContainer>
            ))}

            { employments.length < MAX_EMPLOYMENTS &&
              <ActionButton onClick={onClickAddEmployment}>
                <PlusIcon />
                {tAct("addEmployment")}
              </ActionButton>
            }
          </>
        )}
      </FieldGroup>

      {/* Plan after graduation */}
      <div>
        <SelectField
          label={t("planAfterGraduation")}
          placeholder={tWithoutPrefix("placeholder.select")}
          options={PLAN_AFTER_GRADUATION_OPTIONS_FOR_STUDY_ABROAD}
          value={planAfterGraduation}
          onValueChange={val => {
            setPlanAfterGraduation(val);

            if (val !== PLAN_AFTER_GRADUATION_OTHER_VALUE)
              setPlanAfterGraduationOther('');
          }}
        />
        { planAfterGraduation === PLAN_AFTER_GRADUATION_OTHER_VALUE &&
          <MultiTextField
            value={planAfterGraduationOther}
            onValueChange={setPlanAfterGraduationOther}
            error={planAfterGraduationOtherError}
            onErrorChange={setPlanAfterGraduationOtherError}
            maxLength={172}
            validators={[validation.createLengthValidator(172)]}
            onBlur={() => {
              onChangeFormP({
                plan_after_graduation_others: planAfterGraduationOther
              });
            }}
            style={{ marginTop: 5 }}
          />
        }
      </div>


      {/* Actual guardian in Japan */} 
      <FieldGroup
        title={tGrd("title")}
        theme="light"
      >
        <RadioField
          label={tGrd("isApplicantToSutdyAtSchool")}
          options={[
            { 
              label: tCommon("yes"), 
              value: true
            },
            { label: tCommon("no"), 
              value: false
            }
          ]}
          value={isApplicantToStudyAtSchool}
          onValueChange={val => {
            const isStudy = val === 'true'
            
            if (!isStudy) {
              setNameOfGuardian('');
              setGrdRelWithApplicant('');
              setGrdPrefecture('');
              setGrdMunicipality('');
              setGrdTownStreetApartment('');
              setGrdCellphoneNumber('');
              setGrdTelephoneNumber('');

              setNameOfGuardianError('');
              setGrdRelWithApplicantError('');
              setGrdTownStreetApartmentError('');
              setGrdCellphoneNumberError('');
              setGrdTelephoneNumberError('');

              saveData({
                actual_guardian_name: '',
                actual_guardian_relationship_with_the_applicant: '',
                actual_guardian_address_prefecture: '',
                actual_guardian_address_municipality: '',
                actual_guardian_address_street: '',
                actual_guardian_telephone_no: '',
                actual_guardian_cellular_phone_no: ''
              });
            }

            setIsApplicantToStudyAtSchool(isStudy);
            onChangeSupplementaryInfo({
              is_applicant_at_junior_high_school_or_elementary_school: isStudy
            })
          }}
        />

        { isApplicantToStudyAtSchool && (
          <>
            {/* Name of guardian */}
            <TextField
              label={tGrd("name")}
              value={nameOfGuardian}
              onValueChange={setNameOfGuardian}
              error={nameOfGuardianError}
              onErrorChange={setNameOfGuardianError}
              maxLength={172}
              validators={[validation.createLengthValidator(172)]}
              onBlur={() => {
                saveData({ actual_guardian_name: nameOfGuardian });
              }}
            />

            {/* Relationship with the applicant */}
            <TextField  
              label={tGrd("relationship")}
              value={grdRelWithApplicant}
              onValueChange={setGrdRelWithApplicant}
              error={grdRelWithApplicantError}
              onErrorChange={setGrdRelWithApplicantError}
              maxLength={172}
              validators={[validation.createLengthValidator(172)]}
              onBlur={() => {
                saveData({ actual_guardian_relationship_with_the_applicant: grdRelWithApplicant });
              }}
            />

            {/* Prefecture */}
            <SelectField
              label={tWithoutPrefix('common.prefecture')}
              placeholder={tWithoutPrefix("placeholder.select")}
              value={grdPrefecture}
              onValueChange={val => {
                setGrdPrefecture(val);
                setGrdMunicipality('');

                saveData({ 
                  actual_guardian_address_prefecture: val,
                  actual_guardian_address_municipality: '',
                });
              }}
            />

            {/* Municipality */}
            <SelectField
              disabled={!grdPrefecture}
              label={tWithoutPrefix('common.municipality')}
              placeholder={tWithoutPrefix("placeholder.select")}
              value={grdMunicipality}
              onValueChange={val => {
                setGrdMunicipality(val);
                saveData({ actual_guardian_address_municipality: val });
              }}
            />

            {/* Town/street/apartment */}
            <MultiTextField
              label={tWithoutPrefix('common.townStreetApartment')}
              placeholder="品川1-2-3ビザダスビル3F"
              value={grdTownStreetApartment}
              onValueChange={setGrdTownStreetApartment}
              error={grdTownStreetApartmentError}
              onErrorChange={setGrdTownStreetApartmentError}
              maxLength={172}
              validators={[validation.createLengthValidator(172)]}
              onBlur={() => {
                saveData({ actual_guardian_address_street: grdTownStreetApartment });
              }}
            />

            {/* Cellphone number */}
            <TextField
              label={tWithoutPrefix('common.cellphoneNumber')}
              placeholder="1234567891234"
              value={grdCellphoneNumber}
              onValueChange={setGrdCellphoneNumber}
              error={grdCellphoneNumberError}
              onErrorChange={setGrdCellphoneNumberError}
              maxLength={12}
              validators={[validation.createLengthValidator(12), validation.isNumeric]}
              restriction={inputNotes.halfwidthNumber}
              onBlur={() => {
                saveData({ actual_guardian_cellular_phone_no: grdCellphoneNumber });
              }}
            />

            {/* Phone number */}
            <TextField
              label={tWithoutPrefix('common.phoneNumber')}
              placeholder="1234567891234"
              value={grdTelephoneNumber}
              onValueChange={setGrdTelephoneNumber}
              error={grdTelephoneNumberError}
              onErrorChange={setGrdTelephoneNumberError}
              maxLength={13}
              validators={[validation.createLengthValidator(13), validation.isNumeric]}
              restriction={inputNotes.halfwidthNumber}
              onBlur={() => {
                saveData({ actual_guardian_telephone_no: grdTelephoneNumber });
              }}
            />
          </>)
        }
      </FieldGroup>

      {/* Legal representative */}
      <FieldGroup
        title={tRep("title")}
        theme="light"
      >
        {/* Is this application being made by a legal representative */}
        <RadioField
          label={tRep("madeByLegalRepresentative")}
          options={[
            { 
              label: tCommon("yes"), 
              value: true
            },
            { 
              label: tCommon("no"), 
              value: false
            }
          ]}
          value={isMadeByLegalRepresentative}
          onValueChange={val => {
            const isMadeByLegalRep = val === 'true';

            if (!isMadeByLegalRep) {
              setNameOfRepresentative('');
              setRepRelWithApplicant('');
              setRepPrefecture('');
              setRepMunicipality('');
              setRepTownStreetApartment('');
              setRepCellphoneNumber('');
              setRepTelephoneNumber('');

              setNameOfRepresentativeError('');
              setRepRelWithApplicantError('');
              setRepTownStreetApartmentError('');
              setRepCellphoneNumberError('');
              setRepTelephoneNumberError('');
              
              saveData({
                legal_representative_name: '',
                legal_representative_relationship_with_the_applicant: '',
                legal_representative_address_prefecture: '',
                legal_representative_address_municipality: '',
                legal_representative_address_street: '',
                legal_representative_telephone_no: '',
                legal_representative_cellular_phone_no: ''
              })
            }
            
            onChangeSupplementaryInfo({ is_this_by_representative: isMadeByLegalRep });
            setIsMadeByLegalRepresentative(isMadeByLegalRep);
          }}
        />

        { isMadeByLegalRepresentative && (
          <>
            {/* Name of representative */}
            <TextField
              label={tRep("name")}
              placeholder="John Doe"
              value={nameOfRepresentative}
              onValueChange={setNameOfRepresentative}
              error={nameOfRepresentativeError}
              onErrorChange={setNameOfRepresentativeError}
              maxLength={172}
              validators={[validation.createLengthValidator(172)]}
              onBlur={() => {
                saveData({ legal_representative_name: nameOfRepresentative });
              }}
            />

            {/* Relationship with the applicant */}
            <TextField
              label={tRep("relationship")}
              value={repRelWithApplicant}
              onValueChange={setRepRelWithApplicant}
              error={repRelWithApplicantError}
              onErrorChange={setRepRelWithApplicantError}
              maxLength={172}
              validators={[validation.createLengthValidator(172)]}
              onBlur={() => {
                saveData({ 
                  legal_representative_relationship_with_the_applicant: repRelWithApplicant 
                });
              }}
            />

            {/* Prefecture */}
            <SelectField
              label={tWithoutPrefix('common.prefecture')}
              placeholder={tWithoutPrefix("placeholder.select")}
              options={PREFECTURE_WITH_JA_VALUE_OPTIONS}
              value={repPrefecture}
              onValueChange={val => {
                setRepPrefecture(val);
                setRepMunicipality('');
                saveData({
                  legal_representative_address_prefecture: val,
                  legal_representative_address_municipality: ''
                });
              }}
            />

            {/* Municipality */}
            <SelectField
              disabled={!repPrefecture}
              label={tWithoutPrefix('common.municipality')}
              options={MUNICIPALITY_WITH_JA_VALUE_OPTIONS[repPrefecture] ?? []}
              placeholder={
                repPrefecture 
                  ? tWithoutPrefix('placeholder.select')
                  : ''
              }
              value={repMunicipality}
              onValueChange={val => {
                setRepMunicipality(val);
                saveData({ legal_representative_address_municipality: val });
              }}
            />

            {/* Town/street/apartment */}
            <MultiTextField
              label={tWithoutPrefix('common.townStreetApartment')}
              placeholder="品川1-2-3ビザダスビル3F"
              value={repTownStreetApartment}
              onValueChange={setRepTownStreetApartment}
              error={repTownStreetApartmentError}
              onErrorChange={setRepTownStreetApartmentError}
              maxLength={172}
              validators={[validation.createLengthValidator(172)]}
              onBlur={() => {
                saveData({ legal_representative_address_street: repTownStreetApartment });
              }}
            />

            {/* Cellphone number */}
            <TextField
              label={tWithoutPrefix('common.cellphoneNumber')}
              placeholder="1234567891234"
              value={repCellphoneNumber}
              onValueChange={setRepCellphoneNumber}
              error={repCellphoneNumberError}
              onErrorChange={setRepCellphoneNumberError}
              maxLength={12}
              validators={[validation.createLengthValidator(12), validation.isNumeric]}
              restriction={inputNotes.halfwidthNumber}
              onBlur={() => {
                saveData({ legal_representative_cellular_phone_no: repCellphoneNumber });
              }}
            />

            {/* Phone number */}
            <TextField
              label={tWithoutPrefix('common.phoneNumber')}
              placeholder="1234567891234"
              value={repTelephoneNumber}
              onValueChange={setRepTelephoneNumber}
              error={repTelephoneNumberError}
              onErrorChange={setRepTelephoneNumberError}
              maxLength={13}
              validators={[validation.createLengthValidator(13), validation.isNumeric]}
              restriction={inputNotes.halfwidthNumber}
              onBlur={() => {
                saveData({ legal_representative_telephone_no: repTelephoneNumber });
              }}
            />
          </>
        )}
      </FieldGroup>
    </Section>
  );
};

export default StudentVisaOthersSection;
