import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ReactComponent as PlusIcon } from "../../../assets/icon-plus.svg";
import { ReactComponent as TrashCanIcon } from "../../../assets/icon-trash-can-2.svg";
import DateSelectField from "../../../components/compound/DateSelectField";
import RadioField from "../../../components/compound/RadioField";
import SelectField from "../../../components/compound/SelectField";
import TextField from "../../../components/compound/TextField";
import FieldGroupNumber from "../../../components/display/FieldGroupNumber";
import Button from "../../../components/form/Button";
import FieldGroup from "../../../components/layout/FieldGroup";
import Section from "../../../components/layout/Section";
import {
  ALL_NATIONALITY_OPTIONS,
  RELATIONSHIP_CB06_OPTIONS_WITHOUT_SELF,
} from "../../../constants/options";
import { useFieldInputNotes, useFieldPlaceholder, useValidation } from "../../../hooks";
import { ExtensionBySelfData } from "../../../types/visa/extensionBySelf/data";
import {
  getLengthOfZincSnzkList,
  getSnzkListKeyOf,
  isNoFieldError,
} from "../../../utils/visaFormDataHelpers/commonVisaFormDataHelper";
import { ChangeBySelfData } from "../../../types/visa/changeBySelf/data";
import {
  isThisVisaChange,
  isThisVisaExtension,
} from "../../../utils/visaApplicationHelper";
import {
  FamilyInJapanOrCoresidents,
  LivingTogetherWithThisFamilyOrCoresident,
} from "../../../types/visa/formCommonValues";
import { MAX_FAMILY_CORESIDENTS } from "../../../constants/values/commonValues";
import InlineMessage from "../../../components/form/InlineMessage";
import { ApplicationFormSectionProps } from "../type";
import { useSectionErrorHandler } from "../hook";

interface FamilyCoresidentsSectionProps extends ApplicationFormSectionProps {
  extensionBySelfData: ExtensionBySelfData | null;
  changeBySelfData: ChangeBySelfData | null;
  onChangeExtensionBySelfData: (data: Partial<ExtensionBySelfData>) => void;
  onChangeChangeBySelfData: (data: Partial<ChangeBySelfData>) => void;
}

export type FamilyCoresident = {
  relationship: string | null;
  name: string | null;
  birthdate: string | null;
  nationality: string | null;
  livingTogether: string | null;
  employerSchoolName: string | null;
  residenceCardNumber: string | null;
};

type FamilyCoresidentError = {
  name: string;
  employerSchoolName: string;
  residenceCardNumber: string;
};

const ButtonTextWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const RemoveButton = styled(Button).attrs({ variant: 'inline' })`
  width: auto;
  height: 40px;
  gap: 10px;
  margin: 0 auto;
  border-color: #ACACAC;
`;

const FamilyCoresidentsSection: FunctionComponent<FamilyCoresidentsSectionProps> = ({
  isActive,
  readOnly,
  visaApplicationType,
  extensionBySelfData,
  changeBySelfData,
  onChangeExtensionBySelfData,
  onChangeChangeBySelfData,
  onChangeSectionReadiness,
  showEmptyRequiredValueError,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "familyCoresidentsSection",
  });
  const { t: tWithoutPrefix } = useTranslation();
  const { inputNotes, conditionalInputNotes } = useFieldInputNotes();
  const placeholder = useFieldPlaceholder();
  const validation = useValidation();

	const getEmptyFamilyCoresident = (): FamilyCoresident => ({
		relationship: "",
		name: "",
		birthdate: "",
		nationality: "",
		livingTogether: "",
		employerSchoolName: "",
		residenceCardNumber: "",
	})

  const getEmptyFamilyCoresidentError = (): FamilyCoresidentError => ({
    name: "",
    employerSchoolName: "",
    residenceCardNumber: "",
  });

  const [familyCoresidentsPresent, setfamilyCoresidentsPresent] = useState("");
  const [familyCoresidents, setFamilyCoresidents] = useState<FamilyCoresident[]>([]);
  const [familyCoresidentErrors, setFamilyCoresidentErrors] = useState<FamilyCoresidentError[]>(
    Array.from({ length: MAX_FAMILY_CORESIDENTS }, () => getEmptyFamilyCoresidentError())
  );
  const isVisaExtension = isThisVisaExtension(visaApplicationType);
  const isVisaChange = isThisVisaChange(visaApplicationType);
  const { createGetEmptyFieldError } = useSectionErrorHandler();
  const getEmptyError = createGetEmptyFieldError({ additionalCondition: showEmptyRequiredValueError });
  const reachedLimit = familyCoresidents.length >= MAX_FAMILY_CORESIDENTS;

  const updateFamilyCoresident = (
    index: number,
    fieldName: keyof FamilyCoresident,
    val: string | null
  ) => {
    setFamilyCoresidents((prev) =>
      prev.map((familyCoresident, i) =>
        i === index
          ? { ...familyCoresident, [fieldName]: val }
          : familyCoresident
      )
    );
  };

  const updateFamilyCoresidentErrors = (
    index: number,
    fieldName: keyof FamilyCoresidentError,
    val: string
  ) => {
    setFamilyCoresidentErrors((prev) =>
      prev.map((familyCoresident, i) =>
        i === index
          ? { ...familyCoresident, [fieldName]: val }
          : familyCoresident
      )
    );
  };

  const addFamilyCoresident = () => {
    const nextIndex = familyCoresidents.length + 1;
    const data = {
      [getSnzkListKeyOf.selZkgr(nextIndex)]: "",
      [getSnzkListKeyOf.txtName(nextIndex)]: "",
      [getSnzkListKeyOf.selDateOfBirthYear(nextIndex)]: "",
      [getSnzkListKeyOf.selDateOfBirthMonth(nextIndex)]: "",
      [getSnzkListKeyOf.selDateOfBirthDay(nextIndex)]: "",
      [getSnzkListKeyOf.selNationalityAndRegion(nextIndex)]: "",
      [getSnzkListKeyOf.radDukyUm(nextIndex)]: "",
      [getSnzkListKeyOf.txtWorkPlaceOrTugkskName(nextIndex)]: "",
      [getSnzkListKeyOf.txtZiryCardNumOrTkeiNum(nextIndex)]: "",
    };

		setFamilyCoresidents((prev) => [...prev, getEmptyFamilyCoresident()]);

    if (isVisaExtension)
			onChangeExtensionBySelfData(data);

    if (isVisaChange)
			onChangeChangeBySelfData(data);
  };

  const getEmptyfamilyCoresidentsFields = () => {
    let data = {};

    for (let i = 1; i <= MAX_FAMILY_CORESIDENTS; i++) {
      data = {
        ...data,
        [getSnzkListKeyOf.selZkgr(i)]: null,
        [getSnzkListKeyOf.txtName(i)]: null,
        [getSnzkListKeyOf.selDateOfBirthYear(i)]: null,
        [getSnzkListKeyOf.selDateOfBirthMonth(i)]: null,
        [getSnzkListKeyOf.selDateOfBirthDay(i)]: null,
        [getSnzkListKeyOf.selNationalityAndRegion(i)]: null,
        [getSnzkListKeyOf.radDukyUm(i)]: null,
        [getSnzkListKeyOf.txtWorkPlaceOrTugkskName(i)]: null,
        [getSnzkListKeyOf.txtZiryCardNumOrTkeiNum(i)]: null,
      };
    }
    return data;
  };

  const onClickRemove = (curFcIndex: number) => {
    const data = extensionBySelfData || changeBySelfData || {};
    let newData = {};

    for (let i = curFcIndex; i < MAX_FAMILY_CORESIDENTS; i++) {
      const nextFcInLocalState: FamilyCoresident = familyCoresidents[i + 1] ?? {
        relationship: null,
        name: null,
        birthdate: null,
        nationality: null,
        livingTogether: null,
        employerSchoolName: null,
        residenceCardNumber: null,
      };
      const nextFcInDB: FamilyCoresident = {
        relationship: data?.[getSnzkListKeyOf.selZkgr(i + 1)] ?? null,
        name: data?.[getSnzkListKeyOf.txtName(i + 1)] ?? null,
        birthdate: data?.[getSnzkListKeyOf.selDateOfBirthYear(i + 1)] ?? null,
        nationality: data?.[getSnzkListKeyOf.selNationalityAndRegion(i + 1)] ?? null,
        livingTogether: data?.[getSnzkListKeyOf.radDukyUm(i + 1)] ?? null,
        employerSchoolName: data?.[getSnzkListKeyOf.txtWorkPlaceOrTugkskName(i + 1)] ?? null,
        residenceCardNumber: data?.[getSnzkListKeyOf.txtZiryCardNumOrTkeiNum(i + 1)] ?? null,
      };

      const nextFcError: FamilyCoresidentError =
        familyCoresidentErrors[i + 1] ?? getEmptyFamilyCoresidentError();

      updateFamilyCoresident(i, "relationship", nextFcInLocalState.relationship);
      updateFamilyCoresident(i, "name", nextFcInLocalState.name);
      updateFamilyCoresident(i, "birthdate", nextFcInLocalState.birthdate);
      updateFamilyCoresident(i, "nationality", nextFcInLocalState.nationality);
      updateFamilyCoresident(i, "livingTogether", nextFcInLocalState.livingTogether);
      updateFamilyCoresident(i, "employerSchoolName", nextFcInLocalState.employerSchoolName);
      updateFamilyCoresident(i, "residenceCardNumber", nextFcInLocalState.residenceCardNumber);

      updateFamilyCoresidentErrors(i, "name", nextFcError.name);
      updateFamilyCoresidentErrors(i, "employerSchoolName", nextFcError.employerSchoolName);
      updateFamilyCoresidentErrors(i, "residenceCardNumber", nextFcError.residenceCardNumber);

			const [year, month, day] = nextFcInLocalState.birthdate?.split("-") ?? [];

      newData = {
        ...newData,
        [getSnzkListKeyOf.selZkgr(i + 1)]: nextFcInLocalState.relationship,
        [getSnzkListKeyOf.txtName(i + 1)]: nextFcError.name
          ? nextFcInDB.name
          : nextFcInLocalState.name,
        [getSnzkListKeyOf.selDateOfBirthYear(i + 1)]: year ?? null,
        [getSnzkListKeyOf.selDateOfBirthMonth(i + 1)]: month ?? null,
        [getSnzkListKeyOf.selDateOfBirthDay(i + 1)]: day ?? null,
        [getSnzkListKeyOf.selNationalityAndRegion(i + 1)]: nextFcInLocalState.nationality,
        [getSnzkListKeyOf.radDukyUm(i + 1)]: nextFcInLocalState.livingTogether,
        [getSnzkListKeyOf.txtWorkPlaceOrTugkskName(i + 1)]:
          nextFcError.employerSchoolName
            ? nextFcInDB.employerSchoolName
            : nextFcInLocalState.employerSchoolName,
        [getSnzkListKeyOf.txtZiryCardNumOrTkeiNum(i + 1)]:
          nextFcError.residenceCardNumber
            ? nextFcInDB.residenceCardNumber
            : nextFcInLocalState.residenceCardNumber,
      };
    }

		setFamilyCoresidents(prev =>
			prev.slice(0, prev.length - 1));

		setFamilyCoresidentErrors(prev => [
			...prev.slice(0, prev.length - 1),
			getEmptyFamilyCoresidentError(),
		]);

		if (isVisaExtension)
			onChangeExtensionBySelfData(newData);

		if (isVisaChange)
			onChangeChangeBySelfData(newData);
  };

  useEffect(() => {
		const data = extensionBySelfData ?? changeBySelfData ?? {};
    const fcs: FamilyCoresident[] = [];

		if (isVisaExtension && extensionBySelfData)
      setfamilyCoresidentsPresent(extensionBySelfData["WCIBS010Dto:radZincSnzkUm1"] ?? "");

		if (isVisaChange && changeBySelfData)
			setfamilyCoresidentsPresent(changeBySelfData["WCIBS010Dto:radZincSnzkUm1"] ?? "");

      for (let i = 1; i <= getLengthOfZincSnzkList(data); i++) {
        fcs.push({
          relationship: data[getSnzkListKeyOf.selZkgr(i)] ?? "",

          name: isNoFieldError(familyCoresidentErrors[i - 1]?.name)
            ? data[getSnzkListKeyOf.txtName(i)] ?? ""
            : familyCoresidents[i - 1]?.name ?? "",

          birthdate: [
            data[getSnzkListKeyOf.selDateOfBirthYear(i)],
            data[getSnzkListKeyOf.selDateOfBirthMonth(i)],
            data[getSnzkListKeyOf.selDateOfBirthDay(i)],
          ].filter((v) => v).join("-"),

          nationality: data[getSnzkListKeyOf.selNationalityAndRegion(i)] ?? "",

          livingTogether: data[getSnzkListKeyOf.radDukyUm(i)] ?? "",

          employerSchoolName:
						isNoFieldError(familyCoresidentErrors[i - 1]?.employerSchoolName)
            	? data[getSnzkListKeyOf.txtWorkPlaceOrTugkskName(i)] ?? ""
            	: familyCoresidents[i - 1]?.employerSchoolName ?? "",

          residenceCardNumber:
						isNoFieldError(familyCoresidentErrors[i - 1]?.residenceCardNumber)
							? data[getSnzkListKeyOf.txtZiryCardNumOrTkeiNum(i)] ?? ""
							: familyCoresidents[i - 1]?.residenceCardNumber ?? "",
        });
      }

    setFamilyCoresidents(fcs);
  }, [extensionBySelfData, changeBySelfData]);

  useEffect(() => {
    const areAllRequiredFieldsFilled = [
			familyCoresidentsPresent
		].every(val => !!val);

    const areThereNoErrors = familyCoresidentErrors
			.flatMap(err => Object.values(err))
			.every(err => !err);

    onChangeSectionReadiness(areAllRequiredFieldsFilled && areThereNoErrors);
  }, [familyCoresidentsPresent, familyCoresidents, familyCoresidentErrors]);


  if (isActive === false)
    return null;

  return (
    <Section>
      {/* "Do you have any family members in Japan or
           are you living together with someone?"  */}
      <RadioField
        required
        disabled={readOnly}
        label={t("familyInJapan")}
        options={[
          {
            label: tWithoutPrefix("common.yes"),
            value: FamilyInJapanOrCoresidents.Present,
          },
          {
            label: tWithoutPrefix("common.no"),
            value: FamilyInJapanOrCoresidents.None,
          },
        ]}
        value={familyCoresidentsPresent}
        error={getEmptyError(familyCoresidentsPresent)}
        note={t("noteOnFamily")}
        onValueChange={(val) => {
          let additionalUpdateData = {};

          setfamilyCoresidentsPresent(val);

          if (val === FamilyInJapanOrCoresidents.None)
            additionalUpdateData = getEmptyfamilyCoresidentsFields() ?? {};

          isVisaExtension &&
            onChangeExtensionBySelfData({
              "WCIBS010Dto:radZincSnzkUm1": val,
              ...additionalUpdateData,
            });

          isVisaChange &&
            onChangeChangeBySelfData({
              "WCIBS010Dto:radZincSnzkUm1": val,
              ...additionalUpdateData,
            });
        }}
      />

      {familyCoresidentsPresent === FamilyInJapanOrCoresidents.Present &&
        familyCoresidents.map((familyCoresident, index) => (
          <FieldGroup key={index}>
            <FieldGroupNumber>{index + 1}</FieldGroupNumber>

            { !readOnly &&
              <RemoveButton onClick={() => onClickRemove(index)}>
                <TrashCanIcon/>
                {t("remove")}
						  </RemoveButton>
            }

            {/* Relationship */}
            <SelectField
              disabled={readOnly}
              label={t("relationship")}
              placeholder={tWithoutPrefix("placeholder.select")}
              options={RELATIONSHIP_CB06_OPTIONS_WITHOUT_SELF}
              value={familyCoresident.relationship ?? ""}
              onValueChange={(val) => {
                updateFamilyCoresident(index, "relationship", val);
                isVisaExtension &&
                  onChangeExtensionBySelfData({
                    [getSnzkListKeyOf.selZkgr(index + 1)]: val,
                  });
                isVisaChange &&
                  onChangeChangeBySelfData({
                    [getSnzkListKeyOf.selZkgr(index + 1)]: val,
                  });
              }}
            />

            {/* Name */}
            <TextField
              disabled={readOnly}
              label={t("name")}
              placeholder="グエン　ハイン"
              value={familyCoresident.name ?? ""}
              error={familyCoresidentErrors[index].name}
              restriction={inputNotes.fullwidthJapanese}
              maxLength={26}
              note={t("noteOnNameOrder")}
              validators={[validation.isFullwidth]}
              onValueChange={(val) =>
                updateFamilyCoresident(index, "name", val)
              }
              onErrorChange={(newErr) =>
                updateFamilyCoresidentErrors(index, "name", newErr)
              }
              onBlur={() => {
                isVisaExtension &&
                  onChangeExtensionBySelfData({
                    [getSnzkListKeyOf.txtName(index + 1)]:
                      familyCoresident.name,
                  });

                isVisaChange &&
                  onChangeChangeBySelfData({
                    [getSnzkListKeyOf.txtName(index + 1)]:
                      familyCoresident.name,
                  });
              }}
            />

            {/* Birthdate */}
            <DateSelectField
              disabled={readOnly}
              label={t("birthdate")}
              minDate={new Date(1930, 0, 1)}
              maxDate={new Date()}
              value={familyCoresident.birthdate ?? ""}
              onValueChange={(val) => {
                updateFamilyCoresident(index, "birthdate", val);

                if (!val) return;

                const [year, month, day] = val.split("-");

                isVisaExtension &&
                  onChangeExtensionBySelfData({
                    [getSnzkListKeyOf.selDateOfBirthYear(index + 1)]: year,
                    [getSnzkListKeyOf.selDateOfBirthMonth(index + 1)]: month,
                    [getSnzkListKeyOf.selDateOfBirthDay(index + 1)]: day,
                  });

                isVisaChange &&
                  onChangeChangeBySelfData({
                    [getSnzkListKeyOf.selDateOfBirthYear(index + 1)]: year,
                    [getSnzkListKeyOf.selDateOfBirthMonth(index + 1)]: month,
                    [getSnzkListKeyOf.selDateOfBirthDay(index + 1)]: day,
                  });
              }}
            />

            {/* Nationality */}
            <SelectField
              disabled={readOnly}
              label={t("nationality")}
              placeholder={placeholder.selectNationality}
              options={ALL_NATIONALITY_OPTIONS}
              value={familyCoresident.nationality ?? ""}
              onValueChange={(val) => {
                updateFamilyCoresident(index, "nationality", val);

                isVisaExtension &&
                  onChangeExtensionBySelfData({
                    [getSnzkListKeyOf.selNationalityAndRegion(index + 1)]: val,
                  });

                isVisaChange &&
                  onChangeChangeBySelfData({
                    [getSnzkListKeyOf.selNationalityAndRegion(index + 1)]: val,
                  });
              }}
            />

            {/* "Are you currently living together?" */}
            <RadioField
              disabled={readOnly}
              label={t("livigingTogether")}
              options={[
                {
                  label: tWithoutPrefix("common.yes"),
                  value: LivingTogetherWithThisFamilyOrCoresident.Yes,
                },
                {
                  label: tWithoutPrefix("common.no"),
                  value: LivingTogetherWithThisFamilyOrCoresident.No,
                },
              ]}
              value={familyCoresident.livingTogether}
              onValueChange={(val) => {
                updateFamilyCoresident(index, "livingTogether", val);

                isVisaExtension &&
                  onChangeExtensionBySelfData({
                    [getSnzkListKeyOf.radDukyUm(index + 1)]: val,
                  });

                isVisaChange &&
                  onChangeChangeBySelfData({
                    [getSnzkListKeyOf.radDukyUm(index + 1)]: val,
                  });
              }}
            />

            {/* Employer/school */}
            <TextField
              disabled={readOnly}
              label={t("employmentOrSchool")}
              placeholder="ビザダス株式会社"
              value={familyCoresident.employerSchoolName ?? ""}
              error={familyCoresidentErrors[index].employerSchoolName}
              restriction={inputNotes.fullwidthJapanese}
              maxLength={60}
              validators={[
                validation.isFullwidth,
                validation.createLengthValidator(60),
              ]}
              onValueChange={(val) =>
                updateFamilyCoresident(index, "employerSchoolName", val)
              }
              onErrorChange={(newErr) =>
                updateFamilyCoresidentErrors(
                  index,
                  "employerSchoolName",
                  newErr
                )
              }
              onBlur={() => {
                isVisaExtension &&
                  onChangeExtensionBySelfData({
                    [getSnzkListKeyOf.txtWorkPlaceOrTugkskName(index + 1)]:
                      familyCoresident.employerSchoolName,
                  });

                isVisaChange &&
                  onChangeChangeBySelfData({
                    [getSnzkListKeyOf.txtWorkPlaceOrTugkskName(index + 1)]:
                      familyCoresident.employerSchoolName,
                  });
              }}
            />

            {/* Residence card number */}
            <TextField
              disabled={readOnly}
              label={t("residenceCardNumber")}
              placeholder="AAAA9999999C"
              value={familyCoresident.residenceCardNumber ?? ""}
              error={familyCoresidentErrors[index].residenceCardNumber}
              restriction={conditionalInputNotes.halfwidthLetterAndNumber}
              maxLength={12}
              validators={[
                validation.isAlphanumeric,
                validation.createLengthValidator(12),
              ]}
              onValueChange={(val) =>
                updateFamilyCoresident(index, "residenceCardNumber", val)
              }
              onErrorChange={(newErr) =>
                updateFamilyCoresidentErrors(
                  index,
                  "residenceCardNumber",
                  newErr
                )
              }
              onBlur={() => {
                isVisaExtension &&
                  onChangeExtensionBySelfData({
                    [getSnzkListKeyOf.txtZiryCardNumOrTkeiNum(index + 1)]:
                      familyCoresident.residenceCardNumber,
                  });

                isVisaChange &&
                  onChangeChangeBySelfData({
                    [getSnzkListKeyOf.txtZiryCardNumOrTkeiNum(index + 1)]:
                      familyCoresident.residenceCardNumber,
                  });
              }}
            />
          </FieldGroup>
        ))}

      {familyCoresidentsPresent === FamilyInJapanOrCoresidents.Present &&
       !readOnly &&
        (
          <>
            { reachedLimit &&
              <InlineMessage>{t("reachedMaximumPersonNum")}</InlineMessage>
            }
            <Button
              disabled={reachedLimit}
              variant="inline"
              onClick={addFamilyCoresident}
            >
              <ButtonTextWrapper>
                <PlusIcon/>
                {t("addFamilyCoresident")}
              </ButtonTextWrapper>
            </Button>
          </>
        )}
    </Section>
  );
};

export default FamilyCoresidentsSection;
