import { ButtonHTMLAttributes, ChangeEvent, FunctionComponent, useRef } from "react";
import Text from "../text/Text";
import { ReactComponent as PlusIcon } from '../../assets/icon-plus.svg';
import { useTranslation } from "react-i18next";
import Button from "./Button";
import styled from "styled-components";
import { ACCEPT_FOR_AI_READING_AND_VISA_APP } from "../../constants/values/acceptFiles";

type UploadButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  customText?: string;
  customIcon?: React.ReactNode;
  uploadLimitInMB?: number;
  onFileChange?: (file: File) => void;
  onFileSizeExceeded?: (sizeInBytes: number, fileIndex: number) => void;
  accept?: string;
};

const AddIcon = styled(PlusIcon)`
  & * {
    fill: #3D3F43;
  }
`;


const UploadButton: FunctionComponent<UploadButtonProps> = ({
  customText,
  customIcon,
  uploadLimitInMB,
  onFileChange,
  onFileSizeExceeded,
  accept,
  ...props
}) => {
  const ref = useRef(null);
  const { t } = useTranslation();
  const defaultUploadLimitInMB = 5;
  const uploadLimitInBytes =
    Math.pow(1024, 2) * (uploadLimitInMB ?? defaultUploadLimitInMB);
  const handleClick = () => {
    if (ref.current) {
      (ref.current! as HTMLInputElement).click();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;

    if (onFileSizeExceeded) {
      for (let i = 0; i < files.length; i++) {
        const size = files[i].size;
        if (size > uploadLimitInBytes) {
          onFileSizeExceeded(size, i);
          return;
        }
      }
    }

    onFileChange && onFileChange(files[0]);
  };

  return (
    <Button
      variant="inline"
      disabled={props.disabled}
      onClick={handleClick}
      style={{ width: "100%" }}
      {...props}
    >
      { customIcon ? customIcon : <AddIcon /> }
      <Text style={{ marginLeft: 10 }}>
        { customText ?? t("upload.attach")}
      </Text>
      <input
        ref={ref}
        style={{ display: "none" }}
        type="file"
        onChange={handleChange}
        accept={accept ?? ACCEPT_FOR_AI_READING_AND_VISA_APP}
      />
    </Button>
  );
};

export default UploadButton;
