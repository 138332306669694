import { SelectOption } from "../../../types/option";
import { PERIOD_OF_STAY_CB20_OPTIONS_FOR_DEPENDENT } from "../periodOfStays/periodOfStayForDependent";

const getTkey = (key: string) => `desiredPeriodOfStay_C970.${key}`;

//Ref: 
//Dependent => https://docs.google.com/spreadsheets/d/1DSH9psUEakHitz56tuTrRxzV0y07nI_KhafCmHlUjCI/edit#gid=184004888&range=A62
//Technical => https://docs.google.com/spreadsheets/d/1DSH9psUEakHitz56tuTrRxzV0y07nI_KhafCmHlUjCI/edit#gid=1606825711

export const DESIRED_PERIOD_OF_STAY_C970_OPTIONS_FOR_DEPENDENT: SelectOption[] = [
  {
      label: getTkey("T440003000"),
      value: "T440003000"
  },
  {
      label: getTkey("T440006000"),
      value: "T440006000"
  },
  {
      label: getTkey("T440100000"),
      value: "T440100000"
  },
  {
      label: getTkey("T440103000"),
      value: "T440103000"
  },
  {
      label: getTkey("T440200000"),
      value: "T440200000"
  },
  {
      label: getTkey("T440203000"),
      value: "T440203000"
  },
  {
      label: getTkey("T440300000"),
      value: "T440300000"
  },
  {
      label: getTkey("T440303000"),
      value: "T440303000"
  },
  {
      label: getTkey("T440400000"),
      value: "T440400000"
  },
  {
      label: getTkey("T440403000"),
      value: "T440403000"
  },
  {
      label: getTkey("T440500000"),
      value: "T440500000"
  },
];

/* This is intentional.
   When it comes to Visa change, we use CB20 codes for "Desired Period of Stay" field,
   and the codes are common to the codes used for the field "Period of Stay"
*/
export const DESIRED_PERIOD_OF_STAY_CB20_OPTIONS_FOR_DEPENDENT: SelectOption[] = 
    PERIOD_OF_STAY_CB20_OPTIONS_FOR_DEPENDENT;