import { User } from "../types/user/data";
import { UserRegistrationApplication } from "../types/userRegistration/data";
import { isUserRegistrationApplicationDraft } from "./userRegistrationHelper";

export const isThisNewUser = (user: User | null): boolean  => {
  if (!user)
    return true;

  return !user.uses_myna && !user.uses_certified_administrative_procedures_legal_specialist;
}

export const isUserSetupCompleted = (user: User | null): boolean => {
  if (!user)
    return false;

  return !!user.status_of_residence && !!user.user_setting.language;
}

/* Onboarding is required if...
  1. User has selected neither "Apply for yourself" nor
    "Request an administrative scrivener"
  2. User selected "Apply for yourself" but hasn't completed the user registration
  3. After the user registration is denied (need to reapply then) 
*/
export const isRequiredToCompleteOnboarding = (user: User | null, userRegApp: UserRegistrationApplication | null): boolean => {
  if (!user || !userRegApp) 
    return true;

  return (isThisNewUser(user) || (
    !!user.uses_myna &&
    isUserRegistrationApplicationDraft(userRegApp)
  ));
}