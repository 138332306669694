import { FunctionComponent, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useComponentVisible } from './hooks'
import { useTranslation } from 'react-i18next';
import { ReactComponent as BlobeIcon } from './assets/icon-globe.svg';
import { CONTENT_MAX_WIDTH } from './constants/styles';
import { LanguageCode } from './types/language';
import Text from './components/text/Text';


const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`

const LocalText = styled(Text)`
  color: #2C2C2C;
`

interface MenuProps {
    visible?: boolean;
}

const Menu = styled.div<MenuProps>`
  position: absolute;
  top: 50%;
  right: 0;
  background: white;
  box-shadow: 2px 2px 8px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 5;
  visibility: ${(props: MenuProps) => props.visible ? 'visible' : 'hidden'};
  
  > *:first-child {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
  
  > *:last-child {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  @media (max-width: ${CONTENT_MAX_WIDTH}px) {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    
    > * {
      border-bottom: 1px solid #D5D5D5;
    }
  }
  
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 10px;
  width: 142px;
  height: 38px;
  font-size: 0.9rem;
  line-height: 24px;
  color: #2C2C2C;
  
  &:hover {
    color: white;
    background: #0069EC;
  }

  @media (max-width: ${CONTENT_MAX_WIDTH}px) {
    width: 100%;
    height: 48px;
  }
`;


interface LanguageSelectorProps extends HTMLAttributes<HTMLDivElement> {
}

const LanguageSelector: FunctionComponent<LanguageSelectorProps> = ({
    ...props
}) => {
    const { i18n } = useTranslation();
    const { ref, visible, setVisible } = useComponentVisible(false);
    const languageLabels: Record<string, string> = {
      [LanguageCode.English]: 'English',
      [LanguageCode.Japanese]: '日本語'
    }

    const handleClick = (language: string) => {
        setVisible(false)
        i18n.changeLanguage(language);
        sessionStorage.setItem('lng', language);
    }

    return (
        <Container onClick={() => setVisible(true)} {...props}>
            <BlobeIcon/>
            <LocalText>{languageLabels[i18n.language]}</LocalText>
            <Menu ref={ref} visible={visible}>
                {Object.entries(languageLabels).map(([key, value]) => (
                    <Item
                        key={key}
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            handleClick(key);
                        }}
                    >
                        {value}
                    </Item>
                ))}
            </Menu>
        </Container>
    )
}

export default LanguageSelector