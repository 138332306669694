export const SECTION_NAMES = {
  basicInfomation: 'basic-information',
  statusOfResidence: 'status-of-residence',
  detailsOfExtension: 'details-of-extension',
  detailsOfChange: 'details-of-change',
  familyCoResidents: 'family-co-residents',
  placeAndDateOfNotification: 'place-and-date-of-notification',
  methodOfSupportEtc: 'method-of-support-etc',
  supporter: 'supporter',
  other: 'other',
  attachments: 'attachments'
}