import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import {
  SectionHeading,
} from "../../../previewBodyCommonStyle";
import { FormN } from "../../../../../../../types/visa/uncommonFormParts/formN/data";
import { FINAL_EDUCATION_COLLEGE_OF_TECH_VALUE, FINAL_EDUCATION_DRMSBCJC_VALUES, FINAL_EDUCATION_OTHER_VALUE, MAJOR_FIELD_OF_STUDY_FOR_COLLEGE_OF_TECH_FOR_TECHNICAL_OTHER_VALUE, MAJOR_FIELD_OF_STUDY_FOR_DRMSBCJC_FOR_TECH_OTHER_VALUE } from "../../../../../../../types/visa/uncommonFormParts/formN/value";
import PreviewList from "../../../../PreviewList";
import { getLabelFromFinalEducation, getLabelFromMajorFieldOfStudyForCollegeOfTech, getLabelFromMajorFieldOfStudyForDrMsBcJc, getTransKeyForFinalEducationLocation } from "../../../../../../../utils/visaFormDataHelpers/gijinkokuVisaFormDataHelper";
import { formatDateString } from "../../../../../../../utils/utils";

interface GijinkokuVisaFinalEducationSectionProps {
  data: FormN
}

export const GijinkokuVisaFinalEducationSection: FunctionComponent<GijinkokuVisaFinalEducationSectionProps> = ({ 
  data 
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'finalEducationSection' });
  const { t : tWithoutPrefix } = useTranslation();

  return (
    <section>
      <SectionHeading>{t("title")}</SectionHeading>
      <PreviewList
        items={[
          {
            label: t("finalEducation"),
            value: data["educational_background_kind"]
              ? tWithoutPrefix(`${getLabelFromFinalEducation(data["educational_background_kind"])}`)
              : ""
          },
          {
            label: t("finalEducationOther"),
            value: data["educational_background_kind_other"],
            skip: data["educational_background_kind"] !== FINAL_EDUCATION_OTHER_VALUE,
          },
          {
            label: t("nameOfSchool"),
            value: data["educational_background_name_of_school"],
          },
          {
            label: t("location"),
            value: data["educational_background_place"]
              ? t(`${getTransKeyForFinalEducationLocation(data["educational_background_place"])}`)
              : ""
          },
          {
            label: t("dateOfGraduation"),
            value: formatDateString(data["educational_background_date"])
          },
          {
            label: t("majorFieldOfStudy"),
            value: data["major_field_of_study"]
              ? tWithoutPrefix(`${getLabelFromMajorFieldOfStudyForDrMsBcJc(data["major_field_of_study"])}`)
              : "",
            skip: 
              !(FINAL_EDUCATION_DRMSBCJC_VALUES.includes(data["educational_background_kind"] ?? ''))
          },
          {
            label: t("majorFieldOfStudyOther"),
            value: data["major_field_of_study_other"],
            skip: 
              !(FINAL_EDUCATION_DRMSBCJC_VALUES.includes(data["educational_background_kind"] ?? '')) ||                 
              data["major_field_of_study"] !== MAJOR_FIELD_OF_STUDY_FOR_DRMSBCJC_FOR_TECH_OTHER_VALUE
          },
          {
            label: t("majorFieldOfStudy"),
            value: data["major_field_of_study"]
              ? tWithoutPrefix(`${getLabelFromMajorFieldOfStudyForCollegeOfTech(data["major_field_of_study"])}`)
              : "",
            skip: 
              !(data["educational_background_kind"] === FINAL_EDUCATION_COLLEGE_OF_TECH_VALUE)
          },
          {
            label: t("majorFieldOfStudyOther"),
            value: data["major_field_of_study_other"],
            skip: 
              !(data["educational_background_kind"] === FINAL_EDUCATION_COLLEGE_OF_TECH_VALUE) ||
              data["major_field_of_study"] !== MAJOR_FIELD_OF_STUDY_FOR_COLLEGE_OF_TECH_FOR_TECHNICAL_OTHER_VALUE
          }
        ]}
      />
    </section>
  );
};

export default GijinkokuVisaFinalEducationSection;
