import { FunctionComponent } from "react";
import { ExtensionBySelfData } from "../../../../../../types/visa/extensionBySelf/data";
import { ChangeBySelfData } from "../../../../../../types/visa/changeBySelf/data";
import {
  convertKValToFullAmount,
  formatDate,
  getChangeBySelfDataProperty,
  getLabelFromNationalityCode,
  getLabelsFromAddressCode,
  parseName,
} from "../../../../../../utils/visaFormDataHelpers/commonVisaFormDataHelper";
import { useTranslation } from "react-i18next";
import {
  Group,
  SectionHeading,
  SectionSubHeading,
} from "../../previewBodyCommonStyle";
import { CrimePunishmentStatus, Gender, MaritalStatus } from "../../../../../../types/visa/formCommonValues";
import PreviewList from "../../../PreviewList";
import { isThisVisaExtension } from "../../../../../../utils/visaApplicationHelper";
import { VisaApplication } from "../../../../../../types/visa/applicationData";

interface BasicInformationSectionProps {
  data: ExtensionBySelfData | ChangeBySelfData;
  visaApplication: VisaApplication;
}

export const BasicInformationSection: FunctionComponent<
  BasicInformationSectionProps
> = ({ data, visaApplication }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'basicInformationSection' });
  const { t : tWithoutPrefix } = useTranslation();
  const isVisaExtension = isThisVisaExtension(visaApplication);

  return (
    <section>
      <SectionHeading>{t("title")}</SectionHeading>
      <PreviewList
        items={[
          {
            label: t("familyName"),
            value: parseName(data["WCIBS010Dto:txtName"], ",").familyName,
          },
          {
            label: t("givenName"),
            value: parseName(data["WCIBS010Dto:txtName"], ",").givenName,
          },
          {
            label: t("middleName"),
            value: parseName(data["WCIBS010Dto:txtName"], ",").middleName,
          },
          {
            label: t("gender"),
            value: (() => {
              switch (data["WCIBS010Dto:radSex"]) {
                case Gender.Male: return t("male");
                case Gender.Female: return t("female")
              }
            })()
          },
          {
            label: t("birthdate"),
            value: formatDate(
              data["WCIBS010Dto:selDateOfBirthYear"],
              data["WCIBS010Dto:selDateOfBirthMonth"],
              data["WCIBS010Dto:selDateOfBirthDay"]
            ),
          },
          {
            label: t("nationality"),
            value: tWithoutPrefix(`${
                getLabelFromNationalityCode(
                  data["WCIBS010Dto:selNationalityAndRegion"]
                ) ?? ""
              }`
            ),
          },
          {
            label: t("birthplace"),
            value: getChangeBySelfDataProperty(data, "WCICS020Dto:txtSyst"),
            skip: isVisaExtension
          },
          {
            label: t("maritalStatus"),
            value: (() => {
              switch (data['WCIBS010Dto:radSpouse']) {
                case MaritalStatus.Married: return t("married");
                case MaritalStatus.Single: return t("single");
                default: return "";
              }
            })()
          },
          {
            label: t("occupation"),
            value: data["WCIBS010Dto:txtJob"]
          },
          {
            label: t("residenceInHomeCountry"),
            value: data["WCIBS010Dto:txtHomeCountryAddress"],
          },
        ]}
      />

      <Group>
        <SectionSubHeading>{t("currentResidence")}</SectionSubHeading>
        <PreviewList
          items={[
            {
              label: t("prefecture"),
              value: tWithoutPrefix(`${
                  getLabelsFromAddressCode(
                    data["WCIBS010Dto:hdnSearchedAddress"]
                  )?.prefecture ?? ""
                }`
              ),
            },
            {
              label: t("municipality"),
              value: tWithoutPrefix(`${
                  getLabelsFromAddressCode(
                    data["WCIBS010Dto:hdnSearchedAddress"]
                  )?.municipality ?? ""
                }`
              ),
            },
            {
              label: t("townStreetApartment"),
              value: data["WCIBS010Dto:txtDetailHomeAddress"],
            },
          ]}
        />
      </Group>

      <PreviewList
        items={[
          {
            label: t("cellphoneNumber"),
            value: data["WCIBS010Dto:txtCelPhoneNum"],
          },
          {
            label: t("telephoneNumber"),
            value: data["WCIBS010Dto:txtTelNum"],
          },
          {
            label: t("email"),
            value: data["WCIBS010Dto:txtMailAddress"],
          },
          {
            label: t("passportNumber"),
            value: data["WCIBS010Dto:txtPassportNum"],
          },
          {
            label: t("passportExpirationDate"),
            value: formatDate(
              data["WCIBS010Dto:selPassportExpirationYear"],
              data["WCIBS010Dto:selPassportExpirationMonth"],
              data["WCIBS010Dto:selPassportExpirationDay"]
            ),
          },
          {
            label: t("amountOfResidentTaxPaid"),
            value: convertKValToFullAmount(data["WCIBS010Dto:txtTaxPay"], true)
          },
          {
            label: t("criminalRecordLabel"),
            value: (() => {
              switch (data["WCIBS010Dto:radHnziUm1"]) {
                case CrimePunishmentStatus.Punished: return tWithoutPrefix("common.yes");
                case CrimePunishmentStatus.None: return tWithoutPrefix("common.no");
                default: return "";
              }
            })()
          },
          {
            label: t("criminalRecordDetails"),
            value: data["WCIBS010Dto:txtHnziNiyu"],
            skip: data["WCIBS010Dto:radHnziUm1"] === CrimePunishmentStatus.None,
          },
        ]}
      />
    </section>
  );
};

export default BasicInformationSection;
