//Ref: https://docs.google.com/spreadsheets/d/1DSH9psUEakHitz56tuTrRxzV0y07nI_KhafCmHlUjCI/edit?gid=1813510246#gid=1813510246&range=C405

/*
01	本人	Self
03	夫	Husband
02	妻	Wife
04	子	Child
05	父	Father
06	母	Mother
07	兄	Old brother
08	弟	Young brother
09	姉	Old sister
10	妹	Young sister
11	祖父	Grandfather
12	祖母	Grandmother
13	孫	Grandchild
14	養父	Foster father
15	養母	Foster mother
16	養子	Adopted child
17	義父	Father in law
18	義母	Mother in law
19	連れ子	Child of spouse (Please select just below)
20	配偶者の子	Stepchild
21	身元保証人	Guarantor
51	兄弟姉妹	Sibling
52	叔父(伯父)・叔母(伯母)	Uncle / Aunt
41	受入教育機関	Educational institute
31	雇用主	Employer
32	友人・知人	Friend / Acquaintance
33	友人・知人の親族	Relative of friend / acquaintance
42	取引関係者・現地企業等職員	Business connection / Personnel of local enterprise
43	取引関係者・現地企業等職員の親族	Relative of business connection / personnel of local enterprise
99	その他	Others
*/

export enum Relationship_CB06 {
  Self = '01',
  Husband = '03',
  Wife = '02',
  Child = '04',
  Father = '05',
  Mother = '06',
  OldBrother = '07',
  YoungBrother = '08',
  OldSister = '09',
  YoungSister = '10',
  Grandfather = '11',
  Grandmother = '12',
  Grandchild = '13',
  FosterFather = '14',
  FosterMother = '15',
  AdoptedChild = '16',
  FatherInLaw = '17',
  MotherInLaw = '18',
  ChildOfSpouse = '19',
  Stepchild = '20',
  Guarantor = '21',
  Sibling = '51',
  UncleAunt = '52',
  EducationalInstitute = '41',
  Employer = '31',
  FriendAcquaintance = '32',
  RelativeOfFriendAcquaintance = '33',
  BusinessConnectionPersonnelOfLocalEnterprise = '42',
  RelativeOfBusinessConnectionPersonnelOfLocalEnterprise = '43',
  Others = '99',
}

export enum RelationshipInJapanese {
  Self = '本人',
  Husband = '夫',
  Wife = '妻',
  Child = '子',
  Father = '父',
  Mother = '母',
  OldBrother = '兄',
  YoungBrother = '弟',
  OldSister = '姉',
  YoungSister = '妹',
  Grandfather = '祖父',
  Grandmother = '祖母',
  Grandchild = '孫',
  FosterFather = '養父',
  FosterMother = '養母',
  AdoptedChild = '養子',
  FatherInLaw = '義父',
  MotherInLaw = '義母',
  ChildOfSpouse = '連れ子',
  Stepchild = '配偶者の子',
  Guarantor = '身元保証人',
  Sibling = '兄弟姉妹',
  UncleAunt = '叔父(伯父)・叔母(伯母)',
  EducationalInstitute = '受入教育機関',
  Employer = '雇用主',
  FriendAcquaintance = '友人・知人',
  RelativeOfFriendAcquaintance = '友人・知人の親族',
  BusinessConnectionPersonnelOfLocalEnterprise = '取引関係者・現地企業等職員',
  RelativeOfBusinessConnectionPersonnelOfLocalEnterprise = '取引関係者・現地企業等職員の親族',
  Others = 'その他',
}