import React, { FunctionComponent, HTMLAttributes } from 'react';
import styled from 'styled-components';

interface TextProps extends HTMLAttributes<HTMLSpanElement> {
    variant?: 'regular'|'tiny';
    color?: string;
}

const Container = styled.span<TextProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${(props: TextProps) => props.variant === 'tiny' ? '1.375rem' : '0.9rem'};
  line-height: ${(props: TextProps) => props.variant === 'tiny' ? '36px' : '20px'};
  color: ${(props: TextProps) => props.color ? props.color :
      (props.variant === 'tiny' ? '#999999' : '#444444')
  };
  zoom: ${(props: TextProps) => props.variant === 'tiny' ? '0.5' : 'normal'};
`;

const Text: FunctionComponent<TextProps> = ({
    children,
    ...props
}) => {
    return (
        <Container {...props}>{children}</Container>
    );
};

export default Text;