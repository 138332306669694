import { FunctionComponent } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import Modal from "./Modal";
import Button from "../form/Button";
import Text from "../text/Text";
import Column from "../layout/Column";
import { CONTENT_MAX_WIDTH } from "../../constants/styles";
import PageHeading from "../text/PageHeading";
import EmailAnchor from "../text/EmailAnchor";
import { FEE_APPLY_THRU_ADMINISTRATIVE_SCRIVENER, VISADAS_SUPPORT_EMAIL } from "../../constants/values/commonValues";
import CloseButton from "../misc/CloseButton";
import RadioItem from "../form/RadioItem";
import BottomAlignedContainer from "../layout/BottomAlignedContainer";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/values/query";

interface PaymentPromptModalProps {
  onClose: () => void;
  promptType?: "required" | "general"
  paymentUrl: string;
}

const HeadingContainer = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 10px;
  align-items: center;
`;

const ContentContainer = styled.div`
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const Content = styled(Column)`
  padding: 20px;
  max-width: ${CONTENT_MAX_WIDTH}px;
  height: auto;
  min-height: 100%;
  margin: 0 auto;
  gap: 30px;
`;

const DetailContainer = styled(Column)`
  gap: 20px;
`;

const RadioTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const PaymentNoteList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding-left: 8px;
`;

const PaymentNote = styled.li`
  display: flex;
  align-items: center;
  &::before {
    content: "•";
    font-size: 0.9rem;
    margin-right: 8px;
  }
`;

const SmallText = styled(Text)`
  white-space: "pre-wrap";
  display: inline;
  font-size: 0.8rem;
  color: #999999;
  line-height: 20px;
`;

const ButtonContainer = styled(BottomAlignedContainer)`
  margin-top: 58px;
`;


const PaymentPromptModal: FunctionComponent<PaymentPromptModalProps> = ({
  paymentUrl,
  promptType = "general",
  onClose,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const onProceed = () => { 
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER]});
    window.location.href = paymentUrl; 
  }

  return (
    <Modal>
      <ContentContainer>
        <Content>
          <HeadingContainer>
            <CloseButton onClick={onClose} />
            <PageHeading>
              { promptType === "general" && t("modalPaymentPrompt.title.paymentForVisadas")}
              { promptType === "required" && t("modalPaymentPrompt.title.paymentRequired")}
            </PageHeading>
          </HeadingContainer>
          <DetailContainer>
            <Text style={{ fontWeight: 500 }}>
              {t("modalPaymentPrompt.description1")}
            </Text>
            
              {/* Apply by yourself / 自分で申請 
                Note: Until we can use new Myna APIs and implement the flow,
                we only show the option to apply through an administrative scrivener. */}

              {/* <RadioItem>
                <RadioTextContainer>
                  <strong>
                    <Text>{t("modalPaymentPrompt.applyByYourself")}</Text>
                  </strong>
                  <Text>{t("modalPaymentPrompt.for1Application")}</Text>
                  <strong>
                    <Text>{t("modalPaymentPrompt.yenTaxIncluded", { amount: FEE_APPLY_BY_MYSELF })}</Text>
                  </strong>
                </RadioTextContainer>
              </RadioItem> */}

              {/* Request an administrative scrivener / 行政書士に依頼 */}
              <RadioItem
                checked={true}
              >
                <RadioTextContainer>
                  <strong>
                    <Text>{t("modalPaymentPrompt.requestAdministrativeScrivener")}</Text>
                  </strong>
                  <Text>{t("modalPaymentPrompt.for1Application")}</Text>
                  <strong>
                    <Text>{t("modalPaymentPrompt.yenTaxIncluded", { amount: FEE_APPLY_THRU_ADMINISTRATIVE_SCRIVENER })}</Text>
                  </strong>
                </RadioTextContainer>
              </RadioItem>

            
            <div>
              <Text>{t("modalPaymentPrompt.description2")}</Text>
              <br/>
              <PaymentNoteList>
                <PaymentNote>
                  <Text>{t("modalPaymentPrompt.description3")}</Text>
                </PaymentNote>
                <PaymentNote>
                  <Text>{t("modalPaymentPrompt.description4")}</Text>
                </PaymentNote>
              </PaymentNoteList>
            </div>

            <Text>{t(("modalPaymentPrompt.description5"))}</Text>
          </DetailContainer>
          <SmallText>
            <Trans
              i18nKey={"common.ifYouAreHavingTrouble"}
              components={{
                email: VISADAS_SUPPORT_EMAIL,
                anchor: <EmailAnchor mailTo={VISADAS_SUPPORT_EMAIL} />,
              }}
            />
          </SmallText>
          <ButtonContainer>
            <Button variant="primary" onClick={onProceed}>
              {t("modalPaymentPrompt.proceedToPayment")}
            </Button>
          </ButtonContainer>
        </Content>
      </ContentContainer>
    </Modal>
  );
};

export default PaymentPromptModal;
