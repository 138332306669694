export enum PeriodOfStayCode_CB20 {
  FifteenDays = "0000015",
  ThirtyDays = "0000030",
  ThreeMonths = "0003000",
  FourMonths = "0004000",
  SixMonths = "0006000",
  OneYear = "0100000",
  OneYearThreeMonths = "0103000",
  TwoYears = "0200000",
  TwoYearsThreeMonths = "0203000",
  ThreeYears = "0300000",
  ThreeYearsThreeMonths = "0303000",
  FourYears = "0400000",
  FourYearsThreeMonths = "0403000",
  FiveYears = "0500000",
  Unlimited = "9999999",
}

export enum PeriodOfStayInJapanese {
  FifteenDays = "１５日",
  ThirtyDays = "３０日",
  ThreeMonths = "３月",
  FourMonths = "４月",
  SixMonths = "６月",
  OneYear = "１年",
  OneYearThreeMonths = "１年３月",
  TwoYears = "２年",
  TwoYearsThreeMonths = "２年３月",
  ThreeYears = "３年",
  ThreeYearsThreeMonths = "３年３月",
  FourYears = "４年",
  FourYearsThreeMonths = "４年３月",
  FiveYears = "５年",
  Unlimited = "無期限",
}