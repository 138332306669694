import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import FieldGroup from '../../../../components/layout/FieldGroup';
import MultiTextField from '../../../../components/compound/MultiTextField';
import SelectField from '../../../../components/compound/SelectField';
import TextField from '../../../../components/compound/TextField';
import Section from '../../../../components/layout/Section';
import {
    MUNICIPALITY_WITH_JA_VALUE_OPTIONS,
    PREFECTURE_WITH_JA_VALUE_OPTIONS
} from '../../../../constants/options';
import { useFieldInputNotes, useValidation } from '../../../../hooks';
import { VisaApplicationType } from '../../../../types/visa/applicationType';
import RadioField from '../../../../components/compound/RadioField';
import DateSelectField from '../../../../components/compound/DateSelectField';
import { FormP } from '../../../../types/visa/uncommonFormParts/formP/data';
import { formatDateStrWithJaUnits, parseDateWithJaUnits } from '../../../../utils/visaFormDataHelpers/commonVisaFormDataHelper';
import { IsParticipatingInStudentExProgram, ORG_IN_CHARGE_OTHER_VALUE, REGISTRATION_VALUES_WHICH_REQUIRE_SPECIALIZED_COURSE_NAME, REGISTRATION_VALUES_WHICH_REQUIRE_FACULTY_COURSE, REGISTRATION_VALUES_WHICH_REQUIRE_RESEARCH_ROOM_INFO } from '../../../../types/visa/uncommonFormParts/formP/value';
import { ENROLLMENT_CATEGORY_OPTIONS_FOR_STUDY_ABROAD, FACULTY_COURSE_OPTIONS_FOR_STUDY_ABROAD, SPECIALIZED_COURSE_OPTIONS_FOR_STUDY_ABROAD, TYPE_OF_CLASS_OPTIONS_FOR_STUDY_ABROAD } from '../../../../constants/options/education';
import { SupplementaryInfo } from '../../../../types/visa/supplementaryInfo';
import { ORGANIZATGION_IN_CHARGE_OPTIONS_FOR_STUDY_ABROAD } from '../../../../constants/options/organization';
import { ApplicationFormSectionProps } from '../../type';


interface StudentVisaOrganizationSectionProps extends ApplicationFormSectionProps {
  formP: FormP | null;
  onChangeFormP: (formP: Partial<FormP>) => void;
  supplementaryInfo: SupplementaryInfo;
  onChangeSupplementaryInfo: (supplementaryInfo: Partial<SupplementaryInfo>) => void;
};

const StudentVisaOrganizationSection: FunctionComponent<StudentVisaOrganizationSectionProps> = ({
  visaApplicationType,
  formP,
  onChangeFormP,
  supplementaryInfo,
  onChangeSupplementaryInfo,
  onChangeSectionReadiness,
  showEmptyRequiredValueError
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'organizationSection' });
  const { t : tWithoutPrefix } = useTranslation();
  const { inputNotes } = useFieldInputNotes();
  const validation = useValidation();

  //Segment: "Name and residence card number of the foreigner 
  //being at school or planning to enter the school"
  const [foreignerName, setForeignerName] = useState<string>('');
  const [residenceCardNum, setResidenceCardNum] = useState<string>('');
  const [foreignerNameError, setForeignerNameError] = useState<string>('');
  const [residenceCardNumError, setResidenceCardNumError] = useState<string>('');

  //Segment: "Place of study"
  const [nameOfSchool, setNameOfSchool] = useState<string>('');
  const [prefecture, setPrefecture] = useState<string>('');
  const [municipality, setMunicipality] = useState<string>('');
  const [townStreetApartment, setTownStreetApartment] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [corporationName, setCorporationName] = useState<string>('');
  const [corporationNumber, setCorporationNumber] = useState<string>('');
  const [typeOfClass, setTypeOfClass] = useState<string>('');
  const [nameOfResidentAdviser, setNameOfResidentAdviser] = useState<string>('');
  const [participatingStuExProgram, setParticipatingStuExProgram] = useState<string>('');
  const [orgInCharge, setOrgInCharge] = useState<string>('');
  const [orgOthers, setOrgOthers] = useState<string>('');
  const [nameOfSchoolError, setNameOfSchoolError] = useState<string>('');
  const [townStreetApartmentError, setTownStreetApartmentError] = useState<string>('');
  const [phoneNumberError, setPhoneNumberError] = useState<string>('');
  const [corporationNameError, setCorporationNameError] = useState<string>('');
  const [corporationNumberError, setCorporationNumberError] = useState<string>('');
  const [nameOfResidentAdviserError, setNameOfResidentAdviserError] = useState<string>('');
  const [orgOthersError, setOrgOthersError] = useState<string>('');

  const [dateOfEntrance, setDateOfEntrance] = useState<string>('');
  const [lessonsHoursPerWeek, setLessonsHoursPerWeek] = useState<string>('');
  const [registration, setRegistration] = useState<string>('');
  const [facultyCourse, setFacultyCourse] = useState<string>('');
  const [nameOfResearchRoom, setNameOfResearchRoom] = useState<string>('');
  const [nameOfMentoringProf, setNameOfMentoringProf] = useState<string>('');
  const [nameOfSpecializedCourse, setNameOfSpecializedCourse] = useState<string>('');
  const [isExchangeStudent, setIsExchangeStudent] = useState<boolean | null>();
  const [graduationDate, setGraduationDate] = useState<string>('');
  const [expirationDate, setExpirationDate] = useState<string>('');
  
  const [lessonHoursPerWeekError, setLessonHoursPerWeekError] = useState<string>('');
  const [nameOfResearchRoomError, setNameOfResearchRoomError] = useState<string>('');
  const [nameOfMentoringProfError, setNameOfMentoringProfError] = useState<string>('');


  //Currently, the item names for this section are common between visa extension and change, 
  //so using the same function.
  const saveData = (data: Partial<FormP>) => {
    onChangeFormP(data);
  }

  useEffect(() => {
    if (!formP) 
      return;

    setForeignerName(formP.foreigner_name ?? '');
    setResidenceCardNum(formP.foreigner_residence_card_number ?? '');
    setNameOfSchool(formP.organization_place_of_study_name_of_school ?? '');
    setPrefecture(formP.organization_place_of_study_address_prefecture ?? '');
    setMunicipality(formP.organization_place_of_study_address_municipality ?? '');
    setTownStreetApartment(formP.organization_place_of_study_address_street ?? '');
    setPhoneNumber(formP.organization_place_of_study_telephone_no ?? '');
    setCorporationName(formP.organization_place_of_study_corporation_name ?? '');
    setCorporationNumber(formP.organization_place_of_study_corporation_no ?? '');
    setTypeOfClass(formP.organization_place_of_study_type_of_class ?? '');
    setNameOfResidentAdviser(formP.organization_place_of_study_name_of_the_resident_adviser ?? '');
    setParticipatingStuExProgram(formP.organization_place_of_study_exchange_program ?? '');
    setOrgInCharge(formP.organization_place_of_study_exchange_program_organization ?? '');
    setDateOfEntrance(formP.date_of_entrance ?? '');
    setLessonsHoursPerWeek(formP.lesson_hours_per_week ?? '');
    setRegistration(formP.registration ?? '');
    setFacultyCourse(formP.faculty_course_others_cultural_science_social_science ?? '');
    setNameOfResearchRoom(formP.research_room_name_of_research_room ?? '');
    setNameOfMentoringProf(formP.research_room_name_of_mentoring_professor ?? '');
    setNameOfSpecializedCourse(formP.name_of_specialized_course ?? '');

    const graduationDate = parseDateWithJaUnits(formP.month_and_year_of_scheduled_graduation);
    const expirationDate = parseDateWithJaUnits(formP.month_and_year_of_expiration_of_the_exchange);
    setGraduationDate(graduationDate.yearMonth ?? '');
    setExpirationDate(expirationDate.yearMonth ?? '');

  }, [formP]);

  useEffect(() => {
    if (!supplementaryInfo) 
      return;

    setIsExchangeStudent(supplementaryInfo.is_exchange_student);
  }, [supplementaryInfo])

  useEffect(() => {
    const areAllRequiredFiedsFilled = [
      foreignerName,
      residenceCardNum
    ].every(val => !!val);

    const areThereNoErrors = [
      foreignerNameError,
      residenceCardNumError,
      nameOfSchoolError,
      townStreetApartmentError,
      phoneNumberError,
      corporationNameError,
      corporationNumberError,
      nameOfResidentAdviserError,
      orgOthersError,
      lessonHoursPerWeekError,
      nameOfResearchRoomError,
    ].every(val => !val);

    onChangeSectionReadiness(
      areAllRequiredFiedsFilled && areThereNoErrors
    );
  }, [
    foreignerName,
    residenceCardNum,
    foreignerNameError,
    residenceCardNumError,
    nameOfSchoolError,
    townStreetApartmentError,
    phoneNumberError,
    corporationNameError,
    corporationNumberError,
    nameOfResidentAdviserError,
    orgOthersError,
    lessonHoursPerWeekError,
    nameOfResearchRoomError,
    nameOfMentoringProfError
  ])
  

  return (
    <Section>
      {/* Name and residence card number of the foreigner being 
          at school or planning to enter the school */}
      <FieldGroup 
        title={t('nameAndResidenceCardNum.title')} 
        theme="light"
      >  
        {/* Name */}
        <TextField
          required
          label={t('nameAndResidenceCardNum.name')}
          placeholder='Nam Van Nguyen'
          maxLength={172}
          validators={[validation.createLengthValidator(172)]}
          restriction={inputNotes.halfwidthLetterAndNumber}
          value={foreignerName}
          onValueChange={setForeignerName}
          error={foreignerNameError}
          onErrorChange={setForeignerNameError}
          onBlur={() => {
            saveData({
              foreigner_name: foreignerName
            });
          }}
        />

        {/* Residence card number */}
        <TextField
          required
          label={t('nameAndResidenceCardNum.residenceCardNum')}
          placeholder='AB12345678CD'
          maxLength={12}
          validators={[validation.createLengthValidator(12)]}
          restriction={inputNotes.halfwidthLetterAndNumber}
          value={residenceCardNum}
          onValueChange={setResidenceCardNum}
          error={residenceCardNumError}
          onErrorChange={setResidenceCardNumError}
          onBlur={() => {
            saveData({
              foreigner_residence_card_number: residenceCardNum
            });
          }}
        />
      </FieldGroup>

      {/* Place of study */}
      <FieldGroup 
        title={t('placeOfStudy.title')} 
        theme="light"
      >
        {/* Name of school */}
        <TextField
          label={t('placeOfStudy.nameOfSchool')}
          maxLength={172}
          validators={[validation.createLengthValidator(172)]}
          restriction={inputNotes.halfwidthLetterAndNumber}
          value={nameOfSchool}
          onValueChange={setNameOfSchool}
          error={nameOfSchoolError}
          onErrorChange={setNameOfSchoolError}
          onBlur={() => {
            saveData({
              organization_place_of_study_name_of_school: nameOfSchool
            });
          }}
        />

        {/* Prefecture */}
        <SelectField
          label={t('placeOfStudy.prefecture')}
          options={PREFECTURE_WITH_JA_VALUE_OPTIONS}
          placeholder={tWithoutPrefix("placeholder.select")}
          value={prefecture}
          onValueChange={val => {
            setPrefecture(val);
            setMunicipality('');

            saveData({
              organization_place_of_study_address_prefecture: val,
              organization_place_of_study_address_municipality: ''
            });
          }}
        />

        {/* Municipality */}
        <SelectField
          disabled={!prefecture}
          label={t('placeOfStudy.municipality')}
          options={MUNICIPALITY_WITH_JA_VALUE_OPTIONS[prefecture] ?? []}
          placeholder={
            prefecture
              ? tWithoutPrefix("placeholder.select")
              : ''
          }
          value={municipality}
          onValueChange={val => {
            setMunicipality(val);
            saveData({
              organization_place_of_study_address_municipality: val
            });
          }}
        />

        {/* Town/street/apartment */}
        <MultiTextField 
          label={t('placeOfStudy.townStreetApartment')}
          placeholder='品川1-2-3ビザダスビル3F'
          maxLength={172}
          validators={[validation.createLengthValidator(172)]}
          value={townStreetApartment}
          onValueChange={setTownStreetApartment}
          error={townStreetApartmentError}
          onErrorChange={setTownStreetApartmentError}
          onBlur={() => {
            saveData({
              organization_place_of_study_address_street: townStreetApartment
            });
          }}
        />

        {/* Phone number */}
        <TextField
          label={t('placeOfStudy.phoneNumber')}
          placeholder='03-1234-5678'
          maxLength={13}
          restriction={inputNotes.halfwidthNumberNoHyphens}
          value={phoneNumber}
          onValueChange={setPhoneNumber}
          error={phoneNumberError}
          onErrorChange={setPhoneNumberError}
          onBlur={() => {
            saveData({
              organization_place_of_study_telephone_no: phoneNumber
            });
          }}
        />

        {/* Corporation name */}
        <TextField
          label={t('placeOfStudy.corporationName')}
          value={corporationName}
          onValueChange={setCorporationName}
          maxLength={172}
          restriction={inputNotes.halfwidthLetterAndNumber}
          error={corporationNameError}
          onErrorChange={setCorporationNameError}
          onBlur={() => {
            saveData({
              organization_place_of_study_corporation_name: corporationName
            });
          }}
        />

        {/* Corporation no. */}
        <TextField
          label={t('placeOfStudy.corporationNumber')}
          placeholder='1234567890123'
          value={corporationNumber}
          onValueChange={setCorporationNumber}
          maxLength={13}
          restriction={inputNotes.halfwidthNumberNoHyphens}
          error={corporationNumberError}
          onErrorChange={setCorporationNumberError}
          onBlur={() => {
            saveData({
              organization_place_of_study_corporation_no: corporationNumber
            });
          }}
        />

        {/* Type of class */}
        <SelectField
          label={t('placeOfStudy.typeOfClass')}
          placeholder={tWithoutPrefix("placeholder.select")}
          options={TYPE_OF_CLASS_OPTIONS_FOR_STUDY_ABROAD}
          value={typeOfClass}
          onValueChange={val => {
            setTypeOfClass(val);
            saveData({
              organization_place_of_study_type_of_class: val
            });
          }}
        />

        {/* Name of the resident adviser in Japan */}
        <TextField
          label={t('placeOfStudy.nameOfResidentAdviser')}
          maxLength={172}
          validators={[validation.createLengthValidator(172)]}
          note={t('placeOfStudy.noteOnResidentAdviser')}
          value={nameOfResidentAdviser}
          onValueChange={setNameOfResidentAdviser}
          error={nameOfResidentAdviserError}
          onErrorChange={setNameOfResidentAdviserError}
          onBlur={() => {
            saveData({
              organization_place_of_study_name_of_the_resident_adviser: nameOfResidentAdviser
            });
          }}
        />

        {/* Is the applicant participating in a student exchange program? */}
        <RadioField
          label={t('placeOfStudy.participatingStuExProgram')}
          options={[
            {
              label: tWithoutPrefix('common.yes'),
              value: IsParticipatingInStudentExProgram.Yes
            },
            {
              label: tWithoutPrefix('common.no'),
              value: IsParticipatingInStudentExProgram.No
            },
          ]}
          value={participatingStuExProgram}
          onValueChange={val => {
            setIsExchangeStudent(val);
            saveData({
              organization_place_of_study_exchange_program: val
            })
          }}
        />

        {/* Which organization is in charge of that program? */}
        <div>
          <SelectField
            label={t('placeOfStudy.orgInCharge')}
            placeholder={tWithoutPrefix("placeholder.select")}
            options={ORGANIZATGION_IN_CHARGE_OPTIONS_FOR_STUDY_ABROAD}
            value={orgInCharge}
            onValueChange={val => {
              const data: Partial<FormP> = {
                organization_place_of_study_exchange_program_organization: val
              };

              
              if (val !== ORG_IN_CHARGE_OTHER_VALUE) {
                setOrgOthers('');
                data.organization_place_of_study_exchange_program_organization_other = '';
              }
              
              setOrgInCharge(val);
              saveData(data);
            }}
          />
          <MultiTextField
            maxLength={172}
            value={orgOthers}
            onValueChange={setOrgOthers}
            error={orgOthersError}
            onErrorChange={setOrgOthersError}
            style={{ marginTop: '5px' }}
            onBlur={() => {
              saveData({
                organization_place_of_study_exchange_program_organization_other: orgOthers
              });
            }}
          />
        </div>
      </FieldGroup>

      {/* Date of entrance */}
      <DateSelectField
        label={t('dateOfEntrance')}
        value={dateOfEntrance}
        onValueChange={val => {
          setDateOfEntrance(val);
          saveData({
            date_of_entrance: formatDateStrWithJaUnits(val)
          });
        }
      }
      />

      {/* Lesson hours per week (Hours) */}
      <TextField
        label={t('lessonsHoursPerWeek')}
        placeholder='12'
        maxLength={3}
        restriction={inputNotes.halfwidthNumber}
        note={t('noteOnLessons')}
        value={lessonsHoursPerWeek}
        onValueChange={setLessonsHoursPerWeek}
        error={lessonHoursPerWeekError}
        onErrorChange={setLessonHoursPerWeekError}
        onBlur={() => {
          saveData({
            lesson_hours_per_week: lessonsHoursPerWeek
          });
        }}
      />

      {/* Registration */}
      <SelectField
        label={t('registration')}
        placeholder={tWithoutPrefix("placeholder.select")}
        options={ENROLLMENT_CATEGORY_OPTIONS_FOR_STUDY_ABROAD}
        value={registration}
        onValueChange={val => {
          const data: Partial<FormP> = {
            registration: val
          };

          if (!REGISTRATION_VALUES_WHICH_REQUIRE_FACULTY_COURSE.includes(val)) {
            setFacultyCourse('');
            data.faculty_course_others_cultural_science_social_science = '';
          }

          if (!REGISTRATION_VALUES_WHICH_REQUIRE_RESEARCH_ROOM_INFO.includes(val)) {
            setNameOfResearchRoom('');
            setNameOfMentoringProf('');
            setNameOfMentoringProfError('');
            setNameOfResearchRoomError('');
            data.research_room_name_of_research_room = '';
            data.research_room_name_of_mentoring_professor = '';
          }

          if (!REGISTRATION_VALUES_WHICH_REQUIRE_SPECIALIZED_COURSE_NAME.includes(val)) {
            setNameOfSpecializedCourse('');
            data.name_of_specialized_course = '';
          }

          setRegistration(val);
          saveData(data);
        }}
      />

      {/* Faculty / Course */}
      { REGISTRATION_VALUES_WHICH_REQUIRE_FACULTY_COURSE.includes(registration) &&
        <SelectField
          label={t('facultyCourse')}
          placeholder={tWithoutPrefix("placeholder.select")}
          options={FACULTY_COURSE_OPTIONS_FOR_STUDY_ABROAD}
          value={facultyCourse}
          onValueChange={val => {
            setFacultyCourse(val);
            saveData({
              faculty_course_others_cultural_science_social_science: val
            });
          }}
        />
      }

      { REGISTRATION_VALUES_WHICH_REQUIRE_RESEARCH_ROOM_INFO.includes(registration) &&
        <FieldGroup
          title={t('researchRoom.title')}
          theme="light"
          >
          {/* Name of research room */}
          <TextField
            label={t('researchRoom.nameOfResearchRoom')}
            maxLength={172}
            restriction={inputNotes.halfwidthLetterAndNumber}
            value={nameOfResearchRoom}
            onValueChange={setNameOfResearchRoom}
            error={nameOfResearchRoomError}
            onErrorChange={setNameOfResearchRoomError}
            onBlur={() => {
              saveData({
                research_room_name_of_research_room: nameOfResearchRoom
              });
            }}
          />

          {/* Name of mentoring professor */}
          <TextField
            label={t('researchRoom.nameOfMentoringProf')}
            maxLength={172}
            restriction={inputNotes.halfwidthLetterAndNumber}
            value={nameOfMentoringProf}
            onValueChange={setNameOfMentoringProf}
            error={nameOfMentoringProfError}
            onErrorChange={setNameOfMentoringProfError}
            onBlur={() => {
              saveData({
                research_room_name_of_mentoring_professor: nameOfMentoringProf
              });
            }}
          />
        </FieldGroup>
      }

      {/* Name of specialized course */}
      { REGISTRATION_VALUES_WHICH_REQUIRE_SPECIALIZED_COURSE_NAME.includes(registration) &&
        <SelectField
          label={t('nameOfSpecializedCourse')}
          placeholder={tWithoutPrefix("placeholder.select")}
          options={SPECIALIZED_COURSE_OPTIONS_FOR_STUDY_ABROAD}
          value={nameOfSpecializedCourse}
          onValueChange={val => {
            setNameOfSpecializedCourse(val);
            saveData({
              name_of_specialized_course: val
            });
          }}
        />
      }
      
      {/* Are you an exchange student? */}
      <RadioField 
        label={t('areYouExchangeStudent')}
        options={[
          {
            label: tWithoutPrefix('common.yes'),
            value: true
          },
          {
            label: tWithoutPrefix('common.no'),
            value: false
          },
        ]}
        value={isExchangeStudent}
        onValueChange={val => {
          const isExtStu = val === 'true';
          setIsExchangeStudent(isExtStu);
          onChangeSupplementaryInfo({
            is_exchange_student: isExtStu
          });
        }}  
      />

      { isExchangeStudent && (
        <>
          {/* Month and year of (scheduled) graduation */}
          <DateSelectField
            hideDayField
            label={t('monthAndYearOfGraduation')}
            value={graduationDate}
            onValueChange={val => {
              setGraduationDate(val);
              saveData({
                month_and_year_of_scheduled_graduation: formatDateStrWithJaUnits(val)
              })
            }}
          />

          {/* Month and year of expiration of the exchange student acceptance period */}
          <DateSelectField
            hideDayField
            label={t('monthAndYearOfExpiration')}
            value={expirationDate}
            onValueChange={val => {
              setExpirationDate(val);
              saveData({
                month_and_year_of_expiration_of_the_exchange: formatDateStrWithJaUnits(val)
              })
            }}
          />
        </>
      )}
    </Section>
  );
};

export default StudentVisaOrganizationSection;