import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Section from '../../../components/layout/Section';
import MultiTextField from '../../../components/compound/MultiTextField';
import { useFieldInputNotes, useFieldPlaceholder, useValidation } from '../../../hooks';
import TextField from '../../../components/compound/TextField';
import Text from '../../../components/text/Text';
import DateSelectField from '../../../components/compound/DateSelectField';
import SelectField from '../../../components/compound/SelectField';
import { ApplicationFormSectionProps } from '../type';
import { useSectionErrorHandler } from '../hook';
import Label from '../../../components/form/Label';
import { ALL_NATIONALITY_WITH_JA_VALUE_OPTIONS, MUNICIPALITY_WITH_JA_VALUE_OPTIONS, PREFECTURE_WITH_JA_VALUE_OPTIONS, RELATIONSHIP_WITH_JA_VALUE_OPTIONS_WITHOUT_SELF, RESIDENCE_STATUS_OF_DEPENDENT_WITH_JA_VALUE_OPTIONS, SUPPORTER_PERIOD_OF_STAY_WITH_JA_VALUE_OPTIONS_DIC_FOR_DEPENDENT } from '../../../constants/options';
import { FormR } from '../../../types/visa/uncommonFormParts/formR/data';
import { formatDateStrWithJaUnits, isNoFieldError, parseDateWithJaUnits } from '../../../utils/visaFormDataHelpers/commonVisaFormDataHelper';
import { Column10, Column30 } from '../commonStyles';
import { getFirstDayOfCurrentYear, getLastDayWithYearOffset } from '../../../utils/utils';
import { RelationshipInJapanese } from '../../../types/visa/relationship';
import { StatusOfResidenceInJapanese } from '../../../types/visa/statusOfResidence';
import { PeriodOfStayInJapanese } from '../../../types/visa/periodOfStay';
import { API_MAX_DATE } from '../../../constants/values/date';

interface SupporterSectionProps extends ApplicationFormSectionProps {
  formR: FormR | null;
  onChangeFormR: (data: Partial<FormR>) => void;
}

const SupporterSection: FunctionComponent<SupporterSectionProps> = ({
  isActive,
  readOnly,
  formR,
  onChangeFormR,
  onChangeSectionReadiness,
  showEmptyRequiredValueError
}) => {
  const placeholder = useFieldPlaceholder();
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });
  const { t: tFor } = useTranslation('translation', { keyPrefix: 'supporterSection.foreignerToBeSupported' });
  const { t: tSup } = useTranslation('translation', { keyPrefix: 'supporterSection.supporter' });
  const { inputNotes, conditionalInputNotes } = useFieldInputNotes();
  const validation = useValidation();
  const { createGetEmptyFieldError } = useSectionErrorHandler();
  const getEmptyError = createGetEmptyFieldError({ additionalCondition: showEmptyRequiredValueError });

  const [nameOfSupporter, setNameOfSupporter] = useState('');
  const [birthdateOfSupporter, setBirthdateOfSupporter] = useState('');
  const [nationalityOfSupporter, setNationalityOfSupporter] = useState('');
  const [residenceCardNumberOfSupporter, setResidenceCardNumberOfSupporter] = useState('');
  const [currentStatusOfResidenceOfSupporter, setCurrentStatusOfResidenceOfSupporter] = useState('');
  const [periodOfStayOfSupporter, setPeriodOfStayOfSupporter] = useState('');
  const [dateOfExpirationOfSupporter, setDateOfExpirationOfSupporter] = useState('');
  const [relWithApplicant, setRelWithApplicant] = useState('');
  const [relWithApplicantOther, setRelWithApplicantOther] = useState('');
  const [corporationName, setCorporationName] = useState('');
  const [corporationNumber, setCorporationNumber] = useState('');
  const [nameOfBranch, setNameOfBranch] = useState('');
  const [prefecture, setPrefecture] = useState('');
  const [municipality, setMunicipality] = useState('');
  const [townStreetApartment, setTownStreetApartment] = useState('');
  const [telephoneNumber, setTelephoneNumber] = useState('');
  const [annualIncome, setAnnualIncome] = useState('');

  const [nameOfSupporterError, setNameOfSupporterError] = useState('');
  const [residenceCardNumberError, setResidenceCardNumberError] = useState('');
  const [relWithApplicantOtherError, setRelWithApplicantOtherError] = useState('');
  const [corporationNameError, setCorporationNameError] = useState('');
  const [corporationNumberError, setCorporationNumberError] = useState('');
  const [nameOfBranchError, setNameOfBranchError] = useState('');
  const [townStreetApartmentError, setTownStreetApartmentError] = useState('');
  const [telephoneNumberError, setTelephoneNumberError] = useState('');
  const [annualIncomeError, setAnnualIncomeError] = useState('');

  const checkIfUnlimitedPeriodOfStay = (statusOfResidence: string) => (
    [ StatusOfResidenceInJapanese.HighlySkilledProfessional_ii,
      StatusOfResidenceInJapanese.PermanentResident
    ].includes(statusOfResidence as StatusOfResidenceInJapanese)
  );

  const dateOfExpirationMaxDate = useMemo(() => {
    if (checkIfUnlimitedPeriodOfStay(currentStatusOfResidenceOfSupporter))
      return new Date(API_MAX_DATE);

    return getLastDayWithYearOffset(10);
  }, [currentStatusOfResidenceOfSupporter]);

  const saveData = (data: Partial<FormR>) => {
    onChangeFormR(data);
  }

  useEffect(() => {
    if (!formR)
      return;

    if (isNoFieldError(nameOfSupporterError))
      setNameOfSupporter(formR.supporter_name ?? '');

    setBirthdateOfSupporter(parseDateWithJaUnits(formR.supporter_date_of_birth).yearMonthDay ?? '');
    setNationalityOfSupporter(formR.supporter_nationality_region ?? '');

    if (isNoFieldError(residenceCardNumberError))
      setResidenceCardNumberOfSupporter(formR.supporter_residence_card_number ?? '');

    setCurrentStatusOfResidenceOfSupporter(formR.supporter_status_of_residence ?? '');
    setPeriodOfStayOfSupporter(formR.supporter_period_of_stay ?? '');
    setDateOfExpirationOfSupporter(parseDateWithJaUnits(formR.supporter_date_of_expiration).yearMonthDay ?? '');
    setRelWithApplicant(formR.supporter_relationship ?? '');

    if (isNoFieldError(relWithApplicantOtherError))
      setRelWithApplicantOther(formR.supporter_relationship_others ?? '');

    if (isNoFieldError(corporationNameError))
      setCorporationName(formR.supporter_place_of_employment ?? '');

    if (isNoFieldError(corporationNumberError))
      setCorporationNumber(formR.supporter_corporation_no ?? '');

    if (isNoFieldError(nameOfBranchError))
      setNameOfBranch(formR.supporter_name_of_branch ?? '');

    setPrefecture(formR.supporter_employment_prefecture ?? '');
    setMunicipality(formR.supporter_employment_municipality ?? '');

    if (isNoFieldError(townStreetApartmentError))
      setTownStreetApartment(formR.supporter_employment_street ?? '');

    if (isNoFieldError(telephoneNumberError))
      setTelephoneNumber(formR.supporter_employment_telephone ?? '');

    if (isNoFieldError(annualIncomeError))
      setAnnualIncome(formR.supporter_annual_income ?? '');

  }, [formR]);

  useEffect(() => {
    const conditionalRequiredFields = [];

    if (relWithApplicant === RelationshipInJapanese.Others)
      conditionalRequiredFields.push(relWithApplicantOther);

    const areConditionalRequiredFieldsFilled =
      conditionalRequiredFields.every(val => !!val);

    const areAllRequiredFieldsFilled = [
      formR?.applicant_family_name,
      formR?.applicant_given_name,
      formR?.applicant_residence_card_number,
      nameOfSupporter,
      birthdateOfSupporter,
      nationalityOfSupporter,
      residenceCardNumberOfSupporter,
      currentStatusOfResidenceOfSupporter,
      periodOfStayOfSupporter,
      dateOfExpirationOfSupporter,
      relWithApplicant,
      corporationName,
      corporationNumber,
      nameOfBranch,
      prefecture,
      municipality,
      telephoneNumber,
      annualIncome
    ].every(val => !!val);

    const areThereNoErrors = [
      nameOfSupporterError,
      residenceCardNumberError,
      relWithApplicantOtherError,
      corporationNameError,
      corporationNumberError,
      nameOfBranchError,
      townStreetApartmentError,
      telephoneNumberError,
      annualIncomeError
    ].every(val => !val);

    onChangeSectionReadiness(
      areAllRequiredFieldsFilled &&
      areConditionalRequiredFieldsFilled &&
      areThereNoErrors
    );
  }, [
    formR?.applicant_family_name,
    formR?.applicant_given_name,,
    formR?.applicant_residence_card_number,
    nameOfSupporter,
    birthdateOfSupporter,
    nationalityOfSupporter,
    residenceCardNumberOfSupporter,
    currentStatusOfResidenceOfSupporter,
    periodOfStayOfSupporter,
    dateOfExpirationOfSupporter,
    relWithApplicant,
    relWithApplicantOther,
    corporationName,
    corporationNumber,
    nameOfBranch,
    prefecture,
    municipality,
    townStreetApartment,
    telephoneNumber,
    annualIncome,
    nameOfSupporterError,
    residenceCardNumberError,
    relWithApplicantOtherError,
    corporationNameError,
    corporationNumberError,
    nameOfBranchError,
    townStreetApartmentError,
    telephoneNumberError,
    annualIncomeError
  ]);

  if (isActive === false)
    return null;

  return (
    <Section>

      <Column30>
        {/* Name and residence card number of the foreigner to be supported (applicant) */}
        <Label>
          {tFor("title")}
        </Label>

        {/* Family name */}
        <TextField
          required
          disabled
          label={tFor("familyName")}
          value={formR?.applicant_family_name ?? ''}
          error={getEmptyError(formR?.applicant_family_name)}
        />

        {/* Given name */}
        <TextField
          required
          disabled
          label={tFor("givenName")}
          value={formR?.applicant_given_name ?? ''}
          error={getEmptyError(formR?.applicant_given_name)}
        />

        {/* Middle name */}
        <TextField
          disabled
          label={tFor("middleName")}
          value={formR?.applicant_middle_name ?? ''}
        />

        {/* Residence card number */}
        <TextField
          required
          disabled
          label={tFor("residenceCardNumber")}
          value={formR?.applicant_residence_card_number ?? ''}
          error={getEmptyError(formR?.applicant_residence_card_number)}
        />
      </Column30>


      <Column30>
        <Label>{tSup("title")}</Label>

        {/* Name of supporter */}
        <MultiTextField
          compact
          required
          disabled={readOnly}
          label={tSup("nameOfSupporter")}
          placeholder='Nam Van Nguyen'
          value={nameOfSupporter}
          error={nameOfSupporterError || getEmptyError(nameOfSupporter)}
          maxLength={104}
          validators={[validation.createLengthValidator(104)]}
          onValueChange={setNameOfSupporter}
          onErrorChange={setNameOfSupporterError}
          onBlur={() => {
            saveData({ supporter_name: nameOfSupporter });
          }}
        />

        {/* Birthdate */}
        <DateSelectField
          required
          disabled={readOnly}
          label={tSup("birthdate")}
          value={birthdateOfSupporter}
          error={getEmptyError(birthdateOfSupporter)}
          onValueChange={val => {
            setBirthdateOfSupporter(val);

            if (val)
              saveData({ supporter_date_of_birth: formatDateStrWithJaUnits(val) });
          }}
        />

        {/* Nationality */}
        <SelectField
          required
          disabled={readOnly}
          label={tSup("nationality")}
          options={ALL_NATIONALITY_WITH_JA_VALUE_OPTIONS}
          placeholder={placeholder.selectNationality}
          value={nationalityOfSupporter}
          error={getEmptyError(nationalityOfSupporter)}
          onValueChange={val => {
            setNationalityOfSupporter(val);
            saveData({ supporter_nationality_region: val });
          }}
        />

        {/* Residence card number */}
        <TextField
          required
          disabled={readOnly}
          label={tSup("residenceCardNumber")}
          placeholder="AAAA9999999C"
          value={residenceCardNumberOfSupporter}
          error={residenceCardNumberError || getEmptyError(residenceCardNumberOfSupporter)}
          maxLength={12}
          validators={[validation.createLengthValidator(12)]}
          restriction={inputNotes.halfwidthLetterAndNumber}
          onValueChange={setResidenceCardNumberOfSupporter}
          onErrorChange={setResidenceCardNumberError}
          onBlur={() => {
            saveData({ supporter_residence_card_number: residenceCardNumberOfSupporter });
          }}
        />

        {/* Current status of residence */}
        <SelectField
          required
          disabled={readOnly}
          label={tSup("currentStatusOfResidence")}
          placeholder={placeholder.select}
          options={RESIDENCE_STATUS_OF_DEPENDENT_WITH_JA_VALUE_OPTIONS}
          value={currentStatusOfResidenceOfSupporter}
          error={getEmptyError(currentStatusOfResidenceOfSupporter)}
          onValueChange={val => {
            setCurrentStatusOfResidenceOfSupporter(val);
            setPeriodOfStayOfSupporter('');

            if (checkIfUnlimitedPeriodOfStay(val)) {
              setPeriodOfStayOfSupporter(PeriodOfStayInJapanese.Unlimited);
              setDateOfExpirationOfSupporter(API_MAX_DATE);

              saveData({
                supporter_status_of_residence: val,
                supporter_period_of_stay: PeriodOfStayInJapanese.Unlimited,
                supporter_date_of_expiration: formatDateStrWithJaUnits(API_MAX_DATE)
              });
            } else {
              setPeriodOfStayOfSupporter('');
              setDateOfExpirationOfSupporter('');

              saveData({
                supporter_status_of_residence: val,
                supporter_period_of_stay: null,
                supporter_date_of_expiration: null
              });
            }
          }}
        />

        {/* Period of stay */}
        <SelectField
          required
          disabled={
            !currentStatusOfResidenceOfSupporter ||
            readOnly ||
            checkIfUnlimitedPeriodOfStay(currentStatusOfResidenceOfSupporter)
          }
          label={tSup("periodOfStay")}
          placeholder={placeholder.select}
          options={currentStatusOfResidenceOfSupporter
            ? SUPPORTER_PERIOD_OF_STAY_WITH_JA_VALUE_OPTIONS_DIC_FOR_DEPENDENT[currentStatusOfResidenceOfSupporter] ?? []
            : []
          }
          value={periodOfStayOfSupporter}
          error={
            currentStatusOfResidenceOfSupporter
              ? getEmptyError(periodOfStayOfSupporter)
              : tSup("enterCurrentStatusOfResidenceFirst")
          }
          onValueChange={val => {
            setPeriodOfStayOfSupporter(val);
            saveData({ supporter_period_of_stay: val });
          }}
        />

        {/* Date of expiration */}
        <DateSelectField
          required
          disabled={
            readOnly ||
            checkIfUnlimitedPeriodOfStay(currentStatusOfResidenceOfSupporter)
          }
          label={tSup("dateOfExpiration")}
          minDate={getFirstDayOfCurrentYear()}
          maxDate={dateOfExpirationMaxDate}
          value={dateOfExpirationOfSupporter}
          error={getEmptyError(dateOfExpirationOfSupporter)}
          onValueChange={val => {
            setDateOfExpirationOfSupporter(val);

            if (val)
              saveData({ supporter_date_of_expiration: formatDateStrWithJaUnits(val) });
          }}
        />

        {/* Relationship with the applicant */}
        <Column10>
          <SelectField
            required
            disabled={readOnly}
            label={tSup("relationshipWithApplicant")}
            placeholder={placeholder.select}
            options={RELATIONSHIP_WITH_JA_VALUE_OPTIONS_WITHOUT_SELF}
            value={relWithApplicant}
            error={getEmptyError(relWithApplicant)}
            onValueChange={val => {
              setRelWithApplicant(val);
              saveData({ supporter_relationship: val });
            }}
          />

          {/* Relationship with the applicant (other) */}
          { relWithApplicant === RelationshipInJapanese.Others &&
            <MultiTextField
              disabled={readOnly}
              value={relWithApplicantOther}
              error={relWithApplicantOtherError || getEmptyError(relWithApplicantOther)}
              maxLength={40}
              validators={[validation.createLengthValidator(40)]}
              onValueChange={setRelWithApplicantOther}
              onErrorChange={setRelWithApplicantOtherError}
              onBlur={() => {
                saveData({ supporter_relationship_others: relWithApplicantOther });
              }}
            />
          }
        </Column10>

        {/* Corporation Name */}
        <MultiTextField
          required
          disabled={readOnly}
          label={tSup("corporationName")}
          value={corporationName}
          error={corporationNameError || getEmptyError(corporationName)}
          placeholder='Text'
          maxLength={60}
          validators={[validation.createLengthValidator(60)]}
          onValueChange={setCorporationName}
          onErrorChange={setCorporationNameError}
          onBlur={() => {
            saveData({ supporter_place_of_employment: corporationName });
          }}
        />

        {/* Corporation number */}
        <TextField
          required
          disabled={readOnly}
          label={tSup("corporationNumber")}
          placeholder="1234567890123"
          maxLength={13}
          restriction={inputNotes.halfwidthNumberNoHyphens}
          validators={[validation.createLengthValidator(13)]}
          value={corporationNumber}
          error={corporationNumberError || getEmptyError(corporationNumber)}
          onValueChange={setCorporationNumber}
          onErrorChange={setCorporationNumberError}
          onBlur={() => {
            saveData({ supporter_corporation_no: corporationNumber });
          }}
        />

        {/* Name of branch */}
        <MultiTextField
          required
          disabled={readOnly}
          label={tSup("nameOfBranch")}
          placeholder='Text'
          value={nameOfBranch}
          error={nameOfBranchError || getEmptyError(nameOfBranch)}
          maxLength={60}
          validators={[validation.createLengthValidator(60)]}
          onValueChange={setNameOfBranch}
          onErrorChange={setNameOfBranchError}
          onBlur={() => {
            saveData({ supporter_name_of_branch: nameOfBranch });
          }}
        />

        {/* Prefecture */}
        <SelectField
          required
          disabled={readOnly}
          label={tCommon("prefecture")}
          placeholder={placeholder.selectPrefecture}
          options={PREFECTURE_WITH_JA_VALUE_OPTIONS}
          value={prefecture}
          error={getEmptyError(prefecture)}
          onValueChange={val => {
            setPrefecture(val);
            saveData({ supporter_employment_prefecture: val });
          }}
        />

        {/* Municipality */}
        <SelectField
          required
          disabled={!prefecture || readOnly}
          placeholder={placeholder.selectMunicipality}
          label={tCommon("municipality")}
          options={MUNICIPALITY_WITH_JA_VALUE_OPTIONS[prefecture] ?? []}
          value={municipality}
          error={prefecture
            ? getEmptyError(municipality)
            : tSup("enterPrefectureFirst")
          }
          onValueChange={val => {
            setMunicipality(val);
            saveData({ supporter_employment_municipality: val });
          }}
        />

        {/* Town/street/apartment */}
        <MultiTextField
          disabled={readOnly}
          label={tCommon("townStreetApartment")}
          placeholder='品川１ー２ー３ビザダスビル３F'
          value={townStreetApartment}
          error={townStreetApartmentError}
          maxLength={80}
          validators={[validation.createLengthValidator(80)]}
          onValueChange={setTownStreetApartment}
          onErrorChange={setTownStreetApartmentError}
          onBlur={() => {
            saveData({ supporter_employment_street: townStreetApartment });
          }}
        />

        {/* Telephone number */}
        <TextField
          required
          disabled={readOnly}
          label={tSup("telephoneNumber")}
          value={telephoneNumber}
          error={telephoneNumberError || getEmptyError(telephoneNumber)}
          placeholder='1234567891234'
          maxLength={12}
          restriction={inputNotes.halfwidthNumberNoHyphens}
          validators={[validation.createLengthValidator(12), validation.isNumeric]}
          onValueChange={setTelephoneNumber}
          onErrorChange={setTelephoneNumberError}
          onBlur={() => {
            saveData({ supporter_employment_telephone: telephoneNumber });
          }}
        />

        {/* Annual income */}
        <TextField
          required
          disabled={readOnly}
          label={tSup("annualIncome")}
          value={annualIncome}
          error={annualIncomeError || getEmptyError(annualIncome)}
          restriction={conditionalInputNotes.halfwidthNumber}
          maxLength={6}
          validators={[validation.createLengthValidator(6), validation.isNumeric]}
          onValueChange={setAnnualIncome}
          onErrorChange={setAnnualIncomeError}
          onBlur={() => {
            saveData({ supporter_annual_income: annualIncome });
          }}
          suffixComponent={<Text><strong>0,000</strong></Text>}
          note={tSup("annualIncomeNote")}
        />
      </Column30>
    </Section>
  );
};

export default SupporterSection;
