import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Modal from "../../components/modal/Modal";
import Button from "../../components/form/Button";
import Column from "../../components/layout/Column";
import { CONTENT_MAX_WIDTH } from "../../constants/styles";
import { useDispatcher, useFieldPlaceholder } from "../../hooks";
import PageHeading from "../../components/text/PageHeading";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/navigation/BackButton";
import { createVisaApplication } from "../../apis/visaApplication";
import { createExtensionBySelfData } from "../../apis/extensionBySelfData";
import RadioField from "../../components/compound/RadioField";
import { 
  convertToApplicationCategoryCodeForExtensionBySelf, 
  convertToStatusOfResidenceCodeForChangeBySelf, 
  convertToStatusOfResidenceCodeForExtensionBySelf, 
  parseName
} from "../../utils/visaFormDataHelpers/commonVisaFormDataHelper";
import { createFormP } from "../../apis/formP";
import { createFormN } from "../../apis/formN";
import { createChangeBySelfData } from "../../apis/changeBySelfData";
import { KeptVisaApplicationContent, No } from "../../types/bySelfCommon/specificValues";
import { ApplicationCategoryCodeForChangeBySelf } from "../../types/visa/changeBySelf/value";
import { StatusOfResidenceCode_CB19 } from "../../types/visa/statusOfResidence";
import { createFormR } from "../../apis/formR";
import SelectField from "../../components/compound/SelectField";
import { APPLYING_VISA_OPTIONS } from "../../constants/options/applyingVisa";
import { UserRegistration } from "../../types/userRegistration/data";
import { ExtensionBySelfData } from "../../types/visa/extensionBySelf/data";
import { ChangeBySelfData } from "../../types/visa/changeBySelf/data";
import BottomAlignedContainer from "../../components/layout/BottomAlignedContainer";
import { VisaSubmissionMode } from "../../types/visa/applicationData";


interface CreateNewAppModalProps {
  statusOfResidence: StatusOfResidenceCode_CB19;
  userRegistration: UserRegistration;
  onClose: () => void;
}


enum ApplicationType {
  Extension = 'extension',
  Change = 'change',
}

const ContentContainer = styled.div`
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const Content = styled(Column)`
  padding: 20px;
  max-width: ${CONTENT_MAX_WIDTH}px;
  height: 100%;
  margin: 0 auto;
`;

const UpperPartContainer = styled(Column)`
  gap: 20px;
`;

const LowerPartContainer = styled(BottomAlignedContainer)`
  margin-top: 58px;
`;


const CreateNewAppModal: FunctionComponent<CreateNewAppModalProps> = ({
  statusOfResidence, //On which visa the user is currently on
  userRegistration,
  onClose,
}) => {
  const { dispatcher } = useDispatcher();
  const placeholder = useFieldPlaceholder();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });

  //Used only in the initial release
  //We only support extension of dependent visa or change to dependent visa
  const isCurrentVisaDependent = statusOfResidence === StatusOfResidenceCode_CB19.Dependent;

  const [appType, setAppType] = useState<ApplicationType>(
    //Only in the initial release
    isCurrentVisaDependent
    ? ApplicationType.Extension
    : ApplicationType.Change
  );
  const [selectedStatusOfResidence, setSelectedStatusOfResidence] = useState<StatusOfResidenceCode_CB19>(
    //Only in initial release 
    StatusOfResidenceCode_CB19.Dependent
  );
  const disableCreateButton = !appType || !selectedStatusOfResidence;
  const onClickBack = () => onClose();
  const onClickCreate = async () => {
    dispatcher.startLoading();
    try {
      if (!appType)
        throw new Error('No application type is selected');

      //In the future, we will need to have the user select the submission mode
      //We will use this value until we implement another way to submit the application (i.e. through Myna APIs)
      const submissionMode = VisaSubmissionMode.AdministrativeScrivener;

      const visaApplication = await createVisaApplication({
        submission_mode: submissionMode
      });   

      //The name is a string like "<lastName> <firstName> <middleName>"
      //separated by a space (for now)
      const applicantName = userRegistration["WCFDS010Dto:hdnUserInfoName"];   

      const commonUserData: Partial<ExtensionBySelfData> | Partial<ChangeBySelfData> = {
        'WCIBS010Dto:txtName': applicantName?.replaceAll(' ', ',') ?? null,
        'WCIBS010Dto:radSex': userRegistration['WCFDS010Dto:hdnSex'] ?? null,
        'WCIBS010Dto:selDateOfBirthYear': userRegistration['WCFDS010Dto:hdnDateOfBirthYear'] ?? null,
        'WCIBS010Dto:selDateOfBirthMonth': userRegistration['WCFDS010Dto:hdnDateOfBirthMonth'] ?? null,
        'WCIBS010Dto:selDateOfBirthDay': userRegistration['WCFDS010Dto:hdnDateOfBirthDay'] ?? null,
        'WCIBS010Dto:txtTelNum': userRegistration['WCFDS010Dto:txtUserTelNo'] ?? null,
        'WCIBS010Dto:txtMailAddress': userRegistration['WCFDS010Dto:txtMailAddress'] ?? null,
        'WCIBS010Dto:hdnSearchedAddress': userRegistration['WCFDS010Dto:hdnSearchedAddress'] ?? null,
        'WCIBS010Dto:txtDetailHomeAddress': userRegistration['WCFDS010Dto:txtDetailHomeAddress'] ?? null,
      }
      const extensionSpecificData: Partial<ExtensionBySelfData> = {
        'WCICS010Dto:txtZiryCardNum': userRegistration['WCFDS010Dto:txtZiryCardNum'] ?? null,
      }

      const changeSpecificData: Partial<ChangeBySelfData> = {
        'WCICS020Dto:txtZiryCardNum': userRegistration['WCFDS010Dto:txtZiryCardNum'] ?? null,
      }

      if (appType === ApplicationType.Extension) {
        //This is intentional 
        //user.status_of_residence is available but the design specifies to use
        //the value selected in the Select field
        const statusOfResidenceToExtend = selectedStatusOfResidence;

        switch (statusOfResidenceToExtend) {
          case StatusOfResidenceCode_CB19.Gijinkoku:
            await createFormN(visaApplication.id);
            break;
          case StatusOfResidenceCode_CB19.Student:
            await createFormP(visaApplication.id);
            break;
          case StatusOfResidenceCode_CB19.Dependent:
            await createFormR(visaApplication.id, {
              applicant_family_name: parseName(applicantName, ' ').familyName || null,
              applicant_given_name: parseName(applicantName, ' ').givenName || null,
              applicant_middle_name: parseName(applicantName, ' ').middleName || null,
              applicant_residence_card_number: userRegistration['WCFDS010Dto:txtZiryCardNum'] || null,
            });
            break;
          default:
            throw new Error('Invalid status of residence');
        }

        await createExtensionBySelfData(
          visaApplication.id,
          {
            "No.": No, //This value ('1') must be used for this kind of application,
            
            //Note: Current status of residence code and Status of residence code are different
            //Don't mix them up. They will be used in different fields.

            //現在の在留資格 / Current status of residence
            "WCICS010Dto:selCurrentZirySkk": statusOfResidenceToExtend,

            //申請内容の控え有無 / Keep a copy of the application content
            //We only use Yes
            "WZAAS190Dto:chkHkeDui": KeptVisaApplicationContent.Yes, 

            //申請種別　/ Application category
            selSnsiShbt: convertToApplicationCategoryCodeForExtensionBySelf(statusOfResidenceToExtend),

            //在留資格 / Status of residence
            selZirySkk: convertToStatusOfResidenceCodeForExtensionBySelf(statusOfResidenceToExtend),
            ...commonUserData,
            ...extensionSpecificData
          }
        );
      }

      if (appType === ApplicationType.Change) {
        const currentStatusOfResidence = statusOfResidence;
        let targetApplicationCategoryCode: ApplicationCategoryCodeForChangeBySelf;

        switch (selectedStatusOfResidence) {
          case StatusOfResidenceCode_CB19.Gijinkoku:
            await createFormN(visaApplication.id);
            targetApplicationCategoryCode = ApplicationCategoryCodeForChangeBySelf.Gijinkoku;
            break;
          case StatusOfResidenceCode_CB19.Dependent:
            await createFormR(visaApplication.id, {
              applicant_family_name: parseName(applicantName, ' ').familyName || null,
              applicant_given_name: parseName(applicantName, ' ').givenName || null,
              applicant_middle_name: parseName(applicantName, ' ').middleName || null,
              applicant_residence_card_number: userRegistration['WCFDS010Dto:txtZiryCardNum'] || null,
            });
            targetApplicationCategoryCode = ApplicationCategoryCodeForChangeBySelf.Dependent;
            break;
          default:
            throw new Error('Invalid target visa');
        }

        await createChangeBySelfData(
          visaApplication.id,
          {
            "No.": No, //This value ('1') must be used for this kind of application,

            //Note: Current status of residence code and Status of residence code are different
            //Don't mix them up. They will be used in different fields.

            //現在の在留資格 / Current status of residence
            "WCICS020Dto:selCurrentZirySkk": currentStatusOfResidence,

            //申請内容の控え有無 / Keep a copy of the application content
            "WZAAS200Dto:chkHkeDui": KeptVisaApplicationContent.Yes, //We only use Yes

            //新しい在留資格 / New status of residence
            "WCICS020Dto:selNewZirySkk": selectedStatusOfResidence,

            //申請種別 / Application category
            //We use the code based on the target visa, not the current visa
            selSnsiShbt: targetApplicationCategoryCode,

            //在留資格 / Status of residence
            //We use the code based on the target visa, not the current visa
            selZirySkk: convertToStatusOfResidenceCodeForChangeBySelf(selectedStatusOfResidence),

            ...commonUserData,
            ...changeSpecificData
          }
        );

        
      }

      navigate(`/application/${visaApplication.id}/edit/`);
      dispatcher.showSuccessOnSnackbar(t("snackbar.appCreated"));
    } catch (e) {
      dispatcher.showWarningOnSnackbar(t("snackbar.failedToProceed"));
    } finally {
      dispatcher.stopLoading();
    }
  }


  return (
    <Modal>
      <ContentContainer>
        <Content>
          <UpperPartContainer>
            <BackButton onClick={onClickBack}/>
            <PageHeading>{t("modalCreateApp.createApplication")}</PageHeading>
            <RadioField
              label={t("modalCreateApp.chooseApplication")}
              options={[
                {
                  label: t("modalCreateApp.extendPeriodOfStay"),
                  value: ApplicationType.Extension,

                  //*Only in the initial release
                  skip: !isCurrentVisaDependent
                },
                {
                  label: t("modalCreateApp.changeToDifferentStatusOfResidence"),
                  value: ApplicationType.Change,

                  //*Only in the initial release
                  skip: isCurrentVisaDependent
                }
              ]}
              value={appType}
              onValueChange={setAppType}
            />
            { !!appType &&
              <SelectField
                disabled
                showNoteBelowLabel
                label={t("modalCreateApp.selectVisaType")}
                placeholder={placeholder.selectVisaType}
                note={
                  <>
                    <ul style={{ paddingLeft: 18 }}>
                      <li>{t("modalCreateApp.ifYouWantToExtend")}</li>
                      <li>{t("modalCreateApp.ifYouWantToChange")}</li>
                    </ul>
                  </>
                }
                options={APPLYING_VISA_OPTIONS}
                value={selectedStatusOfResidence}
                onValueChange={setSelectedStatusOfResidence}
              />
            }
          </UpperPartContainer>
          <LowerPartContainer>
            <Button
              disabled={disableCreateButton}
              variant="primary"
              style={{ width: '100%' }}
              onClick={onClickCreate}
            >
              {tCommon("create")}
            </Button>
          </LowerPartContainer>
        </Content>
      </ContentContainer>
    </Modal>
  );
};

export default CreateNewAppModal;
