import { FunctionComponent, useState } from "react";
import Modal from "./Modal";
import styled from "styled-components";
import Text from "../text/Text";
import Column from "../layout/Column";
import { CONTENT_MAX_WIDTH } from "../../constants/styles";
import BackButton from "../navigation/BackButton";
import Button from "../form/Button";
import { useTranslation } from "react-i18next";
import { useDispatcher, useValidation } from "../../hooks";
import { sendInvitationToken } from "../../apis/invitation";
import { ReactComponent as Icon } from "../../assets/icon-send.svg";
import TextField from "../compound/TextField";
import BottomAlignedContainer from "../layout/BottomAlignedContainer";

interface UserInvitationModalProps {
  visaApplicationId: string | number;
  isVisaApplicationSubmitted?: boolean;
  onBack: () => void;
}

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  overflow: auto;
`;

const Content = styled(Column)`
  min-height: 100%;
  height: auto;
  max-width: ${CONTENT_MAX_WIDTH}px;
  padding: 20px;
  margin: 0 auto;
  gap: 20px;
`;

const IconContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SendIcon = styled(Icon)`
  margin-right: 10px;
  path {
    fill: #C8E0FF;
  }
`;

const HeadingText = styled(Text)`
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 29px;
`;


const UserInvitationModal: FunctionComponent<UserInvitationModalProps> = ({
  visaApplicationId,
  isVisaApplicationSubmitted,
  onBack
}) => {
  const { dispatcher } = useDispatcher();
  const { t } = useTranslation('translation', { keyPrefix: 'modalUserInvitation' });
  const { t : tWithOutPrefix } = useTranslation('translation');
  const validation = useValidation();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const onClick = async () => {
    dispatcher.startLoading();
    try {
      await sendInvitationToken(visaApplicationId);
      dispatcher.showSuccessOnSnackbar(tWithOutPrefix("snackbar.emailSent"));
    } catch {
     dispatcher.showWarningOnSnackbar(tWithOutPrefix("snackbar.failedToSendEmail"));
    } finally {
      dispatcher.stopLoading();
    }
  };

  return (
    <Modal>
      <ContentContainer>
        <Content>
          <IconContainer>
            <BackButton onClick={onBack} />
          </IconContainer>
          
          <HeadingText>{t("title")}</HeadingText>
          
          <Text>
            <b>
              {t(`${
                isVisaApplicationSubmitted 
                  ? "afterSubmission.description1"
                  : "beforeSubmission.description1" 
              }`)}
            </b>
          </Text>
          <Text color="#626161">
            {t(`${
              isVisaApplicationSubmitted 
                ? "afterSubmission.description2"
                : "beforeSubmission.description2" 
            }`)}
          </Text>
          
          <TextField 
            placeholder="visadas@gmail.com"
            label={t("email")}
            value={email} 
            error={emailError}
            validators={[validation.isEmailAddress]}
            onValueChange={setEmail} 
            onErrorChange={setEmailError}
          />
            
          <BottomAlignedContainer>
            <Button
              disabled={!email || !!emailError}
              variant="primary"
              onClick={onClick}
            >
              <SendIcon />
              {t("sendInvitationEmail")}
            </Button>
          </BottomAlignedContainer>

        </Content>
      </ContentContainer>
    </Modal>
  );
};

export default UserInvitationModal;
