import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import DateSelectField from "../../../components/compound/DateSelectField";
import SelectField from "../../../components/compound/SelectField";
import TextField from "../../../components/compound/TextField";
import Section from '../../../components/layout/Section';
import {
    CURRENT_STATUS_OF_RESIDENCE_CB19_OPTIONS,
    PERIOD_OF_STAY_CB20_OPTIONS_DIC
} from '../../../constants/options';
import { useFieldInputNotes, useValidation } from '../../../hooks';
import { ExtensionBySelfData } from '../../../types/visa/extensionBySelf/data';
import { VisaApplicationType } from '../../../types/visa/applicationType';
import { ChangeBySelfData } from '../../../types/visa/changeBySelf/data';
import { isThisVisaChange, isThisVisaExtension } from '../../../utils/visaApplicationHelper';
import { formatDate, isNoFieldError } from '../../../utils/visaFormDataHelpers/commonVisaFormDataHelper';
import { SelectOption } from '../../../types/option';
import { ApplicationFormSectionProps } from '../type';
import { useSectionErrorHandler } from '../hook';
import { FormR } from '../../../types/visa/uncommonFormParts/formR/data';
import { StatusOfResidenceCode_CB19 } from '../../../types/visa/statusOfResidence';
import { getFirstDayOfCurrentYear, getLastDayWithYearOffset } from '../../../utils/utils';

interface StatusOfResidenceSectionProps extends ApplicationFormSectionProps {
  extensionBySelfData: ExtensionBySelfData | null;
  changeBySelfData: ChangeBySelfData | null;
  onChangeExtensionBySelfData: (data: Partial<ExtensionBySelfData>) => void;
  onChangeChangeBySelfData: (data: Partial<ChangeBySelfData>) => void;
  onChangeFormR?: (data: Partial<FormR>) => void;
}

const StatusOfResidenceSection: FunctionComponent<StatusOfResidenceSectionProps> = ({
  isActive,
  readOnly,
  visaApplicationType,
  extensionBySelfData,
  changeBySelfData,
  onChangeExtensionBySelfData,
  onChangeChangeBySelfData,
  onChangeFormR,
  onChangeSectionReadiness,
  showEmptyRequiredValueError,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'statusOfResidenceSection' });
  const { t : tWithoutPrefix } = useTranslation();
  const { conditionalInputNotes } = useFieldInputNotes();
  const validation = useValidation();
  const [statusOfResidence, setStatusOfResidenceStatus] = useState('');
  const [periodOfStay, setPeriodOfStay] = useState('');
  const [dateOfExpiration, setDateOfExpiration] = useState('');
  const [residenceCardNumber, setResidenceCardNumber] = useState('');
  const [residenceCardNumberError, setResidenceCardNumberError] = useState('');
  const isVisaExtension = isThisVisaExtension(visaApplicationType);
  const isVisaChange = isThisVisaChange(visaApplicationType);
  const { createGetEmptyFieldError } = useSectionErrorHandler();
  const getEmptyError = createGetEmptyFieldError({ additionalCondition: showEmptyRequiredValueError });
  const saveExtensionData = (data: Partial<ExtensionBySelfData>) => { onChangeExtensionBySelfData(data) }
  const saveChangeData = (data: Partial<ChangeBySelfData>) => { onChangeChangeBySelfData(data) }
  const currentStatusOfResidenceOptions: SelectOption[] = useMemo(() => {
    return CURRENT_STATUS_OF_RESIDENCE_CB19_OPTIONS.filter(options => {
      switch (visaApplicationType) {
        //In the case of visa change, we need to filter out the target visa status
        //In the case of visa extension, this is not necessary because the field is read-only
        case VisaApplicationType.ChangeToDependentVisaBySelf:
          return options.value !== StatusOfResidenceCode_CB19.Dependent;
        case VisaApplicationType.ChangeToGijinkokuVisaBySelf:
          return options.value !== StatusOfResidenceCode_CB19.Gijinkoku;
        case VisaApplicationType.ChangeToStudyAbroadVisaBySelf:
          return options.value !== StatusOfResidenceCode_CB19.Student;
        default:
          return true;
      }
    })
  }, [visaApplicationType]);

  useEffect(() => {
    if (isVisaExtension && extensionBySelfData) {
      const data: ExtensionBySelfData = extensionBySelfData;
      setStatusOfResidenceStatus(data['WCICS010Dto:selCurrentZirySkk'] || '');
      setPeriodOfStay(data['WCICS010Dto:selZiryPeriod'] || '');
      setDateOfExpiration(
        formatDate(
          data['WCICS010Dto:selExpirationDateYear'],
          data['WCICS010Dto:selExpirationDateMonth'],
          data['WCICS010Dto:selExpirationDateDay'],
          '-'
        )
      );

      if (isNoFieldError(residenceCardNumberError))
        setResidenceCardNumber(data['WCICS010Dto:txtZiryCardNum'] || '');
    }

    if (isVisaChange && changeBySelfData) {
      const data: ChangeBySelfData = changeBySelfData;
      setStatusOfResidenceStatus(data['WCICS020Dto:selCurrentZirySkk'] || '');
      setPeriodOfStay(data['WCICS020Dto:selZiryPeriod'] || '');
      setDateOfExpiration(
        formatDate(
          data['WCICS020Dto:selExpirationDateYear'],
          data['WCICS020Dto:selExpirationDateMonth'],
          data['WCICS020Dto:selExpirationDateDay'],
          '-'
        )
      );

      if (isNoFieldError(residenceCardNumberError))
        setResidenceCardNumber(data['WCICS020Dto:txtZiryCardNum'] || '');
    }

  }, [extensionBySelfData, changeBySelfData]);


  useEffect(() => {
    const areAllRequiredFieldsFilled = [
      statusOfResidence,
      periodOfStay,
      dateOfExpiration,
      residenceCardNumber
    ].every(val => !!val);

    const areThereNoErrors = [
      residenceCardNumberError
    ].every(val => !val);

    onChangeSectionReadiness(areAllRequiredFieldsFilled && areThereNoErrors);
  }, [
    statusOfResidence,
    periodOfStay,
    dateOfExpiration,
    residenceCardNumber,
    residenceCardNumberError
  ]);

  if (isActive === false)
    return null;

  return (
    <Section>
      {/* Current status of residence
        NOTE: this field is read-only when extending a visa.
        but it is editable when changing a visa.
      */}
      <SelectField
        disabled={isVisaExtension || readOnly}
        required
        label={t("currentStatusOfResidence")}
        placeholder={tWithoutPrefix("placeholder.select")}
        options={currentStatusOfResidenceOptions}
        value={statusOfResidence}
        error={getEmptyError(statusOfResidence)}
        onValueChange={val => {
          if (!isVisaChange)
            return;

          setStatusOfResidenceStatus(val);
          saveChangeData({
            'WCICS020Dto:selCurrentZirySkk': val
          });
        }}
      />

      {/* Period of stay */}
      <SelectField
        required
        disabled={readOnly}
        label={t("periodOfStay")}
        placeholder={tWithoutPrefix("placeholder.select")}
        options={PERIOD_OF_STAY_CB20_OPTIONS_DIC[statusOfResidence] || []}
        value={periodOfStay}
        error={getEmptyError(periodOfStay)}
        onValueChange={val => {
          setPeriodOfStay(val);
          isVisaExtension && saveExtensionData({
            'WCICS010Dto:selZiryPeriod': val
          });
          isVisaChange && saveChangeData({
            'WCICS020Dto:selZiryPeriod': val
          });
        }}
      />

      {/* Date of expiration */}
      <DateSelectField
        required
        disabled={readOnly}
        label={t("dateOfExpiration")}
        minDate={getFirstDayOfCurrentYear()}
        maxDate={getLastDayWithYearOffset(5)}
        value={dateOfExpiration}
        error={getEmptyError(dateOfExpiration)}
        onValueChange={val => {
          setDateOfExpiration(val);

          if (val) {
            const [year, month, day] = val.split('-');

            isVisaExtension && saveExtensionData({
              'WCICS010Dto:selExpirationDateYear': year,
              'WCICS010Dto:selExpirationDateMonth': month,
              'WCICS010Dto:selExpirationDateDay': day,
            });

            isVisaChange && saveChangeData({
              'WCICS020Dto:selExpirationDateYear': year,
              'WCICS020Dto:selExpirationDateMonth': month,
              'WCICS020Dto:selExpirationDateDay': day,
            });
          }
        }}
      />

      {/* Residence card number */}
      <TextField
        required
        disabled={readOnly}
        label={t("residenceCardNumber")}
        placeholder="AAAA9999999C"
        value={residenceCardNumber}
        error={residenceCardNumberError || getEmptyError(residenceCardNumber)}
        restriction={conditionalInputNotes.halfwidthLetterAndNumber}
        maxLength={12}
        validators={[validation.isAlphanumeric, validation.createLengthValidator(12)]}
        onValueChange={setResidenceCardNumber}
        onErrorChange={setResidenceCardNumberError}
        onBlur={() => {
          isVisaExtension && saveExtensionData({
            'WCICS010Dto:txtZiryCardNum': residenceCardNumber
          });

          isVisaChange && saveChangeData({
            'WCICS020Dto:txtZiryCardNum': residenceCardNumber
          });

          onChangeFormR?.({
            applicant_residence_card_number: residenceCardNumber
          });
        }}
      />
    </Section>
  );
};

export default StatusOfResidenceSection;
