import { FunctionComponent, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import Modal from "../../components/modal/Modal";
import Button from "../../components/form/Button";
import Text from "../../components/text/Text";
import Column from "../../components/layout/Column";
import { ReactComponent as CloseIcon } from "../../assets/icon-cross.svg";
import { CONTENT_MAX_WIDTH } from "../../constants/styles";
import { useDispatcher, useNextAction } from "../../hooks";
import BackButton from "../../components/navigation/BackButton";
import { VISADAS_SUPPORT_EMAIL } from "../../constants/values/commonValues";
import EmailAnchor from "../../components/text/EmailAnchor";
import { formatDateString } from "../../utils/utils";
import { VisaMessage } from "../../types/visa/applicationData";
import Spinner from "../../components/display/Spinner";
import Row from "../../components/layout/Row";
import { fetchLatestVisaMessages, fetchMynaUrlToFetchLatestVisaMessages, getVisaApplication } from "../../apis/visaApplication";
import { getVisaMessages } from "../../utils/visaApplicationHelper";
import { VisaMessageModalNextAction as NextAction } from "../../types/nextActions";
import { fetchFakeVisaMessages } from "../../apis/mock";
import BottomAlignedContainer from "../../components/layout/BottomAlignedContainer";


interface VisaMessageModalProps {
  visaApplicationId: number | string;
  onClose: () => void;
}

interface OnEvent {
  click: () => void;
  close: () => void;
  back: () => void;
}

interface ModalContentProps {
  contentType: ContentType;
  messages: VisaMessage[];
  onEvent: OnEvent;
}

enum ContentType {
  CheckMessage,
  Processing,
  Messages,
}

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  overflow: auto;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
  max-width: ${CONTENT_MAX_WIDTH}px;
  padding: 20px;
  margin: 0 auto;
`;

const IconContainer = styled.div`
  margin-bottom: 20px;
`;

const LocalColumn = styled(Column)`
  gap: 30px;
`;

const HeadingText = styled(Text)`
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 29px;
`;

const LinkAnchor = styled.a`
  text-decoration: none;
  color: inherit;
  width: 100%;
  margin-top: 20px;
`;
const MessagesContainer = styled(Column)`
  padding-top: 30px;
  gap: 30px;
`;

const StepTextContainer = styled.div`
  width: 100%;
  padding: 10px;
  background-color: #f2f2f2;
`;

const ProcessingContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled(BottomAlignedContainer)`
  margin-top: 78px;
`;

const LocalOl = styled.ol`
  margin: 0;
  padding-left: 25px;
`;

const LocalLi = styled.li`
  &::marker {
    font-size: 0.9rem;
    color: #444444;
  }
`;

const SmallText = styled(Text)`
  white-space: "pre-wrap";
  display: inline;
  font-size: 0.8rem;
  color: #999999;
  line-height: 17px;
`;

const LocalButton = styled(Button)`
  min-width: 280px;
  @media (max-width: ${CONTENT_MAX_WIDTH}px) {
    width: 100%;
  }
`;

const ModalContent: FunctionComponent<ModalContentProps> = ({
  messages,
  contentType,
  onEvent,
}) => {
  const { t } = useTranslation();
  const confMsgs = "modalVisaMessage.checkMessages";
  const processing = "modalVisaMessage.processing";
  const msgs = "modalVisaMessage.messages";

  switch (contentType) {
    //"Check Messages" - メッセージの確認
    case ContentType.CheckMessage:
      return (
        <>
          <IconContainer>
            <BackButton onClick={onEvent.back} />
          </IconContainer>
          <LocalColumn>
            <HeadingText>{t(`${confMsgs}.title`)}</HeadingText>
            <Column style={{ gap: 20 }}>
              <Text>{t(`${confMsgs}.description`)}</Text>
              <StepTextContainer>
                <LocalOl>
                  <LocalLi>
                    <Text>{t(`${confMsgs}.step1`)}</Text>
                  </LocalLi>
                  <LocalLi>
                    <Text>{t(`${confMsgs}.step2`)}</Text>
                  </LocalLi>
                  <LocalLi>
                    <Text>{t(`${confMsgs}.step3`)}</Text>
                  </LocalLi>
                  <LocalLi>
                    <Text>{t(`${confMsgs}.step4`)}</Text>
                  </LocalLi>
                </LocalOl>
              </StepTextContainer>
            </Column>
            <SmallText>
              <Trans
                i18nKey={"common.ifYouAreHavingTrouble"}
                components={{
                  email: VISADAS_SUPPORT_EMAIL,
                  anchor: <EmailAnchor mailTo={VISADAS_SUPPORT_EMAIL}/>,
                }}
              />
            </SmallText>
          </LocalColumn>
          <ButtonContainer>
            <LocalButton variant="primary" onClick={onEvent.click}>
              {t("common.launch")}
            </LocalButton>
          </ButtonContainer>
        </>
      );

    //"Processing" - 確認中
    case ContentType.Processing:
      return (
        <>
          <ProcessingContainer>
            <Column style={{ width: "auto", gap: 20, alignItems: "center" }}>
              <Spinner />
              <Text>{t(`${processing}`)}</Text>
            </Column>
          </ProcessingContainer>
        </>
      );

    //"Messages" - メッセージ
    case ContentType.Messages:
      return (
        <>
          <Row style={{ justifyContent: "space-between" }}>
            <HeadingText>{t(`${msgs}.title`)}</HeadingText>
            <CloseIcon style={{ cursor: "pointer" }} onClick={onEvent.click}/>
          </Row>
          <MessagesContainer>
            {messages.length > 0 ? (
              messages.map((message, i) => {
                return (
                  <Column key={`message-${i}`}>
                    <Text style={{ marginBottom: 15 }}>
                      {formatDateString(message.added_at)}
                    </Text>
                    <Text>{message.message_content}</Text>
                    {message.download_url && (
                      <LinkAnchor
                        href={message.download_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button variant="inline">
                          {t(`${msgs}.viewDocument`)}
                        </Button>
                      </LinkAnchor>
                    )}
                  </Column>
                );
              })
            ) : (
              <Text color="#626161"> 
                {t(`${msgs}.noMessagesReceived`)}
              </Text>
            )}
          </MessagesContainer>
        </>
      );
  }
};

const VisaMessageModal: FunctionComponent<VisaMessageModalProps> = ({ 
  visaApplicationId,
  onClose 
}) => {
  const { t } = useTranslation();
  const { dispatcher } = useDispatcher();
  const { isVisaMessageModalNextAction, consumeNextAction } = useNextAction();
  const [visaMessages, setVisaMessages] = useState<VisaMessage[]>([]);
  const [contentType, setContentType] = useState<ContentType>(
    ContentType.CheckMessage
  );
  const comebackDir = `/application/${visaApplicationId}/review`;

  const onClick = (() => {
    switch (contentType) {
      case ContentType.CheckMessage:
        return async () => {
          dispatcher.startLoading();
          try {
            dispatcher.setNextAction(NextAction.RequestToConfirmMessages);
            const result = await fetchMynaUrlToFetchLatestVisaMessages(
              visaApplicationId,
              comebackDir
            );
            window.location.href = result.myna_cert_id_url;
          } catch (e) {
            dispatcher.clearNextAction();
            dispatcher.showWarningOnSnackbar(t("snackbar.failedToProceed"));
          } finally {
            dispatcher.stopLoading();
          }
        };
      case ContentType.Messages:
        return () => {
          onClose();
        };
      default:
        return () => {};
    }
  })();

  const onBack = (() => {
    switch (contentType) {
      case ContentType.CheckMessage:
        return () => onClose();

      default:
        return () => {};
    }
  })();

  useEffect(() => {
    if (!isVisaMessageModalNextAction()) {
      dispatcher.clearNextAction();
      return;
    }

    const nextAction = consumeNextAction();

    switch (nextAction.identifier) {
      case NextAction.RequestToConfirmMessages:
        setContentType(ContentType.Processing);

        (async () => {
          try {
            const visaApplication = await getVisaApplication(visaApplicationId);
            const visaSubmissionId = visaApplication.last_submitted_visa_submission!.id;
            
            //Uncomment one of the following lines to use the real API or the mock API
            const visaSubmission = await fetchLatestVisaMessages(visaSubmissionId);
            //const visaSubmission = await fetchFakeVisaMessages(visaSubmissionId);
            
            setVisaMessages(getVisaMessages(visaSubmission));
            setContentType(ContentType.Messages);
          } catch (e) {
            setContentType(ContentType.CheckMessage);
            dispatcher.showWarningOnSnackbar(t("snackbar.failedToProceed"));
          }
        })();
        break;
    }
  }, []);

  return (
    <Modal>
      <ContentContainer>
        <Content>
          <ModalContent
            contentType={contentType}
            messages={visaMessages ?? []}
            onEvent={{
              click: () => onClick(),
              close: () => onClose(),
              back: () => onBack(),
            }}
          />
        </Content>
      </ContentContainer>
    </Modal>
  );
};

export default VisaMessageModal;
