import { SelectOption } from "../../types/option";

export const OFFICE_OPTIONS: Record<string, SelectOption[]>  = {
  //Hokkaido
  '01': [
      {
          'label': 'office.140',
          'value': '140',
      },
      {
          'label': 'office.160',
          'value': '160',
      },
      {
          'label': 'office.161',
          'value': '161',
      },
      {
          'label': 'office.162',
          'value': '162',
      },
      {
          'label': 'office.163',
          'value': '163',
      },
      {
          'label': 'office.164',
          'value': '164',
      },
  ],
  //Aomori
  '02': [
      {
          'label': 'office.190',
          'value': '190',
      },
      {
          'label': 'office.220',
          'value': '220',
      },
      {
          'label': 'office.221',
          'value': '221',
      },
      {
          'label': 'office.223',
          'value': '223',
      },
  ],
  //Iwate
  '03': [
      {
          'label': 'office.190',
          'value': '190',
      },
      {
          'label': 'office.220',
          'value': '220',
      },
      {
          'label': 'office.221',
          'value': '221',
      },
      {
          'label': 'office.223',
          'value': '223',
      },
  ],
  //Miyagi
  '04': [
      {
          'label': 'office.190',
          'value': '190',
      },
  ],
  //Akita
  '05': [
      {
          'label': 'office.190',
          'value': '190',
      },
      {
          'label': 'office.220',
          'value': '220',
      },
      {
          'label': 'office.221',
          'value': '221',
      },
      {
          'label': 'office.223',
          'value': '223',
      },
      {
          'label': 'office.224',
          'value': '224',
      },
  ],
  //Yamagata
  '06': [
      {
          'label': 'office.190',
          'value': '190',
      },
      {
          'label': 'office.223',
          'value': '223',
      },
      {
          'label': 'office.224',
          'value': '224',
      },
      {
          'label': 'office.225',
          'value': '225',
      },
  ],
  //Fukushima
  '07': [
      {
          'label': 'office.190',
          'value': '190',
      },
      {
          'label': 'office.225',
          'value': '225',
      },
  ],
  //Ibaraki
  '08': [
      {
          'label': 'office.280',
          'value': '280',
      },
      {
          'label': 'office.345',
          'value': '345',
      },
      {
          'label': 'office.346',
          'value': '346',
      },
      {
          'label': 'office.349',
          'value': '349',
      },
      {
          'label': 'office.357',
          'value': '357',
      },
  ],
  //Tochigi
  '09': [
      {
          'label': 'office.280',
          'value': '280',
      },
      {
          'label': 'office.345',
          'value': '345',
      },
      {
          'label': 'office.346',
          'value': '346',
      },
      {
          'label': 'office.347',
          'value': '347',
      },
  ],
  //Gunma
  '10': [
      {
          'label': 'office.280',
          'value': '280',
      },
      {
          'label': 'office.346',
          'value': '346',
      },
      {
          'label': 'office.347',
          'value': '347',
      },
  ],
  //Saitama
  '11': [
      {
          'label': 'office.280',
          'value': '280',
      },
      {
          'label': 'office.347',
          'value': '347',
      },
      {
          'label': 'office.348',
          'value': '348',
      },
  ],
  //Chiba
  '12': [
      {
          'label': 'office.280',
          'value': '280',
      },
      {
          'label': 'office.349',
          'value': '349',
      },
      {
          'label': 'office.357',
          'value': '357',
      },
  ],
  //Tokyo
  '13': [
      {
          'label': 'office.280',
          'value': '280',
      },
      {
          'label': 'office.350',
          'value': '350',
      },
  ],
  '132098': [
      {
          'label': 'office.280',
          'value': '280',
      },
      {
          'label': 'office.475',
          'value': '475',
      },
      {
          'label': 'office.350',
          'value': '350',
      },
  ],
  '132195': [
      {
          'label': 'office.280',
          'value': '280',
      },
      {
          'label': 'office.475',
          'value': '475',
      },
      {
          'label': 'office.350',
          'value': '350',
      },
  ],
  '132241': [
      {
          'label': 'office.280',
          'value': '280',
      },
      {
          'label': 'office.475',
          'value': '475',
      },
      {
          'label': 'office.350',
          'value': '350',
      },
  ],
  '132250': [
      {
          'label': 'office.280',
          'value': '280',
      },
      {
          'label': 'office.475',
          'value': '475',
      },
      {
          'label': 'office.350',
          'value': '350',
      },
  ],
  //Niigata
  '15': [
      {
          'label': 'office.280',
          'value': '280',
      },
      {
          'label': 'office.347',
          'value': '347',
      },
      {
          'label': 'office.351',
          'value': '351',
      },
      {
          'label': 'office.352',
          'value': '352',
      },
  ],
  //Yamanashi
  '19': [
      {
          'label': 'office.280',
          'value': '280',
      },
      {
          'label': 'office.350',
          'value': '350',
      },
      {
          'label': 'office.353',
          'value': '353',
      },
  ],
  //Nagano
  '20': [
      {
          'label': 'office.280',
          'value': '280',
      },
      {
          'label': 'office.347',
          'value': '347',
      },
      {
          'label': 'office.353',
          'value': '353',
      },
      {
          'label': 'office.352',
          'value': '352',
      },
  ],
  //Kanagawa
  '14': [
      {
          'label': 'office.280',
          'value': '280',
      },
      {
          'label': 'office.460',
          'value': '460',
      },
      {
          'label': 'office.475',
          'value': '475',
      },
  ],
  '141500': [
      {
          'label': 'office.280',
          'value': '280',
      },
      {
          'label': 'office.350',
          'value': '350',
      },
      {
          'label': 'office.460',
          'value': '460',
      },
      {
          'label': 'office.475',
          'value': '475',
      },
  ],
  //Toyama
  '16': [
      {
          'label': 'office.530',
          'value': '530',
      },
      {
          'label': 'office.580',
          'value': '580',
      },
      {
          'label': 'office.581',
          'value': '581',
      },
  ],
  //Ishikawa
  '17': [
      {
          'label': 'office.530',
          'value': '530',
      },
      {
          'label': 'office.581',
          'value': '581',
      },
      {
          'label': 'office.582',
          'value': '582',
      },
  ],
  //Fukui
  '18': [
      {
          'label': 'office.530',
          'value': '530',
      },
      {
          'label': 'office.582',
          'value': '582',
      },
  ],
  //Gifu
  '21': [
      {
          'label': 'office.530',
          'value': '530',
      },
      {
          'label': 'office.580',
          'value': '580',
      },
      {
          'label': 'office.583',
          'value': '583',
      },
  ],
  //Shizuoka
  '22': [
      {
          'label': 'office.530',
          'value': '530',
      },
      {
          'label': 'office.584',
          'value': '584',
      },
      {
          'label': 'office.585',
          'value': '585',
      },
  ],
  //Aichi
  '23': [
      {
          'label': 'office.530',
          'value': '530',
      },
      {
          'label': 'office.586',
          'value': '586',
      },
  ],
  //Mie
  '24': [
      {
          'label': 'office.530',
          'value': '530',
      },
      {
          'label': 'office.587',
          'value': '587',
      },
  ],
  //Shiga
  '25': [
      {
          'label': 'office.620',
          'value': '620',
      },
      {
          'label': 'office.661',
          'value': '661',
      },
      {
          'label': 'office.660',
          'value': '660',
      },
  ],
  //Kyoto
  '26': [
      {
          'label': 'office.620',
          'value': '620',
      },
      {
          'label': 'office.661',
          'value': '661',
      },
      {
          'label': 'office.660',
          'value': '660',
      },
      {
          'label': 'office.662',
          'value': '662',
      },
  ],
  //Osaka
  '27': [
      {
          'label': 'office.620',
          'value': '620',
      },
  ],
  //Hyogo
  '28': [
      {
          'label': 'office.620',
          'value': '620',
      },
      {
          'label': 'office.662',
          'value': '662',
      },
      {
          'label': 'office.710',
          'value': '710',
      },
      {
          'label': 'office.716',
          'value': '716',
      },
  ],
  //Nara
  '29': [
      {
          'label': 'office.620',
          'value': '620',
      },
      {
          'label': 'office.663',
          'value': '663',
      },
      {
          'label': 'office.664',
          'value': '664',
      },
  ],
  //Wakayama
  '30': [
      {
          'label': 'office.620',
          'value': '620',
      },
      {
          'label': 'office.663',
          'value': '663',
      },
      {
          'label': 'office.664',
          'value': '664',
      },
  ],
  //Tottori
  '31': [
      {
          'label': 'office.770',
          'value': '770',
      },
      {
          'label': 'office.800',
          'value': '800',
      },
      {
          'label': 'office.801',
          'value': '801',
      },
      {
          'label': 'office.802',
          'value': '802',
      },
  ],
  //Shimane
  '32': [
      {
          'label': 'office.770',
          'value': '770',
      },
      {
          'label': 'office.800',
          'value': '800',
      },
      {
          'label': 'office.801',
          'value': '801',
      },
      {
          'label': 'office.805',
          'value': '805',
      },
      {
          'label': 'office.806',
          'value': '806',
      },
  ],
  //Okayama
  '33': [
      {
          'label': 'office.770',
          'value': '770',
      },
      {
          'label': 'office.802',
          'value': '802',
      },
      {
          'label': 'office.803',
          'value': '803',
      },
  ],
  //Hiroshima
  '34': [
      {
          'label': 'office.770',
          'value': '770',
      },
      {
          'label': 'office.803',
          'value': '803',
      },
      {
          'label': 'office.806',
          'value': '806',
      },
  ],
  //Yamaguchi
  '35': [
      {
          'label': 'office.770',
          'value': '770',
      },
      {
          'label': 'office.805',
          'value': '805',
      },
      {
          'label': 'office.806',
          'value': '806',
      },
  ],
  //Kagawa
  '37': [
      {
          'label': 'office.730',
          'value': '730',
      },
      {
          'label': 'office.751',
          'value': '751',
      },
  ],
  //Ehime
  '38': [
      {
          'label': 'office.730',
          'value': '730',
      },
      {
          'label': 'office.750',
          'value': '750',
      },
  ],
  //Tokushima
  '36': [
      {
          'label': 'office.730',
          'value': '730',
      },
      {
          'label': 'office.751',
          'value': '751',
      },
      {
          'label': 'office.752',
          'value': '752',
      },
  ],
  //Kochi
  '39': [
      {
          'label': 'office.730',
          'value': '730',
      },
      {
          'label': 'office.751',
          'value': '751',
      },
      {
          'label': 'office.750',
          'value': '750',
      },
      {
          'label': 'office.752',
          'value': '752',
      },
  ],
  //Fukuoka
  '40': [
      {
          'label': 'office.820',
          'value': '820',
      },
      {
          'label': 'office.836',
          'value': '836',
      },
      {
          'label': 'office.839',
          'value': '839',
      },
      {
          'label': 'office.842',
          'value': '842',
      },
  ],
  //Saga
  '41': [
      {
          'label': 'office.820',
          'value': '820',
      },
      {
          'label': 'office.839',
          'value': '839',
      },
      {
          'label': 'office.840',
          'value': '840',
      },
  ],
  //Kumamoto
  '43': [
      {
          'label': 'office.820',
          'value': '820',
      },
      {
          'label': 'office.842',
          'value': '842',
      },
      {
          'label': 'office.843',
          'value': '843',
      },
      {
          'label': 'office.844',
          'value': '844',
      },
      {
          'label': 'office.845',
          'value': '845',
      },
  ],
  //Nagasaki
  '42': [
      {
          'label': 'office.820',
          'value': '820',
      },
      {
          'label': 'office.839',
          'value': '839',
      },
      {
          'label': 'office.840',
          'value': '840',
      },
      {
          'label': 'office.841',
          'value': '841',
      },
  ],
  //Oita
  '44': [
      {
          'label': 'office.820',
          'value': '820',
      },
      {
          'label': 'office.836',
          'value': '836',
      },
      {
          'label': 'office.842',
          'value': '842',
      },
      {
          'label': 'office.843',
          'value': '843',
      },
  ],
  //Miyazaki
  '45': [
      {
          'label': 'office.820',
          'value': '820',
      },
      {
          'label': 'office.842',
          'value': '842',
      },
      {
          'label': 'office.843',
          'value': '843',
      },
      {
          'label': 'office.844',
          'value': '844',
      },
      {
          'label': 'office.845',
          'value': '845',
      },
  ],
  //Kagoshima
  '46': [
      {
          'label': 'office.820',
          'value': '820',
      },
      {
          'label': 'office.845',
          'value': '845',
      },
  ],
  //Okinawa
  '47': [
      {
          'label': 'office.820',
          'value': '820',
      },
      {
          'label': 'office.880',
          'value': '880',
      },
      {
          'label': 'office.892',
          'value': '892',
      },
  ],
  '472140': [
      {
          'label': 'office.820',
          'value': '820',
      },
      {
          'label': 'office.880',
          'value': '880',
      },
      {
          'label': 'office.892',
          'value': '892',
      },
      {
          'label': 'office.893',
          'value': '893',
      },
  ],
  '473707': [
      {
          'label': 'office.820',
          'value': '820',
      },
      {
          'label': 'office.880',
          'value': '880',
      },
      {
          'label': 'office.892',
          'value': '892',
      },
      {
          'label': 'office.893',
          'value': '893',
      },
  ],
  '472077': [
      {
          'label': 'office.820',
          'value': '820',
      },
      {
          'label': 'office.880',
          'value': '880',
      },
      {
          'label': 'office.892',
          'value': '892',
      },
      {
          'label': 'office.891',
          'value': '891',
      },
  ],
};
