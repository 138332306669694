import { Language } from "../types/language";
import { axiosInstance } from "./common";

export const getLanguages = async () => {
  const response = await axiosInstance.request({
      method: 'GET',
      url: `languages`
  });

  return response.data as Language[];
};
