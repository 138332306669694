import { createContext } from 'react';
import ACTIONS from './actions';
import { ReducerAction, ReducerContextValue, ReducerState } from './types/reducer';
import { NextAction } from './types/nextActions';

const ITEM_NAME = 'reducerState';
const loadState = () => JSON.parse(localStorage.getItem(ITEM_NAME) || '{}');
const saveState = (state: Partial<Record<keyof ReducerState, any>>) => {
    localStorage.setItem(ITEM_NAME, JSON.stringify({ ...loadState(), ...state }))
};
const clearState = () => localStorage.removeItem(ITEM_NAME);
const getNextActionInitState = (): NextAction => ({ identifier: '' });

export const INITIAL_STATE: ReducerState = {
    isLoggedIn: false,
    isSetupComplete: false,
    isOnboradingRequired: true,
    isLoading: false,
    isSaving: false,
    isUploading: false,
    uploaderIds: [],
    loadingMessage: '',
    snackbar: {
        message: '',
        type: 'neutral',
        isShowing: false
    },
    nextAction: getNextActionInitState(),
    ...loadState()
};

export const ReducerContext = createContext<ReducerContextValue>({
    state: INITIAL_STATE,
    dispatch: () => {},
});

export const reducer = (state: ReducerState, action: ReducerAction) => {
    switch (action.type) {
        case ACTIONS.LOGIN:
        case ACTIONS.TREAT_AS_LOGGED_IN:
            saveState({ isLoggedIn: true });
            return {
                ...state,
                isLoggedIn: true
            }
        case ACTIONS.LOGOUT:
        case ACTIONS.TREAT_AS_LOGGED_OUT:
            clearState();
            return {
                ...state,
                isLoggedIn: false
            }
        case ACTIONS.START_LOADING:
            return {
                ...state,
                isLoading: true,
                loadingMessage: action.payload.message ?? '',
            }
        case ACTIONS.STOP_LOADING:
            return {
                ...state,
                isLoading: false,
                loadingMessage: '',
            }
        case ACTIONS.SHOW_SNACKBAR:
            return {
                ...state,
                snackbar: {
                    ...action.payload,
                    isShowing: true
                }
            }
        case ACTIONS.CLEAR_SNACKBAR:
            return {
                ...state,
                snackbar: {
                    ...state.snackbar,
                    isShowing: false
                }
            }
        case ACTIONS.START_SAVING:
            return {
                ...state,
                isSaving: true
            }
        case ACTIONS.END_SAVING:
            return {
                ...state,
                isSaving: false
            }
        case ACTIONS.START_UPLOADING:
            return {
                ...state,
                isUploading: true,
                uploaderIds: action.payload.uploaderId
                    ? [...state.uploaderIds, action.payload.uploaderId]
                    : state.uploaderIds
            }
        case ACTIONS.END_UPLOADING:
            const newUploaderIds = action.payload.uploaderId
                ? state.uploaderIds.filter(id => id !== action.payload.uploaderId) 
                : state.uploaderIds

            return {
                ...state,
                isUploading: newUploaderIds.length > 0,
                uploaderIds: newUploaderIds,
            }
        case ACTIONS.SET_SETUP_COMPLETE:
            saveState({ isSetupComplete: action.payload.isSetupComplete });
            return {
                ...state,
                isSetupComplete: action.payload.isSetupComplete
            }
        case ACTIONS.SET_NEXT_ACTION:
            saveState({
                ...action.payload
            });

            return {
                ...state,
                ...action.payload
            }
        case ACTIONS.CLEAR_NEXT_ACTION:
            saveState({ nextAction: getNextActionInitState() });
            return {
                ...state,
                nextAction: getNextActionInitState()
            }
        case ACTIONS.SET_IS_ONBOARDING_REQUIRED:
            saveState({ isOnboradingRequired: action.payload.isOnboardingRequired });
            return {
                ...state,
                isOnboradingRequired: action.payload.isOnboardingRequired
            }
        default:
            return state
    }
};