import { FunctionComponent, ReactNode, useState } from "react";
import styled from "styled-components";
import InlineMessage from "../form/InlineMessage";
import Label from "../form/Label";
import UploadButton from "../form/UploadButton";
import {
  VisaAttachment,
  VisaAttachmentKind,
} from "../../types/visa/attachment";
import Column from "../layout/Column";
import Text from "../text/Text";
import {
  extractVisaAttachments,
} from "../../utils/visaFormDataHelpers/commonVisaFormDataHelper";
import { useDispatcher } from "../../hooks";
import { useTranslation } from "react-i18next";
import { generateRandomString } from "../../utils/utils";
import AttachmentPreview from "../display/AttachmentPreview";
import UploadingMessage from "../display/UploadingMessage";

interface VisaDocUploadFieldProps {
  required?: boolean;
  label?: string;
  note?: ReactNode | string;
  additionalButton?: ReactNode;
  uploadButtonText?: string;
  visaAttachments?: VisaAttachment[];
  visaAttachmentKind?: VisaAttachmentKind;
  hideButtonIfFileExists?: boolean;
  onAttachmentUpload?: (
    file: File,
    kind: VisaAttachmentKind,
    uploaderId?: string
  ) => void;
  onAttachmentDelete?: (visaAttachmentId: number) => void;
  enableRequiredAlert?: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const AlertMessage = styled(Text)`
  font-size: 0.813rem;
  color: #e93232;
`;


const VisaDocUploadField: FunctionComponent<VisaDocUploadFieldProps> = ({
  required,
  label,
  note,
  additionalButton,
  uploadButtonText,
  visaAttachments,
  visaAttachmentKind,
  hideButtonIfFileExists,
  onAttachmentUpload,
  onAttachmentDelete,
  enableRequiredAlert,
}) => {
  const sizeLimitInMB = 5;
  const { state, dispatcher } = useDispatcher();
  const { t } = useTranslation();
  const [id] = useState(generateRandomString());
  const targetAttachments = extractVisaAttachments(
    visaAttachments,
    visaAttachmentKind
  );
  const isFileBeingUploaded =
    state.isUploading &&
    state.uploaderIds.some((uploaderId) => uploaderId === id);
  const showAttachButton = !(hideButtonIfFileExists && targetAttachments.length > 0);

  return (
    <Container>
      {(label || note) && (
        <Column style={{ gap: 10 }}>
          {label && <Label required={required}>{label}</Label>}
          {note && (
            <InlineMessage variant="info" size="small">
              {note}
            </InlineMessage>
          )}
        </Column>
      )}
      {targetAttachments.map((visaAttachment, i) => (
        <AttachmentPreview key={`file-preview-${i}`} 
          onClickDelete={() => onAttachmentDelete?.(visaAttachment.id)}
          file={{
            size: visaAttachment.size,
            url: visaAttachment.url,
            name: visaAttachment.filename
          }}
        />
      ))}
      {isFileBeingUploaded && (
        <UploadingMessage />
      )}

      <Column style={{ gap: 10 }}>
        {showAttachButton &&
          <Column style={{ gap: 20 }}>
            {additionalButton && additionalButton}
            <UploadButton
              uploadLimitInMB={sizeLimitInMB}
              disabled={isFileBeingUploaded}
              customText={uploadButtonText}
              onFileSizeExceeded={() => {
                dispatcher.showWarningOnSnackbar(t("snackbar.under5MB"));
              }}
              onFileChange={(file: File) => {
                visaAttachmentKind &&
                  onAttachmentUpload?.(file, visaAttachmentKind, id);
              }}
            />
          </Column>
        }
        
        {enableRequiredAlert && required && targetAttachments.length === 0 && (
          <AlertMessage>{t("inputAlert.attachment")}</AlertMessage>
        )}
      </Column>
    </Container>
  );
};

export default VisaDocUploadField;
