import { FunctionComponent, useEffect, useState } from 'react';
import Section from '../../../components/layout/Section';
import { useTranslation } from "react-i18next";
import { DESIRED_PERIOD_OF_STAY_CB20_OPTIONS_FOR_DEPENDENT } from '../../../constants/options';
import SelectField from "../../../components/compound/SelectField";
import MultiTextField from "../../../components/compound/MultiTextField";
import RadioField from "../../../components/compound/RadioField";
import { VisaApplicationType } from '../../../types/visa/applicationType';
import { HowToReceiveNewResidenceCard } from '../../../types/visa/formCommonValues';
import { useFieldInputNotes, useFieldPlaceholder, useValidation } from '../../../hooks';
import { OFFICE_OPTIONS } from '../../../constants/options/office';
import { SelectOption } from '../../../types/option';
import { ChangeBySelfData } from '../../../types/visa/changeBySelf/data';
import { ApplicationFormSectionProps } from '../type';
import { useSectionErrorHandler } from '../hook';
import { isNoFieldError } from '../../../utils/visaFormDataHelpers/commonVisaFormDataHelper';

interface DetailsOfChangeSectionProps extends ApplicationFormSectionProps {
  changeBySelfData: ChangeBySelfData | null;
  onChangeChangeBySelfData: (data: Partial<ChangeBySelfData>) => void;
}

const DetailsOfChangeSection: FunctionComponent<DetailsOfChangeSectionProps> = ({
  isActive,
  readOnly,
  visaApplicationType,
  changeBySelfData,
  onChangeChangeBySelfData,
  onChangeSectionReadiness,
  showEmptyRequiredValueError,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'detailsOfChangeSection' });
  const { t : tWithoutPrefix } = useTranslation();
  const { inputNotes } = useFieldInputNotes();
  const validation = useValidation();
  const placeholder = useFieldPlaceholder();

  const [desiredStatusOfResidence, setDesiredStatusOfResidence] = useState('');
  const [desiredPeriodOfStay, setDesiredPeriodOfStay] = useState('');
  const [reasonForChange, setReasonForChange] = useState('');
  const [reasonForChangeError, setReasonForChangeError] = useState('');
  const [receivingMethod, setReceivingMethod] = useState('');
  const [officeToPickUp, setOfficeToPickUp] = useState('');

  //Used to determine which office to pick up at
  const [prefecture, setPrefecture] = useState('');
  const [municipality, setMunicipality] = useState('');
  const desiredPeriodOfStayOptions: SelectOption[] = (() => {
    switch (visaApplicationType) {
      case VisaApplicationType.ChangeToDependentVisaBySelf:
        return DESIRED_PERIOD_OF_STAY_CB20_OPTIONS_FOR_DEPENDENT;
      case VisaApplicationType.ChangeToGijinkokuVisaBySelf:
        //TODO: Prepare the option list later
        //return DESIRED_PERIOD_OF_STAY_CB20_OPTIONS_FOR_GIJINKOKU;
        return [];
      default:
        return [];
    }
  })();

  const { createGetEmptyFieldError } = useSectionErrorHandler();
  const getEmptyError = createGetEmptyFieldError({ additionalCondition: showEmptyRequiredValueError });

  const saveData = (data: Partial<ChangeBySelfData>) => {
    onChangeChangeBySelfData(data);
  }

  const isAddressInfoInsufficient = !prefecture || !municipality;

  useEffect(() => {
    if (!changeBySelfData)
      return;

    const data = changeBySelfData;

    setDesiredStatusOfResidence(data['WCICS020Dto:selNewZirySkk'] || '');

    if (isNoFieldError(reasonForChangeError))
      setReasonForChange(data['WCICS020Dto:txtKusnReason'] || '');

    setReceivingMethod(data['WCIBS010Dto:radZiryCardJryHuhu'] || '');
    setOfficeToPickUp(data['WCIBS010Dto:selJryuKnsh'] || '');

    if (data['WCIBS010Dto:hdnSearchedAddress']) {
      setPrefecture(data['WCIBS010Dto:hdnSearchedAddress'].slice(0, 2) ?? '');
      setMunicipality(data['WCIBS010Dto:hdnSearchedAddress'].slice(2) ?? '');
    }

    setDesiredPeriodOfStay(data['WCICS020Dto:selNewZiryPeriod'] ?? '');
  }, [changeBySelfData]);


  useEffect(() => {
    const receivingOffice =
      receivingMethod === HowToReceiveNewResidenceCard.VisitImmigrationOffice
        ? [officeToPickUp]
        : [];

    const areAllRequiredFieldsFilled =
      [
        desiredStatusOfResidence,
        desiredPeriodOfStay,
        reasonForChange,
        receivingMethod,
        ...receivingOffice
      ].every(val => !!val);

    const areThereNoErrors =
      [
        reasonForChangeError
      ].every(err => !err);

    onChangeSectionReadiness(
      areAllRequiredFieldsFilled && areThereNoErrors
    );
  }, [
    desiredStatusOfResidence,
    desiredPeriodOfStay,
    reasonForChange,
    reasonForChangeError,
    receivingMethod,
    officeToPickUp
  ])

  if (isActive === false)
    return null;

  return (
    <Section>
      {/* Desired period of stay */}
      <SelectField
        required
        disabled={readOnly}
        label={t("desiredPeriodOfStay")}
        placeholder={tWithoutPrefix("placeholder.select")}
        options={desiredPeriodOfStayOptions}
        value={desiredPeriodOfStay}
        error={getEmptyError(desiredPeriodOfStay)}
        onValueChange={val => {
          setDesiredPeriodOfStay(val);
          saveData({ 'WCICS020Dto:selNewZiryPeriod': val });
        }}
      />

      {/* Reason for change */}
      <MultiTextField
        large
        required
        disabled={readOnly}
        label={t("reasonOfChange")}
        placeholder={"この先も引き続き日本において夫と暮らしていくため"}
        value={reasonForChange}
        error={reasonForChangeError || getEmptyError(reasonForChange)}
        restriction={inputNotes.fullwidthJapanese}
        maxLength={600}
        validators={[validation.isFullwidth, validation.createLengthValidator(600)]}
        onValueChange={setReasonForChange}
        onErrorChange={setReasonForChangeError}
        onBlur={() => {
          saveData({ 'WCICS020Dto:txtKusnReason': reasonForChange });
        }}
      />

      {/* How will you receive your new residence card? */}
      <RadioField
        required
        disabled={readOnly}
        label={t("recievingNewResidenceCard")}
        options={[
          {
            label: t("byMailingService"),
            value: HowToReceiveNewResidenceCard.Mail,
          },
          {
            label: t("pickUpAtImmigrationOffice"),
            value: HowToReceiveNewResidenceCard.VisitImmigrationOffice,
          },
        ]}
        value={receivingMethod}
        error={getEmptyError(receivingMethod)}
        onValueChange={val => {
          setReceivingMethod(val);

          if (val === HowToReceiveNewResidenceCard.Mail) {
            saveData({
              'WCIBS010Dto:radZiryCardJryHuhu': val,
              'WCIBS010Dto:selJryuKnsh': ''
            });
          }

          if (val === HowToReceiveNewResidenceCard.VisitImmigrationOffice) {
            saveData({ 'WCIBS010Dto:radZiryCardJryHuhu': val });
          }
        }}
      />

      {/* Which office to pick up at */}
      {receivingMethod === HowToReceiveNewResidenceCard.VisitImmigrationOffice && (
        <SelectField
          disabled={isAddressInfoInsufficient || readOnly}
          label={t("pickUpOffice")}
          placeholder={placeholder.select}
          options={
            OFFICE_OPTIONS[`${prefecture}${municipality}`]
            ?? OFFICE_OPTIONS[prefecture]
            ?? []
          }
          value={officeToPickUp}
          error={
            (isAddressInfoInsufficient && t("pleaseEnterAddressFirst")) ||
            getEmptyError(officeToPickUp)
          }
          onValueChange={val => {
            setOfficeToPickUp(val);
            saveData({ 'WCIBS010Dto:selJryuKnsh': val });
          }}
        />
      )}
    </Section>
  );
};

export default DetailsOfChangeSection;
