import { FunctionComponent, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import { CONTENT_MAX_WIDTH } from "../../constants/styles";
import Text from "../../components/text/Text";
import { useDispatcher, useNextAction } from "../../hooks";
import Column from "../../components/layout/Column";
import { VISADAS_SUPPORT_EMAIL } from "../../constants/values/commonValues";
import EmailAnchor from "../../components/text/EmailAnchor";
import Button from "../../components/form/Button";
import Modal from "../../components/modal/Modal";
import PageHeading from "../../components/text/PageHeading";
import { ReactComponent as CheckedIcon } from "../../assets/icon-check-circle-green-filled.svg";
import { ReactComponent as CrossIcon } from "../../assets/icon-cross.svg";
import { fetchFirstMynaURLToSubmitVisaApplication, fetchSecondMynaURLToSubmitVisaApplication, processUserDataBeforeSubmission, submitVisaApplication } from "../../apis/visaApplication";
import Spinner from "../../components/display/Spinner";
import { VisaApplication } from "../../types/visa/applicationData";
import { VisaSubmissionModalNextAction as NextAction } from "../../types/nextActions";
import BottomAlignedContainer from "../../components/layout/BottomAlignedContainer";

interface SubmissionThruApiModalProps {
  visaApplication: VisaApplication;
  params?: Record<string, string>;
  onSubmissionComplete: () => void;
  onClose: () => void;
}

interface ModalContentProps {
  type: SubmissionModalContentType;
  onEvent: {
    click: () => void;
    close: () => void;
  };
}

enum SubmissionModalContentType {
  PreparingApplicationData,
  SubmitVisaApplication,
  VerifyMyNumberCard2ndTime,
  Processing,
  VisaApplicationSubmitted,
}

const ContentContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: #ffffff;
  overflow-y: auto;
`;

const Content = styled(Column)`
  min-height: 100%;
  height: auto;
  padding: 20px;
  max-width: ${CONTENT_MAX_WIDTH}px;
  margin: 0 auto;
`;

const ContentBodyContainer = styled(Column)`
  gap: 30px;
  padding-bottom: 70px;
`;

const NavContainer = styled.nav`
  width: 100%;
  margin-bottom: 20px;
`;

const DescriptionContainer = styled(Column)`
  gap: 20px;
`;

const MessageTextContainer = styled(Column)`
  gap: 10px;
`;

const StepTextContainer = styled.div`
  width: 100%;
  padding: 10px;
  background-color: #f2f2f2;
`;

const LocalOl = styled.ol`
  margin: 0;
  padding-left: 25px;
`;

const LocalLi = styled.li`
  &::marker {
    font-size: 0.9rem;
    color: #444444;
  }
`;

const SupplementaryTextContainer = styled.div``;

const ProcessingContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SpinnerTextContainer = styled(Column)`
  align-items: center;
  width: auto;
  gap: 20px;
`;

const SmallText = styled(Text)`
  white-space: "pre-wrap";
  display: inline;
  font-size: 0.813rem;
  color: #999999;
  line-height: 18px;
`;

const CloseIcon = styled(CrossIcon)`
  cursor: pointer;
  margin-left: auto;
`;

const ModalContent = ({ type, onEvent }: ModalContentProps) => {
  const { t } = useTranslation();
  const prepData = "modalAppSubmissionThruAPI.preparingAppData";
  const submitVisa = "modalAppSubmissionThruAPI.submitVisaApp";
  const verifyMyna = "modalAppSubmissionThruAPI.verifyMyna2ndTime";
  const processing = "modalAppSubmissionThruAPI.processing";
  const submitted = "modalAppSubmissionThruAPI.visaAppSubmitted";

  switch (type) {
    // "Preparing application data..." - 申請データを作成中
    case SubmissionModalContentType.PreparingApplicationData:
      return (
        <Content>
          <ProcessingContainer>
            <SpinnerTextContainer>
              <Spinner />
              <Column style={{ gap: 15, alignItems: 'center' }}>
                <Text>
                  {t(`${prepData}.description1`)}
                </Text>
                <Text color="#626161" style={{ fontSize: 13 }}>
                  {t(`${prepData}.description2`)}
                </Text>
              </Column>
            </SpinnerTextContainer>
          </ProcessingContainer>
        </Content>
      )


    // "Submit Visa Application" - ビザ申請を提出
    case SubmissionModalContentType.SubmitVisaApplication:
      return (
        <Content>
          <NavContainer style={{ textAlign: 'right'}}>
            <CloseIcon onClick={onEvent.close} />
          </NavContainer>

          <ContentBodyContainer>
            <PageHeading>{t(`${submitVisa}.title`)}</PageHeading>

            <DescriptionContainer>
              <Text>{t(`${submitVisa}.description`)}</Text>

              <StepTextContainer>
                <LocalOl>
                  <LocalLi>
                    <Text>{t(`${submitVisa}.step1`)}</Text>
                  </LocalLi>
                  <LocalLi>
                    <Text>{t(`${submitVisa}.step2`)}</Text>
                  </LocalLi>
                  <LocalLi>
                    <Text>{t(`${submitVisa}.step3`)}</Text>
                  </LocalLi>
                  <LocalLi>
                    <Text>{t(`${submitVisa}.step4`)}</Text>
                  </LocalLi>
                </LocalOl>
              </StepTextContainer>
            </DescriptionContainer>

            <SupplementaryTextContainer>
              <SmallText>
                <Trans
                  i18nKey={"common.ifYouAreHavingTrouble"}
                  components={{
                    email: VISADAS_SUPPORT_EMAIL,
                    anchor: <EmailAnchor mailTo={VISADAS_SUPPORT_EMAIL} />,
                  }}
                />
              </SmallText>
            </SupplementaryTextContainer>
          </ContentBodyContainer>

          <BottomAlignedContainer>
            <Button onClick={onEvent.click}>{t(`common.launch`)}</Button>
          </BottomAlignedContainer>
        </Content>
      );


    // "Verify your Number Card (2nd Time)" - マイナンバーカードを読み取る（2回目）
    case SubmissionModalContentType.VerifyMyNumberCard2ndTime:
      return (
        <Content>
          <NavContainer style={{ textAlign: 'right'}}>
            <CloseIcon onClick={onEvent.close} />
          </NavContainer>

          <ContentBodyContainer>
            <PageHeading>{t(`${verifyMyna}.title`)}</PageHeading>

            <DescriptionContainer>
              <MessageTextContainer>
                <Text>
                  {t(`${verifyMyna}.description1`)}
                </Text>
                <Text>
                  {t(`${verifyMyna}.description2`)}
                </Text>

              </MessageTextContainer>

              <StepTextContainer>
                <LocalOl>
                  <LocalLi>
                    <Text>{t(`${verifyMyna}.step1`)}</Text>
                  </LocalLi>
                  <LocalLi>
                    <Text>{t(`${verifyMyna}.step2`)}</Text>
                  </LocalLi>
                  <LocalLi>
                    <Text>{t(`${verifyMyna}.step3`)}</Text>
                  </LocalLi>
                  <LocalLi>
                    <Text>{t(`${verifyMyna}.step4`)}</Text>
                  </LocalLi>
                </LocalOl>
              </StepTextContainer>
            </DescriptionContainer>

            <SupplementaryTextContainer>
              <SmallText>
                <Trans
                  i18nKey={"common.ifYouAreHavingTrouble"}
                  components={{
                    email: VISADAS_SUPPORT_EMAIL,
                    anchor: <EmailAnchor mailTo={VISADAS_SUPPORT_EMAIL} />,
                  }}
                />
              </SmallText>
            </SupplementaryTextContainer>
          </ContentBodyContainer>

          <BottomAlignedContainer>
            <Button onClick={onEvent.click}>{t(`common.launch`)}</Button>
          </BottomAlignedContainer>
        </Content>
      );
    

    //"Processing" - 確認中
    case SubmissionModalContentType.Processing:
      return (
        <Content>
          <ProcessingContainer>
            <SpinnerTextContainer>
              <Spinner />
              <Text>{t(`${processing}`)}</Text>
            </SpinnerTextContainer>
          </ProcessingContainer>
        </Content>
      );


    // "Visa Application Submitted" - ビザの申請を提出しました
    case SubmissionModalContentType.VisaApplicationSubmitted:
      return (
        <Content>
          <NavContainer>
            <CheckedIcon/>
          </NavContainer>

          <ContentBodyContainer>
            <PageHeading>{t(`${submitted}.title`)}</PageHeading>

            <DescriptionContainer>
              <MessageTextContainer>
                <Text>
                  {t(`${submitted}.description1`)}
                </Text>
                <Text>
                  {t(`${submitted}.description2`)}
                </Text>
              </MessageTextContainer>
            </DescriptionContainer>

            <SupplementaryTextContainer>
              <SmallText>
                <Trans
                  i18nKey={"common.ifYouAreHavingTrouble"}
                  components={{
                    email: VISADAS_SUPPORT_EMAIL,
                    anchor: <EmailAnchor mailTo={VISADAS_SUPPORT_EMAIL} />,
                  }}
                />
              </SmallText>
            </SupplementaryTextContainer>
          </ContentBodyContainer>

          <BottomAlignedContainer>
            <Button onClick={onEvent.click}>{t(`common.close`)}</Button>
          </BottomAlignedContainer>
        </Content>
      );
  }
};


const SubmissionThruApiModal: FunctionComponent<SubmissionThruApiModalProps> = ({ 
    visaApplication,
    onClose, 
    onSubmissionComplete, 
}) => {
  const { state, dispatcher } = useDispatcher();
  const { isVisaSubmissionModalNextAction, consumeNextAction } = useNextAction();
  const { t } = useTranslation();
  const [contentType, setContentType] = useState<SubmissionModalContentType>(
    SubmissionModalContentType.PreparingApplicationData
  );
  const [visaSubmissionId, setVisaSubmissionId] = useState<string | number>("");
  const comebackDirectory = `/application/${visaApplication.id}/edit`;
  
  const onButtonClick = (() => {
    switch (contentType) {
      case SubmissionModalContentType.SubmitVisaApplication:
        return async () => {
            try {
              dispatcher.setNextAction(NextAction.ReadMynaCard2ndTime);
              const result = await fetchFirstMynaURLToSubmitVisaApplication(
                visaSubmissionId,
                comebackDirectory
              );
              window.location.href = result.myna_cert_id_url;
            } catch {
              dispatcher.clearNextAction();
              dispatcher.showWarningOnSnackbar(t("snackbar.failedToProceed"));
            }
        };

      case SubmissionModalContentType.VerifyMyNumberCard2ndTime:
        return async () => {
          dispatcher.startLoading();
          try {
            dispatcher.setNextAction(NextAction.SubmitApplicationData);
            const result = await fetchSecondMynaURLToSubmitVisaApplication(
              visaSubmissionId,
              comebackDirectory
            );
            window.location.href = result.myna_cert_signature_url;
          } catch (e) {
            dispatcher.clearNextAction();
            dispatcher.showWarningOnSnackbar(t("snackbar.failedToProceed"));
            setContentType(SubmissionModalContentType.SubmitVisaApplication);
          } finally {
            dispatcher.stopLoading();
          }
        };

      case SubmissionModalContentType.VisaApplicationSubmitted:
        return () => {
           onSubmissionComplete();
        };

      default:
        return () => {};
    }
  })();


  useEffect(() => {
    if (isVisaSubmissionModalNextAction()) {
      const nextAction = consumeNextAction(); 

      switch (nextAction.identifier) {
        case NextAction.ReadMynaCard2ndTime:
          setContentType(SubmissionModalContentType.VerifyMyNumberCard2ndTime);
          break;
        
        case NextAction.SubmitApplicationData:
          setContentType(SubmissionModalContentType.Processing);

          (async () => {
            try {
              //TODO: Better to check if the user has paid just in case
              const visaSubmissionId = visaApplication.last_unsubmitted_visa_submission!.id;
              await submitVisaApplication(visaSubmissionId);
              setContentType(SubmissionModalContentType.VisaApplicationSubmitted);
            } catch (e) {
              dispatcher.showWarningOnSnackbar(t("snackbar.failedToProceed"));
              setContentType(SubmissionModalContentType.SubmitVisaApplication);
            }
          })();
          break;
      }
      setVisaSubmissionId(visaApplication.last_unsubmitted_visa_submission!.id);
      return;
    } else {
      dispatcher.clearNextAction();
    }

    
    //This runs When this modal is first opened
    //We send a data processing request to the backend and it processes all user data
    (async () => {
      try {
        const visaSubmission = await processUserDataBeforeSubmission(visaApplication.id); 
        setVisaSubmissionId(visaSubmission.id);
        setContentType(SubmissionModalContentType.SubmitVisaApplication);
      } catch {
        dispatcher.showWarningOnSnackbar(t("snackbar.failedToProceed"));
        onClose();
      }
    })();
  }, []);


  return (
    <Modal>
      <ContentContainer>
        <ModalContent
          type={contentType}
          onEvent={{
            click: onButtonClick,
            close: onClose,
          }}
        />
      </ContentContainer>
    </Modal>
  );
};

export default SubmissionThruApiModal;
