import { Navigate, Route, Routes } from "react-router-dom";
import { useDispatcher } from "./hooks";
import ApplicationFormPage from "./pages/ApplicationFormPage";
import ProfilePage from "./pages/ProfilePage";
import Onboarding from "./pages/OnboardingPage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import { useEffect, useState } from "react";
import { getCurrentUser } from "./apis/user";
import ApplicationReviewPage from "./pages/ApplicationReviewPage/ApplicationReviewPage";
import { extractUserRegistrationAppStatus, isUserRegistrationApplicationDenied } from "./utils/userRegistrationHelper";
import { getLatestUserRegistrationApplication } from "./apis/userRegistration";
import SetupPage from "./pages/SetupPage";
import InvalidLinkPage from "./pages/InvalidLinkPage";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "./constants/values/query";
import PasswordResetPage from "./pages/PasswordResetPage";
import { UserRegistrationApplicationStatus } from "./types/userRegistration/applicationStatus";
import { isRequiredToCompleteOnboarding, isUserSetupCompleted } from "./utils/userHelper";

const SiteRoutes = () => {
  const { state, dispatcher } = useDispatcher();
  const { isLoggedIn, isSetupComplete, isOnboradingRequired } = state;
  const [userRegistrationAppStatus, setUserRegistrationAppStatus] = useState<
    UserRegistrationApplicationStatus | null>(null);
  const isUserRegAppDenied = isUserRegistrationApplicationDenied(userRegistrationAppStatus);

  const { isPending: isUserPending, data: userData, error: userError } = useQuery({
    queryKey: [QUERY_KEYS.USER],
    queryFn: getCurrentUser,
  });

  const { isPending: isUserRegAppPending, data: userRegAppData, error: userRegAppError } = useQuery({
    queryKey: [QUERY_KEYS.USER_REGISTRATION_APPLICATION],
    queryFn: getLatestUserRegistrationApplication,
    enabled: !!userData
  });

  useEffect(() => {
    if (isUserPending) 
      return;

    if (!userData || userError || userRegAppError) {
      dispatcher.treatAsLoggedOut();
      return;
    }

    if (isUserRegAppPending)
      return;
    
    const user = userData;
    const userRegStatus = extractUserRegistrationAppStatus(userRegAppData);
    setUserRegistrationAppStatus(userRegStatus);
    dispatcher.treatAsLoggedIn();
    dispatcher.setSetupComplete(isUserSetupCompleted(user));
    dispatcher.setIsOnboardingRequired(
      isRequiredToCompleteOnboarding(user, userRegAppData)
    );
  }, [
    isUserPending, 
    isUserRegAppPending, 
    userData, 
    userRegAppData, 
    userError, 
    userRegAppError
  ]);


  if (!isLoggedIn)
    return (
      <Routes>
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/reset-password" element={<PasswordResetPage />} />
        <Route path="/shared/invalid" element={<InvalidLinkPage />} />
        <Route path="*" element={<Navigate replace to="/login" />} />
      </Routes>
    );

  if (!isSetupComplete)
    return (
      <Routes>
        <Route path="/setup" element={<SetupPage />} />
        <Route path="*" element={<Navigate replace to="/setup" />} />
      </Routes>
    );

  if (isOnboradingRequired)
    return (
      <Routes>
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="*" element={<Navigate replace to="/onboarding"/>} />
      </Routes>
    );

  return (
    <Routes>
      {isUserRegAppDenied && 
        <Route path="/onboarding" element={<Onboarding />} />
      }
      <Route path="/application/:id/review" element={<ApplicationReviewPage />} />
      <Route path="/application/:id/edit" element={<ApplicationFormPage />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/" element={<HomePage />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  )
};

export default SiteRoutes;
