import { ChangeBySelfData } from "../types/visa/changeBySelf/data";
import { axiosInstance } from "./common";

//*As of July 21, 2023, these APIs are not tested at all 
//because we don't need them for the first MVP, and don't have a place to save at frontend
//We should revist this later

export const createChangeBySelfData = async (visaApplicationId: number | string, data: Partial<ChangeBySelfData>) => {
  const response = await axiosInstance.request({
      method: 'POST',
      url: `henkou_honnins`,
      data: {
        ...data,
        visa_application_id: visaApplicationId
      }
  });

  return response.data as ChangeBySelfData;
};

export const getChangeBySelfData = async (id: number | string) => {
  const response = await axiosInstance.request({
      method: 'GET',
      url: `henkou_honnins/${id}`
  });

  return response.data as ChangeBySelfData;
};


export const updateChangeBySelfData = async (id: number | string, data: Partial<ChangeBySelfData>) => {
  const response = await axiosInstance.request({
      method: 'PUT',
      url: `henkou_honnins/${id}`,
      data
  });

  return response.data as ChangeBySelfData;
};

export const updateChangeBySelfDataAsInvitee = async (id: number | string, data: Partial<ChangeBySelfData>, invitationToken: string) => {
  const response = await axiosInstance.request({
      method: 'PUT',
      url: `henkou_honnins/${id}/invitee_update`,
      data: {
        ...data,
        invitation_token: invitationToken
      }
  });

  return response.data as ChangeBySelfData;
};

export const deleteChangeBySelfData = async (id: number | string) => {
  const response = await axiosInstance.request({
      method: 'DELETE',
      url: `henkou_honnins/${id}`
  });

  return response.data as ChangeBySelfData;
};


