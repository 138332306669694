import { FocusEvent, FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';

import ErrorMessage from '../display/ErrorMessage';
import Restriction from '../display/Restriction';
import InlineMessage from '../form/InlineMessage';
import Label from '../form/Label';
import TextInput from '../form/TextInput';
import { ValidationFunction } from '../../types/validation';
import { InputHTMLAttributes } from 'react';

interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
    disabled?: boolean;
    required?: boolean;
    optional?: boolean;
    label?: string;
    boldLabel?: boolean;
    placeholder?: string;
    value?: string;
    error?: string;
    restriction?: string;
    maxLength?: number;
    note?: string;
    validators?: ValidationFunction[];
    suffixComponent?: React.ReactNode;
    onValueChange?: (value: string) => void;
    onErrorChange?: (error: string) => void;
} 

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const InputFieldWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto auto;
    gap: 5px;
    align-items: center;
`;

const ErrorMessageWrapper = styled.div`
    grid-column: 1 / 2; 
    word-break: break-all;
`;

const RestrictionWrapper = styled.div`
    grid-column: 1 / 2;
`;

const TextField: FunctionComponent<TextFieldProps> = ({
    disabled,
    required,
    optional,
    label,
    boldLabel,
    placeholder,
    value = '',
    error,
    restriction,
    maxLength = 0,
    note,
    validators,
    suffixComponent,
    onValueChange,
    onErrorChange,
    ...props
}) => {
    const isOverMaxLength = maxLength > 0 && value.length > maxLength;
    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
        const value = event.target.value;

        if (value === '') {
            onErrorChange?.('');
            props.onBlur?.(event);
            return
        } 
        
        if (validators) {
            for (const validator of validators) {
                const result = validator(value);
                if (!result.success) {
                    onErrorChange?.(result.message);
                    return;
                }
            }
        }

        props.onBlur?.(event);
    };

    const handleValueChange = (value: string) => {
        onValueChange?.(value);
        onErrorChange?.('');
    };

    
    useEffect(() => {
        if (!isOverMaxLength && 
            (!validators || validators?.every(validator => validator(value).success))) 
            onErrorChange?.('');
    }, [value])

    return (
        <Container>
            {label && (
                <Label required={required} optional={optional}>
                    {boldLabel ? <b>{label}</b> : label}
                </Label>
            )}
            
            <InputFieldWrapper>
                <TextInput
                    {...props}
                    disabled={disabled}
                    invalid={!!error || isOverMaxLength}
                    placeholder={placeholder}
                    value={value}
                    onBlur={handleBlur}
                    onValueChange={handleValueChange}
                />
                {suffixComponent}
                <ErrorMessageWrapper>
                    {error &&
                        <ErrorMessage>
                            {error}
                        </ErrorMessage>
            }
                </ErrorMessageWrapper>
                <RestrictionWrapper>
                    {(restriction || maxLength > 0) && (
                        <Restriction
                            content={restriction}
                            currentLength={value?.length}
                            maxLength={maxLength}
                        />
                    )}
                </RestrictionWrapper>
            </InputFieldWrapper>

            {note && (
                <InlineMessage variant='info' size='small'>
                    {note}
                </InlineMessage>
            )}
        </Container>
    );
};

export default TextField;