import { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Section from "../../../../../components/layout/Section";
import VisaDocUploadField from "../../../../../components/compound/VisaDocUploadField";
import { AttachmentBodyProps } from "../../../../../types/visa/attachmentProp";
import { VisaAttachmentKind } from "../../../../../types/visa/attachment";
import InlineMessage from "../../../../../components/form/InlineMessage";
import { FormR } from "../../../../../types/visa/uncommonFormParts/formR/data";
import {
  Column5,
  NoteOrderedList,
  NoteUnorderedList,
} from "../../../commonStyles";

interface DependentVisaAttachmentsProps extends AttachmentBodyProps {
  formR: FormR | null;
  onChangeFormR: (formR: FormR) => void;
}

const DependentVisaAttachments: FunctionComponent<
  DependentVisaAttachmentsProps
> = ({
  isActive,
  visaAttachments,
  onUploadVisaAttachment,
  onDeleteVisaAttachment,
  onChangeSectionReadiness,
  showUnattachedAlert,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "attachmentsSection",
  });

  useEffect(() => {
    const requiredKinds = [
      VisaAttachmentKind.Passport,
      VisaAttachmentKind.ResidenceCardFront,
      VisaAttachmentKind.ResidenceCardBack,
      VisaAttachmentKind.Photo,
      VisaAttachmentKind.SupporterResidenceCardFront,
      VisaAttachmentKind.SupporterResidenceCardBack,
      VisaAttachmentKind.SupporterPassport,
      VisaAttachmentKind.DocsProvingRelationshipBtwApplicantAndSupporter,
      VisaAttachmentKind.DocsCertifyingOccupationAndIncomeOfDependent,
    ];

    const givenKinds = new Set(visaAttachments.map((va) => va.kind));
    onChangeSectionReadiness(requiredKinds.every((rk) => givenKinds.has(rk)));
  }, [visaAttachments]);

  if (isActive === false) 
    return null;

  return (
    <Section>
      <InlineMessage variant="caution">{t("under5MB")}</InlineMessage>

      {/* Passport / パスポート */}
      <VisaDocUploadField
        hideButtonIfFileExists
        required
        label={t("passport.label")}
        note={t("passport.note")}
        visaAttachments={visaAttachments}
        visaAttachmentKind={VisaAttachmentKind.Passport}
        onAttachmentUpload={onUploadVisaAttachment}
        onAttachmentDelete={onDeleteVisaAttachment}
        enableRequiredAlert={showUnattachedAlert}
      />

      {/* Residence Card / 在留カード（表面） */}
      <VisaDocUploadField
        hideButtonIfFileExists
        required
        label={t("residenceCardFront.label")}
        visaAttachments={visaAttachments}
        visaAttachmentKind={VisaAttachmentKind.ResidenceCardFront}
        onAttachmentUpload={onUploadVisaAttachment}
        onAttachmentDelete={onDeleteVisaAttachment}
        enableRequiredAlert={showUnattachedAlert}
      />

      {/* Residence Card / 在留カード（裏面） */}
      <VisaDocUploadField
        hideButtonIfFileExists
        required
        label={t("residenceCardBack.label")}
        visaAttachments={visaAttachments}
        visaAttachmentKind={VisaAttachmentKind.ResidenceCardBack}
        onAttachmentUpload={onUploadVisaAttachment}
        onAttachmentDelete={onDeleteVisaAttachment}
        enableRequiredAlert={showUnattachedAlert}
      />

      {/* Photo / 顔写真 */}
      <VisaDocUploadField
        hideButtonIfFileExists
        required
        label={t("photo.label")}
        note={
          <Column5>
            <div>{t("photo.notes.description")}</div>
            <NoteOrderedList>
              <li>{t("photo.notes.req1")}</li>
              <li>{t("photo.notes.req2")}</li>
              <li>{t("photo.notes.req3")}</li>
              <li>{t("photo.notes.req4")}</li>
              <li>{t("photo.notes.req5")}</li>
              <li>{t("photo.notes.req6")}</li>
            </NoteOrderedList>
          </Column5>
        }
        visaAttachments={visaAttachments}
        visaAttachmentKind={VisaAttachmentKind.Photo}
        onAttachmentUpload={onUploadVisaAttachment}
        onAttachmentDelete={onDeleteVisaAttachment}
        enableRequiredAlert={showUnattachedAlert}
      />

      {/* Supporter's residence card (front) */}
      <VisaDocUploadField
        hideButtonIfFileExists
        required
        label={t("supporterResidenceCardFront.label")}
        visaAttachments={visaAttachments}
        visaAttachmentKind={VisaAttachmentKind.SupporterResidenceCardFront}
        onAttachmentUpload={onUploadVisaAttachment}
        onAttachmentDelete={onDeleteVisaAttachment}
        enableRequiredAlert={showUnattachedAlert}
      />

      {/* Supporter's residence card (back) */}
      <VisaDocUploadField
        hideButtonIfFileExists
        required
        label={t("supporterResidenceCardBack.label")}
        visaAttachments={visaAttachments}
        visaAttachmentKind={VisaAttachmentKind.SupporterResidenceCardBack}
        onAttachmentUpload={onUploadVisaAttachment}
        onAttachmentDelete={onDeleteVisaAttachment}
        enableRequiredAlert={showUnattachedAlert}
      />

      {/* Supporter's passport */}
      <VisaDocUploadField
        hideButtonIfFileExists
        required
        label={t("supporterPassport.label")}
        visaAttachments={visaAttachments}
        visaAttachmentKind={VisaAttachmentKind.SupporterPassport}
        onAttachmentUpload={onUploadVisaAttachment}
        onAttachmentDelete={onDeleteVisaAttachment}
        enableRequiredAlert={showUnattachedAlert}
        note={t("supporterPassport.note")}
      />

      {/* Document that proves your relationship with your supporter / 扶養者との関係を証明するもの */}
      <VisaDocUploadField
        required
        label={t("docThatProvesRelationshipWithSupporter.label")}
        note={
          <Column5>
            <div>
              {t("docThatProvesRelationshipWithSupporter.notes.description1")}
            </div>
            <NoteUnorderedList>
              <li>
                {t(
                  "docThatProvesRelationshipWithSupporter.notes.case1.description"
                )}
                <NoteUnorderedList>
                  <li>
                    {t(
                      "docThatProvesRelationshipWithSupporter.notes.case1.item1"
                    )}
                  </li>
                  <li>
                    {t(
                      "docThatProvesRelationshipWithSupporter.notes.case1.item2"
                    )}
                  </li>
                  <li>
                    {t(
                      "docThatProvesRelationshipWithSupporter.notes.case1.item3"
                    )}
                  </li>
                </NoteUnorderedList>
              </li>
              <li>
                {t(
                  "docThatProvesRelationshipWithSupporter.notes.case2.description"
                )}
                <NoteUnorderedList>
                  <li>
                    {t(
                      "docThatProvesRelationshipWithSupporter.notes.case2.item1"
                    )}
                  </li>
                  <li>
                    {t(
                      "docThatProvesRelationshipWithSupporter.notes.case2.item2"
                    )}
                  </li>
                </NoteUnorderedList>
              </li>
              <li>
                {t(
                  "docThatProvesRelationshipWithSupporter.notes.case3.description"
                )}
                <NoteUnorderedList>
                  <li>
                    {t(
                      "docThatProvesRelationshipWithSupporter.notes.case3.item1"
                    )}
                  </li>
                </NoteUnorderedList>
              </li>
            </NoteUnorderedList>
            <div>
              {t("docThatProvesRelationshipWithSupporter.notes.description2")}
            </div>
          </Column5>
        }
        visaAttachments={visaAttachments}
        visaAttachmentKind={
          VisaAttachmentKind.DocsProvingRelationshipBtwApplicantAndSupporter
        }
        onAttachmentUpload={onUploadVisaAttachment}
        onAttachmentDelete={onDeleteVisaAttachment}
        enableRequiredAlert={showUnattachedAlert}
      />

      {/* Document that proves your supporter's job and income / 扶養者の職業・収入を証明するもの */}
      <VisaDocUploadField
        required
        label={t("docThatProvesSupportersJobAndIncome.label")}
        note={
          <Column5>
            <div>
              {t("docThatProvesSupportersJobAndIncome.notes.description1")}
            </div>
            <NoteOrderedList>
              <li>
                {t("docThatProvesSupportersJobAndIncome.notes.item1")}
                <NoteUnorderedList>
                  <li>
                    {t("docThatProvesSupportersJobAndIncome.notes.item1a")}
                  </li>
                </NoteUnorderedList>
              </li>
              <li>{t("docThatProvesSupportersJobAndIncome.notes.item2")}</li>
              <li>
                {t("docThatProvesSupportersJobAndIncome.notes.item3")}
                <NoteUnorderedList>
                  <li>
                    {t("docThatProvesSupportersJobAndIncome.notes.item3a")}
                  </li>
                  <li>
                    {t("docThatProvesSupportersJobAndIncome.notes.item3b")}
                  </li>
                  <li>
                    {t("docThatProvesSupportersJobAndIncome.notes.item3c")}
                  </li>
                </NoteUnorderedList>
              </li>
            </NoteOrderedList>
            <div>
              {t("docThatProvesSupportersJobAndIncome.notes.description2")}
            </div>
            <NoteUnorderedList>
              <li>{t("docThatProvesSupportersJobAndIncome.notes.item4")}</li>
            </NoteUnorderedList>
          </Column5>
        }
        visaAttachments={visaAttachments}
        visaAttachmentKind={
          VisaAttachmentKind.DocsCertifyingOccupationAndIncomeOfDependent
        }
        onAttachmentUpload={onUploadVisaAttachment}
        onAttachmentDelete={onDeleteVisaAttachment}
        enableRequiredAlert={showUnattachedAlert}
      />

      {/* Other / その他 */}
      <VisaDocUploadField
        label={t("other.label")}
        note={t("other.note")}
        visaAttachments={visaAttachments}
        visaAttachmentKind={VisaAttachmentKind.Other}
        onAttachmentUpload={onUploadVisaAttachment}
        onAttachmentDelete={onDeleteVisaAttachment}
      />
    </Section>
  );
};

export default DependentVisaAttachments;
