import { FunctionComponent } from "react";
import {
  getLabelFromIndustryOfEmployer,
  getLabelFromMunicipalityInJapanese,
  getLabelFromPrefectureInJapanese,
} from "../../../../../../../utils/visaFormDataHelpers/commonVisaFormDataHelper";
import { useTranslation } from "react-i18next";
import {
  Group,
  SectionHeading,
  SectionSubHeading,
} from "../../../previewBodyCommonStyle";
import { FormN } from "../../../../../../../types/visa/uncommonFormParts/formN/data";
import PreviewList from "../../../../PreviewList";

interface GijinkokuVisaOrganizationSectionProps {
  data: FormN;
}

export const GijinkokuVisaOrganizationSection: FunctionComponent<
  GijinkokuVisaOrganizationSectionProps
> = ({ data }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'organizationSection' });
  const { t : tWithoutPrefix } = useTranslation();

  return (
    <section>
      <SectionHeading>{t("title")}</SectionHeading>
      <PreviewList
        items={[
          {
            label: t("nameOfOrganization"),
            value: data["contracting_organization_name"]
          },
          {
            label: t("nameOfBranchOffice"),
            value: data["contracting_organization_name_of_branch"]
          },
        ]}
      />

      <Group style={{ paddingTop: 10, paddingBottom: 10 }}>
        <SectionSubHeading>{t("location")}</SectionSubHeading>
        <PreviewList
          items={[
            {
              label: t("prefecture"),
              value: getLabelFromPrefectureInJapanese(data["contracting_organization_address_prefecture"])
                ? tWithoutPrefix(`${getLabelFromPrefectureInJapanese(data["contracting_organization_address_prefecture"])}`)
                : ""
            },
            {
              label: t("municipality"),
              value: data["contracting_organization_address_prefecture"]
                ? tWithoutPrefix(`${
                  getLabelFromMunicipalityInJapanese(
                    data["contracting_organization_address_prefecture"],
                    data["contracting_organization_address_municipality"]
                  )}`)
                : ""
            },
            {
              label: t("townStreetApartment"),
              value: data["contracting_organization_address_street"],
            },
          ]}
        />
      </Group>
      
      <PreviewList
        items={[
          {
            label: t("phoneNumber"),
            value: data["contracting_organization_telephone_no"],
          },
          {
            label: t("corporationNumber"),
            value: data["contracting_organization_corporation_no"],
          },
          {
            label: t("employmentInsuranceAppOfficeNum"),
            value: data["contracting_organization_office_number"],
          },
          {
            label: t("mainIndustry"),
            value: 
              tWithoutPrefix(`${
                getLabelFromIndustryOfEmployer(
                  data["contracting_organization_main_business_type"]
                )
              }`)
          },
          {
            label: t("otherIndustry1"),
            value: 
              tWithoutPrefix(`${
                getLabelFromIndustryOfEmployer(
                  data["contracting_organization_other_business_type_1"]
                )
              }`)
          },
          {
            label: t("otherIndustry2"),
            value: 
              tWithoutPrefix(`${
                getLabelFromIndustryOfEmployer(
                  data["contracting_organization_other_business_type_2"]
                )
              }`)
          },
          {
            label: t("capitalYen"),
            value: data["contracting_organization_capital"]
              ? Number(data["contracting_organization_capital"]).toLocaleString()
              : ""
          },
          {
            label: t("annualReveueLastYearYen"),
            value: data["contracting_organization_annual_sales"]
              ? Number(data["contracting_organization_annual_sales"]).toLocaleString()
              : ""
          },
          {
            label: t("numberOfEmployees"),
            value: data["contracting_organization_number_of_employees"],
          },
          {
            label: t("numberOfForeignEmployees"),
            value: data["contracting_organization_number_of_foreign_employees"],
          },
          {
            label: t("numberOfTechnicalInternTrainees"),
            value: data["contracting_organization_number_of_technical_intern_trainees"],
          } 
        ]}
      />
    </section>
  );
};

export default GijinkokuVisaOrganizationSection;
