import { FunctionComponent, useState } from "react";
import styled from "styled-components";
import { CONTENT_MAX_WIDTH } from "../../constants/styles";
import { Trans, useTranslation } from "react-i18next";
import Text from "../../components/text/Text";
import Modal from "../../components/modal/Modal";
import BackButton from "../../components/navigation/BackButton";
import SignatureCanvas from "../../components/misc/SignatureCanvas";
import Button from "../../components/form/Button";
import Column from "../../components/layout/Column";
import { ReactComponent as CheckedIcon } from "../../assets/icon-check-circle-green-filled.svg";
import { VISADAS_SUPPORT_EMAIL } from "../../constants/values/commonValues";
import EmailAnchor from "../../components/text/EmailAnchor";
import { useNavigate } from "react-router-dom";
import { useDispatcher } from "../../hooks";
import { createVisaSignature, updateVisaSignature } from "../../apis/signature";
import { VisaApplication, VisaSignatureKind } from "../../types/visa/applicationData";
import { tryToFindVisaSignature } from "../../utils/visaApplicationHelper";
import { sendVisaApplicationToAdministrativeScrivener } from "../../apis/visaApplication";
import BottomAlignedContainer from "../../components/layout/BottomAlignedContainer";

interface SubmissionThruScrivenerModalProps {
  visaApplication: VisaApplication;
  onClickBack: () => void;
  onSubmissionComplete?: () => void;
}

enum ContentType {
  SignByHand,
  VisaApplicationSubmitted,
}

const ContentContainer = styled.div`
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const Content = styled(Column)`
  padding: 20px;
  gap: 20px;
  max-width: ${CONTENT_MAX_WIDTH}px;
  height: auto;
  min-height: 100%;
  margin: 0 auto;
`;

const SignByHandBodyContainer = styled(Column)`
  gap: 16px;
`;

const SubmittedBodyContainer = styled(Column)`
  gap: 30px;
  padding-bottom: 70px;
`;

const VisaDescriptionContainer = styled(Column)`
  gap: 10px;
`;

const HeadingText = styled(Text)`
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 29px;
`;

const DescriptionText = styled(Text)`
  color: #444;
`;

const ButtonContainer = styled(BottomAlignedContainer)`
  margin-top: 58px;
`;

const SmallText = styled(Text)`
  display: inline;
  font-size: 0.813rem;
  color: #999999;
  line-height: 18px;
`;

const SubmissionThruScrivenerModal: FunctionComponent<
  SubmissionThruScrivenerModalProps
> = ({ 
  visaApplication, 
  onClickBack,
  onSubmissionComplete, 
}) => {
  const { dispatcher } = useDispatcher();
  const { t: tScr } = useTranslation("translation", {
    keyPrefix: "modalSubmissionThruScrivener",
  });
  const { t: tCommon } = useTranslation("translation", { keyPrefix: "common" });
  const { t: tSnack } = useTranslation("translation", {
    keyPrefix: "snackbar",
  });
  const nagivate = useNavigate();
  const [signatureFile, setSignatureFile] = useState<File | null>(null);
  const [contentType, setContentType] = useState<ContentType>(
    ContentType.SignByHand
  );

  const onSignAndSubmit = async () => {
    dispatcher.startLoading();
    const kind = VisaSignatureKind.ApplicantOrRepresentative;
    const existingVisaSignature = tryToFindVisaSignature(visaApplication, kind);

    try {
      if (existingVisaSignature) 
        await updateVisaSignature(existingVisaSignature.id, { file: signatureFile! });
      else
        await createVisaSignature(visaApplication.id, signatureFile!, kind);

      await sendVisaApplicationToAdministrativeScrivener(visaApplication.id);

      setContentType(ContentType.VisaApplicationSubmitted);
      onSubmissionComplete?.();
    } catch (e) {
      dispatcher.showWarningOnSnackbar(tSnack("failedToProceed"));
    }
    dispatcher.stopLoading();
  };

  return (
    <Modal>
      <ContentContainer>
        <Content>
          {contentType === ContentType.SignByHand && (
            <>
              <BackButton onClick={onClickBack} />

              <SignByHandBodyContainer>
                <HeadingText>{tScr("signByHand")}</HeadingText>
                <DescriptionText>{tScr("pleaseSign")}</DescriptionText>
                <SignatureCanvas onUpdateSignature={setSignatureFile} />
              </SignByHandBodyContainer>

              <ButtonContainer>
                <Button disabled={!signatureFile} onClick={onSignAndSubmit}>
                  {tScr("completeYourSignature")}
                </Button>
              </ButtonContainer>
            </>
          )}

          {contentType === ContentType.VisaApplicationSubmitted && (
            <>
              <CheckedIcon />

              <SubmittedBodyContainer>
                <HeadingText>{tScr("visaAppSubmitted")}</HeadingText>

                <VisaDescriptionContainer>
                  <Text>{tScr("checkStatusOfYourApp")}</Text>
                  <DescriptionText>{tScr("itMayTakeLonger")}</DescriptionText>
                </VisaDescriptionContainer>

                <div>
                  <SmallText>
                    <Trans
                      i18nKey={"common.ifYouAreHavingTrouble"}
                      components={{
                        email: VISADAS_SUPPORT_EMAIL,
                        anchor: <EmailAnchor mailTo={VISADAS_SUPPORT_EMAIL} />,
                      }}
                    />
                  </SmallText>
                </div>
              </SubmittedBodyContainer>

              <ButtonContainer>
                <Button onClick={() => nagivate("/")}>{tCommon("home")}</Button>
              </ButtonContainer>
            </>
          )}
        </Content>
      </ContentContainer>
    </Modal>
  );
};

export default SubmissionThruScrivenerModal;
