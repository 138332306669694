import { ChangeEvent, FunctionComponent, InputHTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

interface RadioItemProps extends InputHTMLAttributes<HTMLInputElement> {
    invalid?: boolean;
    onValueChange?: (value: any) => void;
    children?: ReactNode;
}

const Container = styled.label<RadioItemProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
  min-height: 40px;
  padding: ${(props: RadioItemProps) => props.checked ? '9px 9px' : '10px 10px'};
  border: ${(props: RadioItemProps) => props.disabled
          ? '1px solid #8A8E94'
          : (props.invalid
                  ? '1px solid #E93232'
                  : (props.checked ? '2px solid #1F82FF' : '1px solid #8A8E94')
          )
  };
  border-radius: 5px;
  box-sizing: border-box;
  color: ${(props: RadioItemProps) => props.disabled
          ? '#999999'
          : (props.invalid
                  ? '#444444'
                  : (props.checked ? '#0069EC' : '#444444')
          )
  };
  background: ${(props: RadioItemProps) => props.disabled
          ? '#D5D5D5'
          : (props.invalid
                  ? '#FFF0F0'
                  : (props.checked ? '#EBF4FF' : '#F7F7F7')
          )
  };
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 20px;
  cursor: pointer;
  pointer-events: ${(props: RadioItemProps) => props.disabled ? 'none' : 'auto'};
`;

const Input = styled.input`
  display: none;
`;

const Button = styled.span<RadioItemProps>`
  position: relative;
  max-width: 18px;
  min-width: 18px;
  max-height: 18px;
  min-height: 18px;
  background: ${(props: RadioItemProps) => props.disabled
          ? '#D5D5D5'
          : '#FFFFFF'
  };
  border: 1px solid ${(props: RadioItemProps) => props.disabled
          ? '#8A8E94'
          : (props.invalid
                  ? '#E93232'
                  : (props.checked ? '#1F82FF' : '#8A8E94')
          )
  };
  border-radius: 50%;
  box-sizing: border-box;
  
  &:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 8px;
    height: 8px;
    background: ${(props: RadioItemProps) => props.disabled
            ? (props.checked ? '#8A8E94' : '#D5D5D5')
            : (props.checked
                    ? (props.invalid ? '#E93232' : '#1F82FF')
                    : '#FFFFFF'
            )
    };
    border-radius: 50%;
  }
`;

const RadioItem: FunctionComponent<RadioItemProps> = ({
    disabled,
    invalid,
    checked,
    onValueChange,
    children,
    ...props
}) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onValueChange && onValueChange(event.target.value);
    }

    return (
        <Container
            disabled={disabled}
            invalid={invalid}
            checked={checked}
        >
            <Input
                type="radio"
                disabled={disabled}
                checked={checked}
                onChange={handleChange}
                {...props}
            />
            <Button
                disabled={disabled}
                invalid={invalid}
                checked={checked}
            />
            {children}
        </Container>
    )
}

export default RadioItem;