import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Section from '../../../components/layout/Section';
import MultiTextField from '../../../components/compound/MultiTextField';
import { useFieldInputNotes, useValidation } from '../../../hooks';
import { FormR } from '../../../types/visa/uncommonFormParts/formR/data';
import FieldGroup from '../../../components/layout/FieldGroup';
import DateSelectField from '../../../components/compound/DateSelectField';
import { useSectionErrorHandler } from '../hook';
import { ApplicationFormSectionProps } from '../type';
import Label from '../../../components/form/Label';
import { formatDateStrWithJaUnits, isNoFieldError, parseDateWithJaUnits } from '../../../utils/visaFormDataHelpers/commonVisaFormDataHelper';
import { getDateWithDayOffset, getFirstDayOfYearOffset } from '../../../utils/utils';

interface PlaceAndDateOfNotificationProps extends ApplicationFormSectionProps {
  formR: FormR | null;
  onChangeFormR: (data: Partial<FormR>) => void;
}

const PlaceAndDateOfNotificationSection: FunctionComponent<PlaceAndDateOfNotificationProps> = ({
  isActive,
  readOnly,
  formR,
  onChangeFormR,
  onChangeSectionReadiness,
  showEmptyRequiredValueError
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'placeAndDateOfNotificationSection' });
  const { inputNotes } = useFieldInputNotes();
  const validation = useValidation();
  const [japaneseAuthorities, setJapaneseAuthorities] = useState('');
  const [jaAuthDateOfRegistration, setJaAuthDateOfRegistration] = useState('');
  const [foreignAuthorities, setForeignAuthorities] = useState('');
  const [foAuthDateOfRegistration, setFoAuthDateOfRegistration] = useState('');
  const [japaneseAuthoritiesError, setJapaneseAuthoritiesError] = useState('');
  const [foreignAuthoritiesError, setForeignAuthoritiesError] = useState('');
  const { createGetEmptyFieldError } = useSectionErrorHandler();
  const getEmptyError = createGetEmptyFieldError({ additionalCondition: showEmptyRequiredValueError });
  const saveData = (data: Partial<FormR>) => { onChangeFormR(data); };

  useEffect(() => {
    if (!formR)
      return;

    if (isNoFieldError(japaneseAuthoritiesError))
      setJapaneseAuthorities(formR.place_and_date_japanese_authorities ?? '');

    setJaAuthDateOfRegistration(
      parseDateWithJaUnits(formR?.place_and_date_japanese_authorities_date_of_registration).yearMonthDay ?? ''
    );

    if (isNoFieldError(foreignAuthoritiesError))
      setForeignAuthorities(formR.place_and_date_foreign_authorities ?? '');

    setFoAuthDateOfRegistration(
      parseDateWithJaUnits(formR?.place_and_date_foreign_authorities_date_of_registration).yearMonthDay ?? ''
    );

  }, [formR]);

  useEffect(() => {
    const areAllRequiredFieldsFilled = [
      japaneseAuthorities,
      jaAuthDateOfRegistration,
      foreignAuthorities,
      foAuthDateOfRegistration,
    ].every(val => val);

    const areThereNoErrors = [
      foreignAuthoritiesError,
      japaneseAuthoritiesError,
    ].every(val => !val);

    onChangeSectionReadiness(areAllRequiredFieldsFilled && areThereNoErrors);
  }, [
    japaneseAuthorities,
    jaAuthDateOfRegistration,
    foreignAuthorities,
    foAuthDateOfRegistration,
    japaneseAuthoritiesError,
    foreignAuthoritiesError,
  ]);

  if (isActive === false)
    return null;

  return (
    <Section>
      <Label>
        {t("noteOnSection")}
      </Label>

      <FieldGroup
        theme="light"
      >
        {/* Japanese authorities */}
        <MultiTextField
          required
          disabled={readOnly}
          label={t("japaneseAuthorities")}
          validators={[validation.isFullwidth, validation.createLengthValidator(40)]}
          maxLength={40}
          restriction={inputNotes.fullwidthJapanese}
          value={japaneseAuthorities}
          onValueChange={setJapaneseAuthorities}
          error={japaneseAuthoritiesError || getEmptyError(japaneseAuthorities)}
          onErrorChange={setJapaneseAuthoritiesError}
          onBlur={() => {
            saveData({ place_and_date_japanese_authorities: japaneseAuthorities });
          }}
        />

        {/* Date of registration */}
        <DateSelectField
          required
          disabled={readOnly}
          label={t("jaAuthDateOfRegistration")}
          value={jaAuthDateOfRegistration}
          error={getEmptyError(jaAuthDateOfRegistration)}
          minDate={getFirstDayOfYearOffset(-70)}
          maxDate={getDateWithDayOffset(+14)}
          onValueChange={val => {
            setJaAuthDateOfRegistration(val);
            if (val) {
              saveData({
                place_and_date_japanese_authorities_date_of_registration: formatDateStrWithJaUnits(val)
              });
            }
          }}
        />
      </FieldGroup>

      <FieldGroup
        theme="light"
      >
        {/* Foreign authorities */}
        <MultiTextField
          required
          disabled={readOnly}
          label={t("foreignAuthorities")}
          validators={[validation.isFullwidth, validation.createLengthValidator(40)]}
          maxLength={40}
          restriction={inputNotes.fullwidthJapanese}
          value={foreignAuthorities}
          onValueChange={setForeignAuthorities}
          error={foreignAuthoritiesError || getEmptyError(foreignAuthorities)}
          onErrorChange={setForeignAuthoritiesError}
          onBlur={() => {
            saveData({ place_and_date_foreign_authorities: foreignAuthorities });
          }}
        />

        {/* Date of registration */}
        <DateSelectField
          required
          disabled={readOnly}
          label={t("foreignAuthDateOfRegistration")}
          value={foAuthDateOfRegistration}
          error={getEmptyError(foAuthDateOfRegistration)}
          minDate={getFirstDayOfYearOffset(-70)}
          maxDate={getDateWithDayOffset(+14)}
          onValueChange={val => {
            setFoAuthDateOfRegistration(val);
            if (val) {
              onChangeFormR({
                place_and_date_foreign_authorities_date_of_registration: formatDateStrWithJaUnits(val)
              });
            }
          }}
        />
      </FieldGroup>
    </Section>
  );
};

export default PlaceAndDateOfNotificationSection;
