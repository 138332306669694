import { FunctionComponent } from "react";
import styled from "styled-components";
import Page from "../../components/layout/Page";
import Button from "../../components/form/Button";
import Column from "../../components/layout/Column";
import Text from "../../components/text/Text";
import { ReactComponent as ArrowIcon } from "../../assets/icon-right-arrow.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface InvalidLinkPageProps {}

const LocalPage = styled(Page)`
  padding-top: 50px;
`;

const LocalColumn = styled(Column)`
  padding: 20px;
  gap: 50px;
`;

const WarningMessage = styled(Text)`
  font-weight: 500;
  font-size: 1.625rem;
  line-height: 38px;
`;

const ForwardIcon = styled(ArrowIcon)`
  margin-left: 10px;
`;


const HomePage: FunctionComponent<InvalidLinkPageProps> = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'invalidLinkPage' });
  const navigate = useNavigate();

  return (
    <LocalPage>
      <LocalColumn>
        <WarningMessage>{ t("linkIsInvalid")}</WarningMessage>
        <Button variant="inline" onClick={() => navigate("/login")}>
          { t("goToLoginPage")}
          <ForwardIcon />
        </Button>
      </LocalColumn>
    </LocalPage>
  )
}
export default HomePage;
