import { SelectOption } from "../../types/option";
import { StatusOfResidenceCode_CB19 } from "../../types/visa/statusOfResidence";

const getTKey = (key: string) => `statusOfResidence_CB19.${key}`;

export const APPLYING_VISA_OPTIONS: SelectOption[] = [
    {
        label: getTKey(StatusOfResidenceCode_CB19.Dependent),
        value: StatusOfResidenceCode_CB19.Dependent
    }
]