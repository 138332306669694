import { ChangeEvent, FunctionComponent, InputHTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components'

import { ReactComponent as CheckedIcon } from '../../assets/icon-checked-white.svg';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
    error?: boolean;
    onValueChange?: (value: boolean) => void;
    children?: ReactNode;
}

const Container = styled.label<CheckboxProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
  min-height: 40px;
  padding: ${(props: CheckboxProps) => props.checked ? '9px 9px' : '10px 10px'};
  border: ${(props: CheckboxProps) => props.disabled
          ? '1px solid #8A8E94'
          : (props.error
                  ? '1px solid #E93232'
                  : (props.checked ? '2px solid #1F82FF' : '1px solid #8A8E94')
          )
  };
  border-radius: 5px;
  box-sizing: border-box;
  color: ${(props: CheckboxProps) => props.disabled
          ? '#999999'
          : (props.error
                  ? '#444444'
                  : (props.checked ? '#0069EC' : '#444444')
          )
  };
  background: ${(props: CheckboxProps) => props.disabled
          ? '#D5D5D5'
          : (props.error
                          ? '#FFF0F0'
                          : (props.checked ? '#EBF4FF' : '#F7F7F7')
          )
  };
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 20px;
  cursor: pointer;
  pointer-events: ${(props: CheckboxProps) => props.disabled ? 'none' : 'auto'};
`;

const Input = styled.input`
  display: none !important;
`;

const Button = styled.div<CheckboxProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 18px;
  min-width: 18px;
  max-height: 18px;
  min-height: 18px;
  background: ${(props: CheckboxProps) => props.disabled
          ? '#D5D5D5'
          : (props.checked
                  ? (props.error ? '#E93232' : '#0069EC')
                  : '#FFFFFF'
          )
  };
  border: 1px solid ${(props: CheckboxProps) => props.disabled
          ? '#8A8E94'
          : (props.error
                  ? '#E93232'
                  : (props.checked ? '#1F82FF' : '#8A8E94')
          )
  };
  border-radius: 2px;
  box-sizing: border-box;
`;

const Checkbox: FunctionComponent<CheckboxProps> = ({
    disabled,
    error,
    checked,
    onValueChange,
    children,
    ...props
}) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onValueChange && onValueChange(event.target.checked);
    }

    return (
        <Container
            disabled={disabled}
            error={error}
            checked={!!checked}
        >
            <Input
                type="checkbox"
                disabled={disabled}
                checked={!!checked}
                onChange={handleChange}
                {...props}
            />
            <Button
                disabled={disabled}
                error={error}
                checked={!!checked}
            >
                {!!checked && <CheckedIcon />}
            </Button>
            {children}
        </Container>
    );
};

export default Checkbox;
