import { FunctionComponent, useEffect, useState } from "react";
import DependentVisaForm from "./VisaForms/DependentVisaForm";
import Page from "../../components/layout/Page";
import Row from "../../components/layout/Row";
import Text from "../../components/text/Text";
import PageHeading from "../../components/text/PageHeading";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Button from "../../components/form/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatcher } from "../../hooks";
import { VisaApplicationType } from "../../types/visa/applicationType";
import { FormKind, VisaApplication } from "../../types/visa/applicationData";
import { getVisaApplication, updateVisaApplication } from "../../apis/visaApplication";
import { checkVisaApplicationType, getVisaInfoTransKeys, isVisaApplicationWithFormN, isVisaApplicationWithFormR, isThisVisaNecessaryToWorkWithOrg, isThisVisaApplicationSubmitted, isVisaChangeApplication, isVisaExtensionApplication, isVisaApplicationWithFormP, isThisVisaChange, isThisVisaExtension, isThisVisaApplicationInsufficient } from "../../utils/visaApplicationHelper";
import SubmissionThruApiModal from "./SubmissionThruApiModal";
import BackButton from "../../components/navigation/BackButton";
import { ReactComponent as OkIcon } from "../../assets/icon-check-circle-green-filled.svg";
import { ReactComponent as FailedIcon } from "../../assets/icon-cross-red.svg";
import { ReactComponent as SubmitIcon } from "../../assets/icon-send-white.svg";
import { ReactComponent as ShareIcon } from "../../assets/icon-share.svg";
import { VisaAttachment, VisaAttachmentKind } from "../../types/visa/attachment";
import { updateExtensionBySelfData } from "../../apis/extensionBySelfData";
import { deleteVisaAttachments, uploadVisaAttachment } from "../../apis/visaAttachment";
import { ExtensionBySelfData } from "../../types/visa/extensionBySelf/data";
import { ChangeBySelfData } from "../../types/visa/changeBySelf/data";
import { updateChangeBySelfData } from "../../apis/changeBySelfData";
import Column from "../../components/layout/Column";
import Spinner from "../../components/display/Spinner";
import PreviewModal from "../../components/modal/previewModal";
import { getCurrentUser } from "../../apis/user";
import { FormN } from "../../types/visa/uncommonFormParts/formN/data";
import { updateFormN } from "../../apis/formN";
import GijinkokuVisaForm from "./VisaForms/GijinkokuVisaForm";
import UserInvitationModal from "../../components/modal/UserInvitationModal";
import { SupplementaryInfo } from "../../types/visa/supplementaryInfo";
import { DependentAIReadingData } from "../../types/visa/aIReadingData";
import { updateSupplementaryInfo } from "../../apis/supplementaryInfo";
import axios from "axios";
import StudyAbroadVisaForm from "./VisaForms/StudyAbroadVisaForm";
import { FormP } from "../../types/visa/uncommonFormParts/formP/data";
import { updateFormP } from "../../apis/formP";
import { FormR } from "../../types/visa/uncommonFormParts/formR/data";
import { updateFormR } from "../../apis/formR";
import SubmissionThruScrivenerModal from "./SubmissionThruScrivenerModal";
import PromptCard from "../../components/display/PromptCard";
import InputAssistanceModal from "./InputAssistanceModal";
import { extractReusableChangeBySelfData, extractReusableExtensionBySelfData, extractReusableSupplementaryData } from "./extractCopyableVisaData";
import { Attachment } from "../../types/api/attachment";
import { analyzeUserFiles } from "../../apis/aiAnalysis";
import { CommonFormData, getUpdatedCommonFormWithAIValues, getUpdatedFormRWithAIValues } from "./getUpdatedFormStateWithAIValues";

interface ApplicationFormPageProps {}

const HeaderContainer = styled(Column)`
  padding: 20px;
  gap: 30px;
`;

const LocalPageHeading = styled(PageHeading)`
  font-size: 1.625rem;
`;

const NavStatusContainer = styled(Row)`
  justify-content: space-between;
  gap: 15px;
`;

const ShareButtonContainer = styled(Row)``;

const ShareButton = styled(Button).attrs(() => ({
  variant: 'inline',
}))`
  width: auto;
  min-width: 109px;
`;

const HeadingButtonContainer = styled(Row)`
  justify-content: space-between;
`;

const AutoSaveStatusText = styled(Text)`
  color: #626161;
  gap: 10px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  padding: 50px 20px 20px 20px;
  box-sizing: border-box;
`;

const PreviewButton = styled(Button).attrs(() => ({
  variant: 'primary',
}))`
  min-width: 120px;;
  width: auto;
`;

const SubmitButton = styled(Button).attrs(() => ({
  variant: 'primary',
}))`
  min-width: 120px;;
  width: auto;
  gap: 10px;
`;


const ApplicationFormPage: FunctionComponent<ApplicationFormPageProps> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { state, dispatcher } = useDispatcher();
  const [visaApplicationType, setVisaApplicationType] = useState<VisaApplicationType>();
  const [visaApplication, setVisaApplication] = useState<VisaApplication>();
  const [extensionBySelfData, setExtensionBySelfData] = useState<ExtensionBySelfData | null>(null);
  const [changeBySelfData, setChangeBySelfData] = useState<ChangeBySelfData | null>(null);
  const [supplementaryInfo, setSupplementaryInfo] = useState<SupplementaryInfo | null>(null);
  const [formN, setFormN] = useState<FormN | null>(null);
  const [formP, setFormP] = useState<FormP | null>(null);
  const [formR, setFormR] = useState<FormR | null>(null);
  const [visaAttachments, setVisaAttachments] = useState<VisaAttachment[]>([]);
  const [hasAutoSavedAtLeastOnce, setHasAutoSavedAtLeastOnce] = useState<boolean>(false);
  const [isVisaToWorkWithOrg, setIsVisaToWorkWithOrg] = useState<boolean>(false);
  const [isUserPaid, setIsUserPaid] = useState<boolean>(false);
  const [showAllErrorAlerts, setShowAllErrorAlerts] = useState<boolean>(false);
  const [hasFailedToSave, setHasFailedToSave] = useState<boolean>(false);
  const [showInputAssistanceUI, setShowInputAssistanceUI] = useState<boolean>(false);
  const [showInputAssistanceModal, setShowInputAssistanceModal] = useState<boolean>(false);
  const [showSubmissionThruApiModal, setShowSubmissionThruApiModal] = useState<boolean>(false);
  const [showSubmissionThruScrivenerModal, setShowSubmissionThruScrivenerModal] = useState<boolean>(false);
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
  const [showUserInvitationModal, setShowUserInvitationModal] = useState<boolean>(false);
  const [isApplicationReadyToSubmit, setIsApplicationReadyToSubmit] = useState<boolean>(false);
  const disableSubmitButton = !isUserPaid || !isApplicationReadyToSubmit;

  /* This can prevent a flickering issue:
     e.g. Tap an option of a radio button group while focusing on a text field */
  const debounce = <T,> (fn: (data: Partial<T>) => Promise<void>) => {
    let timeoutId: number;
    let accumudatedParams: Partial<T> = {};
    const DEBOUNCE_DELAY = 70;

    return (params: Partial<T>) => {
      accumudatedParams = { ...accumudatedParams, ...params };

      window.clearTimeout(timeoutId);
      timeoutId = window.setTimeout(async () => {
        await fn(accumudatedParams);
        accumudatedParams = {};
      }, DEBOUNCE_DELAY);
    }
  }

  const saveFormData = async (saveProc: () => Promise<void>) => {
    dispatcher.startSaving();
    try {
      setHasFailedToSave(false);
      await saveProc();
      setHasAutoSavedAtLeastOnce(true);
    } catch (e) {
      setHasFailedToSave(true);

      /* Once the user fails to save, section states are reverted
         (the section states load data from the states below in useEffect hook) */
      visaApplication && setVisaApplication({ ...visaApplication })
      extensionBySelfData && setExtensionBySelfData({ ...extensionBySelfData });
      changeBySelfData && setChangeBySelfData({ ...changeBySelfData });
      supplementaryInfo && setSupplementaryInfo({ ...supplementaryInfo });
      formN && setFormN({ ...formN });
      formP && setFormP({ ...formP });
      formR && setFormR({ ...formR });
    }
    dispatcher.endSaving();
  }

  const saveExtensionBySelfDataDebounced = debounce(async (data: Partial<ExtensionBySelfData>) => {
    if (!isVisaExtensionApplication(visaApplication))
      return;

    await saveFormData(async () => {
      const id = visaApplication!.koushin_honnin.id;
      const updatedExtensionBySelfData = await updateExtensionBySelfData(id, data);
      setExtensionBySelfData(updatedExtensionBySelfData);
      setVisaApplication({...visaApplication, koushin_honnin: updatedExtensionBySelfData
      });
    });
  });

  const saveChangeBySelfDataDebounced = debounce(async (data: Partial<ChangeBySelfData>) => {
    if (!isVisaChangeApplication(visaApplication))
      return;

    await saveFormData(async () => {
      const id = visaApplication.henkou_honnin.id;
      const updatedChangeBySelfData = await updateChangeBySelfData(id, data);
      setChangeBySelfData(updatedChangeBySelfData);
      setVisaApplication({...visaApplication, henkou_honnin: updatedChangeBySelfData})
    });
  });

  const saveFormRDataDebounced = debounce(async (data: Partial<FormR>) => {
    if (!isVisaApplicationWithFormR(visaApplication))
      return;

    await saveFormData(async () => {
      const updatedFormRData = await updateFormR(visaApplication.form_r.id, data);
      setFormR(updatedFormRData);
      setVisaApplication({ ...visaApplication, form_r: updatedFormRData });
    });
  });

  const saveFormNDataDebounced = debounce(async (data: Partial<FormN>) => {
    if (!isVisaApplicationWithFormN(visaApplication))
      return;

    await saveFormData(async () => {
      const id = visaApplication.form_n.id;
      const updatedFormNData = await updateFormN(id, data);
      setFormN(updatedFormNData);
      setVisaApplication({ ...visaApplication, form_n: updatedFormNData});
      setHasAutoSavedAtLeastOnce(true);
    });
  });

  const saveFormPDataDebounced = debounce(async (data: Partial<FormP>) => {
    if (!isVisaApplicationWithFormP(visaApplication))
      return;

    await saveFormData(async () => {
      const id = visaApplication.form_p.id;
      const updatedFormPData = await updateFormP(id, data);
      setFormP(updatedFormPData);
      setVisaApplication({ ...visaApplication, form_p: updatedFormPData });
    });
  });

  const saveSupplementaryInfo = async (data: Partial<SupplementaryInfo>) => {
    await saveFormData(async () => {
      const id = visaApplication!.supplementary_info!.id;
      const updatedSupplementaryInfo = await updateSupplementaryInfo(id, data);
      setVisaApplication({ ...visaApplication!, supplementary_info: updatedSupplementaryInfo });
      setSupplementaryInfo(updatedSupplementaryInfo);
    });
  }

  const saveVisaAttachment = async (file: File, kind: VisaAttachmentKind, uploaderId?: string) => {
    try {
      setHasFailedToSave(false);
      dispatcher.startUploading(uploaderId);
      const uploadedVisaAttachment = await uploadVisaAttachment(visaApplication!.id, file, kind);
      const newAttachments = [...visaAttachments, uploadedVisaAttachment];

      setVisaAttachments(newAttachments);
      setVisaApplication({ ...visaApplication!, visa_attachments: newAttachments });
      setHasAutoSavedAtLeastOnce(true);
    } catch (e) {
      setHasFailedToSave(true);
    } finally {
      dispatcher.endUploading(uploaderId);
    }
  }

  const removeVisaAttachment = async (visaAttachmentId: number | number[]) => {
    const ids = Array.isArray(visaAttachmentId) ? visaAttachmentId : [visaAttachmentId];

    if (ids.length === 0)
      return;

    try {
      dispatcher.startSaving();
      await deleteVisaAttachments(ids);

      const newAttachments = visaAttachments.filter(va => !ids.includes(va.id));

      setVisaAttachments(newAttachments);
      setVisaApplication({ ...visaApplication!, visa_attachments: newAttachments });
      setHasAutoSavedAtLeastOnce(true);
    } catch (e) {
    } finally {
      dispatcher.endSaving();
    }
  }

  const updateUsedAssistanceFuncFlag = async () => {
    const latestApp = await updateVisaApplication(id!, { is_used_assistance_function: true });
    setShowInputAssistanceUI(false);
    setVisaApplication(latestApp);
  }

  const handleAIResponseData = (
    response: DependentAIReadingData,
    formKind: FormKind
  ) => {
    if (extensionBySelfData) {
      const updatedExtensionBySelfData = getUpdatedCommonFormWithAIValues(
        extensionBySelfData as CommonFormData, // REFACTOR: remove type casting here
        response
      );
      setExtensionBySelfData(updatedExtensionBySelfData);
    }

    if (changeBySelfData) {
      const updatedChangeBySelfData = getUpdatedCommonFormWithAIValues(
        changeBySelfData as CommonFormData, // REFACTOR: remove type casting here
        response
      );
      setChangeBySelfData(updatedChangeBySelfData);
    }

    if (formKind === FormKind.R && formR) {
      const updatedFormRData = getUpdatedFormRWithAIValues(formR, response);
      setFormR(updatedFormRData);
    }

    else if (formKind === FormKind.N && formN) {
      // TODO: implement this
      // const updatedFormNData = getUpdatedFormNWithAIValues(formN, response);
      // setFormN(updatedFormNData);
    }

    else if (formKind === FormKind.P && formP) {
      // TODO: implement this
      // const updatedFormPData = getUpdatedFormPWithAIValues(formP, response);
      // setFormP(updatedFormPData);
    }
  };

  const onSubmit = () => {
    //Enable verify_to_submit to show all errors (even after logout and login again)
    if (!!!visaApplication?.verify_to_submit) {
      (async () => {
        try {
          updateVisaApplication(id!, { verify_to_submit: true });
        } catch (e) {
        } finally {
          setShowAllErrorAlerts(true);
        }
      })();
    }

    if (!isApplicationReadyToSubmit) {
      setShowAllErrorAlerts(true);
      dispatcher.showWarningOnSnackbar(t("snackbar.enterAllFields"));
      return;
    }

    //Submission modal is shown after the user confirms the data on the preview modal
    setShowPreviewModal(true);
  };

  const onPreviewByOrganization = () => {
    if (!isApplicationReadyToSubmit) {
      setShowAllErrorAlerts(true);
      dispatcher.showWarningOnSnackbar(t("snackbar.enterAllFields"));
      return;
    }

    setShowPreviewModal(true);
  }

  const onClickRunAIReading = async (uploadedFiles: Attachment[]) => {
    dispatcher.startLoading();
    try {
      const formKind = visaApplication?.form_kind
      const formId = formN?.id || formP?.id || formR?.id

      if (!formKind || !formId) {
        throw new Error()
      }

      const response = await analyzeUserFiles({
        formKind: formKind,
        formId: formId,
        params: uploadedFiles,
      });

      if (!response) {
        throw new Error();
      }

      handleAIResponseData(response, formKind);
      updateUsedAssistanceFuncFlag();
      dispatcher.showSnackbar(t('snackbar.aIReadSuccess'));
      setShowInputAssistanceModal(false);
    } catch (e) {
      dispatcher.showSnackbar(t('snackbar.failedToProceed'));
    }
    dispatcher.stopLoading();
  };

  const onClickUseThisAppData = async (visaApplicationToCopy: VisaApplication) => {
    if (isThisVisaExtension(visaApplicationToCopy)) {
      const data = extractReusableExtensionBySelfData(visaApplicationToCopy);
      data && saveExtensionBySelfDataDebounced(data);
    }

    if (isThisVisaChange(visaApplicationToCopy)) {
      const data = extractReusableChangeBySelfData(visaApplicationToCopy);
      data && saveChangeBySelfDataDebounced(data);
    }

    /* We only copy the uncommon part
      if the current visa application type is the same as the past one:
      e.g.
      [Dependent extension] => [Dependent extension]
      [Change to dependent] => [Change to dependent]

      Because applications like [Change to Student] => [Change to Dependent]
      require different uncommon parts, we don't copy them.
    */

    const pastVisaAppType = checkVisaApplicationType(visaApplicationToCopy);
    const loadUncommonParts = pastVisaAppType === visaApplicationType;
    
    if (loadUncommonParts) {
      //TODO: add Supplementary Info handlinhg
      if (isVisaApplicationWithFormP(visaApplicationToCopy)) {
        saveFormPDataDebounced(visaApplicationToCopy.form_p);
      }

      //TODO: add Supplementary Info handlinhg
      if (isVisaApplicationWithFormN(visaApplicationToCopy)) {
        saveFormNDataDebounced(visaApplicationToCopy.form_n);
      }

      if (isVisaApplicationWithFormR(visaApplicationToCopy)) {
        saveFormRDataDebounced(visaApplicationToCopy.form_r);
        const reusableSupInfo = extractReusableSupplementaryData(visaApplicationToCopy, FormKind.R);
        reusableSupInfo && saveSupplementaryInfo(reusableSupInfo);
      }
    }

    dispatcher.startLoading();
    try {
      updateUsedAssistanceFuncFlag();
    } catch {
      dispatcher.showWarningOnSnackbar(t("snackbar.failedToProceed"));
    }
    dispatcher.stopLoading();
    setShowInputAssistanceModal(false);
  }

  useEffect(() => {
    (async () => {
      try {
        dispatcher.startLoading();

        //From here, the code is for the normal user
        const visaApplication = await getVisaApplication(id!);
        const visaApplicationType = checkVisaApplicationType(visaApplication);

        if (isThisVisaApplicationSubmitted(visaApplication) &&
            !isThisVisaApplicationInsufficient(visaApplication)) {
          navigate('/');
          return;
        }

        setVisaApplication(visaApplication);
        setVisaApplicationType(visaApplicationType);
        setExtensionBySelfData(visaApplication.koushin_honnin);
        setChangeBySelfData(visaApplication.henkou_honnin);
        setSupplementaryInfo(visaApplication.supplementary_info);
        setVisaAttachments(visaApplication.visa_attachments);
        setIsVisaToWorkWithOrg(isThisVisaNecessaryToWorkWithOrg(visaApplication));
        setShowInputAssistanceUI(!visaApplication.is_used_assistance_function);

        switch (visaApplication.form_kind) {
          case FormKind.N:
            setFormN(visaApplication.form_n);
            break;
          case FormKind.P:
            setFormP(visaApplication.form_p);
            break;
          case FormKind.R:
            setFormR(visaApplication.form_r);
            break;
        }

        const user = await getCurrentUser();
        setIsUserPaid(user.is_paid);

        setShowAllErrorAlerts(visaApplication.verify_to_submit ?? false);
      } catch (e) {
        if (axios.isAxiosError(e)) {
          navigate("/");
        }
      } finally {
        dispatcher.stopLoading();
      }
    })();
  }, []);

  return (
    <Page>
      <HeaderContainer>
        <NavStatusContainer>
          <BackButton
            onClick={() => navigate("/")}
          />
          <AutoSaveStatusText>
            { (!hasAutoSavedAtLeastOnce) &&
              <>{t("applicationFormPage.autoSave.willBeAutoSaved")}</>
            }

            { ((hasAutoSavedAtLeastOnce && state.isSaving) ||
               (state.isUploading)) &&
              <><Spinner />{t("applicationFormPage.autoSave.saving")}</>
            }

            { ((hasAutoSavedAtLeastOnce && !hasFailedToSave &&
                !(state.isSaving || state.isUploading))) &&
              <><OkIcon />{t("applicationFormPage.autoSave.saved")}</>
            }

            { ((hasFailedToSave && !(state.isSaving || state.isUploading))) &&
              <><FailedIcon style={{ width: 20, height: 20 }}/>{t("applicationFormPage.autoSave.failed")}</>
            }
          </AutoSaveStatusText>
        </NavStatusContainer>
        { isVisaToWorkWithOrg &&
          <ShareButtonContainer>
            <ShareButton onClick={() => setShowUserInvitationModal(true)}>
              <Row style={{gap: 10}}>
                <ShareIcon />
                Share
              </Row>
            </ShareButton>
          </ShareButtonContainer>
        }
        <HeadingButtonContainer>
          <LocalPageHeading>
            { (visaApplication && getVisaInfoTransKeys(visaApplication).type) &&
              t(`applicationFormPage.title.${getVisaInfoTransKeys(visaApplication).type}`)
            }
          </LocalPageHeading>
          { isVisaToWorkWithOrg
            ? <PreviewButton onClick={onPreviewByOrganization} >
                {t("common.preview")}
              </PreviewButton>
            : <SubmitButton onClick={onSubmit} disabled={disableSubmitButton}>
                <SubmitIcon />
                {t("applicationFormPage.submit")}
              </SubmitButton>
          }
        </HeadingButtonContainer>

        { showInputAssistanceUI && 
          <PromptCard>
            <Text>{t("applicationFormPage.promptCard.inputAssistanceFuncExplanation")}</Text>
            <Button variant="inline" onClick={() => setShowInputAssistanceModal(true)}>
              {t("applicationFormPage.promptCard.useInputAssistanceFunc")}
            </Button>
          </PromptCard>
        }
      </HeaderContainer>

      {/* Actual Form body */}
      <div>
        {(() => {
          if (!visaApplication)
            return null;

          switch (visaApplicationType) {
            case VisaApplicationType.ExtendDependentVisaBySelf:
            case VisaApplicationType.ChangeToDependentVisaBySelf:
              return (
                <DependentVisaForm
                  visaApplication={visaApplication}
                  visaApplicationType={visaApplicationType}
                  extensionBySelfData={extensionBySelfData}
                  changeBySelfData={changeBySelfData}
                  formR={formR}
                  supplementaryInfo={supplementaryInfo}
                  visaAttachments={visaAttachments}
                  onChangeFormR={saveFormRDataDebounced}
                  onChangeBySelfDataChange={saveChangeBySelfDataDebounced}
                  onChangeExtensionBySelfData={saveExtensionBySelfDataDebounced}
                  onChangeApplicationReadiness={setIsApplicationReadyToSubmit}
                  onUploadVisaAttachment={saveVisaAttachment}
                  onDeleteVisaAttachment={removeVisaAttachment}
                  onChangeSupplementaryInfo={saveSupplementaryInfo}
                  showErrorAlerts={showAllErrorAlerts}
                />
              );

            case VisaApplicationType.ExtendGijinkokuVisaBySelf:
            case VisaApplicationType.ChangeToGijinkokuVisaBySelf:
              return (
                <GijinkokuVisaForm
                  visaApplication={visaApplication}
                  visaApplicationType={visaApplicationType}
                  extensionBySelfData={extensionBySelfData}
                  changeBySelfData={changeBySelfData}
                  formN={formN}
                  supplementaryInfo={supplementaryInfo}
                  visaAttachments={visaAttachments}
                  showErrorAlerts={showAllErrorAlerts}
                  onChangeBySelfDataChange={saveChangeBySelfDataDebounced}
                  onChangeExtensionBySelfData={saveExtensionBySelfDataDebounced}
                  onChangeFormN={saveFormNDataDebounced}
                  onChangeSupplementaryInfo={saveSupplementaryInfo}
                  onChangeApplicationReadiness={setIsApplicationReadyToSubmit}
                  onUploadVisaAttachment={saveVisaAttachment}
                  onDeleteVisaAttachment={removeVisaAttachment}
                />
              );

            case VisaApplicationType.ExtendStudyAbroadVisaBySelf:
              return (
                <StudyAbroadVisaForm
                  visaApplication={visaApplication}
                  visaApplicationType={visaApplicationType}
                  extensionBySelfData={extensionBySelfData}
                  changeBySelfData={changeBySelfData}
                  formP={formP}
                  supplementaryInfo={supplementaryInfo}
                  visaAttachments={visaAttachments}
                  showErrorAlerts={showAllErrorAlerts}
                  onChangeBySelfDataChange={saveChangeBySelfDataDebounced}
                  onChangeExtensionBySelfData={saveExtensionBySelfDataDebounced}
                  onChangeFormP={saveFormPDataDebounced}
                  onChangeSupplementaryInfo={saveSupplementaryInfo}
                  onChangeApplicationReadiness={setIsApplicationReadyToSubmit}
                  onUploadVisaAttachment={saveVisaAttachment}
                  onDeleteVisaAttachment={removeVisaAttachment}
                />
              );

            default:
              return null;
            }
          })()}
      </div>

      <ButtonWrapper>
        { isVisaToWorkWithOrg
          ? <PreviewButton onClick={onPreviewByOrganization} style={{ width: '100%'}}>
              {t("common.preview")}
            </PreviewButton>
          : <SubmitButton
              onClick={onSubmit}
              style={{ width: '100%' }}
              disabled={disableSubmitButton}
            >
              <SubmitIcon />
              {t("applicationFormPage.submit")}
            </SubmitButton>
        }
      </ButtonWrapper>

      { showInputAssistanceModal && visaApplication &&
        <InputAssistanceModal
          onClickBack={() => setShowInputAssistanceModal(false)}
          onClickRunAIReading={onClickRunAIReading}
          onClickUseThisAppData={onClickUseThisAppData}
        />
      }

      { showSubmissionThruApiModal && visaApplication &&
        <SubmissionThruApiModal
          visaApplication={visaApplication}
          onClose={() => setShowSubmissionThruApiModal(false)}
          onSubmissionComplete={() => {
            setShowSubmissionThruApiModal(false);
            navigate(`application/${id}/review`)
          }}
        />
      }

      { showPreviewModal && visaApplication &&
        <PreviewModal
          visaApplication={visaApplication}
          title={t("modalPreview.forApplicationFormPage.title")}
          description={t("modalPreview.forApplicationFormPage.description")}
          warningText={t("modalPreview.forApplicationFormPage.warning")}
          buttonText={t("common.confirm")}
          onClickButton={() => {
            setShowPreviewModal(false);

            //TODO: this should be conditional in the future
            //after the application through the API is implemented
            setShowSubmissionThruScrivenerModal(true);
          }}
          onClickBackIcon={() => setShowPreviewModal(false)}
      />
      }

      { showUserInvitationModal && visaApplication &&
        <UserInvitationModal
          visaApplicationId={visaApplication.id}
          onBack={() => setShowUserInvitationModal(false)}
        />
      }

      { showSubmissionThruScrivenerModal && visaApplication &&
        <SubmissionThruScrivenerModal
          visaApplication={visaApplication}
          onClickBack={() => setShowSubmissionThruScrivenerModal(false)}
        />
      }
    </Page>
  );
};

export default ApplicationFormPage;
