import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Page from "../../components/layout/Page";
import PageHeading from "../../components/text/PageHeading";
import { signUp } from "../../apis/auth";
import { useDispatcher, useValidation } from "../../hooks";
import Text from "../../components/text/Text";
import Column from "../../components/layout/Column";
import TextField from "../../components/compound/TextField";
import Button from "../../components/form/Button";
import { PASSWORD_MIN_LENGTH } from "../../constants/values/password";
import BottomAlignedContainer from "../../components/layout/BottomAlignedContainer";

interface SignupPageProps {}

const LocalPage = styled(Page)`
  padding: 20px;
  gap: 30px;
`;

const ButtonContainer = styled(BottomAlignedContainer)`
  padding-top: 30px;
`;

const SignupPage: FunctionComponent<SignupPageProps> = () => {
  const { dispatcher } = useDispatcher();
  const validation = useValidation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [isInputting, setIsInputting] = useState(false);
  const disableCreateButton = (
    isInputting ||
    (!!!email || !!!password || !!!confirmPassword) ||
    (!!emailError || !!passwordError || !!confirmPasswordError)
  )

  const checkIfPaswordsMatch = () => {
    if (confirmPassword !== password) {
      setConfirmPasswordError(t("signupPage.passwordMismatchError"));
    } else {
      setConfirmPasswordError("");
    }
  }

  const handlePasswordBlur = () => {
      setIsInputting(false);

    if (password.length < PASSWORD_MIN_LENGTH) {
      setPasswordError(t("signupPage.passwordTooShortError", { length: PASSWORD_MIN_LENGTH }));
      return;
    } 

    if (confirmPassword.length >= PASSWORD_MIN_LENGTH) 
      checkIfPaswordsMatch();
  };

  const handleConfirmPasswordBlur = () => {
    setIsInputting(false);
    checkIfPaswordsMatch();
  };

  const onCreate = async () => {
    dispatcher.startLoading();
    try {
      await signUp(email, password);
      dispatcher.treatAsLoggedIn();
      navigate("/setup");
    } catch (e) {
      dispatcher.showWarningOnSnackbar(t("snackbar.failedToProceed"));
    } finally {
      dispatcher.stopLoading();
    }
  };

  return (
    <LocalPage>
      <PageHeading>{t("signupPage.createAccount")}</PageHeading>
      <Column style={{ gap: 10 }}>
        <Text>{t("signupPage.description1")}</Text>
        <Text>{t("signupPage.description2")}</Text>
      </Column>

      <TextField
        label={t("signupPage.email")}
        value={email}
        error={emailError}
        validators={[validation.isEmailAddress]}
        onErrorChange={setEmailError}
        placeholder="visadas@gmail.com"
        onFocus={() => setIsInputting(true)}
        onValueChange={setEmail}
        onBlur={() => setIsInputting(false)}
      />

      <TextField
        type="password"
        label={t("signupPage.password")}
        value={password}
        error={passwordError}
        onValueChange={setPassword}
        onErrorChange={setPasswordError}
        onFocus={() => setIsInputting(true)}
        onBlur={handlePasswordBlur}
        minLength={8}
        restriction={t("signupPage.mustBeAtLeast8Letters", { length: PASSWORD_MIN_LENGTH })}
      />

      <TextField
        type="password"
        label={t("signupPage.confirmPassword")}
        value={confirmPassword}
        error={confirmPasswordError}
        onValueChange={setConfirmPassword}
        onErrorChange={setConfirmPasswordError}
        onFocus={() => setIsInputting(true)}
        onBlur={handleConfirmPasswordBlur}
        minLength={PASSWORD_MIN_LENGTH}
        restriction={t("signupPage.passwordMustMatch")}
      />

      <ButtonContainer>
        <Button
          variant="primary"
          disabled={disableCreateButton}
          onClick={onCreate}
        >
          {t("signupPage.create")}
        </Button>
      </ButtonContainer>
    </LocalPage>
  );
};

export default SignupPage;
