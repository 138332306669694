import { FunctionComponent, HTMLAttributes } from "react";
import Row from "../layout/Row";
import CategoryHeading from "../text/CategoryHeading";
import AppStatusLabel from "../form/AppStatusLabel";
import Text from "../text/Text";
import { VisaApplicationStatus } from "../../types/visa/applicationStatus";
import styled from "styled-components";
import { VisaApplication } from "../../types/visa/applicationData";
import { getVisaInfoTransKeys } from "../../utils/visaApplicationHelper";
import { formatDateString } from "../../utils/utils";
import { useTranslation } from "react-i18next";

interface ApplicationCardProps extends HTMLAttributes<HTMLDivElement> {
  visaApplication: VisaApplication;
  selected?: boolean;
  onClick?: () => void;
}

interface AppCardContainerProps {
  selected?: boolean;
  clickable?: boolean;
}

const AppCardContainer = styled.div<AppCardContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 280px;
  width: 100%;
  padding: 20px;
  border-radius: 10px;

  border: ${({ selected }) => (selected 
    ? "2px solid #0069EC" 
    : "1px solid #d5d5d5"
  )};
  
  background-color: #ffffff;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.1);
  cursor: ${({ clickable }) => (clickable ? "pointer" : "default")};
`;

const LocalRow = styled(Row)`
  justify-content: space-between;
`;

const SmallText = styled(Text)`
  font-size: 0.8rem;
  color: #999999;
`;

const ApplicationCard: FunctionComponent<ApplicationCardProps> = ({
  visaApplication,
  selected,
  onClick,
  ...props
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'visa' });
  const lastSubmission = visaApplication.last_submitted_visa_submission;
  const visaInfoTransKeys = getVisaInfoTransKeys(visaApplication);
  const createdAt = formatDateString(visaApplication.created_at);
  const appType = visaInfoTransKeys.type ? t("types." + visaInfoTransKeys.type) : "";
  const visaName = visaInfoTransKeys.visa ? t("kinds." + visaInfoTransKeys.visa) : "";

  return (
    <AppCardContainer 
      selected={selected}
      onClick={() => onClick?.()} 
      clickable={!!onClick}
      {...props}
    >
      <LocalRow>
        <CategoryHeading style={{ margin: 0 }}>
          {appType}
        </CategoryHeading>
        <AppStatusLabel 
          status={lastSubmission?.app_status ?? VisaApplicationStatus.Working}
        />
      </LocalRow>
      <Row>
        <Text>{visaName}</Text>
      </Row>
      <Row style={{ flexDirection: "row-reverse" }}>
        <SmallText>{createdAt}</SmallText>
      </Row>
    </AppCardContainer>
  );
};

export default ApplicationCard;
