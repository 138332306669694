import { HTMLAttributes } from 'react';
import styled from 'styled-components';

interface ErrorMessageProps extends HTMLAttributes<HTMLDivElement> {
};

const ErrorMessage = styled.div<ErrorMessageProps>`
  width: 100%;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 17px;
  color: #E93232;
`;

export default ErrorMessage;