import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { SectionHeading } from "../../previewBodyCommonStyle";
import PreviewList from "../../../PreviewList";
import { FormR } from "../../../../../../types/visa/uncommonFormParts/formR/data";
import { convert10KValToFullAmount, getLabelFromMunicipalityInJapanese, getLabelFromPrefectureInJapanese, parseDateWithJaUnits } from "../../../../../../utils/visaFormDataHelpers/commonVisaFormDataHelper";
import { RelationshipInJapanese } from "../../../../../../types/visa/relationship";

interface SupporterSectionProps {
  data: FormR;
}

export const SupporterSection: FunctionComponent<SupporterSectionProps> = ({
  data
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "supporterSection" });
  const { t: tFor } = useTranslation("translation", { keyPrefix: "supporterSection.foreignerToBeSupported" });
  const { t: tSup } = useTranslation("translation", { keyPrefix: "supporterSection.supporter" });
  const { t: tCommon } = useTranslation("translation", { keyPrefix: "common" });
  const { t: tWithoutPrefix } = useTranslation();

  return (
    <section>
      <SectionHeading>{t("title")}</SectionHeading>

      <PreviewList
        items={[
          {
            label: tFor("familyName"),
            value: data["applicant_family_name"]
          },
          {
            label: tFor("givenName"),
            value: data["applicant_given_name"]
          },
          {
            label: tFor("middleName"),
            value: data["applicant_middle_name"]
          },
          {
            label: tFor("residenceCardNumber"),
            value: data["applicant_residence_card_number"]
          },
          {
            label: tSup("nameOfSupporter"),
            value: data["supporter_name"]
          },
          {
            label: tSup("birthdate"),
            value: 
              parseDateWithJaUnits(
                data["supporter_date_of_birth"],
                '/'
              ).yearMonthDay
          },
          {
            label: tSup("residenceCardNumber"),
            value: data["supporter_residence_card_number"]
          },
          {
            label: tSup("currentStatusOfResidence"),
            value: data["supporter_status_of_residence"]
          },
          {
            label: tSup("periodOfStay"),
            value: data["supporter_period_of_stay"]
          },
          {
            label: tSup("dateOfExpiration"),
            value: 
              parseDateWithJaUnits(
                data["supporter_date_of_expiration"],
                '/'
              ).yearMonthDay
          },
          {
            label: tSup("relationshipWithApplicant"),
            value: data["supporter_relationship"]
          },
          {
            label: tSup("relationshipWithApplicantOther"),
            value: data["supporter_relationship_others"],
            skip: data["supporter_relationship"] !== RelationshipInJapanese.Others
          },
          {
            label: tSup("corporationName"),
            value: data["supporter_place_of_employment"]
          },
          {
            label: tSup("corporationNumber"),
            value: data["supporter_corporation_no"]
          },
          {
            label: tSup("nameOfBranch"),
            value: data["supporter_name_of_branch"]
          },
          {
            label: tCommon("prefecture"),
            value: tWithoutPrefix(
              getLabelFromPrefectureInJapanese(
                data["supporter_employment_prefecture"]
              )
            )
          },
          {
            label: tCommon("municipality"),
            value: tWithoutPrefix(
              getLabelFromMunicipalityInJapanese(
                data["supporter_employment_prefecture"],
                data["supporter_employment_municipality"]
              )
            )
          },
          {
            label: tCommon("townStreetApartment"),
            value: data["supporter_employment_street"]
          },
          {
            label: tSup("telephoneNumber"),
            value: data["supporter_employment_telephone"]
          },
          {
            label: tSup("annualIncome"),
            value: convert10KValToFullAmount(data["supporter_annual_income"], true)
          }

        ]}
      />
      
    
    </section>
  );
};

export default SupporterSection;
