import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import DateSelectField from '../../../components/compound/DateSelectField';
import FieldGroup from '../../../components/layout/FieldGroup';
import MultiTextField from '../../../components/compound/MultiTextField';
import RadioField from '../../../components/compound/RadioField';
import SelectField from '../../../components/compound/SelectField';
import TextField from '../../../components/compound/TextField';
import Section from '../../../components/layout/Section';
import {
    MUNICIPALITY_OPTIONS,
    FOREIGNER_NATIONALITY_OPTIONS,
    PREFECTURE_OPTIONS
} from '../../../constants/options';
import { useFieldInputNotes, useFieldPlaceholder, useValidation } from '../../../hooks';
import { ExtensionBySelfData } from '../../../types/visa/extensionBySelf/data';
import { ChangeBySelfData } from '../../../types/visa/changeBySelf/data';
import { isChangeBySelfData, isThisVisaChange, isThisVisaExtension } from '../../../utils/visaApplicationHelper';
import { CrimePunishmentStatus, Gender, MaritalStatus } from '../../../types/visa/formCommonValues';
import { formatDate, isNoFieldError, parseName } from '../../../utils/visaFormDataHelpers/commonVisaFormDataHelper';
import { ApplicationFormSectionProps } from '../type';
import { useSectionErrorHandler } from '../hook';
import { FormR } from '../../../types/visa/uncommonFormParts/formR/data';
import { getLastDayWithYearOffset } from '../../../utils/utils';
import Text from '../../../components/text/Text';

interface BasicInformationSectionProps extends ApplicationFormSectionProps {
  extensionBySelfData: ExtensionBySelfData | null;
  changeBySelfData: ChangeBySelfData | null;
  onChangeExtensionBySelfData: (data: Partial<ExtensionBySelfData>) => void;
  onChangeChangeBySelfData: (data: Partial<ChangeBySelfData>) => void;

  //In Dependent visa, we need some values used in this section
  onChangeFormR?: (data: Partial<FormR>) => void;
}

const BasicInformationSection: FunctionComponent<BasicInformationSectionProps> = ({
  isActive,
  readOnly,
  visaApplicationType,
  extensionBySelfData,
  changeBySelfData,
  onChangeExtensionBySelfData,
  onChangeChangeBySelfData,
  onChangeFormR,
  onChangeSectionReadiness,
  showEmptyRequiredValueError,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'basicInformationSection' });
  const { t : tWithoutPrefix } = useTranslation();
  const { inputNotes, conditionalInputNotes } = useFieldInputNotes();
  const placeholder = useFieldPlaceholder();
  const validation = useValidation();
  const [familyName, setFamilyName] = useState('');
  const [familyNameError, setFamilyNameError] = useState('');
  const [givenName, setGivenName] = useState('');
  const [givenNameError, setGivenNameError] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [middleNameError, setMiddleNameError] = useState('');
  const [nationality, setNationality] = useState('');
  const [birthplace, setBirthplace] = useState('');
  const [birthplaceError, setBirthplaceError] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [gender, setGender] = useState('');
  const [maritalStatus, setMaritalStatus] = useState('');
  const [occupation, setOccupation] = useState('');
  const [occupationError, setOccupationError] = useState('');
  const [residenceInHomeCountry, setResidenceInHomeCountry] = useState('');
  const [residenceInHomeCountryError, setResidenceInHomeCountryError] = useState('');
  const [prefecture, setPrefecture] = useState('');
  const [municipality, setMunicipality] = useState('');
  const [townStreetApartment, setTownStreetApartment] = useState('');
  const [townStreetApartmentError, setTownStreetApartmentError] = useState('');
  const [cellphoneNumber, setCellphoneNumber] = useState('');
  const [cellphoneNumberError, setCellphoneNumberError] = useState('');
  const [telephoneNumber, setTelephoneNumber] = useState('');
  const [telephoneNumberError, setTelephoneNumberError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passportNumber, setPassportNumber] = useState('');
  const [passportNumberError, setPassportNumberError] = useState('');
  const [passportExpirationDate, setPassportExpirationDate] = useState('');
  const [residentTaxPaid, setResidentTaxPaid] = useState('');
  const [residentTaxPaidError, setResidentTaxPaidError] = useState('');
  const [criminalRecord, setCriminalRecord] = useState('');
  const [criminalRecordDetails, setCriminalRecordDetails] = useState('');
  const [criminalRecordDetailsError, setCriminalRecordDetailsError] = useState('');
  const NAME_SEPARATOR = ',';
  const fullName = [familyName, givenName, middleName].filter(Boolean).join(NAME_SEPARATOR);
  const isVisaExtension = isThisVisaExtension(visaApplicationType);
  const isVisaChange = isThisVisaChange(visaApplicationType);
  const { createGetEmptyFieldError } = useSectionErrorHandler();
  const getEmptyError = createGetEmptyFieldError({ additionalCondition: showEmptyRequiredValueError });

  //Currently, the item names for this section are common between visa extension and change,
  //so using the same function.
  const saveData = (data: Partial<ExtensionBySelfData | ChangeBySelfData>) => {
    isVisaExtension && onChangeExtensionBySelfData(data);
    isVisaChange && onChangeChangeBySelfData(data);
  }

  useEffect(() => {
    const data = extensionBySelfData || changeBySelfData;

    if (!data)
      return;

    if (isNoFieldError([familyNameError, givenNameError, middleNameError])) {
      const { familyName, givenName, middleName } = parseName(data['WCIBS010Dto:txtName'], NAME_SEPARATOR);
      setFamilyName(familyName);
      setGivenName(givenName);
      setMiddleName(middleName);
    }

    setNationality(data['WCIBS010Dto:selNationalityAndRegion'] || '');

    //Unique field for visa change
    if (isVisaChange && isChangeBySelfData(data) && isNoFieldError(birthplaceError))
      setBirthplace(data['WCICS020Dto:txtSyst'] || '');

    setBirthdate(
      formatDate(
        data['WCIBS010Dto:selDateOfBirthYear'],
        data['WCIBS010Dto:selDateOfBirthMonth'],
        data['WCIBS010Dto:selDateOfBirthDay'],
        '-'
      )
    );
    setGender(data['WCIBS010Dto:radSex'] || '');
    setMaritalStatus(data['WCIBS010Dto:radSpouse'] || '');

    if (isNoFieldError(occupationError))
      setOccupation(data['WCIBS010Dto:txtJob'] || '');

    if (isNoFieldError(residenceInHomeCountryError))
      setResidenceInHomeCountry(data['WCIBS010Dto:txtHomeCountryAddress'] || '');

    if (data['WCIBS010Dto:hdnSearchedAddress']) {
      setPrefecture(data['WCIBS010Dto:hdnSearchedAddress'].slice(0, 2));
      setMunicipality(data['WCIBS010Dto:hdnSearchedAddress'].slice(2));
    }

    if (isNoFieldError(townStreetApartmentError))
      setTownStreetApartment(data['WCIBS010Dto:txtDetailHomeAddress'] || '');

    if (isNoFieldError(cellphoneNumberError))
      setCellphoneNumber(data['WCIBS010Dto:txtCelPhoneNum'] || '');

    if (isNoFieldError(telephoneNumberError))
      setTelephoneNumber(data['WCIBS010Dto:txtTelNum'] || '');

    if (isNoFieldError(emailError))
      setEmail(data['WCIBS010Dto:txtMailAddress'] || '');

    if (isNoFieldError(passportNumberError))
      setPassportNumber(data['WCIBS010Dto:txtPassportNum'] || '');

    setPassportExpirationDate(
      formatDate(
        data['WCIBS010Dto:selPassportExpirationYear'],
        data['WCIBS010Dto:selPassportExpirationMonth'],
        data['WCIBS010Dto:selPassportExpirationDay'],
        '-'
      )
    )

    if (isNoFieldError(residentTaxPaidError))
      setResidentTaxPaid(data['WCIBS010Dto:txtTaxPay'] || '');

    setCriminalRecord(data['WCIBS010Dto:radHnziUm1'] || '');

    if (isNoFieldError(criminalRecordDetailsError))
      setCriminalRecordDetails(data['WCIBS010Dto:txtHnziNiyu'] || '');

  }, [extensionBySelfData, changeBySelfData]);

  useEffect(() => {
    const areAllRequiredFiedsFilled = [
      familyName,
      givenName,
      gender,
      birthdate,
      nationality,
      maritalStatus,
      occupation,
      residenceInHomeCountry,
      prefecture,
      municipality,
      townStreetApartment,
      cellphoneNumber,
      telephoneNumber,
      email,
      passportNumber,
      passportExpirationDate,
      residentTaxPaid,
      criminalRecord,
    ].every(val => !!val);

    const areThereNoErrors = [
      familyNameError,
      givenNameError,
      middleNameError,
      occupationError,
      residenceInHomeCountryError,
      townStreetApartmentError,
      cellphoneNumberError,
      telephoneNumberError,
      emailError,
      passportNumberError,
      residentTaxPaidError,
      criminalRecordDetailsError
    ].every(val => !val);

    onChangeSectionReadiness(
      areAllRequiredFiedsFilled && areThereNoErrors
    );
  }, [
    familyName,
    familyNameError,
    givenName,
    givenNameError,
    middleName,
    middleNameError,
    gender,
    birthdate,
    nationality,
    maritalStatus,
    occupation,
    occupationError,
    residenceInHomeCountry,
    residenceInHomeCountryError,
    prefecture,
    municipality,
    townStreetApartment,
    townStreetApartmentError,
    cellphoneNumber,
    cellphoneNumberError,
    telephoneNumber,
    telephoneNumberError,
    email,
    emailError,
    passportNumber,
    passportNumberError,
    passportExpirationDate,
    residentTaxPaid,
    residentTaxPaidError,
    criminalRecord,
    criminalRecordDetails,
    criminalRecordDetailsError
  ])

  if (isActive === false)
    return null;

  return (
    <Section>
      {/* Family Name */}
      <MultiTextField
        compact
        required
        disabled={readOnly}
        label={t('familyName')}
        placeholder="Nguyen"
        value={familyName}
        error={familyNameError || getEmptyError(familyName)}
        restriction={conditionalInputNotes.halfwidth}
        maxLength={34}
        validators={[validation.isAlpha, validation.createLengthValidator(34)]}
        onValueChange={setFamilyName}
        onErrorChange={setFamilyNameError}
        onBlur={() => {
          if ([familyNameError, givenNameError, middleNameError].every(err => !err))
            saveData({'WCIBS010Dto:txtName': fullName})

          onChangeFormR?.({
            applicant_family_name: familyName
          })
        }}
      />

      {/* Given Name */}
      <MultiTextField
        compact
        required
        disabled={readOnly}
        label={t("givenName")}
        placeholder="Nam Van"
        value={givenName}
        error={givenNameError || getEmptyError(givenName)}
        restriction={conditionalInputNotes.halfwidth}
        maxLength={34}
        validators={[validation.isAlpha, validation.createLengthValidator(34)]}
        onValueChange={setGivenName}
        onErrorChange={setGivenNameError}
        onBlur={() => {
          if ([familyNameError, givenNameError, middleNameError].every(err => !err))
            saveData({'WCIBS010Dto:txtName': fullName})

          onChangeFormR?.({
            applicant_given_name: givenName
          })
        }}
      />

      {/* Middle Name */}
      <MultiTextField
        compact
        disabled={readOnly}
        label={t("middleName")}
        placeholder="Nguyen"
        value={middleName}
        error={middleNameError}
        restriction={conditionalInputNotes.halfwidth}
        maxLength={34}
        validators={[validation.isAlpha, validation.createLengthValidator(34)]}
        onValueChange={setMiddleName}
        onErrorChange={setMiddleNameError}
        onBlur={() => {
          if ([familyNameError, givenNameError, middleNameError].every(err => !err))
            saveData({'WCIBS010Dto:txtName': fullName})

          onChangeFormR?.({
            applicant_middle_name: middleName
          })
        }}
      />

      {/* Gender */}
      <RadioField
        required
        disabled={readOnly}
        label={t('gender')}
        options={[
          {
            label: t('male'),
            value: Gender.Male,
          },
          {
            label: t('female'),
            value: Gender.Female,
          },
        ]}
        value={gender}
        error={getEmptyError(gender)}
        onValueChange={val => {
          setGender(val);
          saveData({'WCIBS010Dto:radSex': val});
        }}
      />

      {/* Birth date */}
      <DateSelectField
        required
        disabled={readOnly}
        label={t("birthdate")}
        minDate={new Date(1930, 0, 1)}
        maxDate={new Date()}
        value={birthdate}
        error={getEmptyError(birthdate)}
        onValueChange={val => {
          setBirthdate(val);

          if (val) {
            const [year, month, day] = val.split('-');
            saveData({
              'WCIBS010Dto:selDateOfBirthYear': year,
              'WCIBS010Dto:selDateOfBirthMonth': month,
              'WCIBS010Dto:selDateOfBirthDay': day,
            });
          }
        }}
      />

      {/* Nationality */}
      <SelectField
        required
        disabled={readOnly}
        label={t('nationality')}
        placeholder={placeholder.selectNationality}
        options={FOREIGNER_NATIONALITY_OPTIONS}
        value={nationality}
        error={getEmptyError(nationality)}
        onValueChange={val => {
          setNationality(val);
          saveData({'WCIBS010Dto:selNationalityAndRegion': val});
        }}
      />

      {/* Birthplace (*This field is Visa change only) */}
      {isVisaChange && (
        <MultiTextField
          compact
          required
          disabled={readOnly}
          label={t('birthplace')}
          placeholder="Ho Chi Minh"
          value={birthplace}
          error={birthplaceError || getEmptyError(birthplace)}
          maxLength={40}
          validators={[validation.createLengthValidator(40)]}
          onValueChange={setBirthplace}
          onErrorChange={setBirthplaceError}
          onBlur={() => {
            saveData({'WCICS020Dto:txtSyst': birthplace});
          }}
        />
      )}


      {/* Marital Status */}
      <RadioField
        required
        disabled={readOnly}
        label={t('maritalStatus')}
        options={[
          {
            label: t('married'),
            value: MaritalStatus.Married,
          },
          {
            label: t('single'),
            value: MaritalStatus.Single,
          },
        ]}
        value={maritalStatus}
        error={getEmptyError(maritalStatus)}
        onValueChange={ val => {
          setMaritalStatus(val);
          saveData({'WCIBS010Dto:radSpouse': val});
        }}
      />

      {/* Occupation */}
      <MultiTextField
        compact
        required
        disabled={readOnly}
        label={t("occupation")}
        placeholder="なし"
        value={occupation}
        error={occupationError || getEmptyError(occupation)}
        maxLength={40}
        validators={[validation.createLengthValidator(40)]}
        onValueChange={setOccupation}
        onErrorChange={setOccupationError}
        onBlur={() => {
          saveData({'WCIBS010Dto:txtJob': occupation})
        }}
      />

      {/* Home Country Residence */}
      <MultiTextField
        required
        disabled={readOnly}
        label={t('residenceInHomeCountry')}
        placeholder="ベトナム国ハノイ市バーディン区ゴックカイン街区キンマー通り９９９番"
        value={residenceInHomeCountry}
        error={residenceInHomeCountryError || getEmptyError(residenceInHomeCountry)}
        restriction={inputNotes.fullwidthJapanese}
        maxLength={80}
        validators={[validation.isFullwidth, validation.createLengthValidator(80)]}
        onValueChange={setResidenceInHomeCountry}
        onErrorChange={setResidenceInHomeCountryError}
        onBlur={() => {
          saveData({'WCIBS010Dto:txtHomeCountryAddress': residenceInHomeCountry})
        }}
      />

      {/* Current Residence */}
      <FieldGroup title={t('currentResidence')}>

        {/* Prefecture */}
        <SelectField
          required
          disabled={readOnly}
          label={t('prefecture')}
          placeholder={placeholder.selectPrefecture}
          options={PREFECTURE_OPTIONS}
          value={prefecture}
          error={getEmptyError(prefecture)}
          onValueChange={val => {
            setPrefecture(val);

            // Reset municipality as the prefecture has changed
            setMunicipality('');
          }}
        />

        {/* Municipality */}
        <SelectField
          disabled={!prefecture || readOnly}
          required
          label={t('municipality')}
          placeholder={placeholder.selectMunicipality}
          options={MUNICIPALITY_OPTIONS[prefecture] ?? []}
          value={municipality}
          error={prefecture
            ? getEmptyError(municipality)
            : t('enterPrefectureFirst')
          }
          onValueChange={val => {
            setMunicipality(val);
            saveData({
              'WCIBS010Dto:hdnSearchedAddress': `${prefecture}${val}`,

              //"Which office to pick up at" in Details of Change/Extension section
              //This value depends on the selected municipality
              'WCIBS010Dto:selJryuKnsh': null
            })
          }}
        />

        {/* Town/Street/Apartment */}
        <MultiTextField
          required
          disabled={readOnly}
          label={t('townStreetApartment')}
          placeholder="駅東１－４－３ライオンズアパート１０５号室"
          value={townStreetApartment}
          error={townStreetApartmentError || getEmptyError(townStreetApartment)}
          restriction={inputNotes.fullwidthJapanese}
          maxLength={80}
          validators={[validation.isFullwidth, validation.createLengthValidator(80)]}
          onValueChange={setTownStreetApartment}
          onErrorChange={setTownStreetApartmentError}
          onBlur={() => {
            saveData({'WCIBS010Dto:txtDetailHomeAddress': townStreetApartment})
          }}
        />
      </FieldGroup>

      {/* Cellphone number */}
      <TextField
        required
        disabled={readOnly}
        label={t('cellphoneNumber')}
        placeholder="09012345678"
        value={cellphoneNumber}
        error={cellphoneNumberError || getEmptyError(cellphoneNumber)}
        restriction={inputNotes.halfwidthNumberNoHyphens}
        maxLength={12}
        validators={[validation.isNumeric, validation.createLengthValidator(12)]}
        onValueChange={setCellphoneNumber}
        onErrorChange={setCellphoneNumberError}
        onBlur={() => {
          saveData({'WCIBS010Dto:txtCelPhoneNum': cellphoneNumber})
        }}
      />

      {/* Telephone number */}
      <TextField
        required
        disabled={readOnly}
        label={t('telephoneNumber')}
        placeholder="0312345678"
        value={telephoneNumber}
        error={telephoneNumberError || getEmptyError(telephoneNumber)}
        restriction={inputNotes.halfwidthNumberNoHyphens}
        maxLength={12}
        validators={[validation.isNumeric, validation.createLengthValidator(12)]}
        onValueChange={setTelephoneNumber}
        onErrorChange={setTelephoneNumberError}
        onBlur={() => {
          saveData({'WCIBS010Dto:txtTelNum': telephoneNumber})
        }}
      />

      {/* Email */}
      <MultiTextField
        compact
        required
        disabled={readOnly}
        label={t('email')}
        placeholder="namnguyen1234@gmail.com"
        value={email}
        error={emailError || getEmptyError(email)}
        restriction={conditionalInputNotes.halfwidthLetterAndNumber}
        maxLength={60}
        validators={[validation.isEmailAddress, validation.createLengthValidator(60)]}
        onValueChange={setEmail}
        onErrorChange={setEmailError}
        onBlur={() => {
          saveData({'WCIBS010Dto:txtMailAddress': email})
        }}
      />

      {/* Passport number */}
      <TextField
        required
        disabled={readOnly}
        label={t('passportNumber')}
        placeholder="GA1234567"
        value={passportNumber}
        error={passportNumberError || getEmptyError(passportNumber)}
        restriction={conditionalInputNotes.halfwidthLetterAndNumber}
        maxLength={12}
        validators={[validation.isAlphanumeric, validation.createLengthValidator(12)]}
        onValueChange={setPassportNumber}
        onErrorChange={setPassportNumberError}
        onBlur={() => {
          saveData({'WCIBS010Dto:txtPassportNum': passportNumber})
        }}
      />

      {/* Passport expiration date */}
      <DateSelectField
        required
        disabled={readOnly}
        label={t('passportExpirationDate')}
        value={passportExpirationDate}
        error={getEmptyError(passportExpirationDate)}
        minDate={new Date()}
        maxDate={getLastDayWithYearOffset(10)}
        onValueChange={val => {
          setPassportExpirationDate(val)

          if (val) {
            const [year, month, day] = val.split('-');
            saveData({
              'WCIBS010Dto:selPassportExpirationYear': year,
              'WCIBS010Dto:selPassportExpirationMonth': month,
              'WCIBS010Dto:selPassportExpirationDay': day,
            })
          }
        }}
      />

      {/* Amount of resident tax paid in most recent year (Yen) */}
      <TextField
        required
        disabled={readOnly}
        label={t('amountOfResidentTaxPaid')}
        placeholder="20000"
        value={residentTaxPaid}
        error={residentTaxPaidError || getEmptyError(residentTaxPaid)}
        restriction={conditionalInputNotes.halfwidthNumber}
        maxLength={5}
        validators={[validation.isNumeric, validation.createLengthValidator(5)]}
        onValueChange={setResidentTaxPaid}
        onErrorChange={setResidentTaxPaidError}
        onBlur={() => {
          saveData({'WCIBS010Dto:txtTaxPay': residentTaxPaid})
        }}
        suffixComponent={
          <Text><strong>,000</strong></Text>
        }
        note={t('amountOfResidentTaxPaidNote')}
      />

      {/* Criminal record */}
      <RadioField
        required
        disabled={readOnly}
        label={t('criminalRecordLabel')}
        note={t('criminalRecordNote')}
        options={[
          {
            label: tWithoutPrefix('common.yes'),
            value: CrimePunishmentStatus.Punished,
          },
          {
            label: tWithoutPrefix('common.no'),
            value: CrimePunishmentStatus.None,
          },
        ]}
        value={criminalRecord}
        error={getEmptyError(criminalRecord)}
        onValueChange={val => {
          setCriminalRecord(val);

          if (val === CrimePunishmentStatus.Punished)
            saveData({'WCIBS010Dto:radHnziUm1': val})

          if (val === CrimePunishmentStatus.None) {
            saveData({
              'WCIBS010Dto:radHnziUm1': val,
              'WCIBS010Dto:txtHnziNiyu': null,
            })
          }
        }}
      />

      {/* Criminal record details */}
      {criminalRecord === CrimePunishmentStatus.Punished && (
        <MultiTextField
          disabled={readOnly}
          label={t('criminalRecordDetails')}
          value={criminalRecordDetails}
          maxLength={40}
          onValueChange={setCriminalRecordDetails}
          onErrorChange={setCriminalRecordDetailsError}
          onBlur={() => {
            saveData({'WCIBS010Dto:txtHnziNiyu': criminalRecordDetails})
          }}
        />
      )}
    </Section>
  );
};

export default BasicInformationSection;
