import { use } from "i18next";
import { ServerOnlyUserData, User } from "../types/user/data";
import { axiosInstance } from "./common";

export const getCurrentUser = async () => {
  const response = await axiosInstance.request({
      method: 'GET',
      url: 'users/current'
  });
  
  return response.data as User;
}

export const updateCurrentUser = async (data: Partial<User & ServerOnlyUserData>) => {
  const response = await axiosInstance.request({
      method: 'PATCH',
      url: `users/current`,
      data
  });
  
  return response.data as User;
}

export const requestPasswordReset = async (email: string) => {
  const response = await axiosInstance.request({
      method: 'POST',
      url: 'users/password',
      data: { 
        user: {
          email
        }
      }
  });

  return response.data as User;
}

export const resetPassword = async (password: string, resetPasswordToken: string) => {
  const response = await axiosInstance.request({
      method: 'PUT',
      url: 'users/password',
      data: { 
        user: {
          password, 
          reset_password_token: resetPasswordToken 
        }
      }
  });

  return response.data as User;
}