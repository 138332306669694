import { FocusEvent, FunctionComponent, InputHTMLAttributes, useEffect } from 'react';
import styled from 'styled-components';

import ErrorMessage from '../display/ErrorMessage';
import Restriction from '../display/Restriction';
import InlineMessage from '../form/InlineMessage';
import Label from '../form/Label';
import { ValidationFunction } from '../../types/validation';
import MultiTextInput from '../form/MultiTextInput';

interface MultiTextFieldProps extends InputHTMLAttributes<HTMLTextAreaElement> {
    disabled?: boolean;
    required?: boolean;
    compact?: boolean;
    large?: boolean;
    label?: string;
    placeholder?: string;
    value?: string;
    error?: string;
    restriction?: string;
    maxLength?: number;
    note?: string;
    validators?: ValidationFunction[];
    onValueChange?: (value: string) => void;
    onErrorChange?: (error: string) => void;
}

interface LocalMultiTextInputProps {
    compact?: boolean;
    large?: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const LocalMultiTextInput = styled(MultiTextInput)<LocalMultiTextInputProps>`
  height: 85px;
  height: ${({ compact, large }) => {
    if (compact) return '40px';
    if (large) return '200px';
    return null;
  }};
`;

const MultiTextField: FunctionComponent<MultiTextFieldProps> = ({
    disabled,
    required,
    compact,
    large,
    label,
    placeholder,
    value = '',
    error,
    restriction,
    maxLength = 0,
    note,
    validators,
    onValueChange,
    onErrorChange,
    ...props
}) => {
    const isOverMaxLength = maxLength > 0 && value.length > maxLength;
    const handleBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
        const value = event.target.value;

        if (value === '') {
            onErrorChange?.('');
            props.onBlur?.(event);
            return
        } 
        
        if (validators) {
            for (const validator of validators) {
                const result = validator(value);
                if (!result.success) {
                    onErrorChange?.(result.message);
                    return;
                }
            }
        }
        props.onBlur?.(event);
    };

    const handleValueChange = (value: string) => {
        onValueChange?.(value);
        onErrorChange?.('');
    };

    
    useEffect(() => {
        if (!isOverMaxLength && 
            (!validators || validators?.every(validator => validator(value).success))) 
            onErrorChange?.('');
    }, [value])


    return (
        <Container>
            {label && (
                <Label required={required}>
                    {label}
                </Label>
            )}
            <LocalMultiTextInput
                {...props}
                compact={compact}
                large={large}
                disabled={disabled}
                invalid={!!error || (maxLength > 0 && value.length > maxLength)}
                placeholder={placeholder}
                value={value}
                onBlur={handleBlur}
                onValueChange={handleValueChange}
            />
            {error && (
                <ErrorMessage>
                    {error}
                </ErrorMessage>
            )}
            {(restriction || maxLength) && (
                <Restriction
                    content={restriction}
                    currentLength={value?.length}
                    maxLength={maxLength}
                />
            )}
            {note && (
                <InlineMessage variant='info' size="small">
                    {note}
                </InlineMessage>
            )}
        </Container>
    );
};

export default MultiTextField;