import { FunctionComponent, HTMLAttributes } from "react";
import styled from "styled-components";
import { VisaApplicationStatus } from "../../types/visa/applicationStatus";
import { useTranslation } from "react-i18next";

type AppStatusLabelProps = HTMLAttributes<HTMLSpanElement> & {
  status: VisaApplicationStatus;
};

const StatusLabel = styled.div<AppStatusLabelProps & { language: string }>`
  font-size: 0.688rem;
  font-weight: 500;
  border-radius: 100px;
  height: 20px;
  width: ${({ language }) => (language === "ja" ? "54px" : "65px")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  color: ${({ status }) => {
    switch (status) {
      case VisaApplicationStatus.Working:
        return "444444";
      case VisaApplicationStatus.Applying:
        return "#227AFF";
      case VisaApplicationStatus.Approved:
        return "#1FA44F";
      case VisaApplicationStatus.Denied:
        return "#E93232";
      case VisaApplicationStatus.Insufficient:
        return "#928300";
    }
  }};
  background-color: ${({ status }) => {
    switch (status) {
      case VisaApplicationStatus.Working:
        return "#E5E5E5";
      case VisaApplicationStatus.Applying:
        return "#D9E8FF";
      case VisaApplicationStatus.Approved:
        return "#DBF0E3";
      case VisaApplicationStatus.Denied:
        return "#FFF0F0";
      case VisaApplicationStatus.Insufficient:
        return "#FFF3C9";
    }
  }};
`;

//See the design here:
//https://www.figma.com/file/3ZOB9niXv03FyAQ29Zw848/Visadas?type=design&node-id=14-3795&mode=design&t=MPAeQCh9uqCY83VN-4
const AppStatusLabel: FunctionComponent<AppStatusLabelProps> = ({
  status,
  ...props
}) => {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'appStatus' });
  const statusText = (() => {
    switch (status) {
      case VisaApplicationStatus.Working:
        return t("working");
      case VisaApplicationStatus.Applying:
        return t("applying");
      case VisaApplicationStatus.Approved:
        return t("approved");
      case VisaApplicationStatus.Denied:
        return t("denied");
      case VisaApplicationStatus.Insufficient:
        return t("insufficient");
    }
  })();

  return (
    <StatusLabel status={status} language={i18n.language} {...props}>
      {statusText}
    </StatusLabel>
  );
};

export default AppStatusLabel;
