import { FunctionComponent } from "react";
import { ExtensionBySelfData } from "../../../../../../types/visa/extensionBySelf/data";
import { ChangeBySelfData } from "../../../../../../types/visa/changeBySelf/data";
import { useTranslation } from "react-i18next";
import { SectionHeading } from "../../previewBodyCommonStyle";
import { VisaApplicationType } from "../../../../../../types/visa/applicationType";
import {
  isChangeBySelfData,
  isExtensionBySelfData,
  isThisVisaChange,
  isThisVisaExtension,
} from "../../../../../../utils/visaApplicationHelper";
import PreviewList from "../../../PreviewList";

interface OtherSectionProps {
  visaApplicationType: VisaApplicationType;
  data: ExtensionBySelfData | ChangeBySelfData;
}

export const OtherSection: FunctionComponent<OtherSectionProps> = ({
  visaApplicationType,
  data,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "otherSection" });
  const isVisaExtension = isThisVisaExtension(visaApplicationType);
  const isVisaChange = isThisVisaChange(visaApplicationType);

  return (
    <section>
      <SectionHeading>{t("title")}</SectionHeading>
      <PreviewList
        items={[
          {
            label: t("other"),
            value: (() => {
              if (isVisaExtension && isExtensionBySelfData(data))
                return data["WCIBS010Dto:lblFreeInputC"];

              if (isVisaChange && isChangeBySelfData(data))
                return data["WCIBS010Dto:lblFreeInputC"];

              return "";
            })(),
          },
        ]}
      />
    </section>
  );
};

export default OtherSection;
