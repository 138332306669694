import { ChangeEvent, FunctionComponent, InputHTMLAttributes } from 'react';
import styled from 'styled-components';

import { ReactComponent as SearchIcon } from '../../assets/icon-search.svg';
import { useTranslation } from 'react-i18next';

interface SearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  onValueChange?: (value: string) => void;
}

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled.input<SearchInputProps>`
  width: 100%;
  height: 40px;
  padding: 0 40px 0 10px;
  border: 1px solid #8A8E94;
  border-radius: 5px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 1rem;
  line-height: 20px;
  color: #444444;
  background: #F5F9FF;
  outline: none;
  
  &:focus {
    border: 2px solid #1F82FF;
  }
  
  &::placeholder {
    color: #999999;
  }
`;

const IconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  width: 20px;
  height: 20px;
  margin: auto 0;
`;

const SearchInput: FunctionComponent<SearchInputProps> = ({
    onChange,
    onValueChange,
    ...props
}) => {
    const { t } = useTranslation();
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange?.(event);
        onValueChange?.(event.target.value);
    };

    return (
        <Container>
            <Input
                placeholder={t("placeholder.search")}
                onChange={handleChange}
                {...props}
            />
            <IconContainer>
                <SearchIcon />
            </IconContainer>
        </Container>
    );
};

export default SearchInput;