import {FunctionComponent, HTMLAttributes, MouseEvent, useState} from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'

import SearchInput from './SearchInput';
import { useComponentVisible } from '../../hooks'
import { ReactComponent as CloseIcon } from '../../assets/icon-close.svg';
import { generateRandomString } from '../../utils/utils';
import { SelectOption } from '../../types/option';

interface SelectProps extends HTMLAttributes<HTMLDivElement> {
    disabled?: boolean;
    invalid?: boolean;
    searchable?: boolean;
    placeholder?: string;
    options?: SelectOption[];
    value?: any;
    onValueChange?: (value: any) => void;
    error?: string;
}

interface MenuProps {
    visible?: boolean;
    searchable?: boolean;
}

const Container = styled.div<SelectProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border: 1px solid ${(props: SelectProps) => props.invalid
          ? '#E93232' 
          : '#8A8E94'
  };
  border-radius: 5px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 20px;
  color: #444444;
  background: ${(props: SelectProps) => props.disabled
          ? '#D5D5D5'
          : (props.invalid ? '#FFF0F0' : '#F5F9FF')
  };
  pointer-events: ${(props: SelectProps) => props.disabled ? 'none' : 'auto'};

  &:focus {
    border: 2px solid #1F82FF;
    background: #F5F9FF;
  }
`;

const Placeholder = styled.span`
  flex: 1;
  color: #999999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Label = styled.span`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Arrow = styled.span`
  width: 0;
  height: 0;
  margin-left: 4px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #999999;
`

const Backdrop = styled.div<MenuProps>`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
  opacity: ${(props: MenuProps) => props.visible ? 1 : 0};
  transition: 0.3s ease-in-out;
  pointer-events: ${(props: MenuProps) => props.visible ? 'auto' : 'none'};
`;

const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  background: #FFFFFF;
`;

const Menu = styled.div<MenuProps>`
  position: absolute;
  top: 50px;
  right: 0;
  bottom: 0;
  left: 0;
  padding-top: ${(props: MenuProps) => props.searchable ? 0 : 20}px;
  background: #FFFFFF;
  border-top: 1px solid #ACACAC;
  border-radius: 20px 20px 0 0;
  box-shadow: -2px -4px 6px rgba(0, 0, 0, 0.15);

  transition: 0.3s ease-in-out;
  transform: translateY(${(props: MenuProps) => props.visible ? '0' : '100%'});
`;

const SearchContainer = styled.div`
  padding: 20px;
  border-bottom: 1px solid #D5D5D5;
  border-radius: 20px 20px 0 0;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  background: #F1F4F8;
`;

const Items = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 150px;
  overflow: auto;
  
  > *:not(:first-child) {
    border-top: 1px solid #D5D5D5;
  }

  > *:last-child {
    border-bottom: 1px solid #D5D5D5;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 40px;
  padding: 10px 20px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 1rem;
  line-height: 20px;
  color: #444444;
  
  &:active {
    background: #C8E0FF;
  }
`

const Select: FunctionComponent<SelectProps> = ({
    searchable,
    placeholder,
    options,
    value,
    onValueChange,
    ...props
}) => {
    const { t } = useTranslation();
    const { ref, visible, setVisible } = useComponentVisible(false);
    const [filter, setFilter] = useState('');
    const option = options ? options.find(option => option.value === value) : null

    const handleContainerClick = () => {
        setVisible(!visible);
        setFilter('');
    };

    const handleMenuClick = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
    };

    const handleItemClick = (event: MouseEvent<HTMLDivElement>, value: any) => {
        event.stopPropagation();
        onValueChange && onValueChange(value);
        setVisible(false);
    };

    return (
        <Container
            tabIndex={0}
            onClick={handleContainerClick}
            {...props}
        >
            {option
                ? <Label>{t(option.label)}</Label>
                : (placeholder ? <Placeholder>{placeholder}</Placeholder> : <Label />)
            }
            <Arrow />
            <Backdrop
                ref={ref}
                visible={visible}
                onClick={handleContainerClick}
            >
                <CloseButton>
                    <CloseIcon />
                </CloseButton>
                <Menu
                    visible={visible}
                    searchable={searchable}
                    onClick={handleMenuClick}
                >
                    {searchable && (
                        <SearchContainer>
                            <SearchInput
                                style={{ height: 35 }}
                                value={filter}
                                onValueChange={setFilter}
                            />
                        </SearchContainer>
                    )}
                    <Items>
                        {options && options
                            .filter(option => filter 
                              ? t(option.label).toLowerCase().includes(filter.toLowerCase()) 
                              : true
                            )
                            .map(option => (
                                <Item
                                    key={generateRandomString()}
                                    onClick={(event) => handleItemClick(event, option.value)}
                                >
                                    {t(option.label)}
                                </Item>
                            ))
                        }
                    </Items>
                </Menu>
            </Backdrop>
        </Container>
    );
};

export default Select;
