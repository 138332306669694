import { FunctionComponent } from "react";
import { VisaApplicationType } from "../../../../types/visa/applicationType";
import GijinkokuVisaAttachments from "./SectionBodies/GijinkokuVisaAttachments";
import { VisaAttachment, VisaAttachmentKind } from "../../../../types/visa/attachment";
import { SupplementaryInfo } from "../../../../types/visa/supplementaryInfo";
import StudentVisaAttachments from "./SectionBodies/StudyAbroadVisaAttachments";
import { isThisVisaChange, isThisVisaExtension } from "../../../../utils/visaApplicationHelper";
import { ApplicationAttachmentSectionProps } from "../../type";

interface AttachmentsSectionProps extends ApplicationAttachmentSectionProps {
  supplementaryInfo: SupplementaryInfo;
  onChangeSupplementaryInfo: (data: Partial<SupplementaryInfo>) => void;
}

const AttachmentsSection: FunctionComponent<AttachmentsSectionProps> = ({
  visaAttachments,
  visaApplicationType,
  supplementaryInfo,
  onUploadVisaAttachment,
  onDeleteVisaAttachment,
  onChangeSupplementaryInfo,
  onChangeSectionReadiness,
  showUnattachedAlert,
}) => {
  const isVisaExtension = isThisVisaExtension(visaApplicationType);
  const isVisaChange = isThisVisaChange(visaApplicationType);

  switch (visaApplicationType) {
    case VisaApplicationType.ExtendGijinkokuVisaBySelf:
    case VisaApplicationType.ChangeToGijinkokuVisaBySelf:
      return (
        <GijinkokuVisaAttachments
          visaAttachments ={visaAttachments}
          visaApplicationType={visaApplicationType}
          onUploadVisaAttachment={onUploadVisaAttachment}
          onDeleteVisaAttachment={onDeleteVisaAttachment}
          supplementaryInfo={supplementaryInfo}
          onChangeSupplementaryInfo={onChangeSupplementaryInfo}
          onChangeSectionReadiness={onChangeSectionReadiness}
          showUnattachedAlert={showUnattachedAlert}
          isVisaExtension={isVisaExtension}
          isVisaChange={isVisaChange}
        />
      );
    case VisaApplicationType.ExtendStudyAbroadVisaBySelf:
    case VisaApplicationType.ChangeToStudyAbroadVisaBySelf:
      return (
        <StudentVisaAttachments
          visaAttachments ={visaAttachments}
          visaApplicationType={visaApplicationType}
          onUploadVisaAttachment={onUploadVisaAttachment}
          onDeleteVisaAttachment={onDeleteVisaAttachment}
          supplementaryInfo={supplementaryInfo}
          onChangeSupplementaryInfo={onChangeSupplementaryInfo}
          onChangeSectionReadiness={onChangeSectionReadiness}
          showUnattachedAlert={showUnattachedAlert}
        />
      );

    default:
      return null;
  }
};

export default AttachmentsSection;
