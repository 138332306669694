import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { LocalColumn, UserTypeBlockHeading } from "./previewBodyCommonStyle";
import { PreviewModalBodyProps } from "../../../../types/modal/preview";
import {
  isExtensionBySelfData,
  isChangeBySelfData,
  isThisVisaChange,
  isThisVisaExtension,
} from "../../../../utils/visaApplicationHelper";
import BasicInformationSection from "./previewBodySections/ApplicantBlock/BasicInformationSection";
import StatusOfResidenceSection from "./previewBodySections/ApplicantBlock/StatusOfResidenceSection";
import OtherSection from "./previewBodySections/ApplicantBlock/OtherSection";
import DetailsOfExtensionSection from "./previewBodySections/ApplicantBlock/DetailsOfExtensionSection";
import FamilyCoresidentsSection from "./previewBodySections/ApplicantBlock/FamilyCoresidentsSection";
import PlaceOfEmploymentSection from "./previewBodySections/ApplicantBlock/PlaceOfEmploymentSection";
import FinalEducationSection from "./previewBodySections/ApplicantBlock/FinalEducationSection/GijinkokuVisaFinalEducationSection";
import WorkHistorySection from "./previewBodySections/ApplicantBlock/WorkHistorySection";
import styled from "styled-components";
import OrganizationSection from "./previewBodySections/OrganizationBlock/OrganizationSection/GijinkokuVisaOrganizationSection";
import EmploymentConditionsSection from "./previewBodySections/OrganizationBlock/EmploymentConditionsSection";
import AttachmentsSection from "./previewBodySections/AttachmentsBlock/AttachmentsSection";
import DetailsOfChangeSection from "./previewBodySections/ApplicantBlock/DetailsOfChangeSection";

interface GijinkokuVisaPreviewBodyProps extends PreviewModalBodyProps {}

const ApplicantBlock = styled(LocalColumn)`
`;

const OrganizationBlock = styled(LocalColumn)`
`;

const AttachmentsBlock = styled(LocalColumn)`
`;

const GijinkokuVisaPreviewBody: FunctionComponent<GijinkokuVisaPreviewBodyProps> = ({
  visaApplication,
  visaApplicationType,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'modalPreview' });
  const isVisaExtension = isThisVisaExtension(visaApplicationType);
  const isVisaChange = isThisVisaChange(visaApplicationType);
  const commonData = isVisaExtension
    ? visaApplication.koushin_honnin
    : visaApplication.henkou_honnin;
  const uncommonData = visaApplication.form_n;

  if (!commonData || !uncommonData) return null;

  return (
    <LocalColumn>

      <ApplicantBlock>      
        <UserTypeBlockHeading>{t("blockHeading.forApplicant")}</UserTypeBlockHeading>
        <BasicInformationSection 
          data={commonData} 
          visaApplication={visaApplication}
        />

        <StatusOfResidenceSection
          data={commonData}
          visaApplicationType={visaApplicationType}
        />

        {isVisaExtension && isExtensionBySelfData(commonData) && (
          <DetailsOfExtensionSection
            data={commonData}
            visaApplicationType={visaApplicationType}
          />
        )}

        {isVisaChange && isChangeBySelfData(commonData) && (
          <DetailsOfChangeSection
            data={commonData}
            visaApplicationType={visaApplicationType}
          />
        )}

        <FamilyCoresidentsSection data={commonData} />

        <PlaceOfEmploymentSection data={uncommonData} />
      
        <FinalEducationSection data={uncommonData} />

        <WorkHistorySection data={uncommonData} />

        <OtherSection 
          data={commonData} 
          visaApplicationType={visaApplicationType}
        />
      </ApplicantBlock>

      <OrganizationBlock>
        <UserTypeBlockHeading>{t("blockHeading.forOrganization")}</UserTypeBlockHeading>
        <OrganizationSection data={uncommonData} />
        <EmploymentConditionsSection 
          data={uncommonData} 
          supplementaryInfo={visaApplication.supplementary_info}
        />
      </OrganizationBlock>
      
      <AttachmentsBlock>
        <UserTypeBlockHeading>{t("blockHeading.attachments")}</UserTypeBlockHeading>
        <AttachmentsSection 
          visaApplication={visaApplication}
          visaAttachments={visaApplication.visa_attachments} 
          visaApplicationType={visaApplicationType}
        />
      </AttachmentsBlock>
    </LocalColumn>
  );
};

export default GijinkokuVisaPreviewBody;
