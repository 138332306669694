import { SelectOption } from "../../types/option";
import { StatusOfResidenceCode_CB19, StatusOfResidenceInJapanese } from "../../types/visa/statusOfResidence";

const getStatusOfResidenceTKey = (key: string) => `statusOfResidence_CB19.${key}`;

export const CURRENT_STATUS_OF_RESIDENCE_CB19_OPTIONS: SelectOption[] = [
    {
        label: getStatusOfResidenceTKey(StatusOfResidenceCode_CB19.Dependent),
        value: StatusOfResidenceCode_CB19.Dependent
    },
    {
        label: getStatusOfResidenceTKey(StatusOfResidenceCode_CB19.SpouseOrChildOfJapaneseNational),
        value: StatusOfResidenceCode_CB19.SpouseOrChildOfJapaneseNational
    },
    {
        label: getStatusOfResidenceTKey(StatusOfResidenceCode_CB19.SpouseOrChildOfPermanentResident),
        value: StatusOfResidenceCode_CB19.SpouseOrChildOfPermanentResident
    }, 
    {
        label: getStatusOfResidenceTKey(StatusOfResidenceCode_CB19.LongTermResident),
        value: StatusOfResidenceCode_CB19.LongTermResident
    },
    {
        label: getStatusOfResidenceTKey(StatusOfResidenceCode_CB19.Student),
        value: StatusOfResidenceCode_CB19.Student
    },
    {
        label: getStatusOfResidenceTKey(StatusOfResidenceCode_CB19.Gijinkoku),
        value: StatusOfResidenceCode_CB19.Gijinkoku
    },
    {
        label: getStatusOfResidenceTKey(StatusOfResidenceCode_CB19.DesignatedActivities),
        value: StatusOfResidenceCode_CB19.DesignatedActivities
    }
]


//Since we print the raw value to the Form R form, the value must be in Japanese
export const RESIDENCE_STATUS_OF_DEPENDENT_WITH_JA_VALUE_OPTIONS: SelectOption[] = [
    {
        label: getStatusOfResidenceTKey(StatusOfResidenceCode_CB19.Professor),
        value: StatusOfResidenceInJapanese.Professor
    },
    {
        label: getStatusOfResidenceTKey(StatusOfResidenceCode_CB19.Artist),
        value: StatusOfResidenceInJapanese.Artist
    },
    {
        label: getStatusOfResidenceTKey(StatusOfResidenceCode_CB19.ReligiousActivities),
        value: StatusOfResidenceInJapanese.ReligiousActivities
    },
    {
        label: getStatusOfResidenceTKey(StatusOfResidenceCode_CB19.Journalist),
        value: StatusOfResidenceInJapanese.Journalist
    },
    {
        label: getStatusOfResidenceTKey(StatusOfResidenceCode_CB19.LegalAccountingServices),
        value: StatusOfResidenceInJapanese.LegalAccountingServices
    },
    {
        label: getStatusOfResidenceTKey(StatusOfResidenceCode_CB19.MedicalServices),
        value: StatusOfResidenceInJapanese.MedicalServices
    },
    {
        label: getStatusOfResidenceTKey(StatusOfResidenceCode_CB19.Researcher),
        value: StatusOfResidenceInJapanese.Researcher
    },
    {
        label: getStatusOfResidenceTKey(StatusOfResidenceCode_CB19.Instructor),
        value: StatusOfResidenceInJapanese.Instructor
    },
    {
        label: getStatusOfResidenceTKey(StatusOfResidenceCode_CB19.IntraCompanyTransferee),
        value: StatusOfResidenceInJapanese.IntraCompanyTransferee
    },
    {
        label: getStatusOfResidenceTKey(StatusOfResidenceCode_CB19.Entertainer),
        value: StatusOfResidenceInJapanese.Entertainer
    },
    {
        label: getStatusOfResidenceTKey(StatusOfResidenceCode_CB19.SkilledLabor),
        value: StatusOfResidenceInJapanese.SkilledLabor
    },
    {
        label: getStatusOfResidenceTKey(StatusOfResidenceCode_CB19.BusinessManager),
        value: StatusOfResidenceInJapanese.BusinessManager
    },
    {
        label: getStatusOfResidenceTKey(StatusOfResidenceCode_CB19.EngineerSpecialistInHumanitiesInternationalServices),
        value: StatusOfResidenceInJapanese.EngineerSpecialistInHumanitiesInternationalServices
    },
    {
        label: getStatusOfResidenceTKey(StatusOfResidenceCode_CB19.NursingCare),
        value: StatusOfResidenceInJapanese.NursingCare
    },
    {
        label: getStatusOfResidenceTKey(StatusOfResidenceCode_CB19.CulturalActivities),
        value: StatusOfResidenceInJapanese.CulturalActivities
    },
    {
        label: getStatusOfResidenceTKey(StatusOfResidenceCode_CB19.Student),
        value: StatusOfResidenceInJapanese.Student
    },
    {
        label: getStatusOfResidenceTKey(StatusOfResidenceCode_CB19.SpouseOrChildOfJapaneseNational),
        value: StatusOfResidenceInJapanese.SpouseOrChildOfJapaneseNational
    },
    {
        label: getStatusOfResidenceTKey(StatusOfResidenceCode_CB19.SpouseOrChildOfPermanentResident),
        value: StatusOfResidenceInJapanese.SpouseOrChildOfPermanentResident
    },
    {
        label: getStatusOfResidenceTKey(StatusOfResidenceCode_CB19.SpecifiedSkilledWorker_ii),
        value: StatusOfResidenceInJapanese.SpecifiedSkilledWorker_ii
    },
    {
        label: getStatusOfResidenceTKey(StatusOfResidenceCode_CB19.HighlySkilledProfessional_i_a),
        value: StatusOfResidenceInJapanese.HighlySkilledProfessional_i_a
    },
    {
        label: getStatusOfResidenceTKey(StatusOfResidenceCode_CB19.HighlySkilledProfessional_i_b),
        value: StatusOfResidenceInJapanese.HighlySkilledProfessional_i_b
    },
    {
        label: getStatusOfResidenceTKey(StatusOfResidenceCode_CB19.HighlySkilledProfessional_i_c),
        value: StatusOfResidenceInJapanese.HighlySkilledProfessional_i_c
    },
    {
        label: getStatusOfResidenceTKey(StatusOfResidenceCode_CB19.HighlySkilledProfessional_ii),
        value: StatusOfResidenceInJapanese.HighlySkilledProfessional_ii
    },
    {
        label: getStatusOfResidenceTKey(StatusOfResidenceCode_CB19.PermanentResident),
        value: StatusOfResidenceInJapanese.PermanentResident
    }
];