import { FunctionComponent, useEffect, useState } from 'react';
import Section from '../../../components/layout/Section';
import { useTranslation } from "react-i18next";
import { DESIRED_PERIOD_OF_STAY_C970_OPTIONS_FOR_DEPENDENT, MUNICIPALITY_OPTIONS, PREFECTURE_OPTIONS } from '../../../constants/options';
import SelectField from "../../../components/compound/SelectField";
import MultiTextField from "../../../components/compound/MultiTextField";
import RadioField from "../../../components/compound/RadioField";
import { ExtensionBySelfData } from '../../../types/visa/extensionBySelf/data';
import { VisaApplicationType } from '../../../types/visa/applicationType';
import { HowToReceiveNewResidenceCard } from '../../../types/visa/formCommonValues';
import { useFieldInputNotes, useFieldPlaceholder, useValidation } from '../../../hooks';
import { OFFICE_OPTIONS } from '../../../constants/options/office';
import { ApplicationFormSectionProps } from '../type';
import { useSectionErrorHandler } from '../hook';
import { isNoFieldError } from '../../../utils/visaFormDataHelpers/commonVisaFormDataHelper';
import { DESIRED_PERIOD_OF_STAY_C970_OPTIONS_FOR_GIJINKOKU } from '../../../constants/options/desiredPeriodOfStays/desiredPeriodOfStayForGijinkoku';

interface DetailsOfExtensionSectionProps extends ApplicationFormSectionProps {
  extensionBySelfData: ExtensionBySelfData | null;
  onChangeExtensionBySelfData: (data: Partial<ExtensionBySelfData>) => void;
}

const DetailsOfExtensionSection: FunctionComponent<DetailsOfExtensionSectionProps> = ({
  isActive,
  readOnly,
  visaApplicationType,
  extensionBySelfData,
  onChangeExtensionBySelfData,
  onChangeSectionReadiness,
  showEmptyRequiredValueError,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'detailsOfExtensionSection' });
  const { t : tWithoutPrefix } = useTranslation();
  const { inputNotes } = useFieldInputNotes();
  const validation = useValidation();
  const placeholder = useFieldPlaceholder();

  const [desiredPeriodOfStay, setDesiredPeriodOfStay] = useState('');
  const [reasonForExtension, setReasonForExtension] = useState('');
  const [reasonForExtensionError, setReasonForExtensionError] = useState('');
  const [receivingMethod, setReceivingMethod] = useState('');
  const [officeToPickUp, setOfficeToPickUp] = useState('');

  //Used to determine which office to pick up at
  const [prefecture, setPrefecture] = useState('');
  const [municipality, setMunicipality] = useState('');

  const { createGetEmptyFieldError } = useSectionErrorHandler();
  const getEmptyError = createGetEmptyFieldError({ additionalCondition: showEmptyRequiredValueError });
  const desiredPeriodOfStayOptions = (() => {
    switch (visaApplicationType) {
      case VisaApplicationType.ExtendDependentVisaBySelf:
        return DESIRED_PERIOD_OF_STAY_C970_OPTIONS_FOR_DEPENDENT;
      case VisaApplicationType.ExtendGijinkokuVisaBySelf:
        return DESIRED_PERIOD_OF_STAY_C970_OPTIONS_FOR_GIJINKOKU;
      default:
        return [];
    }
  })();

  const saveExtensionData = (data: Partial<ExtensionBySelfData>) => {
    onChangeExtensionBySelfData(data as Partial<ExtensionBySelfData>);
  }

  const isAddressInfoInsufficient = !prefecture || !municipality;


  useEffect(() => {
    if (!extensionBySelfData)
      return;

    const data = extensionBySelfData;

    if (isNoFieldError(reasonForExtensionError))
      setReasonForExtension(data['WCICS010Dto:txtKusnReason'] || '');

    setReceivingMethod(data['WCIBS010Dto:radZiryCardJryHuhu'] || '');
    setOfficeToPickUp(data['WCIBS010Dto:selJryuKnsh'] || '');

    if (data['WCIBS010Dto:hdnSearchedAddress']) {
      setPrefecture(data['WCIBS010Dto:hdnSearchedAddress'].slice(0, 2) ?? '');
      setMunicipality(data['WCIBS010Dto:hdnSearchedAddress'].slice(2) ?? '');
    }

    setDesiredPeriodOfStay(data['WCICS010Dto:selNewZiryPeriod'] ?? '');
  }, [extensionBySelfData]);


  useEffect(() => {
    const receivingOffice =
      receivingMethod === HowToReceiveNewResidenceCard.VisitImmigrationOffice
        ? [officeToPickUp]
        : [];

    const areAllRequiredFieldsFilled =
      [
        desiredPeriodOfStay,
        reasonForExtension,
        receivingMethod,
        ...receivingOffice
      ].every(val => !!val);

    const areThereNoErrors =
      [
        reasonForExtensionError
      ].every(err => !err);

    onChangeSectionReadiness(
      areAllRequiredFieldsFilled && areThereNoErrors
    );
  }, [
    desiredPeriodOfStay,
    reasonForExtension,
    reasonForExtensionError,
    receivingMethod,
    officeToPickUp
  ])

  if (isActive === false)
    return null;

  return (
    <Section>
      {/* Desired period of stay */}
      <SelectField
        required
        disabled={readOnly}
        label={t("desiredPeriodOfStay")}
        placeholder={tWithoutPrefix("placeholder.select")}
        options={desiredPeriodOfStayOptions}
        value={desiredPeriodOfStay}
        error={getEmptyError(desiredPeriodOfStay)}
        onValueChange={val => {
          setDesiredPeriodOfStay(val);
          saveExtensionData({ 'WCICS010Dto:selNewZiryPeriod': val });
        }}
      />

      {/* Reason for extension */}
      <MultiTextField
        large
        required
        disabled={readOnly}
        label={t("reasonOfExtention")}
        placeholder={"この先も引き続き日本において夫と暮らしていくため"}
        value={reasonForExtension}
        error={reasonForExtensionError || getEmptyError(reasonForExtension)}
        restriction={inputNotes.fullwidthJapanese}
        maxLength={600}
        validators={[validation.isFullwidth, validation.createLengthValidator(600)]}
        onValueChange={setReasonForExtension}
        onErrorChange={setReasonForExtensionError}
        onBlur={() => {
          saveExtensionData({ 'WCICS010Dto:txtKusnReason': reasonForExtension });
        }}
      />

      {/* How will you receive your new residence card? */}
      <RadioField
        required
        disabled={readOnly}
        label={t("recievingNewResidenceCard")}
        options={[
          {
            label: t("byMailingService"),
            value: HowToReceiveNewResidenceCard.Mail,
          },
          {
            label: t("pickUpAtImmigrationOffice"),
            value: HowToReceiveNewResidenceCard.VisitImmigrationOffice,
          },
        ]}
        value={receivingMethod}
        error={getEmptyError(receivingMethod)}
        onValueChange={val => {
          setReceivingMethod(val);

          if (val === HowToReceiveNewResidenceCard.Mail) {
            saveExtensionData({
              'WCIBS010Dto:radZiryCardJryHuhu': val,
              'WCIBS010Dto:selJryuKnsh': ''
            });
          }

          if (val === HowToReceiveNewResidenceCard.VisitImmigrationOffice) {
            saveExtensionData({ 'WCIBS010Dto:radZiryCardJryHuhu': val });
          }
        }}
      />

      {/* Which office to pick up at */}
      {receivingMethod === HowToReceiveNewResidenceCard.VisitImmigrationOffice && (
        <SelectField
          disabled={isAddressInfoInsufficient || readOnly}
          label={t("pickUpOffice")}
          placeholder={placeholder.select}
          options={
            OFFICE_OPTIONS[`${prefecture}${municipality}`]
            ?? OFFICE_OPTIONS[prefecture]
            ?? []
          }
          value={officeToPickUp}
          error={
            (isAddressInfoInsufficient && t("pleaseEnterAddressFirst")) ||
            getEmptyError(officeToPickUp)
          }
          onValueChange={val => {
            setOfficeToPickUp(val);
            saveExtensionData({ 'WCIBS010Dto:selJryuKnsh': val });
          }}
        />
      )}
    </Section>
  );
};

export default DetailsOfExtensionSection;
