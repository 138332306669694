import { FunctionComponent, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import Modal from "../../components/modal/Modal";
import Button from "../../components/form/Button";
import Text from "../../components/text/Text";
import Column from "../../components/layout/Column";
import { ReactComponent as SuccessIcon } from "../../assets/icon-check-circle-green-filled.svg";
import { CONTENT_MAX_WIDTH } from "../../constants/styles";
import { useDispatcher } from "../../hooks";
import BackButton from "../../components/navigation/BackButton";
import { VISADAS_SUPPORT_EMAIL } from "../../constants/values/commonValues";
import EmailAnchor from "../../components/text/EmailAnchor";
import { extractURLParameters } from "../../utils/utils";
import { VisaApplicationStatus } from "../../types/visa/applicationStatus";
import { fetchCurrentVisaApplicationStatus, fetchMynaURLToConfirmVisaApplicationStatus } from "../../apis/visaApplication";
import { VisaApplication } from "../../types/visa/applicationData";
import Spinner from "../../components/display/Spinner";
import { VisaStatusConfirmationModalNextAction as NextAction } from "../../types/nextActions";
import { fetchFakeVisaApplicationStatus } from "../../apis/mock";
import BottomAlignedContainer from "../../components/layout/BottomAlignedContainer";

interface VisaStatusConfirmationModalProps {
  visaApplication: VisaApplication;
  onClose: () => void;
  onStatusFetched: (appStatus: VisaApplicationStatus) => void;
}

interface OnEvent {
  clickNext: () => void;
  clickBack: () => void;
}

interface MessageContentProps {
  contentType: ContentType;
  onEvent: OnEvent;
}

enum ContentType {
  CheckResult,
  Processing,
  ResultRetrieved,
}

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  overflow: auto;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
  max-width: ${CONTENT_MAX_WIDTH}px;
  padding: 20px;
  margin: 0 auto;
`;

const IconContainer = styled.div`
  margin-bottom: 20px;
`;

const LocalColumn = styled(Column)`
  gap: 30px;
`;

const HeadingText = styled(Text)`
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 29px;
`;

const StepTextContainer = styled.div`
  width: 100%;
  padding: 10px;
  background-color: #f2f2f2;
`;

const ProcessingContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled(BottomAlignedContainer)`
  margin-top: 78px;
`;

const LocalOl = styled.ol`
  margin: 0;
  padding-left: 25px;
`;

const LocalLi = styled.li`
  &::marker {
    font-size: 0.9rem;
    color: #444444;
  }
`;

const SmallText = styled(Text)`
  white-space: "pre-wrap";
  display: inline;
  font-size: 0.8rem;
  color: #999999;
  line-height: 17px;
`;

const LocalButton = styled(Button)`
  min-width: 280px;
  @media (max-width: ${CONTENT_MAX_WIDTH}px) {
    width: 100%;
  }
`;

const MessageContent = ({ contentType, onEvent }: MessageContentProps) => {
  const { t } = useTranslation();
  const checkResult = "modalVisaStatusConfirmation.checkResult";
  const processing = "modalVisaStatusConfirmation.processing";
  const resultRetrieved = "modalVisaStatusConfirmation.resultRetrieved";

  switch (contentType) {
    //"Check result" - 結果を確認
    case ContentType.CheckResult:
      return (
        <>
          <IconContainer>
            <BackButton onClick={onEvent.clickBack} />
          </IconContainer>
          <LocalColumn>
            <HeadingText>{t(`${checkResult}.title`)}</HeadingText>
            <Column style={{ gap: 20 }}>
              <Text>{t(`${checkResult}.description`)}</Text>
              <StepTextContainer>
                <LocalOl>
                  <LocalLi>
                    <Text>{t(`${checkResult}.step1`)}</Text>
                  </LocalLi>
                  <LocalLi>
                    <Text>{t(`${checkResult}.step2`)}</Text>
                  </LocalLi>
                  <LocalLi>
                    <Text>{t(`${checkResult}.step3`)}</Text>
                  </LocalLi>
                  <LocalLi>
                    <Text>{t(`${checkResult}.step4`)}</Text>
                  </LocalLi>
                </LocalOl>
              </StepTextContainer>
            </Column>
            <SmallText>
              <Trans
                i18nKey={"common.ifYouAreHavingTrouble"}
                components={{
                  email: VISADAS_SUPPORT_EMAIL,
                  anchor: <EmailAnchor mailTo={VISADAS_SUPPORT_EMAIL}/>,
                }}
              />
            </SmallText>
          </LocalColumn>
          <ButtonContainer>
            <LocalButton variant="primary" onClick={onEvent.clickNext}>
              {t(`${checkResult}.launch`)}
            </LocalButton>
          </ButtonContainer>
        </>
      );

    //"Processing" - 確認中
    case ContentType.Processing:
      return (
        <>
          <ProcessingContainer>
            <Column style={{ width: "auto", gap: 20, alignItems: "center" }}>
              <Spinner />
              <Text>{t(`${processing}`)}</Text>
            </Column>
          </ProcessingContainer>
        </>
      );

    //"Result retrieved" - 結果を取得しました
    case ContentType.ResultRetrieved:
      return (
        <>
          <IconContainer>
            <SuccessIcon />
          </IconContainer>
          <LocalColumn>
            <HeadingText>{t(`${resultRetrieved}.title`)}</HeadingText>
            <Text>{t(`${resultRetrieved}.description`)}</Text>
            <SmallText>
              <Trans
                i18nKey={"common.ifYouAreHavingTrouble"}
                components={{
                  email: VISADAS_SUPPORT_EMAIL,
                  anchor: <EmailAnchor mailTo={VISADAS_SUPPORT_EMAIL}/>,
                }}
              />
            </SmallText>
          </LocalColumn>
          <ButtonContainer>
            <LocalButton variant="primary" onClick={onEvent.clickNext}>
              {t(`${resultRetrieved}.close`)}
            </LocalButton>
          </ButtonContainer>
        </>
      );
  }
};

const VisaStatusConfirmationModal: FunctionComponent<VisaStatusConfirmationModalProps> = ({
  visaApplication,
  onClose,
  onStatusFetched,
}) => {
  const nextActionKey = "next_action";
  const { dispatcher } = useDispatcher();
  const { t } = useTranslation();
  const [contentType, setContentType] = useState<ContentType>(
    ContentType.CheckResult
  );
  const getReturnPathWithActionParam = (action: NextAction) => {
    return `/application/${visaApplication.id}/review?${nextActionKey}=${action}`;
  };

  const onClickNext = (() => {
    switch (contentType) {
      case ContentType.CheckResult:
        return async () => {
          dispatcher.startLoading();
          try {
            const result = await fetchMynaURLToConfirmVisaApplicationStatus(
              visaApplication.id,
              getReturnPathWithActionParam(NextAction.RequestToConfirmStatus)
            );
            window.location.href = result.myna_cert_id_url;
          } catch (e) {
            dispatcher.showWarningOnSnackbar(t("snackbar.failedToProceed"));
          } finally {
            dispatcher.stopLoading();
          }
        };
      case ContentType.ResultRetrieved:
        return () => {
          onClose();
        };
      default:
        return () => {};
    }
  })();

  useEffect(() => {
    const params = extractURLParameters();

    if (!params || !(nextActionKey in params)) return;

    const nextAction = params[nextActionKey];

    switch (nextAction) {
      case NextAction.RequestToConfirmStatus:
        //Show until the result is fetched (We will have another loading screen)
        setContentType(ContentType.Processing);

        (async () => {
          try {
            const visaSubmissionId = visaApplication.last_submitted_visa_submission!.id;
            
            //Uncomment one of the following lines to use the real API or the mock API
            const result = await fetchCurrentVisaApplicationStatus(visaSubmissionId);
            //const result = await fetchFakeVisaApplicationStatus(visaSubmissionId, VisaApplicationStatus.Denied);

            onStatusFetched(result.app_status);
            setContentType(ContentType.ResultRetrieved);
          } catch (e) {
            setContentType(ContentType.CheckResult);
            dispatcher.showWarningOnSnackbar(t("snackbar.failedToProceed"));
          }
        })();
        break;
    }
  }, []);

  return (
    <Modal>
      <ContentContainer>
        <Content>
          <MessageContent
            contentType={contentType}
            onEvent={{
              clickNext: onClickNext,

              //Currently we have only one screen to go back
              clickBack: () => onClose(),
            }}
          />
        </Content>
      </ContentContainer>
    </Modal>
  );
};

export default VisaStatusConfirmationModal;
