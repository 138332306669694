import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import {
  SectionHeading,
} from "../../previewBodyCommonStyle";
import { FormN } from "../../../../../../types/visa/uncommonFormParts/formN/data";
import { 
  DoesApplicantHavePositionOrTitle, 
  START_DATE_OF_EMPLOYMENT_UNDECIDED_OTHER_VALUE, 
  ScheduledPeriodOfEmployment, 
  StartDateOfEmployment 
} from "../../../../../../types/visa/uncommonFormParts/formN/value";
import { SupplementaryInfo } from "../../../../../../types/visa/supplementaryInfo";
import { 
  getLabelFromStartDateOfEmploymentUndecided, 
  getLabelFromTimePeriodMonth, 
  getLabelFromTimePeriodYear, 
  getLabelFromTypeOfWork
} from "../../../../../../utils/visaFormDataHelpers/commonVisaFormDataHelper";
import { getLabelFromTypeOfContract, getTransKeyForHavingPositionTitle, getTransKeyForScheduledPeriodOfEmployment } from "../../../../../../utils/visaFormDataHelpers/gijinkokuVisaFormDataHelper";
import PreviewList from "../../../PreviewList";
import { formatDateString } from "../../../../../../utils/utils";

interface EmploymentConditionsSectionProps {
  data: FormN;
  supplementaryInfo: SupplementaryInfo;
}

export const EmploymentConditionsSection: FunctionComponent<EmploymentConditionsSectionProps> = ({ 
  data ,
  supplementaryInfo
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'employmentConditionsSection' });
  const { t : tWithoutPrefix } = useTranslation();

  return (
    <section>
      <SectionHeading>{t("title")}</SectionHeading>   
      <PreviewList
        items={[
          {
            label: t("nameOfForeigner"),
            value: data["name_of_foreign_national"],
          },
          {
            label: t("typeOfContract"),
            value: tWithoutPrefix(getLabelFromTypeOfContract(data["type_of_contract"])),
          },
          {
            label: t("scheduledPeriodOfEmployment"),
            value: (() => {
              switch (data["period_of_work"]) {
                case ScheduledPeriodOfEmployment.Fixed: return t("fixed");
                case ScheduledPeriodOfEmployment.NonFixed: return t("nonFixed");
              }
            })()
          },
          {
            label: t("scheduledPeriodOfEmploymentFixed"),
            value: (() => {
              const yearVal = data["period_of_work_fixed_period_year"];
              const monthVal = data["period_of_work_fixed_period_month"];

              if (!yearVal || !monthVal)
                return "";

              const yearLabel = getLabelFromTimePeriodYear(yearVal);
              const monthLabel = getLabelFromTimePeriodMonth(monthVal);
              return `${tWithoutPrefix(yearLabel)} ${tWithoutPrefix(monthLabel)}`;
            })(), 
            skip: data["period_of_work"] !== ScheduledPeriodOfEmployment.Fixed
          },
          {
            label: t("startDateOfEmployment"),
            value: (() => {
              switch (supplementaryInfo["start_date_of_employment_kind"]) {
                case StartDateOfEmployment.Decided: return t("decided");
                case StartDateOfEmployment.Undecided: return t("undecided");
              }
            })()
          },
          {
            label: t("startDateOfEmploymentDecided"),
            value: formatDateString(data["start_date_of_employment"]),
            skip: supplementaryInfo["start_date_of_employment_kind"] !== StartDateOfEmployment.Decided
          },
          {
            label: t("startDateOfEmploymentUndecided"),
            value: data["start_date_of_employment_if_it_is_undecided"]
              ? tWithoutPrefix(`${
                  getLabelFromStartDateOfEmploymentUndecided(data["start_date_of_employment_if_it_is_undecided"])
                }`)
              : "",
            skip: supplementaryInfo["start_date_of_employment_kind"] !== StartDateOfEmployment.Undecided
          },
          {
            label: t("startDateOfEmploymentUndecidedOther"),
            value: data["start_date_of_employment_if_it_is_undecided_other"],
            skip: 
              !(supplementaryInfo["start_date_of_employment_kind"] === StartDateOfEmployment.Undecided &&
                data["start_date_of_employment_if_it_is_undecided"] === START_DATE_OF_EMPLOYMENT_UNDECIDED_OTHER_VALUE)
          },
          {
            label: t("monthlyCompensationBeforeTaxYen"),
            value: data["salary_reward"]
              ? Number(data["salary_reward"]).toLocaleString()
              : ""
          },
          {
            label: t("yearsOfBusinessExperience"),
            value: data["business_experience"],
          },
          {
            label: t("doesApplicantHavePositionTitle"),
            value: (() => {
              switch (data["position"]) {
                case DoesApplicantHavePositionOrTitle.Yes: return tWithoutPrefix("common.yes");
                case DoesApplicantHavePositionOrTitle.No: return tWithoutPrefix("common.no");
                default: return "";
              }
            })()
          },
          {
            label: t("nameOfPositionTitle"),
            value: data["position_name"],
            skip: data["position"] !== DoesApplicantHavePositionOrTitle.Yes
          },
          {
            label: t("mainOccupationTypeOfWork"),
            value: data["occupation_main_type_of_work"]
              ? tWithoutPrefix(`${getLabelFromTypeOfWork(data["occupation_main_type_of_work"])}`)
              : ""
          },
          {
            label: t("otherOccupation1"),
            value: data["occupation_other_occupation_1"]
              ? tWithoutPrefix(`${getLabelFromTypeOfWork(data["occupation_other_occupation_1"])}`)
              : ""
          },
          {
            label: t("otherOccupation2"),
            value: data["occupation_other_occupation_2"]
              ? tWithoutPrefix(`${getLabelFromTypeOfWork(data["occupation_other_occupation_2"])}`)
              : ""
          },
          {
            label: t("detailsOfApplicantsWork"),
            value: data["details_of_activities"],
          }
        ]}
      />
    </section>
  );
};

export default EmploymentConditionsSection;
