import { APPLICANT_PERIOD_OF_STAY_OPTIONS_FOR_GIJINKOKU } from "../../constants/options/periodOfStays/periodOfStayForGijinkoku";
import { TYPE_OF_CONTRACT_OPTIONS_FOR_GIJINKOKU } from "../../constants/options/contract";
import { FINAL_EDUCATION_OPTIONS_FOR_GIJINKOKU } from "../../constants/options/education";
import { MAJOR_FIELD_OF_STUDY_FOR_COLLEGE_OF_TECHNOLOGY_OPTIONS_FOR_GIJINKOKU, MAJOR_FIELD_OF_STUDY_FOR_DRMSBCJC_OPTIONS_FOR_GIJINKOKU } from "../../constants/options/major";
import { MAX_WORK_HISTORIES } from "../../constants/values/gijinkokuVisaValues";
import { FormN } from "../../types/visa/uncommonFormParts/formN/data";
import { DoesApplicantHavePositionOrTitle, DoesWorkInvolveInInfoProcessing, LocationOfFinalEducation, ScheduledPeriodOfEmployment, StartDateOfEmployment } from "../../types/visa/uncommonFormParts/formN/value";
import { FirstRenewalApplication } from "../../types/visa/supplementaryInfo";


//TODO: Remove getTransKeyFor... functions when finishing up GiJinkoku visa form


export const getTransKeyForFinalEducationLocation = (finalEducationLocation: string | null) => {
  switch (finalEducationLocation) {
    case LocationOfFinalEducation.Japan: return "japan";
    case LocationOfFinalEducation.ForeignCountry: return "foreignCountry";
    default: return "";
  }
}

export const getTransKeyForHavingPositionTitle = (havingPositionTitle: string | null) => {
  switch (havingPositionTitle) {
    case DoesApplicantHavePositionOrTitle.Yes: return "common.yes";
    case DoesApplicantHavePositionOrTitle.No: return "common.no";
    default: return "";
  }
}

export const getTransKeyForScheduledPeriodOfEmployment = (scheduledPeriodOfEmployment: string | null) => {
  switch (scheduledPeriodOfEmployment) {
    case ScheduledPeriodOfEmployment.Fixed: return "fixed";
    case ScheduledPeriodOfEmployment.NonFixed: return "nonFixed";
    default: return "";
  }
}

export const getLabelFromPeriodOfStayCodeForTechnical = (periodOfStayCode: string | null) => {
  return APPLICANT_PERIOD_OF_STAY_OPTIONS_FOR_GIJINKOKU.find(op => op.value === periodOfStayCode)?.label ?? '';
}

export const getLabelFromFinalEducation = (finalEducation: string | null) => {
  return FINAL_EDUCATION_OPTIONS_FOR_GIJINKOKU.find(op => op.value === finalEducation)?.label ?? '';
}

export const getLabelFromMajorFieldOfStudyForDrMsBcJc = (majorFieldOfStudy: string | null) => {
  return MAJOR_FIELD_OF_STUDY_FOR_DRMSBCJC_OPTIONS_FOR_GIJINKOKU.find(op => op.value === majorFieldOfStudy)?.label ?? '';
}

export const getLabelFromMajorFieldOfStudyForCollegeOfTech = (majorFieldOfStudy: string | null) => {
  return MAJOR_FIELD_OF_STUDY_FOR_COLLEGE_OF_TECHNOLOGY_OPTIONS_FOR_GIJINKOKU.find(op => op.value === majorFieldOfStudy)?.label ?? '';
}

export const getLabelFromTypeOfContract = (typeOfContract: string | null) => {
  return TYPE_OF_CONTRACT_OPTIONS_FOR_GIJINKOKU.find(op => op.value === typeOfContract)?.label ?? '';
}

export const getWorkHistoryLength = (data: FormN): number => {
  let length = 0;

  for (let i = 1; i <= MAX_WORK_HISTORIES; i++) 
  {
    const isThereValue = [
      data[`work_experience_${i}_place_of_employment` as keyof FormN],
      data[`work_experience_${i}_date_of_joining` as keyof FormN],
      data[`work_experience_${i}_date_of_leaving` as keyof FormN]
    ].some(v => !!v);

    if (isThereValue) {
      length = i;
    }
  }

  return length;
}