import { RELATIONSHIP_WITH_SUPPORTER_OPTIONS_FOR_STUDY_ABROAD } from "../../constants/options";
import { ENROLLMENT_CATEGORY_OPTIONS_FOR_STUDY_ABROAD, ENROLLMENT_STATUS_OPTIONS_FOR_STUDY_ABROAD, FACULTY_COURSE_OPTIONS_FOR_STUDY_ABROAD, FINAL_EDUCATION_OPTIONS_FOR_STUDY_ABROAD, SPECIALIZED_COURSE_OPTIONS_FOR_STUDY_ABROAD, TYPE_OF_CLASS_OPTIONS_FOR_STUDY_ABROAD } from "../../constants/options/education";
import { ORGANIZATGION_IN_CHARGE_OPTIONS_FOR_STUDY_ABROAD } from "../../constants/options/organization";
import { MAX_EMPLOYMENTS, MAX_SUPPORTERS } from "../../constants/values/studentVisaValues";
import { FormP } from "../../types/visa/uncommonFormParts/formP/data";

export const getSupporterKeyOf = {
  name: (index: number) => `expenses_supporter_${index}_name` as keyof FormP,
  prefecture: (index: number) => `expenses_supporter_${index}_address_prefecture` as keyof FormP,
  municipality: (index: number) => `expenses_supporter_${index}_address_municipality` as keyof FormP,
  townStreetApartment: (index: number) => `expenses_supporter_${index}_address_street` as keyof FormP,
  phoneNumber: (index: number) => `expenses_supporter_${index}_telephone_no` as keyof FormP,
  occupationAtWork: (index: number) => `expenses_supporter_${index}_place_of_employment` as keyof FormP,
  phoneNumberAtWork: (index: number) => `expenses_supporter_${index}_place_of_employment_telephone_no` as keyof FormP,
  annualIncome: (index: number) => `expenses_supporter_${index}_annual_income` as keyof FormP
}

export const getEmploymentKeyOf = {
  typeOfWork: (index: number) => `other_activities_${index}_type_of_work` as keyof FormP,
  placeOfEmployment: (index: number) => `other_activities_${index}_place_of_employment` as keyof FormP,
  telephoneNumber: (index: number) => `other_activities_${index}_telephone_no` as keyof FormP,
  workTimePerWeek: (index: number) => `other_activities_${index}_work_time_per_week` as keyof FormP,
  salaryType: (index: number) => `other_activities_${index}_salary_monthly_or_daily` as keyof FormP,
  salaryYen: (index: number) => `other_activities_${index}_salary` as keyof FormP
}

export const getLengthOfSupporters = (data: FormP): number => {
  let length = 0;

  for (let i = 1; i <= MAX_SUPPORTERS; i++) 
  {
    const isThereValue = [
      data[getSupporterKeyOf.name(i)],
      data[getSupporterKeyOf.prefecture(i)],
      data[getSupporterKeyOf.municipality(i)],
      data[getSupporterKeyOf.townStreetApartment(i)],
      data[getSupporterKeyOf.phoneNumber(i)],
      data[getSupporterKeyOf.occupationAtWork(i)],
      data[getSupporterKeyOf.phoneNumberAtWork(i)],
      data[getSupporterKeyOf.annualIncome(i)]
    ].some(v => !!v);

    if (isThereValue) {
      length = i;
    }
  }

  return length;
}

export const getLengthOfEmployments = (data: FormP): number => {
  let length = 0;

  for (let i = 1; i <= MAX_EMPLOYMENTS; i++) 
  {
    const isThereValue = [
      data[getEmploymentKeyOf.typeOfWork(i)],
      data[getEmploymentKeyOf.placeOfEmployment(i)],
      data[getEmploymentKeyOf.telephoneNumber(i)],
      data[getEmploymentKeyOf.workTimePerWeek(i)],
      data[getEmploymentKeyOf.salaryType(i)],
      data[getEmploymentKeyOf.salaryYen(i)]
    ].some(v => !!v);

    if (isThereValue) {
      length = i;
    }
  }

  return length;
}

export const getLabelFromEnrollmentStatus = (value: string | null): string => {
  return ENROLLMENT_STATUS_OPTIONS_FOR_STUDY_ABROAD.find(op => op.value === value)?.label ?? "";
}

export const getLabelFromFinalEducation = (value: string | null): string => {
  return FINAL_EDUCATION_OPTIONS_FOR_STUDY_ABROAD.find(op => op.value === value)?.label ?? "";
}

export const getLabelFromRelationshipWithApplicant = (value: string | null): string => {
  return RELATIONSHIP_WITH_SUPPORTER_OPTIONS_FOR_STUDY_ABROAD.find(op => op.value === value)?.label ?? "";
}

export const getLabelFromTypeOfClass = (value: string | null): string => {
  return TYPE_OF_CLASS_OPTIONS_FOR_STUDY_ABROAD.find(op => op.value === value)?.label ?? "";
}

export const getLabelFromOrgInCharge = (value: string | null): string => {
  return ORGANIZATGION_IN_CHARGE_OPTIONS_FOR_STUDY_ABROAD.find(op => op.value === value)?.label ?? "";
}

export const getLabelFromRegistration = (value: string | null): string => {
  return ENROLLMENT_CATEGORY_OPTIONS_FOR_STUDY_ABROAD.find(op => op.value === value)?.label ?? "";
}

export const getLabelFromFacultyCourse = (value: string | null): string => {
  return FACULTY_COURSE_OPTIONS_FOR_STUDY_ABROAD.find(op => op.value === value)?.label ?? "";
}

export const getLabelFromNameOfSpecializedCourse = (value: string | null): string => {
  return SPECIALIZED_COURSE_OPTIONS_FOR_STUDY_ABROAD.find(op => op.value === value)?.label ?? "";
}