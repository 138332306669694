import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import FieldGroup from '../../../../components/layout/FieldGroup';
import { useFieldInputNotes, useValidation } from '../../../../hooks';
import Section from '../../../../components/layout/Section';
import SelectField from '../../../../components/compound/SelectField';
import TextField from '../../../../components/compound/TextField';
import { FormP } from '../../../../types/visa/uncommonFormParts/formP/data';
import { formatDateStrWithJaUnits, parseDateWithJaUnits } from '../../../../utils/visaFormDataHelpers/commonVisaFormDataHelper';
import DateSelectField from '../../../../components/compound/DateSelectField';
import { ENROLLMENT_STATUS_OPTIONS_FOR_STUDY_ABROAD, FINAL_EDUCATION_OPTIONS_FOR_STUDY_ABROAD } from '../../../../constants/options/education';
import { FINAL_EDUCATION_OTHER_VALUE } from '../../../../types/visa/uncommonFormParts/formP/value';
import { ApplicationFormSectionProps } from '../../type';


interface StudentVisaFinalEducationSectionProps extends ApplicationFormSectionProps {
  formP?: FormP | null;
  onChangeFormP: (data: Partial<FormP>) => void;
};

const LocalFieldGroup = styled(FieldGroup)`
  background-color: #FFFFFF;
  border: solid 1px #D5D5D5;
`;

const StudentVisaFinalEducationSection: FunctionComponent<StudentVisaFinalEducationSectionProps> = ({
  formP,
  onChangeFormP,
  onChangeSectionReadiness,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'finalEducationSection' });
  const { t : tWithoutPrefix } = useTranslation();
  const { inputNotes } = useFieldInputNotes();
  const validation = useValidation();
  const [totalPeriodOfEducation, setTotalPeriodOfEducation] = useState<string>('');
  const [enrollmentStatus, setEnrollmentStatus] = useState<string>('');
  const [finalEducation, setFinalEducation] = useState<string>('');
  const [finalEducationOther, setFinalEducationOther] = useState<string>('');
  const [nameOfSchool, setNameOfSchool] = useState<string>('');
  const [graduationDate, setGraduationDate] = useState<string>('');
  
  const [totalPeriodOfEducationError, setTotalPeriodOfEducationError] = useState<string>('');
  const [finalEducationOtherError, setFinalEducationOtherError] = useState<string>('');
  const [nameOfSchoolError, setNameOfSchoolError] = useState<string>('');
  
  //Currently, the item names for this section are common between visa extension and change, 
  //so using the same function.
  const saveData = (data: Partial<FormP>) => {
    onChangeFormP(data);
  }

  useEffect(() => {
    if (!formP) 
      return;
  
    setTotalPeriodOfEducation(formP.total_period_of_education ?? '');
    setEnrollmentStatus(formP.education_registered_enrollment ?? '');
    setFinalEducation(formP.education_registered_enrollment_school ?? '');
    setFinalEducationOther(formP.education_registered_enrollment_others ?? '');
    setNameOfSchool(formP.education_name_of_school ?? '');

    const graduationDate = parseDateWithJaUnits(formP.education_date_of_graduation);
    setGraduationDate(graduationDate.yearMonth ?? '');

  }, [formP]);

  useEffect(() => {
    const areThereNoErrors = [
      totalPeriodOfEducationError,
      finalEducationOtherError,
      nameOfSchoolError,
    ].every(val => !val);

    onChangeSectionReadiness(areThereNoErrors);
  }, [
    totalPeriodOfEducationError,
    finalEducationOtherError,
    nameOfSchoolError,
  ]);


  return (
    <Section>

      {/* Address (school) */}
      <LocalFieldGroup 
        title={t('finalEducation')}
        note={t('noteOfSection')}
        theme="light"
      >

        {/* Total period of education */}
        <TextField
          label={t('totalPeriodOfEducation')}
          placeholder="12"
          value={totalPeriodOfEducation}
          error={totalPeriodOfEducationError}
          maxLength={2}
          validators={[validation.isNumeric, validation.createLengthValidator(2)]}
          restriction={inputNotes.halfwidthNumberNoHyphens}
          onValueChange={setTotalPeriodOfEducation}
          onErrorChange={setTotalPeriodOfEducationError}
          onBlur={() => {
            saveData({ 
              total_period_of_education: totalPeriodOfEducation 
            });
          }}  
        />

        {/* Enriollment status (of final education) */}
        <SelectField
          label={t('enrollmentStatus')}
          placeholder={tWithoutPrefix("placeholder.select")}
          options={ENROLLMENT_STATUS_OPTIONS_FOR_STUDY_ABROAD}
          value={enrollmentStatus}
          onValueChange={val => {
            setEnrollmentStatus(val);
            saveData({ education_registered_enrollment: val });
          }}
        />

        {/* Final education */} 
        <SelectField
          label={t('finalEducation')}
          placeholder={tWithoutPrefix("placeholder.select")}
          options={FINAL_EDUCATION_OPTIONS_FOR_STUDY_ABROAD}
          value={finalEducation}
          onValueChange={val => {
            setFinalEducation(val);
            const data: Partial<FormP> = { education_registered_enrollment_school: val };
              
            if (val !== FINAL_EDUCATION_OTHER_VALUE)
              data.education_registered_enrollment_others = "";
          
            saveData(data);
          }}
        />

        {/* Final education (other) */}
        { finalEducation === FINAL_EDUCATION_OTHER_VALUE &&
          <TextField
            label={t('finalEducationOther')}
            value={finalEducationOther}
            error={finalEducationOtherError}
            maxLength={172}
            validators={[validation.createLengthValidator(172)]}
            onValueChange={setFinalEducationOther}
            onErrorChange={setFinalEducationOtherError}
            onBlur={() => {
              saveData({ education_registered_enrollment_others: finalEducationOther });  
            }}
          />
        }

        {/* Name of school */}
        <TextField
          label={t('nameOfSchool')}
          placeholder="Visadas University"
          value={nameOfSchool}
          error={nameOfSchoolError}
          maxLength={172}
          validators={[validation.createLengthValidator(172)]}
          onValueChange={setNameOfSchool}
          onErrorChange={setNameOfSchoolError}
          onBlur={() => {
            saveData({ education_name_of_school: nameOfSchool });
          }}
        />

        {/* Date of graduation or expected graduation */}
        <DateSelectField
          hideDayField
          label={t('dateOfGraduation')}
          maxDate={new Date()}
          value={graduationDate}
          onValueChange={val => {
            setGraduationDate(val);
            saveData({ education_date_of_graduation: formatDateStrWithJaUnits(val) });
          }}
        />

      </LocalFieldGroup>
    
    </Section>
  );
};

export default StudentVisaFinalEducationSection;