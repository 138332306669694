import { Attachment } from "../types/api/attachment";
import { FormKind } from "../types/visa/applicationData";
import { axiosInstance } from "./common";

type analyzeUserFilesArgs<T extends Attachment> = {
  formKind: FormKind;
  formId: number | string;
  params: T[];
};

const analyzeFormP = async (formId: number | string, data: FormData) => {
  const response = await axiosInstance.request({
    method: "PATCH",
    url: `/form_ps/${formId}/ai_analyze`,
    data,
  });

  return response.data;
};

const analyzeFormR = async (formId: number | string, data: FormData) => {
  const response = await axiosInstance.request({
    method: "PATCH",
    url: `/form_rs/${formId}/ai_analyze`,
    data,
  });

  return response.data;
};

const analyzeFormN = async (formId: number | string, data: FormData) => {
  const response = await axiosInstance.request({
    method: "PATCH",
    url: `/form_s/${formId}/ai_analyze`,
    data,
  });

  return response.data;
};

export const analyzeUserFiles = async <T extends Attachment>(
  args: analyzeUserFilesArgs<T>
) => {
  const { formKind, formId, params } = args;
  const data = new FormData();

  params.forEach((param) => {
    data.append("files[]", param.file);
    data.append("kinds[]", param.kind);
  });

  switch (formKind) {
    case "p":
      return analyzeFormP(formId, data);
    case "r":
      return analyzeFormR(formId, data);
    case "n":
      return analyzeFormN(formId, data);
  }
};
