import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Modal from "../../components/modal/Modal";
import Button from "../../components/form/Button";
import Column from "../../components/layout/Column";
import { CONTENT_MAX_WIDTH } from "../../constants/styles";
import { useDispatcher } from "../../hooks";
import BackButton from "../../components/navigation/BackButton";
import InlineMessage from "../../components/form/InlineMessage";
import Text from "../../components/text/Text";
import UserDocUploadField from "../../components/compound/UserDocUploadField";
import RadioItem from "../../components/form/RadioItem";
import { VisaApplication } from "../../types/visa/applicationData";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/values/query";
import { getVisaApplications } from "../../apis/visaApplication";
import ApplicationCard from "../../components/display/ApplicationCard";
import { findLatestApprovedChangeVisaApplication, getProcessedDate, isThisVisaApplicationApproved, isThisVisaExtension } from "../../utils/visaApplicationHelper";
import { Attachment } from "../../types/api/attachment";
import { VisaAIReadingAttachmentKind } from "../../types/visa/attachment";

interface InputAssistanceModalProps {
  onClickBack: () => void;
  onClickRunAIReading: (uploadedFiles: Attachment[]) => void;
  onClickUseThisAppData: (visaApplication: VisaApplication) => void;
}

enum ContentType {
  Init,
  UploadedFiles,
  PastApplicationFiles,
  PastApplicationData,
}

enum AssistanceOption {
  UploadedFiles = "UploadedFiles",
  PastApplicationFiles = "PastApplicationFiles",
  PastApplicationData = "PastApplicationData",
}

const ContentContainer = styled(Column)`
  background-color: #fff;
  height: 100%;
  overflow-y: auto;
`;

const Content = styled(Column)`
  flex-grow: 1;
  gap: 30px;
  padding: 20px;
  max-width: ${CONTENT_MAX_WIDTH}px;
  margin: 0 auto;
`;

const ActionBody = styled(Column)`
  gap: 20px;
  padding: 20px;
  border: 1px solid #ededed;
  background-color: #fff;
  flex-grow: 1;
`;

const VisaAppsContainer = styled(Column)`
  gap: 15px;
`;

const Heading = styled(Text)`
  font-size: 1.3rem;
  font-weight: 500;
  line-height: normal;
`;

const SmallText = styled(Text)`
  font-size: 0.8rem;
  line-height: normal;
  font-weight: 400;
  line-height: normal;
`;

const Column10 = styled(Column)`
  gap: 10px;
`;

const Column5 = styled(Column)`
  gap: 5px;
`;

const InstructionList = styled.ul`
  padding-inline-start: 18px;
  margin: 0;
  list-style-type: disc;
`;

const ActionButtonContainer = styled(Column)`
  flex-grow: 1;
  flex-direction: column-reverse;
`;

const UploadItem = styled.div`
  border-bottom: 1px solid #d5d5d5;
  padding: 15px 0;
`;

const InputAssistanceModal: FunctionComponent<InputAssistanceModalProps> = ({
  onClickBack,
  onClickRunAIReading,
  onClickUseThisAppData,
}) => {
  const { state, dispatcher } = useDispatcher();
  const { t } = useTranslation("translation", {keyPrefix: "modalInputAssistance"});
  const { t: tInput } = useTranslation("translation", {keyPrefix: "modalInputAssistance.inputAssistanceFunction"});
  const { t: tUploaded } = useTranslation("translation", {keyPrefix: "modalInputAssistance.uploadedFiles"});
  const { t: tPastFiles } = useTranslation("translation", {keyPrefix: "modalInputAssistance.pastAppFiles"});
  const { t: tPastData } = useTranslation("translation", {keyPrefix: "modalInputAssistance.pastAppData"});
  const { t: tCommon } = useTranslation("translation", { keyPrefix: "common" });
  const [content, setContent] = useState<ContentType>(ContentType.Init);
  const [assistanceOption, setAssistanceOption] = useState<AssistanceOption | null>(null);
  const [residentCardFront, setResidentCardFront] = useState<File | null>(null);
  const [residentCardBack, setResidentCardBack] = useState<File | null>(null);
  const [passport, setPassport] = useState<File | null>(null);
  const [marriageDoc, setMarriageDoc] = useState<File | null>(null);
  const [birthDoc, setBirthDoc] = useState<File | null>(null);
  const [familyDoc, setFamilyDoc] = useState<File | null>(null);
  const [resume, setResume] = useState<File[]>([]);
  const [employmentConditionsDoc, setEmploymentConditionsDoc] = useState<File[]>([]);
  const [supporterPassport, setSupporterPassport] = useState<File | null>(null);
  const [supporterResidentCardFront, setSupporterResidentCardFront] = useState<File | null>(null);
  const [supporterResidentCardBack, setSupporterResidentCardBack] = useState<File | null>(null);
  const [supporterEmploymentConditionsDoc, setSupporterEmploymentConditionsDoc] = useState<File[]>([]);
  const [otherDocs, setOtherDocs] = useState<File[]>([]);
  const [pastAppFiles, setPastAppFiles] = useState<File[]>([]);
  const [selectedVisaApp, setSelectedVisaApp] = useState<VisaApplication | null>(null);
  const areRequiredFilesAttached = [
    residentCardFront,
    residentCardBack,
    passport,
  ].every((file) => file !== null);

  const { isPending: isVisaApplicationsPending, data: visaApplications } =
    useQuery({
      queryKey: [QUERY_KEYS.VISA_APPLICATIONS],
      queryFn: getVisaApplications,
    });

  const approvedVisaApplications = useMemo(() => {
    /* Spec
      1. Extension - show all
      2. Change - show only last one
      3. Only approved applications

      In the case of Visa change, show only the latest approved visa change application
      In the case of Visa extension, show all approved visa extension applications
      and, the latest approved visa change application comes first
    */
    const latestVisaChangeApplication =
      findLatestApprovedChangeVisaApplication(visaApplications);

    const approvedApps =  visaApplications?.filter(visaApplication => (
      isThisVisaApplicationApproved(visaApplication) &&
      (isThisVisaExtension(visaApplication) ||
        visaApplication.id === latestVisaChangeApplication?.id)
    )) ?? [];

    approvedApps.sort((a, b) => {
      //Sort by approved date (i.e processed_at) in descending order
      const aProcessedAt: string | null = getProcessedDate(a);
      const bProcessedAt: string | null = getProcessedDate(b);
      if (!aProcessedAt || !bProcessedAt) return 0;
      return new Date(bProcessedAt).getTime() - new Date(aProcessedAt).getTime();
    });

    return approvedApps;
  }, [visaApplications]);


  const onClickNext = () => {
    switch (assistanceOption) {
      case AssistanceOption.UploadedFiles:
        setContent(ContentType.UploadedFiles);
        break;
      case AssistanceOption.PastApplicationFiles:
        setContent(ContentType.PastApplicationFiles);
        break;
      case AssistanceOption.PastApplicationData:
        setContent(ContentType.PastApplicationData);
        break;
    }
  };

  const onClickAIReadingOnUploadedFilesScreen = () => {
    const uploadedFiles = [
      {
        file: residentCardFront!,
        kind: VisaAIReadingAttachmentKind.residenceCardFront,
      },
      {
        file: residentCardBack!,
        kind: VisaAIReadingAttachmentKind.residenceCardBack,
      },
      {
        file: passport!,
        kind: VisaAIReadingAttachmentKind.passport,
      },
      ...resume.map((file) => ({
        file,
        kind: VisaAIReadingAttachmentKind.other,
      })),
      ...employmentConditionsDoc.map((file) => ({
        file,
        kind: VisaAIReadingAttachmentKind.employmentCertificate,
      })),
      ...supporterEmploymentConditionsDoc.map((file) => ({
        file,
        kind: VisaAIReadingAttachmentKind.employmentCertificate,
      })),
      ...otherDocs.map((file) => ({
        file,
        kind: VisaAIReadingAttachmentKind.other,
      })),
    ];

    if (marriageDoc) {
      uploadedFiles.push({
        file: marriageDoc,
        kind: VisaAIReadingAttachmentKind.marriageRelatedDocument
      });
    }

    if (birthDoc) {
      uploadedFiles.push({
        file: birthDoc,
        kind: VisaAIReadingAttachmentKind.birthRegistration
      });
    }

    if (familyDoc) {
      uploadedFiles.push({
        file: familyDoc,
        kind: VisaAIReadingAttachmentKind.familyRegistration
      })
    }

    if (supporterPassport) {
      uploadedFiles.push({
        file: supporterPassport,
        kind: VisaAIReadingAttachmentKind.supporterPassport
      })
    }

    if (supporterResidentCardFront) {
      uploadedFiles.push({
        file: supporterResidentCardFront,
        kind: VisaAIReadingAttachmentKind.supporterResidenceCardFront,
      });
    }

    if (supporterResidentCardBack) {
      uploadedFiles.push({
        file: supporterResidentCardBack,
        kind: VisaAIReadingAttachmentKind.supporterResidenceCardBack,
      });
    }

    onClickRunAIReading(uploadedFiles);
  }

  const onClickAIReadingOnPastApplicationFilesScreen = () => {
    onClickRunAIReading(
      pastAppFiles.map((file) => ({
        file,
        kind: VisaAIReadingAttachmentKind.other,
      }))
    );
  }

  useEffect(() => {
    if (isVisaApplicationsPending) dispatcher.startLoading();
    else dispatcher.stopLoading();
  }, [isVisaApplicationsPending]);

  return (
    <Modal>
      <ContentContainer>
        <Content>
          {content === ContentType.Init && (
            <>
              <BackButton onClick={onClickBack} />
              <Heading>{tInput("title")}</Heading>
              <Column10>
                <Text>{tInput("aiReadingFunction")}</Text>
                <Column5>
                  <RadioItem
                    checked={
                      assistanceOption === AssistanceOption.UploadedFiles
                    }
                    value={AssistanceOption.UploadedFiles}
                    onValueChange={(val) => setAssistanceOption(val)}
                  >
                    {tInput("createAppDocsFromUploadedFiles")}
                  </RadioItem>
                  <RadioItem
                    checked={
                      assistanceOption === AssistanceOption.PastApplicationFiles
                    }
                    value={AssistanceOption.PastApplicationFiles}
                    onValueChange={(val) => setAssistanceOption(val)}
                  >
                    {tInput("createAppDocsFromPastAppFiles")}
                  </RadioItem>
                </Column5>
              </Column10>
              <Column10>
                <Text>{tInput("usePastAppData")}</Text>
                {approvedVisaApplications.length > 0 && (
                  <RadioItem
                    checked={
                      assistanceOption === AssistanceOption.PastApplicationData
                    }
                    value={AssistanceOption.PastApplicationData}
                    onValueChange={(val) => setAssistanceOption(val)}
                  >
                    {tInput("createAppDocsUsingPastAppData")}
                  </RadioItem>
                )}

                {!isVisaApplicationsPending &&
                  approvedVisaApplications.length === 0 && (
                    <InlineMessage>{tInput("youCannotSelect")}</InlineMessage>
                  )}
              </Column10>
              <ActionButtonContainer>
                <Button disabled={!!!assistanceOption} onClick={onClickNext}>
                  {tCommon("next")}
                </Button>
              </ActionButtonContainer>
            </>
          )}

          {/* Create application documents from past application files */}
          {content === ContentType.PastApplicationFiles && (
            <>
              <BackButton onClick={() => setContent(ContentType.Init)} />
              <ActionBody>
                <Heading>{tPastFiles("title")}</Heading>
                <Column10>
                  <SmallText>{tPastFiles("instruction")}</SmallText>
                  <InstructionList>
                    <li>
                      <SmallText>{tPastFiles("item1")}</SmallText>
                    </li>
                    <li>
                      <SmallText>{tPastFiles("item2")}</SmallText>
                    </li>
                  </InstructionList>
                </Column10>
                <UserDocUploadField
                  attachment={pastAppFiles}
                  onAttachmentUpload={(file) =>
                    setPastAppFiles(
                      pastAppFiles ? [...pastAppFiles, file] : [file]
                    )
                  }
                  onAttachmentDelete={(index) =>
                    pastAppFiles &&
                    setPastAppFiles(pastAppFiles?.filter((_, i) => i !== index))
                  }
                />

                <ActionButtonContainer>
                  <Button
                    disabled={pastAppFiles.length === 0}
                    onClick={onClickAIReadingOnPastApplicationFilesScreen}
                    style={{ gap: 10 }}
                  >
                    {t("runAiReading")}
                  </Button>
                </ActionButtonContainer>
              </ActionBody>
            </>
          )}

          {/* Create application documents from uploaded files */}
          {content === ContentType.UploadedFiles && (
            <>
              <BackButton onClick={() => setContent(ContentType.Init)} />
              <ActionBody>
                <Heading>{tUploaded("title")}</Heading>
                <SmallText>{tUploaded("description")}</SmallText>
                <div style={{ width: "100%" }}>
                  {/* Residence card (front) */}
                  <UploadItem>
                    <UserDocUploadField
                      hideButtonsIfFileExists
                      required
                      label={tUploaded("residenceCardFront")}
                      attachment={residentCardFront}
                      onAttachmentUpload={(file) => setResidentCardFront(file)}
                      onAttachmentDelete={() => setResidentCardFront(null)}
                    />
                  </UploadItem>

                  {/* Residence card (back) */}
                  <UploadItem>
                    <UserDocUploadField
                      hideButtonsIfFileExists
                      required
                      label={tUploaded("residenceCardBack")}
                      attachment={residentCardBack}
                      onAttachmentUpload={(file) => setResidentCardBack(file)}
                      onAttachmentDelete={() => setResidentCardBack(null)}
                    />
                  </UploadItem>

                  {/* Passport */}
                  <UploadItem>
                    <UserDocUploadField
                      hideButtonsIfFileExists
                      required
                      label={tUploaded("passport")}
                      attachment={passport}
                      onAttachmentUpload={(file) => setPassport(file)}
                      onAttachmentDelete={() => setPassport(null)}
                    />
                  </UploadItem>

                  {/* Marriage related document */}
                  <UploadItem>
                    <UserDocUploadField
                      hideButtonsIfFileExists
                      label={tUploaded("marriageDoc")}
                      attachment={marriageDoc}
                      onAttachmentUpload={(file) => setMarriageDoc(file)}
                      onAttachmentDelete={() => setMarriageDoc(null)}
                    />
                  </UploadItem>

                  {/* Birth registration */}
                  <UploadItem>
                    <UserDocUploadField
                      hideButtonsIfFileExists
                      label={tUploaded("birthRegistration")}
                      attachment={birthDoc}
                      onAttachmentUpload={(file) => setBirthDoc(file)}
                      onAttachmentDelete={() => setBirthDoc(null)}
                    />
                  </UploadItem>

                  {/* Family registration */}
                  <UploadItem>
                    <UserDocUploadField
                      hideButtonsIfFileExists
                      label={tUploaded("familyRegistration")}
                      attachment={familyDoc}
                      onAttachmentUpload={(file) => setFamilyDoc(file)}
                      onAttachmentDelete={() => setFamilyDoc(null)}
                    />
                  </UploadItem>

                  {/* Resume */}
                  <UploadItem>
                    <UserDocUploadField
                      label={tUploaded("resume")}
                      attachment={resume}
                      onAttachmentUpload={(file) =>
                        setResume([...resume, file])
                      }
                      onAttachmentDelete={(index) =>
                        setResume(resume.filter((_, i) => i !== index))
                      }
                    />
                  </UploadItem>

                  {/* Employment conditions document */}
                  <UploadItem>
                    <UserDocUploadField
                      label={tUploaded("employmentConditionsDoc")}
                      attachment={employmentConditionsDoc}
                      onAttachmentUpload={(file) =>
                        setEmploymentConditionsDoc([
                          ...employmentConditionsDoc,
                          file,
                        ])
                      }
                      onAttachmentDelete={(index) =>
                        setEmploymentConditionsDoc(
                          employmentConditionsDoc.filter((_, i) => i !== index)
                        )
                      }
                    />
                  </UploadItem>

                  {/* Supporter's Passport */}
                  <UploadItem>
                    <UserDocUploadField
                      hideButtonsIfFileExists
                      label={tUploaded("supporterPassport")}
                      attachment={supporterPassport}
                      onAttachmentUpload={(file) => setSupporterPassport(file)}
                      onAttachmentDelete={() => setSupporterPassport(null)}
                    />
                  </UploadItem>

                  {/* Supporter's residence card (front) */}
                  <UploadItem>
                    <UserDocUploadField
                      hideButtonsIfFileExists
                      label={tUploaded("supporterResidenceCardFront")}
                      attachment={supporterResidentCardFront}
                      onAttachmentUpload={(file) => setSupporterResidentCardFront(file)}
                      onAttachmentDelete={() => setSupporterResidentCardFront(null)}
                    />
                  </UploadItem>

                  {/* Supporter's residence card (back) */}
                  <UploadItem>
                    <UserDocUploadField
                      hideButtonsIfFileExists
                      label={tUploaded("supporterResidenceCardBack")}
                      attachment={supporterResidentCardBack}
                      onAttachmentUpload={(file) => setSupporterResidentCardBack(file)}
                      onAttachmentDelete={() => setSupporterResidentCardBack(null)}
                    />
                  </UploadItem>

                  {/* Supporter's employment conditions document */}
                  <UploadItem>
                    <UserDocUploadField
                      label={tUploaded("supporterEmploymentConditionsDoc")}
                      attachment={supporterEmploymentConditionsDoc}
                      onAttachmentUpload={(file) =>
                        setSupporterEmploymentConditionsDoc([
                          ...supporterEmploymentConditionsDoc,
                          file,
                        ])
                      }
                      onAttachmentDelete={(index) =>
                        setSupporterEmploymentConditionsDoc(
                          supporterEmploymentConditionsDoc.filter(
                            (_, i) => i !== index
                          )
                        )
                      }
                    />
                  </UploadItem>

                  {/* Other */}
                  <UploadItem>
                    <UserDocUploadField
                      label={tUploaded("other")}
                      attachment={otherDocs}
                      onAttachmentUpload={(file) =>
                        setOtherDocs([...otherDocs, file])
                      }
                      onAttachmentDelete={(index) =>
                        setOtherDocs(otherDocs.filter((_, i) => i !== index))
                      }
                    />
                  </UploadItem>
                </div>
                <ActionButtonContainer>
                  <Button
                    disabled={!areRequiredFilesAttached}
                    onClick={onClickAIReadingOnUploadedFilesScreen}
                    style={{ gap: 10 }}
                  >
                    {t("runAiReading")}
                  </Button>
                </ActionButtonContainer>
              </ActionBody>
            </>
          )}

          {/* Create application documents using past application data */}
          {content === ContentType.PastApplicationData && (
            <>
              <BackButton onClick={() => setContent(ContentType.Init)} />
              {selectedVisaApp === null && (
                <InlineMessage>{tPastData("noTargetSelected")}</InlineMessage>
              )}
              <Heading>{tPastData("title")}</Heading>
              <VisaAppsContainer>
                {approvedVisaApplications?.map(
                  (visaApplication: VisaApplication) => (
                    <ApplicationCard
                      key={`visa-application-${visaApplication.id}`}
                      visaApplication={visaApplication}
                      selected={selectedVisaApp?.id === visaApplication.id}
                      onClick={() => {
                        setSelectedVisaApp(visaApplication);
                      }}
                    />
                  )
                )}
              </VisaAppsContainer>
              <ActionButtonContainer>
                <Button
                  disabled={selectedVisaApp === null}
                  onClick={() => onClickUseThisAppData(selectedVisaApp!)}
                >
                  {tPastData("useThisAppData")}
                </Button>
              </ActionButtonContainer>
            </>
          )}
        </Content>
      </ContentContainer>
    </Modal>
  );
};

export default InputAssistanceModal;
