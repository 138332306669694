import { ChangeBySelfData } from "../types/visa/changeBySelf/data";
import { ExtensionBySelfData } from "../types/visa/extensionBySelf/data";
import { StatusOfResidenceCode as StatusOfResidenceCodeForExtensionBySelf } from "../types/visa/extensionBySelf/value";
import { FormN } from "../types/visa/uncommonFormParts/formN/data";
import { FormP } from "../types/visa/uncommonFormParts/formP/data";
import { CSVSourceKind, FormKind, VisaApplication, VisaMessage, VisaSignature, VisaSignatureKind, VisaSubmission } from "../types/visa/applicationData";
import { VisaApplicationStatus } from "../types/visa/applicationStatus";
import { VisaApplicationType } from "../types/visa/applicationType";
import { ApplicationTypeTransKey, VisaTransKey } from "../types/visa/transKeys";
import { ApplicationCategoryCodeForChangeBySelf } from "../types/visa/changeBySelf/value";
import { ApplicationCategoryCodeForExtensionBySelf } from "../types/extensionBySelf/specificValues";
import { FormR } from "../types/visa/uncommonFormParts/formR/data";

const isVisaApplication = (val: unknown): val is VisaApplication => {
  return typeof val === 'object' && val !== null && val.hasOwnProperty('csv_source_kind');
}

export const checkVisaApplicationType = (visaApplication: VisaApplication): VisaApplicationType | undefined => {
  
  //selSnsiShbt : 申請種別 / Application category
  switch (visaApplication.csv_source_kind) {
    case CSVSourceKind.ExtensionBySelf:
    {
      const applicationCategoryCode = visaApplication.koushin_honnin["selSnsiShbt"];

      switch (applicationCategoryCode) {
        case ApplicationCategoryCodeForExtensionBySelf.Dependent:
          return VisaApplicationType.ExtendDependentVisaBySelf;
        case ApplicationCategoryCodeForExtensionBySelf.Gijinkoku:
          return VisaApplicationType.ExtendGijinkokuVisaBySelf;
        case StatusOfResidenceCodeForExtensionBySelf.StudyAbroad:
          return VisaApplicationType.ExtendStudyAbroadVisaBySelf;
      }
      break;
    }

    case CSVSourceKind.ChangeBySelf:
    {
      const applicationCategoryCode = visaApplication?.henkou_honnin["selSnsiShbt"];
      switch (applicationCategoryCode) {
        case ApplicationCategoryCodeForChangeBySelf.Dependent:
          return VisaApplicationType.ChangeToDependentVisaBySelf;
        case ApplicationCategoryCodeForChangeBySelf.Gijinkoku:
          return VisaApplicationType.ChangeToGijinkokuVisaBySelf;
      }
      break;
    }
  }
}

export const isThisVisaExtension = (val: VisaApplication | VisaApplicationType | null | undefined): boolean => {
  let visaApplicationType;

  if (isVisaApplication(val)) 
    visaApplicationType = checkVisaApplicationType(val);
  else
    visaApplicationType = val;
  
  switch (visaApplicationType) {
    case VisaApplicationType.ExtendDependentVisaBySelf:
    case VisaApplicationType.ExtendGijinkokuVisaBySelf:
    case VisaApplicationType.ExtendStudyAbroadVisaBySelf:
      return true;
    default:
      return false;
  }
}

export const isVisaExtensionApplication = (val: VisaApplication | null | undefined): val is VisaApplication & { 
  csv_source_kind: CSVSourceKind.ExtensionBySelf,
  koushin_honnin: ExtensionBySelfData, 
} => {

  if (!val)
    return false;

  return (
    val.csv_source_kind === CSVSourceKind.ExtensionBySelf &&
    val.koushin_honnin !== null
  );
}

export const isThisVisaChange = (val: VisaApplication | VisaApplicationType): boolean => {
  let visaApplicationType;

  if (isVisaApplication(val)) 
    visaApplicationType = checkVisaApplicationType(val);
  else
    visaApplicationType = val;

  switch (visaApplicationType) {
    case VisaApplicationType.ChangeToDependentVisaBySelf:
    case VisaApplicationType.ChangeToGijinkokuVisaBySelf:
      return true;
    default:
      return false;
  }
}

export const isVisaChangeApplication = (val: VisaApplication | null | undefined): val is VisaApplication & {
  csv_source_kind: CSVSourceKind.ChangeBySelf,
  henkou_honnin: ChangeBySelfData,
} => {
  if (!val)
    return false;

  return (
    val.csv_source_kind === CSVSourceKind.ChangeBySelf &&
    val.henkou_honnin !== null
  );
}

export const isExtensionBySelfData = (data: ExtensionBySelfData | ChangeBySelfData): data is ExtensionBySelfData => {
  //Checking "Reason for extension" property
  const uniqueKey: keyof ExtensionBySelfData = 'WCICS010Dto:txtKusnReason';
  return data.hasOwnProperty(uniqueKey);
}

export const isChangeBySelfData = (data: ExtensionBySelfData | ChangeBySelfData): data is ChangeBySelfData => {
  //Checking "Reason for change" property
  const uniqueKey: keyof ChangeBySelfData = 'WCICS020Dto:txtKusnReason';
  return data.hasOwnProperty(uniqueKey);
}

export const isVisaApplicationWithFormR = (visaApplication?: VisaApplication | null): visaApplication is VisaApplication & ({
  form_kind: FormKind.R,
  form_r: FormR
}) => {
  return visaApplication ? (
    visaApplication.form_kind === FormKind.R &&
    visaApplication.form_r !== null
  ) : false;
}

export const isVisaApplicationWithFormN = (visaApplication?: VisaApplication | null): visaApplication is VisaApplication & ({
  form_kind: FormKind.N,
  form_n: FormN
}) => {
  return visaApplication ? (
    visaApplication.form_kind === FormKind.N &&
    visaApplication.form_n !== null
  ) : false;
}

export const isVisaApplicationWithFormP = (visaApplication?: VisaApplication | null): visaApplication is VisaApplication & ({
  form_kind: FormKind.P,
  form_p: FormP
}) => {
  return visaApplication ? (
    visaApplication.form_kind === FormKind.P &&
    visaApplication.form_p !== null
  ) : false;
}

export const isThisVisaNecessaryToWorkWithOrg = (val: VisaApplication | VisaApplicationType): boolean => {
  let visaApplicationType;

  if (isVisaApplication(val)) 
    visaApplicationType = checkVisaApplicationType(val);
  else 
    visaApplicationType = val;
  
  switch (visaApplicationType) {
    case VisaApplicationType.ExtendGijinkokuVisaBySelf:
    case VisaApplicationType.ExtendStudyAbroadVisaBySelf:
    case VisaApplicationType.ChangeToGijinkokuVisaBySelf:
      return true;
    default:
      return false;
  }
}

//Try to find the corresponding translation keys based on the visa application 
//e.g.
//  VisaApplicationType.ExtendDependentVisaBySelf => { visa: "dependent", type: "extension" } 
export const getVisaInfoTransKeys = (VisaApplication: VisaApplication): Record<"visa" | "type", string> => {
  const visaApplicationType = checkVisaApplicationType(VisaApplication);
  switch (visaApplicationType) {
    case VisaApplicationType.ExtendDependentVisaBySelf:
      return {
        visa: VisaTransKey.Dependent,
        type: ApplicationTypeTransKey.Extension,
      };
    case VisaApplicationType.ExtendGijinkokuVisaBySelf:
      return {
        visa: VisaTransKey.Gijinkoku,
        type: ApplicationTypeTransKey.Extension,
      };
    case VisaApplicationType.ExtendStudyAbroadVisaBySelf:
      return {
        visa: VisaTransKey.StudyAbroad,
        type: ApplicationTypeTransKey.Extension,
      }
    case VisaApplicationType.ChangeToDependentVisaBySelf:
      return {
        visa: VisaTransKey.Dependent,
        type: ApplicationTypeTransKey.Change,
      };
    case VisaApplicationType.ChangeToGijinkokuVisaBySelf:
      return {
        visa: VisaTransKey.Gijinkoku,
        type: ApplicationTypeTransKey.Change,
      };
    default:
      return {
        visa: "",
        type: "",
      };
  }
}

export const getVisaApplicationStatus = (visaApplication: VisaApplication): VisaApplicationStatus => {
  const lastSubmission = visaApplication.last_submitted_visa_submission;

  if (!lastSubmission)
    return VisaApplicationStatus.Working;

  return lastSubmission.app_status;
}

export const getProcessedDate = (visaApplication: VisaApplication): string | null => {
  return visaApplication?.last_submitted_visa_submission?.processed_at ?? null;
}

export const isThisVisaApplicationInsufficient = (visaApplication: VisaApplication): boolean => {
  return getVisaApplicationStatus(visaApplication) === VisaApplicationStatus.Insufficient;
}

export const isThisVisaApplicationApproved = (visaApplication: VisaApplication): boolean => {
  return getVisaApplicationStatus(visaApplication) === VisaApplicationStatus.Approved;
}

export const isThisVisaApplicationDenied = (visaApplication: VisaApplication): boolean => {
  return getVisaApplicationStatus(visaApplication) === VisaApplicationStatus.Denied;
}

export const isThisVisaApplicationWorking = (visaApplication: VisaApplication): boolean => {
  return getVisaApplicationStatus(visaApplication) === VisaApplicationStatus.Working;
}

export const getVisaMessages = (val: VisaApplication | VisaSubmission): VisaMessage[] => {
  if (isVisaApplication(val)) {
    const lastSubmission = val.last_submitted_visa_submission;
  
    if (!lastSubmission)
      return [];
  
    return lastSubmission.visa_messages;
  }
  else {
    return val.visa_messages;
  }
}

export const isThisVisaApplicationSubmitted = (visaApplication: VisaApplication): boolean => {
  return visaApplication.last_submitted_visa_submission !== null;
}

export const findLatestApprovedChangeVisaApplication = (visaApplications: VisaApplication[] | null | undefined): VisaApplication | null => {
  const visaAppsInDesc = visaApplications?.sort((a, b) => {
    const aDate = new Date(a.created_at);
    const bDate = new Date(b.created_at);

    return bDate.getTime() - aDate.getTime();
  });

  return visaAppsInDesc?.find(visaApplication => (
    isThisVisaApplicationApproved(visaApplication) && 
    isVisaChangeApplication(visaApplication)
  )) ?? null;
}

export const doesVisaSignatureExist = (visaApplication: VisaApplication, kind?: VisaSignatureKind): boolean => {
  if (!kind)
    return visaApplication.visa_signatures.length > 0;

  return visaApplication.visa_signatures.some(signature => signature.kind === kind);
}

export const tryToFindVisaSignature = (visaApplication: VisaApplication, kind: VisaSignatureKind): VisaSignature | null => {
  return visaApplication.visa_signatures.find(signature => signature.kind === kind) ?? null;
}
