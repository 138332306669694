import { FunctionComponent, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';
import InlineMessage from '../form/InlineMessage';
import Label from '../form/Label';
import ErrorMessage from '../display/ErrorMessage';

type Theme = 'light' | 'dark';

interface CompoundFieldProps extends HTMLAttributes<HTMLDivElement> {
    title?: string;
    note?: string | ReactNode;
    theme?: Theme;
    required?: boolean;
    optional?: boolean;
    error?: string;
}

interface ThemeProps {
    theme?: Theme;
}

const Container = styled.div<ThemeProps>`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  padding: 20px;
  border-radius: 3px;
  border: ${(props: ThemeProps) => (props.theme === 'dark' ? 'none' : 'solid 1px #D5D5D5')};
  background: ${(props: ThemeProps) => (props.theme === 'dark' ? '#EDEDED' : '#FFFFFF')};
`;

const Column5 = styled.div`   
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const LocalLabel = styled(Label)<ThemeProps>`
  font-weight: ${(props: ThemeProps) => (props.theme === 'dark' ? 500 : 700 )};
`;

const FieldGroup: FunctionComponent<CompoundFieldProps> = ({
    title,
    error,
    note,
    theme = 'dark',
    required,
    optional,
    children,
    ...props
}) => {
    return (
        <Container theme={theme} {...props}>
            {title && (
                <Column5>
                    <LocalLabel required={required} optional={optional}>
                        {title}
                    </LocalLabel>
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                </Column5>
            )}

            {!title && error && (
                <ErrorMessage>{error}</ErrorMessage>
            )}

            {note && (
                <InlineMessage>{note}</InlineMessage>
            )}
            {children}
        </Container>
    );
};

export default FieldGroup;