import styled from "styled-components";

export const Column5 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const Column10 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Column30 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const NoteOrderedList = styled.ol`
  padding-inline-start: 18px;
  margin: 0;
`;

export const NoteUnorderedList = styled.ul`
  padding-inline-start: 18px;
  margin: 0;
  list-style-type: disc;
`;

