import { ButtonHTMLAttributes, FunctionComponent } from 'react';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../assets/icon-cross.svg';

type CloseButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  onClick?: () => void; 
};

const LocalButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  background-color: transparent;
`;

const CloseButton: FunctionComponent<CloseButtonProps> = ({
  onClick,
  ...props
}) => {
  return (
    <LocalButton 
      onClick={() => onClick?.()}
      {...props} 
    >
      <CloseIcon/>
    </LocalButton>
  );
};

export default CloseButton;