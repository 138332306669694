import { FunctionComponent, useEffect, useState } from "react";
import { ExtensionBySelfData } from "../../../../../../types/visa/extensionBySelf/data";
import { ChangeBySelfData } from "../../../../../../types/visa/changeBySelf/data";
import {
  formatDate,
  getLabelFromCB06_RelationCode,
  getLabelFromNationalityCode,
  getLengthOfZincSnzkList,
  getSnzkListKeyOf,
} from "../../../../../../utils/visaFormDataHelpers/commonVisaFormDataHelper";
import { useTranslation } from "react-i18next";
import { PreviewItem } from "../../../../../../types/modal/preview";
import {
  isChangeBySelfData,
  isExtensionBySelfData,
} from "../../../../../../utils/visaApplicationHelper";
import {
  Group,
  SectionHeading,
  VariableItem,
} from "../../previewBodyCommonStyle";
import PreviewList from "../../../PreviewList";
import FieldGroupNumber from "../../../../../display/FieldGroupNumber";
import {
  FamilyInJapanOrCoresidents,
  LivingTogetherWithThisFamilyOrCoresident,
} from "../../../../../../types/visa/formCommonValues";

interface FamilyCoresidentsSectionProps {
  data: ExtensionBySelfData | ChangeBySelfData;
}

export const FamilyCoresidentsSection: FunctionComponent<
  FamilyCoresidentsSectionProps
> = ({ data }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "familyCoresidentsSection",
  });
  const { t: tWithoutPrefix } = useTranslation();
  const length = getLengthOfZincSnzkList(data);
  const [previewItemsList, setPreviewItemsList] = useState<PreviewItem[][]>([]);

  useEffect(() => {
    const itemsList: PreviewItem[][] = [];

    for (let i = 1; i <= length; i++) {
      const items: PreviewItem[] = [];

      if (isExtensionBySelfData(data)) {
        items.push(
          {
            label: t("relationship"),
            value: tWithoutPrefix(
              `${
                getLabelFromCB06_RelationCode(
                  data[
                    getSnzkListKeyOf.selZkgr(i) as keyof ExtensionBySelfData
                  ] as string
                ) ?? ""
              }`
            ),
          },
          {
            label: t("name"),
            value: data[
              getSnzkListKeyOf.txtName(i) as keyof ExtensionBySelfData
            ] as string,
          },
          {
            label: t("birthdate"),
            value: formatDate(
              data[
                getSnzkListKeyOf.selDateOfBirthYear(
                  i
                ) as keyof ExtensionBySelfData
              ] as string,
              data[
                getSnzkListKeyOf.selDateOfBirthMonth(
                  i
                ) as keyof ExtensionBySelfData
              ] as string,
              data[
                getSnzkListKeyOf.selDateOfBirthDay(
                  i
                ) as keyof ExtensionBySelfData
              ] as string
            ),
          },
          {
            label: t("nationality"),
            value: tWithoutPrefix(
              `${
                getLabelFromNationalityCode(
                  data[
                    getSnzkListKeyOf.selNationalityAndRegion(
                      i
                    ) as keyof ExtensionBySelfData
                  ] as string
                ) ?? ""
              }`
            ),
          },
          {
            label: t("livigingTogether"),
            value: (() => {
              switch (
                data[
                  `WCIBS010Dto:zincSnzkList[${i}].radDukyUm` as keyof ExtensionBySelfData
                ] as string
              ) {
                case LivingTogetherWithThisFamilyOrCoresident.Yes:
                  return tWithoutPrefix("common.yes");
                case LivingTogetherWithThisFamilyOrCoresident.No:
                  return tWithoutPrefix("common.no");
              }
            })(),
          },
          {
            label: t("employmentOrSchool"),
            value: data[
              getSnzkListKeyOf.txtWorkPlaceOrTugkskName(
                i
              ) as keyof ExtensionBySelfData
            ] as string,
          },
          {
            label: t("residenceCardNumber"),
            value: data[
              getSnzkListKeyOf.txtZiryCardNumOrTkeiNum(
                i
              ) as keyof ExtensionBySelfData
            ] as string,
          }
        );
      }

      if (isChangeBySelfData(data)) {
        items.push(
          {
            label: t("relationship"),
            value: tWithoutPrefix(
              getLabelFromCB06_RelationCode(
                `${
                  data[
                    getSnzkListKeyOf.selZkgr(i) as keyof ChangeBySelfData
                  ] as string
                }`
              )
            ),
          },
          {
            label: t("name"),
            value: data[
              getSnzkListKeyOf.txtName(i) as keyof ChangeBySelfData
            ] as string,
          },
          {
            label: t("birthdate"),
            value: formatDate(
              data[
                getSnzkListKeyOf.selDateOfBirthYear(i) as keyof ChangeBySelfData
              ] as string,
              data[
                getSnzkListKeyOf.selDateOfBirthMonth(
                  i
                ) as keyof ChangeBySelfData
              ] as string,
              data[
                getSnzkListKeyOf.selDateOfBirthDay(i) as keyof ChangeBySelfData
              ] as string
            ),
          },
          {
            label: t("nationality"),
            value:
              tWithoutPrefix(
                `${getLabelFromNationalityCode(
                  data[
                    getSnzkListKeyOf.selNationalityAndRegion(
                      i
                    ) as keyof ChangeBySelfData
                  ] as string
                )}`
              ) ?? "",
          },
          {
            label: t("livigingTogether"),
            value: (() => {
              switch (
                data[getSnzkListKeyOf.radDukyUm(i) as keyof ChangeBySelfData]
              ) {
                case LivingTogetherWithThisFamilyOrCoresident.Yes:
                  return tWithoutPrefix("common.yes");
                case LivingTogetherWithThisFamilyOrCoresident.No:
                  return tWithoutPrefix("common.no");
              }
            })(),
          },
          {
            label: t("employmentOrSchool"),
            value: data[
              `WCIBS010Dto:zincSnzkList[${i}].txtWorkPlaceOrTugkskName` as keyof ChangeBySelfData
            ] as string,
          },
          {
            label: t("residenceCardNumber"),
            value: data[
              `WCIBS010Dto:zincSnzkList[${i}].txtZiryCardNumOrTkeiNum` as keyof ChangeBySelfData
            ] as string,
          }
        );
      }

      itemsList.push(items);
    }

    setPreviewItemsList(itemsList);
  }, []);

  return (
    <section>
      <SectionHeading>{t("title")}</SectionHeading>
      <PreviewList
        items={[
          {
            label: t("familyInJapan"),
            value: (() => {
              switch (data["WCIBS010Dto:radZincSnzkUm1"]) {
                case FamilyInJapanOrCoresidents.Present:
                  return tWithoutPrefix("common.yes");
                case FamilyInJapanOrCoresidents.None:
                  return tWithoutPrefix("common.no");
              }
            })(),
          },
        ]}
      />
      {previewItemsList.length > 0 && (
        <Group>
          {previewItemsList.map((items, i) => (
            <VariableItem key={`family-coresident-${i}`}>
              <FieldGroupNumber>{i + 1}</FieldGroupNumber>
              <PreviewList items={items} />
            </VariableItem>
          ))}
        </Group>
      )}
    </section>
  );
};

export default FamilyCoresidentsSection;
