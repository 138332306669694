import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ACTIONS from './actions';
import { SnackbarType } from './types/snackbar';
import { ReducerContext } from './reducer';
import { isEnumValIncluded, wait } from './utils/utils';
import { UserRegistrationApplicationStatus } from './types/userRegistration/applicationStatus';
import { NextAction, OnboardingNextAction, UserAuthenticationModalNextAction, VisaMessageModalNextAction, VisaStatusConfirmationModalNextAction, VisaSubmissionModalNextAction } from './types/nextActions';
import { LanguageCode } from './types/language';

export const useComponentVisible = (initialVisible: boolean) => {
    const [visible, setVisible] = useState(initialVisible)
    const ref = useRef<HTMLDivElement>(null)

    const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
            setVisible(false)
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    })

    return {ref, visible, setVisible}
}


export const useDispatcher = () => {
    const { state, dispatch } = useContext(ReducerContext);
    const createAction = (type: string, payload?: object) => ({ type, payload });
    const dispatcher = {
        login: function () {
            dispatch(createAction(ACTIONS.LOGIN));   
        },
        logout: function () {
            dispatch(createAction(ACTIONS.LOGOUT));
        },
        treatAsLoggedIn: function () {
            dispatch(createAction(ACTIONS.TREAT_AS_LOGGED_IN));
        },
        treatAsLoggedOut: function () {
            dispatch(createAction(ACTIONS.TREAT_AS_LOGGED_OUT));
        },
        startLoading: function (message?: string) {
            dispatch(createAction(ACTIONS.START_LOADING, { message }));
        },
        stopLoading: function () {
            dispatch(createAction(ACTIONS.STOP_LOADING));
        },
        showSnackbar: function (message: string, type: SnackbarType = SnackbarType.Success) {
            (async () => { 
                //For some reason, an await call is necessary before this method is called more than once.
                //Or, the snackbar doesn't appear in the current logic. 
                //Maybe better to change the implementation later...?
                await wait(0);
                this.clearSnackbar();
                dispatch(createAction(ACTIONS.SHOW_SNACKBAR, {message, type}));
            })();
        },
        showSuccessOnSnackbar: function (message: string) {
            this.showSnackbar(message, SnackbarType.Success);
        },
        showWarningOnSnackbar: function (message: string) {
            this.showSnackbar(message, SnackbarType.Warning);
        },
        clearSnackbar: function () {
            dispatch(createAction(ACTIONS.CLEAR_SNACKBAR));
        },
        startSaving: function () {
            dispatch(createAction(ACTIONS.START_SAVING));
        },
        endSaving: function () {
            dispatch(createAction(ACTIONS.END_SAVING));
        },
        startUploading: function (uploaderId?: string) {
            dispatch(createAction(ACTIONS.START_UPLOADING, { uploaderId }));
        },
        endUploading: function (uploaderId?: string) {
            dispatch(createAction(ACTIONS.END_UPLOADING, { uploaderId }));
        },
        setSetupComplete: function (isSetupComplete: boolean) {
            dispatch(createAction(ACTIONS.SET_SETUP_COMPLETE, { isSetupComplete }));
        },
        setNextAction: function (nextAction: NextAction | string) {
            if (typeof nextAction === 'string') 
                dispatch(createAction(ACTIONS.SET_NEXT_ACTION, { nextAction: { identifier: nextAction } }));
            else
                dispatch(createAction(ACTIONS.SET_NEXT_ACTION, { nextAction }));
        },
        clearNextAction: function () {
            dispatch(createAction(ACTIONS.CLEAR_NEXT_ACTION));
        },
        setIsOnboardingRequired: function (isOnboardingRequired: boolean) {
            dispatch(createAction(ACTIONS.SET_IS_ONBOARDING_REQUIRED, { isOnboardingRequired }));
        }
    };

    return { state, dispatcher };
}

export const useValidation = () => {
    const { t } = useTranslation();

    return {
        isAlpha: (value: string) => {
            const result = /^[A-Za-z ]*$/.test(value);
            return {
                success: result,
                message: result ? '' : t('validation.onlyAlpha'),
            };
        },
        isNumeric: (value: string) => {
            const result = /^[0-9 ]*$/.test(value);
            return {
                success: result,
                message: result ? '' : t('validation.onlyNumeric'),
            };
        },
        isAlphanumeric: (value: string) => {
            const result = /^[A-Za-z0-9 ]*$/.test(value);
            return {
                success: result,
                message: result ? '' : t('validation.onlyAlphanumeric'),
            };
        },
        isFullwidth: (value: string) => {
            const result = /^[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]*$/.test(value);
            return {
                success: result,
                message: result ? '' : t('validation.onlyFullwidth'),
            };
        },
        isEmailAddress: (value: string) => {
            const result = /^[a-zA-Z0-9.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
            return {
                success: result,
                message: result ? '' : t('validation.isEmailAddress'),
            };
        },
        createLengthValidator: (length: number) => {
            return (value: string) => {
                const result = value.length <= length;
                return {
                    success: result,
                    message: result ? '' : t('validation.maxLengthAllowed', { number: length }),
                };
            };
        },
    };
};


export const useFieldPlaceholder = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'placeholder' });
    return {
      select: t('select'),
      selectPrefecture: t('selectPrefecture'),
      selectMunicipality: t('selectMunicipality'),
      selectNationality: t('selectNationality'),
      year: t('year'),
      years: t('years'),
      month: t('month'),
      months: t('months'),
      day: t('day'),
      search: t('search'),
      selectVisaType: t('selectVisaType'),
      reasonForExtension: t('reasonForExtension'),
    }
  }
  
  
  export const useFieldInputNotes = () => {
    const { i18n, t } = useTranslation('translation', { keyPrefix: 'inputNote' });
    const currentLang = i18n.language;
    const hideLangs = [LanguageCode.English];
    const needToBeHidden = hideLangs.includes(currentLang as LanguageCode);
  
   const inputNotes = {
      halfwidth: t('halfwidth'),
      halfwidthNumber: t('halfwidthNumber'),
      halfwidthLetterAndNumber: t('halfwidthLetterAndNumber'),
      halfwidthNumberNoHyphens: t('halfwidthNumberNoHyphens'),
      fullwidthJapanese: t('fullwidthJapanese'),
    }
  
    const conditionalInputNotes = {
      halfwidth: needToBeHidden ? '' : t('halfwidth'),
      halfwidthNumber: needToBeHidden ? '' : t('halfwidthNumber'),
      halfwidthLetterAndNumber: needToBeHidden ? '' : t('halfwidthLetterAndNumber'),
      halfwidthNumberNoHyphens: needToBeHidden ? '' : t('halfwidthNumberNoHyphens'),
      fullwidthJapanese: needToBeHidden ? '' : t('fullwidthJapanese'),
    }
  
    return {
      inputNotes,
      conditionalInputNotes
    }
  
  }
  

  export const useNextAction = () => {
    const { state, dispatcher } = useDispatcher();
  
    const isOnboardingNextAction =
      () => isEnumValIncluded(OnboardingNextAction, state.nextAction.identifier);
  
    const isVisaSubmissionModalNextAction = 
      () => isEnumValIncluded(VisaSubmissionModalNextAction, state.nextAction.identifier);
  
    const isAuthModalNextAction =
      () => isEnumValIncluded(UserAuthenticationModalNextAction, state.nextAction.identifier);
  
    const isVisaMessageModalNextAction =
      () => isEnumValIncluded(VisaMessageModalNextAction, state.nextAction.identifier);
  
    const isVisaStatusConfirmationModalNextAction =
      () => isEnumValIncluded(VisaStatusConfirmationModalNextAction, state.nextAction.identifier);
    
  
    const consumeNextAction = () => {
      const nextAction = state.nextAction;
      dispatcher.clearNextAction();
      return nextAction;
    }
  
    return {
      isOnboardingNextAction,
      isVisaSubmissionModalNextAction,
      isAuthModalNextAction,
      isVisaMessageModalNextAction,
      isVisaStatusConfirmationModalNextAction,
      consumeNextAction
    }
  }