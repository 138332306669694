
import { FormN } from "../types/visa/uncommonFormParts/formN/data";
import { axiosInstance } from "./common";

export const createFormN = async (visaApplicationId: number | string, data?: Partial<FormN>) => {
  const response = await axiosInstance.request({
      method: 'POST',
      url: `form_ns`,
      data: {
        ...data,
        visa_application_id: visaApplicationId
      }
  });

  return response.data as FormN;
};

export const getFormN = async (formRId: number | string) => {
  const response = await axiosInstance.request({
      method: 'GET',
      url: `form_ns/${formRId}`
  });

  return response.data as FormN;
};

export const updateFormN = async (formRId: number | string, data: Partial<FormN>) => {
  const response = await axiosInstance.request({
      method: 'PUT',
      url: `form_ns/${formRId}`,
      data
  });

  return response.data as FormN;
};

export const updateFormNAsInvitee = async (formRId: number | string, data: Partial<FormN>, invitationToken: string) => {
  const response = await axiosInstance.request({
      method: 'PUT',
      url: `form_ns/${formRId}/invitee_update`,
      data: {
        ...data,
        invitation_token: invitationToken
      }
  });

  return response.data as FormN;
};

export const deleteFormN = async (formRId: number | string) => {
  const response = await axiosInstance.request({
      method: 'DELETE',
      url: `form_ns/${formRId}`
  });

  return response.data as FormN;
};