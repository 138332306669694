import { FunctionComponent, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import Modal from "../../components/modal/Modal";
import Button from "../../components/form/Button";
import Text from "../../components/text/Text";
import Column from "../../components/layout/Column";
import { ReactComponent as SuccessIcon } from "../../assets/icon-checked.svg";
import { CONTENT_MAX_WIDTH } from "../../constants/styles";
import { useDispatcher, useFieldInputNotes, useFieldPlaceholder, useNextAction, useValidation } from "../../hooks";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/navigation/BackButton";
import { VISADAS_SUPPORT_EMAIL } from "../../constants/values/commonValues";
import EmailAnchor from "../../components/text/EmailAnchor";
import {
  MUNICIPALITY_OPTIONS,
  PREFECTURE_OPTIONS,
} from "../../constants/options";
import { UserRegistration } from "../../types/userRegistration/data";
import {
  Gender,
  TermsOfUseAgreement,
  UserCategory,
} from "../../types/userRegistration/value";
import {
  createUserRegistration,
  createUserRegistrationApplication,
  createUserRegistrationSubmission,
  fetchFirstMynaURLToSubmitUserRegistrationApplication,
  fetchSecondMynaURLToSubmitUserRegistrationApplication,
  getLatestUserRegistrationApplication,
  submitUserRegistrationApplication,
  updateUserRegistration,
} from "../../apis/userRegistration";
import TextField from "../../components/compound/TextField";
import RadioField from "../../components/compound/RadioField";
import DateSelectField from "../../components/compound/DateSelectField";
import InlineMessage from "../../components/form/InlineMessage";
import SelectField from "../../components/compound/SelectField";
import MultiTextField from "../../components/compound/MultiTextField";
import Checkbox from "../../components/form/Checkbox";
import { getCurrentUser, updateCurrentUser } from "../../apis/user";
import { isUserRegistrationApplicationDenied } from "../../utils/userRegistrationHelper";
import Spinner from "../../components/display/Spinner";
import { OnboardingNextAction as NextAction } from "../../types/nextActions";
import { isValueEmpty } from "../../utils/utils";
import { QUERY_KEYS } from "../../constants/values/query";
import { useQueryClient } from "@tanstack/react-query";
import BottomAlignedContainer from "../../components/layout/BottomAlignedContainer";

interface OnboardingPageProps {}

enum ContentType {
  Welcome,
  UserAuthenticationRequest,
  TellUsAboutYourself1,
  TellUsAboutYourself2,
  DownloadMynaApp,
  ReadMynaCard1stTime,
  ReadMynaCard2ndTime,
  Processing,
  UserAuthenticationRequested,
}

enum ServiceType {
  ApplyForYourself,
  RequestAnAdministrativeScrivener,
}

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  overflow: auto;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
  max-width: ${CONTENT_MAX_WIDTH}px;
  padding: 20px;
  margin: 0 auto;
  overflow-y: auto;
`;

const NavContainer = styled.nav`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FormContentContainer = styled.div`
  width: 100%;
  padding-bottom: 50px;
`;

const LocalColumn = styled(Column)`
  gap: 20px;
`;

const FieldWrapper = styled(Column)`
  padding-top: 10px;
  gap: 50px;
`;

const ResidenceFieldWrapper = styled(Column)`
  padding: 20px;
  background-color: #ededed;
  border-radius: 3px;
  gap: 30px;
`;

const HeadingText = styled(Text)`
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 29px;
`;

const MessageTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StepTextContainer = styled.div`
  width: 100%;
  padding: 10px;
  background-color: #f2f2f2;
`;

const AppTypeSelectButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const NavText = styled(Text)`
  font-size: 0.938rem;
  color: #999999;
`;

const LinkAnchor = styled.a`
  color: #017698;
`;

const LocalOl = styled.ol`
  margin: 0;
  padding-left: 25px;
`;

const LocalLi = styled.li`
  &::marker {
    font-size: 0.875rem;
    color: #444444;
  }
`;

const SupplementaryTextContainer = styled.div``;

const VerifyingResultsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SmallText = styled(Text)`
  white-space: "pre-wrap";
  display: inline;
  font-size: 0.8rem;
  color: #999999;
  line-height: 17px;
`;

const AppAnchor = styled.a`
  color: #017698;
`;

const LocalButton = styled(Button)`
  min-width: 280px;
  @media (max-width: ${CONTENT_MAX_WIDTH}px) {
    width: 100%;
  }
`;

const OnboardingPage: FunctionComponent<OnboardingPageProps> = () => {
  const { inputNotes, conditionalInputNotes } = useFieldInputNotes();
  const placeholder = useFieldPlaceholder();
  const { state, dispatcher } = useDispatcher();
  const { isOnboardingNextAction, consumeNextAction } = useNextAction();
  const queryClient = useQueryClient();
  const contentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const validation = useValidation();
  const { t } = useTranslation();
  const { t: tCommon } = useTranslation("translation", {
    keyPrefix: "common",
  });
  const { t: tWelcome } = useTranslation("translation", {
    keyPrefix: "onboardingPage.welcome",
  });
  const { t: tAuthReq } = useTranslation("translation", {
    keyPrefix: "onboardingPage.userAuthRequest",
  });
  const { t: tTellUs1 } = useTranslation("translation", {
    keyPrefix: "onboardingPage.tellUsAboutYourself1",
  });
  const { t: tTellUs2 } = useTranslation("translation", {
    keyPrefix: "onboardingPage.tellUsAboutYourself2",
  });
  const { t: tMynaApp } = useTranslation("translation", {
    keyPrefix: "onboardingPage.downloadMynaApp",
  });
  const { t: tMyna1st } = useTranslation("translation", {
    keyPrefix: "onboardingPage.readMynaCard1stTime",
  });
  const { t: tMyna2nd } = useTranslation("translation", {
    keyPrefix: "onboardingPage.readMynaCard2ndTime",
  });
  const { t: tProcessing } = useTranslation("translation", {
    keyPrefix: "onboardingPage.processing",
  });
  const { t: tReqAuth } = useTranslation("translation", {
    keyPrefix: "onboardingPage.authRequested",
  });
  const showFailureMessage = () => 
    dispatcher.showWarningOnSnackbar(t("snackbar.failedToProceed"));
  const scrollToTop = () => {
    contentRef.current?.scrollIntoView({ block: "start" });
  };
  const [userRegistrationId, setUserRegistrationId] = useState("");
  const [contentType, setContentType] = useState<ContentType>(
    isUserRegistrationApplicationDenied(state.userRegistrationAppStatus)
      ? ContentType.UserAuthenticationRequest
      : ContentType.Welcome
  );
  const comebackDir = "/onboarding";

  //To find out if the user focuses on any input field now
  //Used to control if the next button should be disabled
  const [isUserInputting, setIsUserInputting] = useState(false);

  //TODO: Currently, we use this fixed value.
  //In the future, we will change this value based on the user input
  const [userCategory] = useState(UserCategory.ForeignerSelf);

  //For "Tell us about yourself (1/2)" Form
  const [familyName, setFamilyName] = useState("");
  const [givenName, setGivenName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [gender, setGender] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [faimilyNameError, setFamilyNameError] = useState("");
  const [givenNameError, setGivenNameError] = useState("");
  const [middleNameError, setMiddleNameError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [emailError, setEmailError] = useState("");
  const firstHalfData: Partial<UserRegistration> = {
    "WCFDS010Dto:hdnUserInfoName": `${familyName} ${givenName} ${middleName}`,
    "WCFDS010Dto:hdnSex": gender,
    "WCFDS010Dto:hdnDateOfBirthYear": birthdate.split("-")[0] ?? "",
    "WCFDS010Dto:hdnDateOfBirthMonth": birthdate.split("-")[1] ?? "",
    "WCFDS010Dto:hdnDateOfBirthDay": birthdate.split("-")[2] ?? "",
    "WCFDS010Dto:txtUserTelNo": phoneNumber,
    "WCFDS010Dto:txtMailAddress": email,
    "WCFDS010Dto:txtMailAddressChk": email,
  };
  const isNoErrorInFirstHalfData = [
    faimilyNameError,
    givenNameError,
    middleNameError,
    phoneNumberError,
    emailError,
  ].every(isValueEmpty);
  const isNoEmptyValuesInFirstHalfData = [
    familyName,
    givenName,
    gender,
    birthdate,
    phoneNumber,
    email,
  ].every(val => !!val);

  //For "Tell us about yourself (2/2)" Form
  const [prefecture, setPrefecture] = useState("");
  const [municipality, setMunicipality] = useState("");
  const [townStreetApartment, setTownStreetApartment] = useState("");
  const [residenceCardNum, setResidenceCardNum] = useState("");
  const [termsOfUseAgreement, setTermsOfUseAgreement] = useState("");
  const [prefectureError] = useState("");
  const [municipalityError] = useState("");
  const [townStreetApartmentError, setTownStreetApartmentError] = useState("");
  const [residenceCardNumError, setResidenceCardNumError] = useState("");
  const serviceTypeRef = useRef<ServiceType | null>(null);
  const secondHalfData: Partial<UserRegistration> = {
    "WCFDS010Dto:hdnSearchedAddress": `${prefecture}${municipality}`,
    "WCFDS010Dto:txtDetailHomeAddress": townStreetApartment,
    "WCFDS010Dto:txtZiryCardNum": residenceCardNum,
    "WCFDS010Dto:chkDui": termsOfUseAgreement,
  };
  const isNoErrorInSecondHalfData = [
    prefectureError,
    municipalityError,
    townStreetApartmentError,
  ].every(isValueEmpty);
  const isNoEmptyValuesInSecondHalfData = [
    prefecture,
    municipality,
    townStreetApartment,
    termsOfUseAgreement,
  ].every(val => !!val);

  const onClickBack = () => {
    switch (contentType) {
      case ContentType.TellUsAboutYourself1:
        setContentType(
          isUserRegistrationApplicationDenied(state.userRegistrationAppStatus)
            ? ContentType.UserAuthenticationRequested
            : ContentType.Welcome
        );
        break;
      case ContentType.TellUsAboutYourself2:
        setContentType(ContentType.TellUsAboutYourself1);
        break;
      case ContentType.DownloadMynaApp:
        setContentType(ContentType.TellUsAboutYourself2);
        break;
      case ContentType.ReadMynaCard1stTime:
        setContentType(ContentType.DownloadMynaApp);
        break;
    }
  };

  const onClickNext = async () => {
    switch (contentType) {
      case ContentType.Welcome:
        setContentType(ContentType.TellUsAboutYourself1);
        break;

      case ContentType.UserAuthenticationRequest:
        setContentType(ContentType.TellUsAboutYourself1);
        break;

      case ContentType.TellUsAboutYourself1:
        setContentType(ContentType.TellUsAboutYourself2);
        break;

      case ContentType.TellUsAboutYourself2:
        if (serviceTypeRef.current === ServiceType.ApplyForYourself) {
          setContentType(ContentType.DownloadMynaApp);
          return;
        }

        dispatcher.startLoading();
        try {
          await updateCurrentUser({ 
            uses_certified_administrative_procedures_legal_specialist: true
          });
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER] });
          dispatcher.setIsOnboardingRequired(false);
        } catch (e) {
          showFailureMessage();
        }
        dispatcher.stopLoading();
        navigate("/");
        break;

      case ContentType.DownloadMynaApp:
        setContentType(ContentType.ReadMynaCard1stTime);
        break;

      case ContentType.ReadMynaCard1stTime:
        dispatcher.startLoading();
        try {
          dispatcher.setNextAction(NextAction.Request2ndMynaURLToRead);
          const userRegistrationApplication =
            await getLatestUserRegistrationApplication();
          const userRegistrationSubmission =
            await createUserRegistrationSubmission(
              userRegistrationApplication.id
            );
          const result =
            await fetchFirstMynaURLToSubmitUserRegistrationApplication(
              userRegistrationSubmission.id,
              comebackDir
            );
          window.location.href = result.myna_cert_id_url;
        } catch (e) {
          dispatcher.clearNextAction();
          showFailureMessage();
        } finally {
          dispatcher.stopLoading();
        }
        break;

      case ContentType.ReadMynaCard2ndTime:
        dispatcher.startLoading();
        try {
          dispatcher.setNextAction(NextAction.RequestToSubmitUserAuth);
          const userRegistrationApplication =
            await getLatestUserRegistrationApplication();
          const userRegistrationSubmissionId =
            userRegistrationApplication.last_unsubmitted_riyousha_touroku_submission!.id;
          const result =
            await fetchSecondMynaURLToSubmitUserRegistrationApplication(
              userRegistrationSubmissionId,
              comebackDir
            );
          window.location.href = result.myna_cert_signature_url;
        } catch (e) {
          dispatcher.clearNextAction();
          showFailureMessage();
          setContentType(ContentType.ReadMynaCard1stTime);
        } finally {
          dispatcher.stopLoading();
        }
        break;

      case ContentType.UserAuthenticationRequested:
        navigate("/");
    }
  };

  const tryToSaveThenGoNext = async (data: Partial<UserRegistration>) => {
    dispatcher.startLoading();
    try {
      await updateUserRegistration(userRegistrationId, {
        "WCFDS010Dto:selUserKbn": userCategory,
        ...data,
      });
      onClickNext();
    } catch (e) {
      showFailureMessage();
    } finally {
      dispatcher.stopLoading();
    }
  };

  useEffect(() => {
    scrollToTop();
  }, [contentType]);

  useEffect(() => {
    if (!isOnboardingNextAction()) {
      dispatcher.clearNextAction();
      return;
    }

    const nextAction = consumeNextAction();

    switch (nextAction.identifier) {
      case NextAction.Request2ndMynaURLToRead:
        setContentType(ContentType.ReadMynaCard2ndTime);
        return;

      case NextAction.RequestToSubmitUserAuth:
        //Show this temporary screen until the submission is complete
        setContentType(ContentType.Processing);

        (async () => {
          try {
            const userRegistrationApplication =
              await getLatestUserRegistrationApplication();
            await submitUserRegistrationApplication(
              userRegistrationApplication.last_unsubmitted_riyousha_touroku_submission!
                .id
            );
            setContentType(ContentType.UserAuthenticationRequested);
          } catch (e) {
            showFailureMessage();
            setContentType(ContentType.ReadMynaCard1stTime);
          }
        })();
        break;
    }
  }, []);

  useEffect(() => {
    //If the user has already started reading Myna card,
    //No need to fetch the registration data as the user cannot go back to the form
    if (contentType > ContentType.ReadMynaCard1stTime) return;

    (async () => {
      try {
        dispatcher.startLoading();
        const user = await getCurrentUser();

        if (!!!user) {
          dispatcher.treatAsLoggedOut();
          return;
        }
        
        let userRegistrationApplication =
          await getLatestUserRegistrationApplication();
        let userRegistration = userRegistrationApplication?.riyousha_touroku;

        if (!!!userRegistrationApplication)
          userRegistrationApplication =
            await createUserRegistrationApplication();

        if (!!!userRegistrationApplication.riyousha_touroku)
          userRegistration = await createUserRegistration(
            userRegistrationApplication.id
          );

        const data: UserRegistration = userRegistration;
        const [familyName, givenName, ...middleArr] = (
          data["WCFDS010Dto:hdnUserInfoName"] ?? ""
        ).split(" ");
        setUserRegistrationId(String(data.id));

        setEmail(data["WCFDS010Dto:txtMailAddress"] ?? user.email);
        setFamilyName(familyName ?? "");
        setGivenName(givenName ?? "");
        setMiddleName(middleArr.join(" ") ?? "");
        setGender(data["WCFDS010Dto:hdnSex"] ?? "");
        setBirthdate(
          [
            data["WCFDS010Dto:hdnDateOfBirthYear"] ?? "",
            data["WCFDS010Dto:hdnDateOfBirthMonth"] ?? "",
            data["WCFDS010Dto:hdnDateOfBirthDay"] ?? "",
          ].join("-")
        );
        setPhoneNumber(data["WCFDS010Dto:txtUserTelNo"] ?? "");
        setPrefecture(
          data["WCFDS010Dto:hdnSearchedAddress"]?.slice(0, 2) ?? ""
        );
        setMunicipality(data["WCFDS010Dto:hdnSearchedAddress"]?.slice(2) ?? "");
        setTownStreetApartment(data["WCFDS010Dto:txtDetailHomeAddress"] ?? "");
        setResidenceCardNum(data["WCFDS010Dto:txtZiryCardNum"] ?? "");
        setTermsOfUseAgreement(data["WCFDS010Dto:chkDui"] ?? "");
      } catch (e) {
      } finally {
        dispatcher.stopLoading();
      }
    })();
  }, []);

  return (
    <Modal>
      <ContentContainer>
        <Content ref={contentRef}>
          {(() => {
            switch (contentType) {
              //"Welcome to Visadas" - ビザダスへようこそ
              //(This is the first screen the user sees after sign-up)
              case ContentType.Welcome:
                return (
                  <>
                    <LocalColumn>
                      <HeadingText>{tWelcome('title')}</HeadingText>
                      <MessageTextContainer>
                        <Text>{tWelcome('description')}</Text>
                      </MessageTextContainer>
                      <StepTextContainer style={{ backgroundColor: "#FFF3C9" }}>
                        <Text
                          style={{
                            textDecoration: "underline",
                            marginBottom: 10,
                          }}
                        >
                          {tWelcome('youWillNeed')}
                        </Text>
                        <LocalOl>
                          <LocalLi style={{ marginBottom: 10 }}>
                            <Text>{tWelcome('item1')}</Text>
                            <Text style={{ marginTop: 5 }}>
                              <LinkAnchor
                                href={
                                  "https://www2.jpki.go.jp/prepare/pdf/nfclist.pdf"
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {tWelcome('compatibleModels')}
                              </LinkAnchor>
                            </Text>
                          </LocalLi>
                          <LocalLi>
                            <Text>{tWelcome('item2')}</Text>
                            <Text style={{ marginTop: 5 }}>
                              <LinkAnchor
                                href={
                                  "https://www.kojinbango-card.go.jp/apprec/apply/online_apply/"
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {tWelcome('applyHere')}
                              </LinkAnchor>
                            </Text>
                          </LocalLi>
                        </LocalOl>
                      </StepTextContainer>
                    </LocalColumn>
                    <BottomAlignedContainer>
                      <LocalButton variant="primary" onClick={onClickNext}>
                        {tWelcome('start')}
                      </LocalButton>
                    </BottomAlignedContainer>
                  </>
                );

              //"User authentication request" - マイナンバーカード認証
              //(This is the first screen the user sees when he/she needs
              //to re-submit the application through the onboarding flow)
              case ContentType.UserAuthenticationRequest:
                return (
                  <>
                    <LocalColumn>
                      <HeadingText>{tAuthReq('title')}</HeadingText>
                      <MessageTextContainer>
                        <Text>{tAuthReq('description')}</Text>
                      </MessageTextContainer>
                    </LocalColumn>
                    <BottomAlignedContainer>
                      <LocalButton variant="primary" onClick={onClickNext}>
                        {tWelcome('start')}
                      </LocalButton>
                    </BottomAlignedContainer>
                  </>
                );

              //"Tell us about yourself (1/2)" - 基本情報(1/2)
              case ContentType.TellUsAboutYourself1:
                return (
                  <>
                    <FormContentContainer>
                      <NavContainer>
                        <BackButton onClick={onClickBack} />
                        <NavText>{tTellUs1('step1/4')}</NavText>
                      </NavContainer>
                      <LocalColumn>
                        <HeadingText>{tTellUs1('title')}</HeadingText>
                        <Text>{tTellUs1('description')}</Text>
                        <InlineMessage variant="caution">
                          <Text>{tTellUs1('caution')}</Text>
                        </InlineMessage>

                        <FieldWrapper>
                          {/* Family Name */}
                          <TextField
                            required
                            label={tTellUs1('familyName')}
                            placeholder="Nguyen"
                            value={familyName}
                            error={faimilyNameError}
                            restriction={conditionalInputNotes.halfwidth}
                            maxLength={34}
                            validators={[validation.isAlpha, validation.createLengthValidator(34)]}
                            onValueChange={setFamilyName}
                            onErrorChange={setFamilyNameError}
                            onFocus={() => setIsUserInputting(true)}
                            onBlur={() => setIsUserInputting(false)}
                          />

                          {/* Given Name */}
                          <TextField
                            required
                            label={tTellUs1('givenName')}
                            placeholder="Liem"
                            value={givenName}
                            error={givenNameError}
                            restriction={conditionalInputNotes.halfwidth}
                            maxLength={34}
                            validators={[validation.isAlpha, validation.createLengthValidator(34)]}
                            onValueChange={setGivenName}
                            onErrorChange={setGivenNameError}
                            onFocus={() => setIsUserInputting(true)}
                            onBlur={() => setIsUserInputting(false)}
                          />

                          {/* Middle Name */}
                          <TextField
                            label={tTellUs1('middleName')}
                            placeholder="Nguyen"
                            value={middleName}
                            error={middleNameError}
                            restriction={conditionalInputNotes.halfwidth}
                            maxLength={34}
                            validators={[validation.isAlpha, validation.createLengthValidator(34)]}
                            onValueChange={setMiddleName}
                            onErrorChange={setMiddleNameError}
                            onFocus={() => setIsUserInputting(true)}
                            onBlur={() => setIsUserInputting(false)}
                          />

                          {/* Gender */}
                          <RadioField
                            required
                            label={tTellUs1('gender')}
                            options={[
                              {
                                label: tTellUs1('male'),
                                value: Gender.Male,
                              },
                              {
                                label: tTellUs1('female'),
                                value: Gender.Female,
                              },
                            ]}
                            value={gender}
                            onValueChange={setGender}
                          />

                          {/* Birth date */}
                          <DateSelectField
                            required
                            maxDate={new Date()}
                            label={tTellUs1('birthDate')}
                            value={birthdate}
                            onValueChange={setBirthdate}
                          />

                          {/* Phone number */}
                          <TextField
                            required
                            label={tTellUs1('phoneNumber')}
                            placeholder="09012345678"
                            maxLength={12}
                            value={phoneNumber}
                            validators={[
                              validation.isNumeric,
                              validation.createLengthValidator(12),
                            ]}
                            error={phoneNumberError}
                            restriction={conditionalInputNotes.halfwidthNumberNoHyphens}
                            onValueChange={setPhoneNumber}
                            onErrorChange={setPhoneNumberError}
                            onFocus={() => setIsUserInputting(true)}
                            onBlur={() => setIsUserInputting(false)}
                          />

                          {/* Email for contact */}
                          <TextField
                            required
                            label={tTellUs1('emailForContact')}
                            placeholder="visadas@gmail.com"
                            value={email}
                            error={emailError}
                            restriction={conditionalInputNotes.halfwidthLetterAndNumber}
                            maxLength={60}
                            validators={[validation.isEmailAddress, validation.createLengthValidator(60)]}
                            onValueChange={setEmail}
                            onErrorChange={setEmailError}
                            onFocus={() => setIsUserInputting(true)}
                            onBlur={() => setIsUserInputting(false)}
                          />
                        </FieldWrapper>
                      </LocalColumn>
                    </FormContentContainer>

                    <BottomAlignedContainer>
                      <LocalButton
                        disabled={
                          isUserInputting ||
                          !isNoEmptyValuesInFirstHalfData ||
                          !isNoErrorInFirstHalfData
                        }
                        onClick={() => {
                          tryToSaveThenGoNext(firstHalfData);
                        }}
                      >
                        {tCommon('next')}
                      </LocalButton>
                    </BottomAlignedContainer>
                  </>
                );

              //"Tell us about yourself (2/2)" - 基本情報(2/2)
              case ContentType.TellUsAboutYourself2:
                return (
                  <>
                    <FormContentContainer>
                      <NavContainer>
                        <BackButton onClick={onClickBack} />
                        <NavText>{tTellUs2('step1/4')}</NavText>
                      </NavContainer>
                      <LocalColumn>
                        <HeadingText>{tTellUs2('title')}</HeadingText>
                        <Text>{tTellUs2('description')}</Text>

                        <FieldWrapper>
                          <ResidenceFieldWrapper>
                            <Text style={{ fontWeight: 500 }}>
                              {tTellUs2('currentResidence')}
                            </Text>
                            {/* Prefecture */}
                            <SelectField
                              required
                              label={tTellUs2('prefecture')}
                              placeholder={placeholder.selectPrefecture}
                              options={PREFECTURE_OPTIONS}
                              value={prefecture}
                              error={prefectureError}
                              onValueChange={setPrefecture}
                            />

                            {/* Municipality */}
                            <SelectField
                              required
                              label={tTellUs2('municipality')}
                              disabled={!!!prefecture}
                              placeholder={placeholder.selectMunicipality}
                              options={
                                prefecture ? MUNICIPALITY_OPTIONS[prefecture] : []
                              }
                              value={municipality}
                              error={
                                prefecture
                                  ? ""
                                  : tTellUs2('enterPrefectureFirst')
                              }
                              onValueChange={setMunicipality}
                            />

                            {/* Town, street, Apartment */}
                            <MultiTextField
                              required
                              label={tTellUs2('townStreetApartment')}
                              placeholder="南町１−２−３ビザダスアパート１０１号室"
                              value={townStreetApartment}
                              error={townStreetApartmentError}
                              maxLength={80}
                              validators={[
                                validation.isFullwidth,
                                validation.createLengthValidator(80),
                              ]}
                              onValueChange={setTownStreetApartment}
                              onErrorChange={setTownStreetApartmentError}
                              restriction={inputNotes.fullwidthJapanese}
                              onFocus={() => setIsUserInputting(true)}
                              onBlur={() => setIsUserInputting(false)}
                            />
                          </ResidenceFieldWrapper>

                          {/* Residence Card Number */}
                          <TextField
                            required
                            label={tTellUs2('residenceCardNumber')}
                            placeholder="AAAA9999999C"
                            value={residenceCardNum}
                            error={residenceCardNumError}
                            restriction={conditionalInputNotes.halfwidthLetterAndNumber}
                            maxLength={12}
                            validators={[
                              validation.isAlphanumeric,
                              validation.createLengthValidator(12),
                            ]}
                            onValueChange={setResidenceCardNum}
                            onErrorChange={setResidenceCardNumError}
                            onFocus={() => setIsUserInputting(true)}
                            onBlur={() => setIsUserInputting(false)}
                          />

                          <Column style={{ gap: 10 }}>
                            <Text>
                              <LinkAnchor
                                href={
                                  "https://www.moj.go.jp/isa/applications/guide/online-riyoukiyaku.html"
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {tTellUs2('termsOfUse')}
                              </LinkAnchor>
                            </Text>

                            <Checkbox
                              required
                              value={TermsOfUseAgreement.Agree}
                              checked={
                                termsOfUseAgreement === TermsOfUseAgreement.Agree
                              }
                              onValueChange={(checked) => {
                                setTermsOfUseAgreement(
                                  checked
                                    ? TermsOfUseAgreement.Agree
                                    : TermsOfUseAgreement.Disagree
                                );
                              }}
                            >
                              {tTellUs2('iAgree')}
                            </Checkbox>
                          </Column>
                        </FieldWrapper>
                      </LocalColumn>
                    </FormContentContainer>

                    <AppTypeSelectButtonsContainer>
                      <BottomAlignedContainer>
                        {/* "Apply by yourself" 
                           (Don't show this option until we implement the application through the Myna APIs ) */}
                        <LocalButton
                          variant="primary"
                          disabled={
                            isUserInputting ||
                            !isNoEmptyValuesInFirstHalfData ||
                            !isNoEmptyValuesInSecondHalfData ||
                            !isNoEmptyValuesInSecondHalfData ||
                            !isNoErrorInSecondHalfData ||
                            termsOfUseAgreement !== TermsOfUseAgreement.Agree
                          }
                          onClick={() => {
                            serviceTypeRef.current = ServiceType.ApplyForYourself;
                            tryToSaveThenGoNext(secondHalfData);
                          }}
                          style={{ display: "none" }}
                        >
                          {tTellUs2('applyByYourself')}
                        </LocalButton>
                      </BottomAlignedContainer>
                      

                      {/* Request an administrative scrivener */}
                      <BottomAlignedContainer>
                        <LocalButton
                          variant="primary"
                          disabled={
                            isUserInputting ||
                            !isNoEmptyValuesInFirstHalfData ||
                            !isNoEmptyValuesInSecondHalfData ||
                            !isNoEmptyValuesInSecondHalfData ||
                            !isNoErrorInSecondHalfData ||
                            termsOfUseAgreement !== TermsOfUseAgreement.Agree
                          }
                          onClick={() => {
                            serviceTypeRef.current = 
                              ServiceType.RequestAnAdministrativeScrivener;
                            tryToSaveThenGoNext(secondHalfData);
                          }}
                        >
                          {tTellUs2('requestAdministrativeScrivener')}
                        </LocalButton>
                      </BottomAlignedContainer>
                    </AppTypeSelectButtonsContainer>
                  </>
                );

              //"Download Myna Portal App" - マイナポータルアプリをダウンロード
              case ContentType.DownloadMynaApp:
                return (
                  <>
                    <NavContainer>
                      <BackButton onClick={onClickBack} />
                      <NavText>{tMynaApp('step2/4')}</NavText>
                    </NavContainer>
                    <LocalColumn>
                      <HeadingText>{tMynaApp('title')}</HeadingText>
                      <MessageTextContainer>
                        <Text style={{ display: "block" }}>
                          <Trans
                            i18nKey={tMynaApp('description1')}
                            components={{
                              iosLink: (
                                <AppAnchor
                                  href="https://apps.apple.com/jp/app/%E3%83%9E%E3%82%A4%E3%83%8A%E3%83%9D%E3%83%BC%E3%82%BF%E3%83%AB/id1476359069"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Link to Myna app (iOS version)
                                </AppAnchor>
                              ),
                              androidLink: (
                                <AppAnchor
                                  href="https://play.google.com/store/apps/details?id=jp.go.cas.mpa&hl=ja&gl=US&pli=1"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Lind to Myna app (Android version)
                                </AppAnchor>
                              ),
                            }}
                          />
                        </Text>
                        <Text>{tMynaApp('description2')}</Text>
                      </MessageTextContainer>
                      <SupplementaryTextContainer>
                        <SmallText>
                          <Trans
                            i18nKey={"common.ifYouAreHavingTrouble"}
                            components={{
                              email: VISADAS_SUPPORT_EMAIL,
                              anchor: (
                                <EmailAnchor mailTo={VISADAS_SUPPORT_EMAIL} />
                              ),
                            }}
                          />
                        </SmallText>
                      </SupplementaryTextContainer>
                    </LocalColumn>
                    <BottomAlignedContainer>
                      <LocalButton variant="primary" onClick={onClickNext}>
                        {tCommon('next')}
                      </LocalButton>
                    </BottomAlignedContainer>
                  </>
                );

              //"Verify your My Number Card (1st time)" - マイナンバーカードの読み取り（１回目）
              case ContentType.ReadMynaCard1stTime:
                return (
                  <>
                    <NavContainer>
                      <BackButton onClick={onClickBack} />
                      <NavText>{tMyna1st('step3/4')}</NavText>
                    </NavContainer>
                    <LocalColumn>
                      <HeadingText>{tMyna1st('title')}</HeadingText>
                      <MessageTextContainer>
                        <Text>{tMyna1st('description1')}</Text>
                        <Text>{tMyna1st('description2')}</Text>
                      </MessageTextContainer>
                      <StepTextContainer>
                        <LocalOl>
                          <LocalLi>
                            <Text>{tMyna1st('step1')}</Text>
                          </LocalLi>
                          <LocalLi>
                            <Text>{tMyna1st('step2')}</Text>
                          </LocalLi>
                          <LocalLi>
                            <Text>{tMyna1st('step3')}</Text>
                          </LocalLi>
                          <LocalLi>
                            <Text>{tMyna1st('step4')}</Text>
                          </LocalLi>
                        </LocalOl>
                      </StepTextContainer>
                      <SupplementaryTextContainer>
                        <SmallText>
                          <Trans
                            i18nKey={"common.ifYouAreHavingTrouble"}
                            components={{
                              email: VISADAS_SUPPORT_EMAIL,
                              anchor: (
                                <EmailAnchor mailTo={VISADAS_SUPPORT_EMAIL} />
                              ),
                            }}
                          />
                        </SmallText>
                      </SupplementaryTextContainer>
                    </LocalColumn>
                    <BottomAlignedContainer>
                      <LocalButton variant="primary" onClick={onClickNext}>
                        {tCommon('launch')}
                      </LocalButton>
                    </BottomAlignedContainer>
                  </>
                );

              //"Verify your My Number Card (2nd time)" - マイナンバーカードの読み取り（２回目）
              case ContentType.ReadMynaCard2ndTime:
                return (
                  <>
                    <NavContainer>
                      <NavText>{tMyna2nd('step4/4')}</NavText>
                    </NavContainer>
                    <LocalColumn>
                      <HeadingText>{tMyna2nd('title')}</HeadingText>
                      <MessageTextContainer>
                        <Text>{tMyna2nd('description1')}</Text>
                        <Text>{tMyna2nd('description2')}</Text>
                      </MessageTextContainer>
                      <StepTextContainer>
                        <LocalOl>
                          <LocalLi>
                            <Text>{tMyna2nd('step1')}</Text>
                          </LocalLi>
                          <LocalLi>
                            <Text>{tMyna2nd('step2')}</Text>
                          </LocalLi>
                          <LocalLi>
                            <Text>{tMyna2nd('step3')}</Text>
                          </LocalLi>
                          <LocalLi>
                            <Text>{tMyna2nd('step4')}</Text>
                          </LocalLi>
                        </LocalOl>
                      </StepTextContainer>
                      <SupplementaryTextContainer>
                        <SmallText>
                          <Trans
                            i18nKey={"common.ifYouAreHavingTrouble"}
                            components={{
                              email: VISADAS_SUPPORT_EMAIL,
                              anchor: (
                                <EmailAnchor mailTo={VISADAS_SUPPORT_EMAIL} />
                              ),
                            }}
                          />
                        </SmallText>
                      </SupplementaryTextContainer>
                    </LocalColumn>
                    <BottomAlignedContainer>
                      <LocalButton variant="primary" onClick={onClickNext}>
                        {tCommon('launch')}
                      </LocalButton>
                    </BottomAlignedContainer>
                  </>
                );

              //"Processing" - 確認中...
              //(A temporary screen to show until the submission request is completed)
              case ContentType.Processing:
                return (
                  <VerifyingResultsContainer>
                    <Column style={{ width: "auto", gap: 20 }}>
                      <Spinner />
                      <Text>{tProcessing('processing')}</Text>
                    </Column>
                  </VerifyingResultsContainer>
                );

              //"User authentication requested" - ユーザー認証リクエストを送信しました
              case ContentType.UserAuthenticationRequested:
                return (
                  <>
                    <NavContainer style={{ marginBottom: 30 }}>
                      <SuccessIcon />
                    </NavContainer>
                    <LocalColumn>
                      <HeadingText>{tReqAuth('title')}</HeadingText>
                      <MessageTextContainer>
                        <Text>{tReqAuth('description1')}</Text>
                        <Text style={{ display: "block" }}>
                          <Trans i18nKey={'authRequested.description2'} />
                        </Text>
                      </MessageTextContainer>
                      <InlineMessage variant="caution">
                        {tReqAuth('youCanStartEntering')}
                      </InlineMessage>
                    </LocalColumn>
                    <BottomAlignedContainer>
                      <LocalButton variant="primary" onClick={onClickNext}>
                        {tCommon('getStarted')}
                      </LocalButton>
                    </BottomAlignedContainer>
                  </>
                );
            }
          })()}
        </Content>
      </ContentContainer>
    </Modal>
  );
};

export default OnboardingPage;