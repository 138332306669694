import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { VisaApplicationType } from "../../../../../../../types/visa/applicationType";
import { VisaAttachment } from "../../../../../../../types/visa/attachment";
import GijinkokuVisaAttachments from "./SectionBodies/GijinkokuVisaAttachments";
import { SectionHeading } from "../../../previewBodyCommonStyle";
import { VisaApplication } from "../../../../../../../types/visa/applicationData";

interface AttachmentsSectionProps {
  visaApplication: VisaApplication;
  visaApplicationType: VisaApplicationType;
  visaAttachments: VisaAttachment[];
}

export const AttachmentsSection: FunctionComponent<AttachmentsSectionProps> = ({
  visaApplication,
  visaApplicationType,
  visaAttachments,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "attachmentsSection" });
  const Attachments = (() => {
    switch (visaApplicationType) {
      case VisaApplicationType.ExtendGijinkokuVisaBySelf:
        return (
          <GijinkokuVisaAttachments 
            visaApplication={visaApplication}
            visaApplicationType={visaApplicationType} 
            visaAttachments={visaAttachments}          
          />
        );

      default:
        return null;
    }
  })();

  return (
    <section>
      <SectionHeading>{t("title")}</SectionHeading>
      {Attachments}
    </section>
  );
};

export default AttachmentsSection;
