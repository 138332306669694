import { FunctionComponent, MouseEvent, ReactNode, useRef } from 'react';
import styled from 'styled-components';

interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
    children?: ReactNode;
    onClose?: () => void;
}

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.34);
  z-index: 10;
  overflow-y: auto;
`;

const Modal: FunctionComponent<ModalProps> = ({
    children,
    onClose,
    style
}) => {
    const ref = useRef(null);

    const handleClick = (event: MouseEvent) => {
        if (event.target === ref.current && onClose) {
            onClose();
        }
    };

    return (
        <Container ref={ref} onClick={handleClick} style={style}>
            {children}
        </Container>
    );
};

export default Modal;