import { RELATIONSHIP_CB06_OPTIONS, MUNICIPALITY_OPTIONS, ALL_NATIONALITY_OPTIONS, PREFECTURE_OPTIONS, DESIRED_PERIOD_OF_STAY_C970_OPTIONS_FOR_DEPENDENT, PREFECTURE_WITH_JA_VALUE_OPTIONS, MUNICIPALITY_WITH_JA_VALUE_OPTIONS, CURRENT_STATUS_OF_RESIDENCE_CB19_OPTIONS, DESIRED_PERIOD_OF_STAY_CB20_OPTIONS_FOR_DEPENDENT, PERIOD_OF_STAY_CB20_OPTIONS_FOR_DEPENDENT } from "../../constants/options";
import { TYPE_OF_CONTRACT_OPTIONS_FOR_GIJINKOKU } from "../../constants/options/contract";
import { FINAL_EDUCATION_OPTIONS_FOR_GIJINKOKU } from "../../constants/options/education";
import { INDUSTRY_OF_EMPLOYER_OPTIONS } from "../../constants/options/industry";
import { MAJOR_FIELD_OF_STUDY_FOR_COLLEGE_OF_TECHNOLOGY_OPTIONS_FOR_GIJINKOKU, MAJOR_FIELD_OF_STUDY_FOR_DRMSBCJC_OPTIONS_FOR_GIJINKOKU } from "../../constants/options/major";
import { OFFICE_OPTIONS } from "../../constants/options/office";
import { TIME_PERIOD_MONTH_OPTIONS, TIME_PERIOD_YEAR_OPTIONS } from "../../constants/options/timePeriod";
import { START_DATE_OF_EMPLOYMENT_UNDECIDED_OPTIONS, TYPE_OF_WORK_OPTIONS } from "../../constants/options/work";
import { ChangeBySelfData } from "../../types/visa/changeBySelf/data";
import { ApplicationCategoryCodeForChangeBySelf, StatusOfResidenceCodeForChangeBySelf } from "../../types/visa/changeBySelf/value";
import { ExtensionBySelfData } from "../../types/visa/extensionBySelf/data";
import { ApplicationCategoryCodeForExtensionBySelf, StatusOfResidenceCodeForExtensionBySelf } from "../../types/extensionBySelf/specificValues";
import { VisaAttachmentKind } from "../../types/visa/attachment";
import { VisaAttachment } from "../../types/visa/attachment";
import { StatusOfResidenceCode_CB19 } from "../../types/visa/statusOfResidence";
import { isChangeBySelfData, isExtensionBySelfData } from "../visaApplicationHelper";
import { MAX_FAMILY_CORESIDENTS } from "../../constants/values/commonValues";
import { APPLICANT_PERIOD_OF_STAY_OPTIONS_FOR_GIJINKOKU } from "../../constants/options/periodOfStays/periodOfStayForGijinkoku";
import { VisaApplicationType } from "../../types/visa/applicationType";
import { DESIRED_PERIOD_OF_STAY_C970_OPTIONS_FOR_GIJINKOKU } from "../../constants/options/desiredPeriodOfStays/desiredPeriodOfStayForGijinkoku";


export const parseName = (name: string | undefined | null, delimiter: string)=> {
  const [familyName, givenName, ...middleArr] = (name ?? '').split(delimiter);
  return { 
    familyName: familyName ?? '', 
    givenName: givenName ?? '',
    middleName: middleArr.join(delimiter) ?? ''
  };
}

export const formatDate = (year: string | undefined | null, month: string | undefined | null, day: string | undefined | null, separator?: string) => {
  if (year && month && day) {
    return [year, month, day].join(separator ?? '/');
  }

  return '';
}

const convertValToFullAmount = ( amount: string | number | undefined | null, base: number, toLocaleString?: boolean) => {
  if (!amount)
    return '';
  
  const converted = Number(amount);
  if (isNaN(converted)) 
    return ''

  const fullAmount = converted * base;
  return toLocaleString ? fullAmount.toLocaleString() : fullAmount.toString();
}

//This converts a short amount (e.g. 1) to a full amount (e.g. 1000)
//This sometimes appear in the form, for example, the amount of tax paid last year.
export const convertKValToFullAmount = (amount: string | number | null, toLocaleString?: boolean) => {
  return convertValToFullAmount(amount, 1000, toLocaleString);
}

export const convert10KValToFullAmount = (amount: string | number | null, toLocaleString?: boolean) => {
  return convertValToFullAmount(amount, 10000, toLocaleString);
}

export const getLabelFromPeriodOfStayCodeForDependent = (periodOfStayCode: string | null) => {
  return PERIOD_OF_STAY_CB20_OPTIONS_FOR_DEPENDENT.find(op => op.value === periodOfStayCode)?.label ?? '';
}

export const getLabelFromPeriodOfStayCodeForGijinkoku = (periodOfStayCode: string | null) => {
  return APPLICANT_PERIOD_OF_STAY_OPTIONS_FOR_GIJINKOKU.find(op => op.value === periodOfStayCode)?.label ?? '';
}

//e.g. "003000" => "periodOfStay_CB20.003000", which is the key for the translation, "3 months"
export const getLabelFromPeriodOfStayCode = (periodOfStayCode: string | null, visaAppType: VisaApplicationType) => {
  switch (visaAppType) {
    case VisaApplicationType.ExtendDependentVisaBySelf:
      return getLabelFromPeriodOfStayCodeForDependent(periodOfStayCode);
    case VisaApplicationType.ExtendGijinkokuVisaBySelf:
      return getLabelFromPeriodOfStayCodeForGijinkoku(periodOfStayCode);
    default:
      return '';
  }
}

export const getLabelFromDesiredPeriodOfStay = (desiredPeriodOfStayCode: string | null, targetVisaAppType: VisaApplicationType) => {
  if (!desiredPeriodOfStayCode)
    return "";

  switch (targetVisaAppType) {
    //Dependent visa
    case VisaApplicationType.ExtendDependentVisaBySelf:
      return DESIRED_PERIOD_OF_STAY_C970_OPTIONS_FOR_DEPENDENT
        .find(op => op.value === desiredPeriodOfStayCode)?.label ?? '';
    case VisaApplicationType.ChangeToDependentVisaBySelf:
        return DESIRED_PERIOD_OF_STAY_CB20_OPTIONS_FOR_DEPENDENT
          .find(op => op.value === desiredPeriodOfStayCode)?.label ?? '';


    //Gijinkoku (Engineer/Specialist in Humanities/International Services) visa
    case VisaApplicationType.ExtendGijinkokuVisaBySelf:
      return DESIRED_PERIOD_OF_STAY_C970_OPTIONS_FOR_GIJINKOKU
        .find(op => op.value === desiredPeriodOfStayCode)?.label ?? '';


    default: 
      return "";
  }
}


export const getLabelFromNationalityCode = (nationalityCode: string | null) => {
  return ALL_NATIONALITY_OPTIONS.find(op => op.value === nationalityCode)?.label ?? '';
}

//The purpose of this file is to provide util parsing methods 
//because we need to parse many values to get the corresponding values
export const getLabelsFromAddressCode = (addressCode: string | null) => {
  const prefectureCode = addressCode?.slice(0, 2);
  const municipalityCode = addressCode?.slice(2);

  return {
    prefecture: PREFECTURE_OPTIONS.find(op => op.value === prefectureCode)?.label ?? '',
    municipality: MUNICIPALITY_OPTIONS[prefectureCode ?? '']?.find(op => op.value === municipalityCode)?.label ?? ''
  }
}

export const getLabelFromPrefectureInJapanese = (prefectureInJp: string | null) => {
  return PREFECTURE_WITH_JA_VALUE_OPTIONS.find(op => op.value === prefectureInJp)?.label ?? '';
}

export const getLabelFromMunicipalityInJapanese = (prefectureInJp: string | null, municipalityInJp: string | null) => {
  if (!prefectureInJp || !municipalityInJp)
    return "";

  const municipalities = MUNICIPALITY_WITH_JA_VALUE_OPTIONS[prefectureInJp];
  if (!municipalities)
    return "";

  return municipalities.find(op => op.value === municipalityInJp)?.label ?? '';
}

export const getLabelFromCurrentStatusOfResidence = (currentStatusOfResidenceCode: string | null) => {
  return CURRENT_STATUS_OF_RESIDENCE_CB19_OPTIONS.find(op => op.value === currentStatusOfResidenceCode)?.label ?? '';
}

//Pass a family-relation code (e.g. "01") to find out the corresponding label 
export const getLabelFromCB06_RelationCode = (familyRelationCode: string | null) => {
  return RELATIONSHIP_CB06_OPTIONS.find(op => op.value === familyRelationCode)?.label ?? '';
}

export const getLabelFromOfficeCode = ( officeCode: string | null, prefectureCode: string | null, municipalityCode: string | null) => {
  if (!officeCode || !prefectureCode || !municipalityCode)
    return "";

  const options = OFFICE_OPTIONS[`${prefectureCode}${municipalityCode}`] 
    ?? OFFICE_OPTIONS[prefectureCode]
    ?? [];
  return options.find(op => op.value === officeCode)?.label ?? '';
}

export const getLabelFromFinalEducation = (finalEducation: string | null) => {
  return FINAL_EDUCATION_OPTIONS_FOR_GIJINKOKU.find(op => op.value === finalEducation)?.label ?? '';
}

export const getLabelFromMajorFieldOfStudyForDrMsBcJc = (majorFieldOfStudy: string | null) => {
  return MAJOR_FIELD_OF_STUDY_FOR_DRMSBCJC_OPTIONS_FOR_GIJINKOKU.find(op => op.value === majorFieldOfStudy)?.label ?? '';
}

export const getLabelFromMajorFieldOfStudyForCollegeOfTech = (majorFieldOfStudy: string | null) => {
  return MAJOR_FIELD_OF_STUDY_FOR_COLLEGE_OF_TECHNOLOGY_OPTIONS_FOR_GIJINKOKU.find(op => op.value === majorFieldOfStudy)?.label ?? '';
}

export const getLabelFromIndustryOfEmployer = (industryOfEmployer: string | null) => {
  return INDUSTRY_OF_EMPLOYER_OPTIONS.find(op => op.value === industryOfEmployer)?.label ?? '';
}

export const getLabelFromTypeOfWork = (typeOfWork: string | null) => {
  return TYPE_OF_WORK_OPTIONS.find(op => op.value === typeOfWork)?.label ?? '';
}

export const getLabelFromTypeOfContract = (typeOfContract: string | null) => {
  return TYPE_OF_CONTRACT_OPTIONS_FOR_GIJINKOKU.find(op => op.value === typeOfContract)?.label ?? '';
}

export const getLabelFromStartDateOfEmploymentUndecided = (startDateOfEmploymentUndecided: string | null) => {
  return START_DATE_OF_EMPLOYMENT_UNDECIDED_OPTIONS.find(op => op.value === startDateOfEmploymentUndecided)?.label ?? '';
}

export const getLabelFromTimePeriodYear = (timePeriod: string | null) => {
  return  TIME_PERIOD_YEAR_OPTIONS.find(op => op.value === timePeriod)?.label ?? '';
}

export const getLabelFromTimePeriodMonth = (timePeriod: string | null) => {
  return TIME_PERIOD_MONTH_OPTIONS.find(op => op.value === timePeriod)?.label ?? '';
}

//Get the corresponding status of residence code (*在留資格 - e.g. '04R1') for the ExtensionBySelf type application
export const convertToStatusOfResidenceCodeForExtensionBySelf = (statusOfResidence: StatusOfResidenceCode_CB19 | null) => {
  switch (statusOfResidence) {
    case StatusOfResidenceCode_CB19.Dependent:
      return StatusOfResidenceCodeForExtensionBySelf.Dependent;
    case StatusOfResidenceCode_CB19.Gijinkoku:
      return StatusOfResidenceCodeForExtensionBySelf.Gijinkoku;
    case StatusOfResidenceCode_CB19.Student:
      return StatusOfResidenceCodeForExtensionBySelf.StudyAbroad;
    default:
      return "";
  }
}

//A different version of the above function for the ChangeBySelf type application
//Compared with the codes above, the codes for the ChangeBySelf type application are slightly different
//For example, "05R1" instead of "04R1" for the family status of residence
export const convertToStatusOfResidenceCodeForChangeBySelf = (statusOfResidence: StatusOfResidenceCode_CB19 | null) => {
  switch (statusOfResidence) {
    case StatusOfResidenceCode_CB19.Dependent:
      return StatusOfResidenceCodeForChangeBySelf.Dependent;
    case StatusOfResidenceCode_CB19.Gijinkoku:
      return StatusOfResidenceCodeForChangeBySelf.Gijinkoku;
    default:
      return "";
  }
}

//Pass the status_of_residence value (e.g. 'dependent') in the User object,
//and get the corresponding application category code (*申請種別 - e.g. '04R') for the ExtensionBySelf type application
export const convertToApplicationCategoryCodeForExtensionBySelf = (statusOfResidence: StatusOfResidenceCode_CB19 | null) => {
  switch (statusOfResidence) {
    case StatusOfResidenceCode_CB19.Dependent:
      return ApplicationCategoryCodeForExtensionBySelf.Dependent;
    case StatusOfResidenceCode_CB19.Gijinkoku:
      return ApplicationCategoryCodeForExtensionBySelf.Gijinkoku;
    case StatusOfResidenceCode_CB19.Student:
      return ApplicationCategoryCodeForExtensionBySelf.StudyAbroad;
    default:
      return "";
  }
}

export const getLengthOfSequencialProperties = (data: object, keys: string[], maxLen: number): number => {
  let length = 0;

  for (let i = 1; i <= maxLen; i++)
  {
    const isThereValue = keys.map(key => getValueWithKey(data, key)).some(v => !!v);

    if (isThereValue) {
      length = i;
    }
  }

  return length;
}

//A different version of the above function for the ChangeBySelf type application
//Since the application category codes are not the same, don't use the same function
export const convertToApplicationCategoryCodeForChangeBySelf = (statusOfResidence: StatusOfResidenceCode_CB19) => {
  switch (statusOfResidence) {
    case StatusOfResidenceCode_CB19.Dependent:
      return ApplicationCategoryCodeForChangeBySelf.Dependent;
    case StatusOfResidenceCode_CB19.Gijinkoku:
      return ApplicationCategoryCodeForChangeBySelf.Gijinkoku;
    default:
      return "";
  }
}

export const getSnzkListKeyOf =  {
  selNationalityAndRegion: <T> (index: number) => `WCIBS010Dto:zincSnzkList[${index}].selNationalityAndRegion` as keyof T,
  txtName: <T> (index: number) => `WCIBS010Dto:zincSnzkList[${index}].txtName` as keyof T,
  selDateOfBirthYear: <T> (index: number) => `WCIBS010Dto:zincSnzkList[${index}].selDateOfBirthYear` as keyof T,
  selDateOfBirthMonth: <T> (index: number) => `WCIBS010Dto:zincSnzkList[${index}].selDateOfBirthMonth` as keyof T,
  selDateOfBirthDay: <T> (index: number) => `WCIBS010Dto:zincSnzkList[${index}].selDateOfBirthDay` as keyof T,
  selZkgr: <T> (index: number) => `WCIBS010Dto:zincSnzkList[${index}].selZkgr` as keyof T,
  radDukyUm: <T> (index: number) => `WCIBS010Dto:zincSnzkList[${index}].radDukyUm` as keyof T,
  txtWorkPlaceOrTugkskName: <T> (index: number) => `WCIBS010Dto:zincSnzkList[${index}].txtWorkPlaceOrTugkskName` as keyof T,
  txtZiryCardNumOrTkeiNum: <T> (index: number) => `WCIBS010Dto:zincSnzkList[${index}].txtZiryCardNumOrTkeiNum` as keyof T
}

//Expect to receive ExtensionBySelfData or ChangeBySelfData
//(these share the same properties used here)
export const getLengthOfZincSnzkList = <T> (data: T): number => {
  let length = 0;

  for (let i = 1; i <= MAX_FAMILY_CORESIDENTS; i++)
  {
    const isThereValue = [
      data[getSnzkListKeyOf.selNationalityAndRegion(i)],
      data[getSnzkListKeyOf.txtName(i)],
      data[getSnzkListKeyOf.selDateOfBirthYear(i)],
      data[getSnzkListKeyOf.selDateOfBirthMonth(i)],
      data[getSnzkListKeyOf.selDateOfBirthDay(i)],
      data[getSnzkListKeyOf.selZkgr(i)],
      data[getSnzkListKeyOf.radDukyUm(i)],
      data[getSnzkListKeyOf.txtWorkPlaceOrTugkskName(i)],
      data[getSnzkListKeyOf.txtZiryCardNumOrTkeiNum(i)]
    ].some(v => v != null);

    if (isThereValue) {
      length = i;
    }
  }

  return length;
}


export const extractVisaAttachments = (visaAttachments?: VisaAttachment [], kind?: VisaAttachmentKind): VisaAttachment[] => {
  if (!visaAttachments || !kind)
    return [];

  return visaAttachments.filter(va => va.kind === kind);
}

export const convertBytesToMB = (bytes: number, digits: number = 1) => {
  const megabytes = bytes / (1024 * 1024);
  return megabytes.toFixed(digits); 
}

export const formatDateStrWithJaUnits = (date: string, separator: string = '-') => {
  const [year, month, day] = date.split(separator);
  let value = '';
  
  if (year) 
    value += `${year}年`;
  
  if (month) 
    value += `${month}月`;

  if (day)
    value += `${day}日`;

  return value;
}

export const parseDateWithJaUnits = (input?: string | null, separator: string = '-') => {
  const result: {
    year: string | null, 
    month: string | null,
    day: string | null,
    yearMonth: string | null,
    yearMonthDay: string | null
  } = { 
    year: null, 
    month: null, 
    day: null,
    yearMonth: null,
    yearMonthDay: null
  };

  const yearMatch = input?.match(/(\d+)年/);
  const monthMatch = input?.match(/(\d+)月/);
  const dayMatch = input?.match(/(\d+)日/);

  if (yearMatch) 
    result.year = yearMatch[1];
  
  if (monthMatch) 
    result.month = monthMatch[1];

  if (dayMatch)
    result.day = dayMatch[1];

  if (result.year && result.month)
    result.yearMonth = `${result.year}${separator}${result.month}`;

  if (result.year && result.month && result.day)
    result.yearMonthDay = `${result.year}${separator}${result.month}${separator}${result.day}`;
  
  return result;
};

export const isValidKey = <T>(obj: T, possibleKey: keyof any): possibleKey is keyof T => {
  if (typeof obj === 'object' && obj !== null)
    return possibleKey in obj;

  return false;
}

export const getValueWithKey = <T, K extends keyof any>(obj: T, key: K): T[keyof T] | null => {
  return isValidKey(obj, key) ? obj[key] : null;
}

export const getExtensionBySelfDataProperty = (data: ExtensionBySelfData | ChangeBySelfData, key: keyof ExtensionBySelfData) => {
  return isExtensionBySelfData(data) ? data[key] : null;
}

export const getChangeBySelfDataProperty = (data: ExtensionBySelfData | ChangeBySelfData, key: keyof ChangeBySelfData) => {
  return isChangeBySelfData(data) ? data[key] : null;
}

export const isNoFieldError = (error: string | string [] | null | undefined): boolean => {
  if (!error)
    return true;

  if (Array.isArray(error))
    return error.every(e => !e);

  return error === '';
}