import { FunctionComponent, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { ReactComponent as SuccessIcon } from '../../assets/icon-success-white.svg';
import { ReactComponent as WarningIcon } from '../../assets/icon-warning-white.svg';
import { SnackbarType } from '../../types/snackbar';

interface SnackbarProps extends HTMLAttributes<HTMLDivElement> {
  type?: SnackbarType;
}

interface ContainerProps {
  type?: SnackbarType;
}

const Container = styled.div<ContainerProps>`
  position: fixed;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 3px auto;
  border-radius: 5px;
  width: 300px;
  min-height: 40px;
  padding: 10px;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 20px;
  z-index: 255;
  gap: 10px;
  color: #FFFFFF;
  background: ${(props: ContainerProps) => {
    switch (props.type) {
      case 'success': 
        return '#40BF6E';
      case 'warning': 
        return '#E93232';
      default: 
        return '#227AFF';
    }  
  }};
  opacity: 0;
  pointer-events: 'auto';

  animation-name: slideinout;
  animation-duration: 5s;
  animation-fill-mode: forwards;

  @keyframes slideinout {
    0% {
      top: -40px;
      opacity: 0;
    }
    10% {
      top: 5px;
      opacity: 1;
    }
    85% {
      top: 5px;
      opacity: 1;
    }
    100% {
      top: -40px;
      opacity: 0;
    }
}`;

const SuccessIconContainer = styled.div`
  width: 18px;
  height: 18px;
`;

const WarningIconContainer = styled.div`
  width: 16px;
  height: 16px;
`;

const Snackbar: FunctionComponent<SnackbarProps> = ({
  type,
  children,
}) => {
  return (
    <Container type={type}>
      {(type === 'success') && (
        <SuccessIconContainer>
          <SuccessIcon/>
        </SuccessIconContainer>
      )}
      {(type === 'warning') && (
        <WarningIconContainer>
          <WarningIcon/>
        </WarningIconContainer>
      )}
      {children}
    </Container>
  );
};

export default Snackbar;