import { VisaSignature, VisaSignatureKind } from "../types/visa/applicationData";
import { axiosInstance } from "./common";

export const createVisaSignature = async (visaApplicationId: string | number, file: File, kind: VisaSignatureKind) => {
  const formData = new FormData();

  formData.append('visa_application_id', visaApplicationId.toString());
  formData.append('kind', kind);
  formData.append('file', file);

  
  const response = await axiosInstance.request({
      method: 'POST',
      url: `visa_signatures`,
      data: formData,
  });

  return response.data as VisaSignature;
};

export const getVisaSignature = async (visaSignatureId: number | string) => {
  const response = await axiosInstance.request({
      method: 'GET',
      url: `visa_signatures/${visaSignatureId}`
  });

  return response.data as VisaSignature;
};

export const updateVisaSignature = async (visaSignatureId: number | string, params: {
  kind?: VisaSignatureKind,
  file?: File
}) => {
  const data = new FormData();

  if (params.kind)
    data.append('kind', params.kind);

  if (params.file)
    data.append('file', params.file);

  const response = await axiosInstance.request({
      method: 'PATCH',
      url: `visa_signatures/${visaSignatureId}`,
      data
  });

  return response.data as VisaSignature;
}

export const deleteVisaSignature = async (visaSignatureId: number | string) => {
  const response = await axiosInstance.request({
      method: 'DELETE',
      url: `visa_signatures/${visaSignatureId}`
  });

  return response.data as VisaSignature;
}