import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatcher, useValidation } from "../../hooks";
import styled from "styled-components";
import Button from "../../components/form/Button";
import Column from "../../components/layout/Column";
import Page from "../../components/layout/Page";
import PageHeading from "../../components/text/PageHeading";
import Text from "../../components/text/Text";
import TextField from "../../components/compound/TextField";
import { PASSWORD_MIN_LENGTH } from "../../constants/values/password";
import { requestPasswordReset, resetPassword } from "../../apis/user";
import BottomAlignedContainer from "../../components/layout/BottomAlignedContainer";

interface PasswordResetPageProps {}

enum ContentType {
  LinkToResetPassword,
  ResetPasswordForm,
}

const LocalPage = styled(Page)`
  padding: 20px;
  gap: 20px;
`;

const BodyContainer = styled(Column)`
  gap: 30px;
  width: 100%;
`;

const HeadingContainer = styled(Column)`
  gap: 20px;
`;

const FieldItemsContainer = styled(Column)`
  gap: 30px;
`;

const LinkText = styled(Text)`
  color: #017698;
  margin: 0 auto;
`;

const Anchor = styled.a`
  text-decoration: none;
`;

const ButtonContainer = styled(BottomAlignedContainer)`
  padding-top: 30px;
`;

const PasswordResetPage: FunctionComponent<PasswordResetPageProps> = () => {
  const { dispatcher } = useDispatcher();
  const validation = useValidation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const code = searchParams.get("code") as string; 
  const contentType = code
    ? ContentType.ResetPasswordForm
    : ContentType.LinkToResetPassword;
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordConfirmError, setPasswordConfirmError] = useState("");
  const [isInputting, setIsInputting] = useState(false);
  const disableResetRequestButton: boolean =
    isInputting || !!!email || !!emailError;
  const disablePasswordUpdateButton: boolean = 
    isInputting ||
    (!!passwordError || !!passwordConfirmError) ||
    (password.length < PASSWORD_MIN_LENGTH) ||
    (password !== passwordConfirm);
  const { t } = useTranslation("translation", {
    keyPrefix: "passwordResetPage",
  });
  const { t: tSnack } = useTranslation("translation", {
    keyPrefix: "snackbar",
  });

  const onClickResetPassword = async () => {
    dispatcher.startLoading();
    try {
      if (contentType === ContentType.LinkToResetPassword) {
        await requestPasswordReset(email);
        dispatcher.showSuccessOnSnackbar(tSnack("emailHasBeenSent"));
      }
      else {
        await resetPassword(password, code);
        dispatcher.showSuccessOnSnackbar(tSnack("passwordUpdateCompleted"));
        navigate("/login");
      }

    } catch (e) {
      dispatcher.showWarningOnSnackbar(tSnack("failedToProceed"));
    } finally {
      dispatcher.stopLoading();
    }
  };

  return (
    <LocalPage>
      {/* Screen to enter the email address to send a link */}
      {contentType === ContentType.LinkToResetPassword && (
        <>
          <BodyContainer>
            <HeadingContainer>
              <PageHeading>{t("title")}</PageHeading>
              <Text>{t("sendYouLinkToReset")}</Text>
            </HeadingContainer>

            <FieldItemsContainer>
              <TextField
                required
                label={t("email")}
                validators={[validation.isEmailAddress]}
                value={email}
                onValueChange={setEmail}
                error={emailError}
                onErrorChange={setEmailError}
                onFocus={() => setIsInputting(true)}
                onBlur={() => setIsInputting(false)}
              />
            </FieldItemsContainer>
          </BodyContainer>

          <ButtonContainer>
            <Column style={{ gap: 10 }}>
              <Button
                disabled={disableResetRequestButton}
                onClick={onClickResetPassword}
              >
                {t("resetPassword")}
              </Button>

              <LinkText>
                <Anchor href="/login">
                  {t("goBackToLogin")}
                </Anchor>
              </LinkText>
            </Column>
          </ButtonContainer>
        </>
      )}

      {/* Screen to enter the new password */}
      {contentType === ContentType.ResetPasswordForm && 
        <>
          <BodyContainer>
            <HeadingContainer>
              <PageHeading>{t("title")}</PageHeading>
              <Text>{t("setNewPassword")}</Text>
            </HeadingContainer>

            <FieldItemsContainer>
              <TextField
                required
                type="password"
                label={t("newPassword")}
                value={password}
                onValueChange={setPassword}
                error={passwordError}
                minLength={PASSWORD_MIN_LENGTH}
                onFocus={() => setIsInputting(true)}
                onBlur={() => {
                  setPasswordError(
                    password.length < PASSWORD_MIN_LENGTH
                      ? t("passwordTooShortError", { length: PASSWORD_MIN_LENGTH })
                      : ""
                  );

                  setIsInputting(false);
                }}
              />

              <TextField
                required
                type="password"
                label={t("newPassword")}
                value={passwordConfirm}
                onValueChange={setPasswordConfirm}
                error={passwordConfirmError}
                minLength={PASSWORD_MIN_LENGTH}
                onFocus={() => setIsInputting(true)}
                onBlur={() => {
                  setPasswordConfirmError(
                    password !== passwordConfirm
                      ? t("passwordMismatchError")
                      : ""
                  );

                  setIsInputting(false);
                }}
              />
            </FieldItemsContainer>
          </BodyContainer>

          <ButtonContainer>
            <Button
              disabled={disablePasswordUpdateButton}
              onClick={onClickResetPassword}
            >
              {t("updatePassword")}
            </Button>
          </ButtonContainer>
        </>
      }
    </LocalPage>
  );
};

export default PasswordResetPage;
