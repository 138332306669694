//Ref: https://www.figma.com/design/3ZOB9niXv03FyAQ29Zw848/Visadas?node-id=8515-7338&m=dev
/*
  AI読み取り・ビザ申請の添付書類
  PDF
  JPG
  PNG
  WEBP
  BMP
  TIFF
  GIF
  TXT
*/
export const FILE_EXTENSIONS_FOR_AI_READING_AND_VISA_APP = [
  "pdf",
  "jpg",
  "png",
  "webp",
  "bmp",
  "tiff",
  "gif",
  "txt"
];


/*
  メッセージ機能でのファイル添付
  Word
  Excel
  Google Docs
  Google Spread Sheet
  PDF
  JPG
  PNG
  WEBP
  BMP
  TIFF
  GIF
  TXT
*/
export const FILE_EXTENSIONS_FOR_MESSAGE_ATTACHMENT = [
  "doc",
  "docx",
  "xls",
  "xlsx",
  "pdf",
  "jpg",
  "png",
  "webp",
  "bmp",
  "tiff",
  "gif",
  "txt"
];