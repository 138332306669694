import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import FieldGroup from '../../../components/layout/FieldGroup';
import MultiTextField from '../../../components/compound/MultiTextField';
import SelectField from '../../../components/compound/SelectField';
import TextField from '../../../components/compound/TextField';
import Section from '../../../components/layout/Section';
import {
    MUNICIPALITY_WITH_JA_VALUE_OPTIONS,
    PREFECTURE_WITH_JA_VALUE_OPTIONS,
    RELATIONSHIP_WITH_SUPPORTER_OPTIONS_FOR_STUDY_ABROAD
} from '../../../constants/options';
import { useFieldInputNotes, useValidation } from '../../../hooks';
import styled from 'styled-components';
import { ReactComponent as PlusIcon } from '../../../assets/icon-plus.svg';
import { ReactComponent as TrashCanIcon } from '../../../assets/icon-trash-can-2.svg' 
import Checkbox from '../../../components/form/Checkbox';
import InlineMessage from '../../../components/form/InlineMessage';
import Text from '../../../components/text/Text';
import Button from '../../../components/form/Button';
import { FormP } from '../../../types/visa/uncommonFormParts/formP/data';
import { MAX_SUPPORTERS } from '../../../constants/values/studentVisaValues';
import DateSelectField from '../../../components/compound/DateSelectField';
import FieldGroupNumber from '../../../components/display/FieldGroupNumber';
import { RELATIONSHIP_W_SUPPORTER_ABROAD_OTHER_VALUE, RELATIONSHIP_W_SUPPORTER_IN_JAPAN_OTHER_VALUE } from '../../../types/visa/uncommonFormParts/formP/value';
import { getLengthOfSupporters, getSupporterKeyOf } from '../../../utils/visaFormDataHelpers/studyAbroadVisaFormDataHelper';
import { formatDateStrWithJaUnits, parseDateWithJaUnits } from '../../../utils/visaFormDataHelpers/commonVisaFormDataHelper';
import { ApplicationFormSectionProps } from '../type';

interface PlaceOfEmploymentSectionProps extends ApplicationFormSectionProps {
  formP: FormP;
  onChangeFormP: (data: Partial<FormP>) => void;
};

type Supporter = {
  name: string;
  prefecture: string;
  municipality: string;
  townStreetApartment: string;
  phoneNumber: string;
  occupationAtWork: string;
  phoneNumberAtWork: string;
  annualIncome: string;
}

type SupporterError = {
  name: string;
  townStreetApartment: string;
  phoneNumber: string;
  occupationAtWork: string;
  phoneNumberAtWork: string;
  annualIncome: string;
}

const LocalFieldGroup = styled(FieldGroup)`
  background-color: #FFFFFF;
  border: solid 1px #D5D5D5;
`;

const IndentBlock = styled.div`
  padding: 0 0 15px 15px;
`;

const IndentSegment = styled.div`
  padding-left: 15px;
`;

const Items30 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Items15 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Items10 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Items5 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Label = styled(Text)`
  font-weight: 700;
`;

const ActionButton = styled(Button).attrs({ variant: 'inline' })`
  width: auto;
  gap: 10px;
  margin: 0 auto;
`;

const SupporterContainer = styled.div`
  background-color: #EDEDED;
  padding: 20px;
`;

const MethodOfSupportToPaySection: FunctionComponent<PlaceOfEmploymentSectionProps> = ({
  visaApplicationType,
  formP,
  onChangeFormP,
  onChangeSectionReadiness,
  showEmptyRequiredValueError,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'methodOfSupportToPaySection' });
  const { t: tMethod } = useTranslation('translation', { keyPrefix: 'methodOfSupportToPaySection.methodAndAmountOfSupport' });
  const { t: tRemmitances } = useTranslation('translation', { keyPrefix: 'methodOfSupportToPaySection.remmitancesOrCarryingCash' });
  const { t: tSupporter } = useTranslation('translation', { keyPrefix: 'methodOfSupportToPaySection.supporter' });
  const { t: tPlaceholder } = useTranslation("translation", { keyPrefix: "placeholder" });
  const { t: tWithoutPrefix } = useTranslation();
  const validation = useValidation();
  const { inputNotes } = useFieldInputNotes();

  const getEmptySupporter = (): Supporter => ({
    name: '',
    prefecture: '',
    municipality: '',
    townStreetApartment: '',
    phoneNumber: '',
    occupationAtWork: '',
    phoneNumberAtWork: '',
    annualIncome: ''
  });

  const getEmptySupporterError = (): SupporterError => ({
    name: '',
    townStreetApartment: '',
    phoneNumber: '',
    occupationAtWork: '',
    phoneNumberAtWork: '',
    annualIncome: ''
  });

  //----------------------------------------------
  //For "Self" option
  const [isSelf, setIsSelf] = useState<boolean>();
  const [selfYen, setSelfYen] = useState<string>('');
  const [selfYenError, setSelfYenError] = useState<string>('');

  //For "Supporter living abroad" option
  const [isSupporterLivingAbroad, setIsSupporterLivingAbroad] = useState<boolean>();
  const [supporterLivingAbroadYen, setSupporterLivingAbroadYen] = useState<string>('');
  const [supporterLivingAbroadRelWithApplicant, setSupporterLivingAbroadRelWithApplicant] = useState<string>('');
  const [supporterLivingAbroadRelWithApplicantOther, setSupporterLivingAbroadRelWithApplicantOther] = useState<string>('');
  const [supporterLivingAbroadYenError, setSupporterLivingAbroadYenError] = useState<string>('');
  const [supporterLivingAbroadRelWithApplicantOtherError, setSupporterLivingAbroadRelWithApplicantOtherError] = useState<string>('');
  
  //For "Supporter in Japan" option
  const [isSupporterInJapan, setIsSupporterInJapan] = useState<boolean>();
  const [supporterInJapanYen, setSupporterInJapanYen] = useState<string>('');
  const [supporterInJapanRelWithApplicant, setSupporterInJapanRelWithApplicant] = useState<string>('');
  const [supporterInJapanRelWithApplicantOther, setSupporterInJapanRelWithApplicantOther] = useState<string>('');
  const [supporterInJapanYenError, setSupporterInJapanYenError] = useState<string>('');
  const [supporterInJapanRelWithApplicantOtherError, setSupporterInJapanRelWithApplicantOtherError] = useState<string>('');

  //For "Scholarship" option
  const [isScholarship, setIsScholarship] = useState<boolean>();
  const [scholarshipYen, setScholarshipYen] = useState<string>('');
  const [isForeignGov, setIsForeignGov] = useState<boolean>();
  const [isJapaneseGov, setIsJapaneseGov] = useState<boolean>();
  const [isLocalGov, setIsLocalGov] = useState<boolean>();
  const [isPublicInterestInc, setIsPublicInterestInc] = useState<boolean>();
  const [isScholarshipOthers, setIsScholarshipOthers] = useState<boolean>();
  const [associationOrFoundationDetail, setAssociationOrFoundationDetail] = useState<string>('');
  const [orgOthers, setOrgOthers] = useState<string>(''); 
  const [scholarshipYenError, setScholarshipYenError] = useState<string>('');
  const [associationOrFoundationDetailError, setAssociationOrFoundationDetailError] = useState<string>('');
  const [orgOthersError, setOrgOthersError] = useState<string>('');


  //For "Others" option
  const [isMethodOthers, setIsMethodOthers] = useState<boolean>();
  const [methodOthersYen, setMethodOthersYen] = useState<string>('');
  const [methodOthersYenError, setMethodOthersYenError] = useState<string>('');

  //----------------------------------------------
 
  //For "Carrying from abroad" option
  const [isCarryingFromAbroad, setIsCarryingFromAbroad] = useState<boolean>();
  const [nameOfIndivisualCarryingCash, setNameOfIndivisualCarryingCash] = useState<string>('');
  const [carryingFromAbroadYen, setCarryingFromAbroadYen] = useState<string>('');
  const [dateAndTimeOfCarryingCash, setDateAndTimeOfCarryingCash] = useState<string>('');
  const [nameOfIndivisualCarryingCashError, setNameOfIndivisualCarryingCashError] = useState<string>('');
  const [carryingFromAbroadYenError, setCarryingFromAbroadYenError] = useState<string>('');

  //For "Remittances from abroad" option
  const [isRemittancesFromAbroad, setIsRemittancesFromAbroad] = useState<boolean>();
  const [remittancesSupporterLivingAbroadYen, setRemittancesRemSupporterLivingAbroadYen] = useState<string>('');
  const [remittancesSupporterLivingAbroadYenError, setRemittancesSupporterLivingAbroadYenError] = useState<string>('');

  //For "Others" option
  const [isRemittancesOthers, setIsRemittancesOthers] = useState<boolean>();
  const [remittancesOthersYen, setRemittancesOthersYen] = useState<string>('');
  const [remittancesOthersYenError, setRemittancesOthersYenError] = useState<string>('');

  //----------------------------------------------

  const [supporters, setSupporters] = useState<Supporter[]>([]);
  const [supporterErrors, setSupporterErrors] = useState<SupporterError[]>(
    Array.from({ length: MAX_SUPPORTERS }, () => getEmptySupporterError())
  );

  //Currently, the item names for this section are common between visa extension and change, 
  //so using the same function.
  const saveData = (data: Partial<FormP>) => {
    onChangeFormP(data);
  }

  const updateSupporter = (index: number, fieldName: keyof Supporter, val: string) => {
    setSupporters(prevState =>
      prevState.map((supporter, i) => i === index
        ? { ...supporter, [fieldName]: val }
        : supporter
      )
    );
  }

  const updateSupporterError = (index: number, fieldName: keyof SupporterError, val: string) => {
    setSupporterErrors(prevState =>
      prevState.map((err, i) => i === index
        ? { ...err, [fieldName]: val }
        : err
      )
    );
  }

  const onClickAddSupporter = () => {
    setSupporters([...supporters, getEmptySupporter()]);
  }

  const onClickRemoveSupporter = (index: number) => {
    let data: Partial<FormP> = {};

    for (let i = index; i < MAX_SUPPORTERS; i++) {
      const nextSupporter = supporters[i + 1] ?? getEmptySupporter();
      const nextSupporterError = supporterErrors[i + 1] ?? getEmptySupporterError();

      updateSupporter(i, 'name', nextSupporter.name);
      updateSupporter(i, 'prefecture', nextSupporter.prefecture);
      updateSupporter(i, 'municipality', nextSupporter.municipality);
      updateSupporter(i, 'townStreetApartment', nextSupporter.townStreetApartment);
      updateSupporter(i, 'phoneNumber', nextSupporter.phoneNumber);
      updateSupporter(i, 'occupationAtWork', nextSupporter.occupationAtWork);
      updateSupporter(i, 'phoneNumberAtWork', nextSupporter.phoneNumberAtWork);
      updateSupporter(i, 'annualIncome', nextSupporter.annualIncome);

      updateSupporterError(i, 'name', nextSupporterError.name);
      updateSupporterError(i, 'townStreetApartment', nextSupporterError.townStreetApartment);
      updateSupporterError(i, 'phoneNumber', nextSupporterError.phoneNumber);
      updateSupporterError(i, 'occupationAtWork', nextSupporterError.occupationAtWork);
      updateSupporterError(i, 'phoneNumberAtWork', nextSupporterError.phoneNumberAtWork);
      updateSupporterError(i, 'annualIncome', nextSupporterError.annualIncome);
      
      data = {
        ...data,
        [getSupporterKeyOf.name(i)]: nextSupporter.name || null,
        [getSupporterKeyOf.prefecture(i)]: nextSupporter.prefecture || null,
        [getSupporterKeyOf.municipality(i)]: nextSupporter.municipality || null,
        [getSupporterKeyOf.townStreetApartment(i)]: nextSupporter.townStreetApartment || null,
        [getSupporterKeyOf.phoneNumber(i)]: nextSupporter.phoneNumber || null,
        [getSupporterKeyOf.occupationAtWork(i)]: nextSupporter.occupationAtWork || null,
        [getSupporterKeyOf.phoneNumberAtWork(i)]: nextSupporter.phoneNumberAtWork || null,
        [getSupporterKeyOf.annualIncome(i)]: nextSupporter.annualIncome || null
      }

    }

    setSupporters(supporters.slice(0, supporters.length - 1));
    setSupporterErrors([...supporterErrors.slice(0, supporterErrors.length - 1), getEmptySupporterError()]);

    saveData(data);
  }


  useEffect(() => {
    if (!formP) 
      return;

    setIsSelf(formP.expenses_method_self_exists ?? false)
    setSelfYen(formP.expenses_method_self_amount ?? '');
    
    setIsSupporterLivingAbroad(formP.expenses_method_living_abroad_exists ?? false);
    setSupporterLivingAbroadYen(formP.expenses_method_living_abroad_amount ?? '');
    setSupporterLivingAbroadRelWithApplicant(formP.expenses_relationship_living_abroad ?? '');
    setSupporterLivingAbroadRelWithApplicantOther(formP.expenses_relationship_living_abroad_other ?? '');

    setIsSupporterInJapan(formP.expenses_method_living_in_japan_exists ?? false);
    setSupporterInJapanYen(formP.expenses_method_living_in_japan_amount ?? '');
    setSupporterInJapanRelWithApplicant(formP.expenses_relationship_living_in_japan ?? '');
    setSupporterInJapanRelWithApplicantOther(formP.expenses_relationship_living_in_japan_other ?? '');

    setIsScholarship(formP.expenses_method_scholarship_exists ?? false);
    setScholarshipYen(formP.expenses_method_scholarship_amount ?? '');
    setIsForeignGov(formP.expenses_organization_foreign_government_exists ?? false);
    setIsJapaneseGov(formP.expenses_organization_japanese_government_exists ?? false);
    setIsLocalGov(formP.expenses_organization_local_government_exists ?? false);
    setIsPublicInterestInc(formP.expenses_organization_public_inc_exists ?? false);
    setIsScholarshipOthers(formP.expenses_organization_other_exists ?? false);
    setAssociationOrFoundationDetail(formP.expenses_organization_public_inc ?? '');
    setOrgOthers(formP.expenses_organization_other ?? '');

    setIsMethodOthers(formP.expenses_method_other_exists ?? false);
    setMethodOthersYen(formP.expenses_method_other_amount ?? '');

    setIsCarryingFromAbroad(formP.expenses_remittances_carrying_carrying_exists ?? false);
    setNameOfIndivisualCarryingCash(formP.expenses_remittances_carrying_carrying_name ?? '');
    setCarryingFromAbroadYen(formP.expenses_remittances_carrying_carrying_amount ?? '');

    setDateAndTimeOfCarryingCash(parseDateWithJaUnits
      (formP.expenses_remittances_carrying_carrying_date_and_time ?? '').yearMonth ?? ''
    );

    setIsRemittancesFromAbroad(formP.expenses_remittances_carrying_remittances_exists ?? false);
    setRemittancesRemSupporterLivingAbroadYen(formP.expenses_remittances_carrying_remittances_amount ?? '');

    setIsRemittancesOthers(formP.expenses_remittances_carrying_others_exists ?? false);
    setRemittancesOthersYen(formP.expenses_remittances_carrying_others_amount ?? '');

    const sups: Supporter[] = [];
    const supsLen = getLengthOfSupporters(formP);
    for(let i = 1; i <= supsLen; i++) {
      sups.push({
        name: formP[getSupporterKeyOf.name(i)] as string ?? '',
        prefecture: formP[getSupporterKeyOf.prefecture(i)] as string ?? '',
        municipality: formP[getSupporterKeyOf.municipality(i)] as string ?? '',
        townStreetApartment: formP[getSupporterKeyOf.townStreetApartment(i)] as string ?? '',
        phoneNumber: formP[getSupporterKeyOf.phoneNumber(i)] as string ?? '',
        occupationAtWork: formP[getSupporterKeyOf.occupationAtWork(i)] as string ?? '',
        phoneNumberAtWork: formP[getSupporterKeyOf.phoneNumberAtWork(i)] as string ?? '',
        annualIncome: formP[getSupporterKeyOf.annualIncome(i)] as string ?? ''
      });
    }

    setSupporters(sups);
  }, [formP]);


  useEffect(() => {
    const areThereNoErrors = [
      selfYenError,
      supporterLivingAbroadYenError,
      supporterLivingAbroadRelWithApplicantOtherError,
      supporterInJapanYenError,
      supporterInJapanRelWithApplicantOtherError,
      scholarshipYenError,
      associationOrFoundationDetailError,
      orgOthersError,
      methodOthersYenError,
      remittancesSupporterLivingAbroadYenError,
      remittancesOthersYenError,
      ...supporterErrors.flatMap(supporterError => Object.values(supporterError)),
    ].every(val => !val);

    onChangeSectionReadiness(areThereNoErrors);
  }, [
    selfYenError,
    supporterLivingAbroadYenError,
    supporterLivingAbroadRelWithApplicantOtherError,
    supporterInJapanYenError,
    supporterInJapanRelWithApplicantOtherError,
    scholarshipYenError,
    associationOrFoundationDetailError,
    orgOthersError,
    methodOthersYenError,
    remittancesSupporterLivingAbroadYenError,
    remittancesOthersYenError,
    supporterErrors
  ]);

  return (
    <Section>

      <InlineMessage variant='info'>
        {t('noteOnSection1')}<br/>
        <br/>
        {t('noteOnSection2')}
      </InlineMessage>

      {/* Method of support and an amount of support per month (average) */}
      <LocalFieldGroup 
        title={tMethod('title')}
        theme="light"
      >
        <Items15>
          {/* Self */}
          <Items10>
            <Checkbox
              checked={isSelf}
              onValueChange={val => {
                const data: Partial<FormP> = {
                  expenses_method_self_exists: val
                };

                if (!val) {
                  data.expenses_method_self_amount = '';
                  setSelfYen('');
                  setSelfYenError('');
                }

                setIsSelf(val);
                saveData(data);
              }}
            >
              {tMethod('self.title')}
            </Checkbox>
            { isSelf &&
              <IndentBlock>
                <TextField
                  label={tMethod('self.selfYen')}
                  placeholder="0"
                  maxLength={14}
                  validators={[validation.isNumeric, validation.createLengthValidator(14)]}
                  restriction={inputNotes.halfwidthNumber}
                  value={selfYen}
                  onValueChange={setSelfYen}
                  error={selfYenError}
                  onErrorChange={setSelfYenError}
                  onBlur={() => {
                    saveData({
                      expenses_method_self_amount: selfYen
                    })
                  }}
                />
              </IndentBlock>
            }
          </Items10>

          {/* Supporter living abroad */}
          <Items10>
            <Checkbox
              checked={isSupporterLivingAbroad}
              onValueChange={val => {
                const data: Partial<FormP> = {
                  expenses_method_living_abroad_exists: val
                };

                if (!val) {
                  data.expenses_method_living_abroad_amount = '';
                  data.expenses_relationship_living_abroad = '';
                  data.expenses_relationship_living_abroad_other = '';
                  setSupporterLivingAbroadYen('');
                  setSupporterLivingAbroadRelWithApplicant('');
                  setSupporterLivingAbroadRelWithApplicantOther('');
                  setSupporterLivingAbroadYenError('');
                  setSupporterLivingAbroadRelWithApplicantOtherError('');
                }

                setIsSupporterLivingAbroad(val);
                saveData({
                  expenses_method_living_abroad_exists: val
                });
              }}
            >
              {tMethod('supporterLivingAbroad.title')}
            </Checkbox>

            { isSupporterLivingAbroad &&
              <IndentBlock>
                <Items10>
                  <TextField
                    label={tMethod('supporterLivingAbroad.supporterLivingAbroadYen')}
                    placeholder="0"
                    maxLength={14}
                    validators={[validation.isNumeric, validation.createLengthValidator(14)]}
                    restriction={inputNotes.halfwidthNumber}
                    value={supporterLivingAbroadYen}
                    onValueChange={setSupporterLivingAbroadYen}
                    error={supporterLivingAbroadYenError}
                    onErrorChange={setSupporterLivingAbroadYenError}
                    onBlur={() => {
                      saveData({
                        expenses_method_living_abroad_amount: supporterLivingAbroadYen
                      })
                    }}
                  />
                  <Items5>
                    <SelectField
                      label={tMethod('supporterLivingAbroad.relationshipWithApplicant')}
                      placeholder={tPlaceholder('select')}
                      options={RELATIONSHIP_WITH_SUPPORTER_OPTIONS_FOR_STUDY_ABROAD}
                      value={supporterLivingAbroadRelWithApplicant}
                      onValueChange={val => {
                        setSupporterLivingAbroadRelWithApplicant(val);
                        saveData({
                          expenses_relationship_living_abroad: val
                        });
                      
                      }}
                    />
                    { supporterLivingAbroadRelWithApplicant === RELATIONSHIP_W_SUPPORTER_ABROAD_OTHER_VALUE &&
                      <MultiTextField
                        maxLength={172}
                        validators={[validation.createLengthValidator(172)]}
                        value={supporterLivingAbroadRelWithApplicantOther}
                        onValueChange={setSupporterLivingAbroadRelWithApplicantOther}
                        error={supporterLivingAbroadRelWithApplicantOtherError}
                        onErrorChange={setSupporterLivingAbroadRelWithApplicantOtherError}
                        onBlur={() => {
                          saveData({
                            expenses_relationship_living_abroad_other: 
                              supporterLivingAbroadRelWithApplicantOther
                          });
                        }}
                      />
                    }
                  </Items5>
                </Items10>
              </IndentBlock>
            }
          </Items10>

          {/* Supporter in Japan */}
          <Items10>
            <Checkbox
              checked={isSupporterInJapan}
              onValueChange={val => {
                const data: Partial<FormP> = {
                  expenses_method_living_in_japan_exists: val
                };

                if (!val) {
                  data.expenses_method_living_in_japan_amount = '';
                  data.expenses_relationship_living_in_japan = '';
                  data.expenses_relationship_living_in_japan_other = '';
                  setSupporterInJapanYen('');
                  setSupporterInJapanRelWithApplicant('');
                  setSupporterInJapanRelWithApplicantOther('');
                  setSupporterInJapanYenError('');
                  setSupporterInJapanRelWithApplicantOtherError('');
                }

                setIsSupporterInJapan(val);
                saveData(data);
              }}
            >
              {tMethod('supporterInJapan.title')}
            </Checkbox>
            { isSupporterInJapan &&
              <IndentBlock>
                <Items10>
                  <TextField
                    label={tMethod('supporterInJapan.supporterInJapanYen')}
                    placeholder="0"
                    maxLength={14}
                    validators={[validation.isNumeric, validation.createLengthValidator(14)]}
                    restriction={inputNotes.halfwidthNumber}
                    value={supporterInJapanYen}
                    onValueChange={setSupporterInJapanYen}
                    error={supporterInJapanYenError}
                    onErrorChange={setSupporterInJapanYenError}
                    onBlur={() => {
                      saveData({
                        expenses_method_living_in_japan_amount: supporterInJapanYen
                      });
                    }}
                  />
                  <Items5>
                    <SelectField
                      label={tMethod('supporterInJapan.relationshipWithApplicant')}
                      placeholder={tPlaceholder('select')}
                      options={RELATIONSHIP_WITH_SUPPORTER_OPTIONS_FOR_STUDY_ABROAD}
                      value={supporterInJapanRelWithApplicant}
                      onValueChange={val => {
                        setSupporterInJapanRelWithApplicant(val);
                        saveData({
                          expenses_relationship_living_in_japan: val
                        });
                      }}
                    />
                    { supporterInJapanRelWithApplicant === RELATIONSHIP_W_SUPPORTER_IN_JAPAN_OTHER_VALUE &&
                      <MultiTextField
                        maxLength={172}
                        validators={[validation.createLengthValidator(172)]}
                        value={supporterInJapanRelWithApplicantOther}
                        onValueChange={setSupporterInJapanRelWithApplicantOther}
                        error={supporterInJapanRelWithApplicantOtherError}
                        onErrorChange={setSupporterInJapanRelWithApplicantOtherError}
                      />
                    }
                  </Items5>
                </Items10>
              </IndentBlock>
            }
          </Items10>

          {/* Scholarship */}
          <Items10>
            <Checkbox
              checked={isScholarship}
              onValueChange={val => {
                const data: Partial<FormP> = {
                  expenses_method_scholarship_exists: val
                };

                if (!val) {
                  data.expenses_method_scholarship_amount = '';
                  data.expenses_organization_foreign_government_exists = false;
                  data.expenses_organization_japanese_government_exists = false;
                  data.expenses_organization_local_government_exists = false;
                  data.expenses_organization_public_inc_exists = false;
                  data.expenses_organization_public_inc = '';
                  data.expenses_organization_other_exists = false;
                  data.expenses_organization_other = '';
                  setScholarshipYen('');
                  setIsForeignGov(false);
                  setIsJapaneseGov(false);
                  setIsLocalGov(false);
                  setIsPublicInterestInc(false);
                  setIsScholarshipOthers(false);
                  setAssociationOrFoundationDetail('');
                  setOrgOthers('');
                  setScholarshipYenError('');
                  setAssociationOrFoundationDetailError('');
                  setOrgOthersError('');
                }

                setIsScholarship(val);
                saveData({
                  expenses_method_scholarship_exists: val
                });
              }}
            >
              {tMethod('scholarship.title')}
            </Checkbox>
            { isScholarship &&
              <IndentBlock>
                <Items10>
                  <TextField
                    label={tMethod('scholarship.scholarshipYen')}
                    placeholder="0"
                    maxLength={14}
                    validators={[validation.isNumeric, validation.createLengthValidator(14)]}
                    restriction={inputNotes.halfwidthNumber}
                    value={scholarshipYen}
                    onValueChange={setScholarshipYen}
                    error={scholarshipYenError}
                    onErrorChange={setScholarshipYenError}
                    onBlur={() => {
                      saveData({
                        expenses_method_scholarship_amount: scholarshipYen
                      });
                    }}
                  />
                  
                  <Items5>
                    <Label>{tMethod('scholarship.orgWhichProvidesScholarship.title')}</Label>

                    {/* Foreign government */}
                    <Checkbox
                      checked={isForeignGov}
                      onValueChange={val => {
                        setIsForeignGov(val);
                        saveData({
                          expenses_organization_foreign_government_exists: val
                        });
                      }}
                    >
                      {tMethod('scholarship.orgWhichProvidesScholarship.foreignGov')}
                    </Checkbox>

                    {/* Japanese government */}
                    <Checkbox
                      checked={isJapaneseGov}
                      onValueChange={val => {
                        setIsJapaneseGov(val);
                        saveData({
                          expenses_organization_japanese_government_exists: val
                        });
                      }}
                    >
                      {tMethod('scholarship.orgWhichProvidesScholarship.japaneseGov')}
                    </Checkbox>

                    {/* Local government */}
                    <Checkbox
                      checked={isLocalGov}
                      onValueChange={val => {
                        setIsLocalGov(val);
                        saveData({
                          expenses_organization_local_government_exists: val
                        });
                      }}
                    >
                      {tMethod('scholarship.orgWhichProvidesScholarship.localGov')}
                    </Checkbox>

                    {/* Public interest incorporated association / 
                        Public interest incorporated foundation */}
                    <Items5>
                      <Checkbox
                        checked={isPublicInterestInc}
                        onValueChange={val => {
                          const data: Partial<FormP> = {
                            expenses_organization_public_inc_exists: val
                          };

                          if (!val) {
                            data.expenses_organization_public_inc = '';
                            setAssociationOrFoundationDetail('');
                            setAssociationOrFoundationDetailError('');
                          }

                          setIsPublicInterestInc(val);
                          saveData(data);
                        }}
                      >
                        {tMethod('scholarship.orgWhichProvidesScholarship.publicInterestInc')}
                      </Checkbox>
                      { isPublicInterestInc &&
                        <IndentSegment>
                          <MultiTextField
                            maxLength={172}
                            validators={[validation.createLengthValidator(172)]}
                            value={associationOrFoundationDetail}
                            onValueChange={setAssociationOrFoundationDetail}
                            error={associationOrFoundationDetailError}
                            onErrorChange={setAssociationOrFoundationDetailError}
                            onBlur={() => {
                              saveData({
                                expenses_organization_public_inc: associationOrFoundationDetail
                              });
                            }}
                          />
                        </IndentSegment>
                      }
                    </Items5>

                    {/* Others */}
                    <Items5>
                      <Checkbox
                        checked={isScholarshipOthers}
                        onValueChange={val => {
                          const data: Partial<FormP> = {
                            expenses_organization_other_exists: val
                          };

                          if (!val) {
                            data.expenses_organization_other = '';
                            setOrgOthers('');
                            setOrgOthersError('');
                          }

                          setIsScholarshipOthers(val);
                          saveData(data);
                        }}
                      >
                        {tMethod('scholarship.orgWhichProvidesScholarship.others')}
                      </Checkbox>
                      { isScholarshipOthers &&
                        <IndentSegment>
                          <MultiTextField
                            maxLength={172}
                            validators={[validation.createLengthValidator(172)]}
                            value={orgOthers}
                            onValueChange={setOrgOthers}
                            error={orgOthersError}
                            onErrorChange={setOrgOthersError}
                          />
                        </IndentSegment>
                      }
                    </Items5>
                  </Items5>
                </Items10>
              </IndentBlock>
            }
          </Items10>

          {/* Others */}
          <Items10>
            <Checkbox
              checked={isMethodOthers}
              onValueChange={val => {
                const data: Partial<FormP> = {
                  expenses_method_other_exists: val
                };

                if (!val) {
                  data.expenses_method_other_amount = '';
                  setMethodOthersYen('');
                  setMethodOthersYenError('');
                }

                setIsMethodOthers(val);
                saveData(data);
              }}
            >
              {tMethod('others.title')}
            </Checkbox>
            { isMethodOthers &&
              <IndentBlock>
                <TextField
                  label={tMethod('others.othersYen')}
                  placeholder="0"
                  maxLength={14}
                  validators={[validation.isNumeric, validation.createLengthValidator(14)]}
                  restriction={inputNotes.halfwidthNumber}
                  value={methodOthersYen}
                  onValueChange={setMethodOthersYen}
                  error={methodOthersYenError}
                  onErrorChange={setMethodOthersYenError}
                />
              </IndentBlock>
            }
          </Items10>
        </Items15>
      </LocalFieldGroup>


      {/* Remittances from abroad or carrying cash */}
      <LocalFieldGroup
        title={tRemmitances('title')}
        theme="light"
      >
       <Items15>
        {/* Carrying from abroad */}
        <Items10>
          <Checkbox
            checked={isCarryingFromAbroad}
            onValueChange={val => {
              const data: Partial<FormP> = {
                expenses_remittances_carrying_carrying_exists: val
              };

              if (!val) {
                data.expenses_remittances_carrying_carrying_amount = '';
                data.expenses_remittances_carrying_carrying_name = '';
                data.expenses_remittances_carrying_carrying_date_and_time = '';
                setNameOfIndivisualCarryingCash('');
                setCarryingFromAbroadYen('');
                setDateAndTimeOfCarryingCash('');
                setNameOfIndivisualCarryingCashError('');
                setCarryingFromAbroadYenError('');
              }

              setIsCarryingFromAbroad(val);
              saveData(data);
            }}
          >
            {tRemmitances('carryingFromAbroad.title')}
          </Checkbox>
          { isCarryingFromAbroad &&
            <IndentBlock>
              <Items10>
                {/* Name of the indivisual carrying cash */}
                <TextField
                  label={tRemmitances('carryingFromAbroad.nameOfIndividual')}
                  placeholder="Nam Van Nguyen"
                  maxLength={172}
                  validators={[validation.createLengthValidator(172)]}
                  value={nameOfIndivisualCarryingCash}
                  onValueChange={setNameOfIndivisualCarryingCash}
                  error={nameOfIndivisualCarryingCashError}
                  onErrorChange={setNameOfIndivisualCarryingCashError}
                  onBlur={() => {
                    saveData({
                      expenses_remittances_carrying_carrying_name: nameOfIndivisualCarryingCash
                    });
                  }}
                />
                
                {/* Carrying from abroad (Yen) */}
                <TextField
                  label={tRemmitances('carryingFromAbroad.carryingFromAboradYen')}
                  placeholder="0"
                  maxLength={14}
                  validators={[validation.isNumeric, validation.createLengthValidator(14)]}
                  restriction={inputNotes.halfwidthNumber}
                  value={carryingFromAbroadYen}
                  onValueChange={setCarryingFromAbroadYen}
                  error={carryingFromAbroadYenError}
                  onErrorChange={setCarryingFromAbroadYenError}
                  onBlur={() => {
                    saveData({
                      expenses_remittances_carrying_carrying_amount: carryingFromAbroadYen
                    });
                  }}
                />
              
                {/* Date and time of carrying cash */}
                <DateSelectField 
                  hideDayField
                  label={tRemmitances('carryingFromAbroad.dateAndTime')}
                  value={dateAndTimeOfCarryingCash}
                  onValueChange={val => {
                    setDateAndTimeOfCarryingCash(val);
                    saveData({
                      expenses_remittances_carrying_carrying_date_and_time: formatDateStrWithJaUnits(val)
                    });
                  }}
                />
              </Items10>
            </IndentBlock>
          }
        </Items10>

          {/* Remittances from abroad */}
          <Items10>
            <Checkbox
              checked={isRemittancesFromAbroad}
              onValueChange={val => {
                const data: Partial<FormP> = {
                  expenses_remittances_carrying_remittances_exists: val
                };

                if (!val) {
                  data.expenses_remittances_carrying_remittances_amount = '';
                  setRemittancesRemSupporterLivingAbroadYen('');
                  setRemittancesSupporterLivingAbroadYenError('');
                }

                setIsRemittancesFromAbroad(val);
                saveData(data);
              }}
            >
              {tRemmitances('remittances.title')}
            </Checkbox>
            { isRemittancesFromAbroad &&
              <IndentBlock>
                <TextField
                  label={tRemmitances('remittances.supporterLivingAbroadYen')}
                  placeholder="0"
                  maxLength={12}
                  validators={[validation.isNumeric, validation.createLengthValidator(12)]}
                  restriction={inputNotes.halfwidthNumber} 
                  value={remittancesSupporterLivingAbroadYen}
                  onValueChange={setRemittancesRemSupporterLivingAbroadYen}
                  error={remittancesSupporterLivingAbroadYenError}
                  onErrorChange={setRemittancesSupporterLivingAbroadYenError}
                  onBlur={() => {
                    saveData({
                      expenses_remittances_carrying_remittances_amount: remittancesSupporterLivingAbroadYen
                    });
                  }}
                />
              </IndentBlock>
            } 
          </Items10>

          {/* Others (Yen) */}
          <Items10>
            <Checkbox
              checked={isRemittancesOthers}
              onValueChange={val => {
                const data: Partial<FormP> = {
                  expenses_remittances_carrying_others_exists: val
                };

                if (!val) {
                  data.expenses_remittances_carrying_others_amount = '';
                  setRemittancesOthersYen('');
                  setRemittancesOthersYenError('');
                }

                setIsRemittancesOthers(val);
                saveData(data);
              }}
            >
              {tRemmitances('others.title')}
            </Checkbox>
            { isRemittancesOthers &&
              <IndentBlock>
                <TextField
                  label={tRemmitances('others.othersYen')}
                  placeholder="0"
                  maxLength={14}
                  validators={[validation.isNumeric, validation.createLengthValidator(14)]}
                  restriction={inputNotes.halfwidthNumber}
                  value={remittancesOthersYen}
                  onValueChange={setRemittancesOthersYen}
                  error={remittancesOthersYenError}
                  onErrorChange={setRemittancesOthersYenError}
                  onBlur={() => {
                    saveData({
                      expenses_remittances_carrying_others_amount: remittancesOthersYen
                    });
                  }}
                />
              </IndentBlock>
            }
          </Items10>
        </Items15>
      </LocalFieldGroup>

      <LocalFieldGroup
        title={tSupporter('title')}
        note={(
          <>
            {tSupporter('noteOnSegment1')}<br/>
            <br/>
            {tSupporter('noteOnSegment2')}<br/>
          </>
        )} 
        theme="light"
      >
        <Items30>

        {/* Each supporter */}
        { supporters.map((supporter, index) => (
          <SupporterContainer key={`supporter-${index} + 1`}>
            <Items30>
              <FieldGroupNumber>{index + 1}</FieldGroupNumber>
              <ActionButton
                onClick={() => onClickRemoveSupporter(index)}
              >
                <TrashCanIcon />
                {tSupporter('remove')}
              </ActionButton>

              <TextField
                  label={tSupporter('name')}
                  placeholder="Nam Van Nguyen"
                  maxLength={172}
                  validators={[validation.createLengthValidator(172)]}
                  value={supporter.name}
                  onValueChange={val => updateSupporter(index, 'name', val)}
                  error={supporterErrors[index].name}
                  onErrorChange={val => updateSupporterError(index, 'name', val)}
                  onBlur={() => {
                    saveData({ 
                      [getSupporterKeyOf.name(index + 1)]: supporter.name
                    });
                  }}  
                /> 

              <SelectField
                label={tWithoutPrefix('common.prefecture')}
                placeholder={tPlaceholder('select')}
                options={PREFECTURE_WITH_JA_VALUE_OPTIONS}
                value={supporter.prefecture}
                onValueChange={val => {
                  updateSupporter(index, 'prefecture', val);
                  saveData({ 
                    [getSupporterKeyOf.prefecture(index + 1)]: val
                  });
                }}
              />

              <SelectField
                disabled={!supporter.prefecture}
                label={tWithoutPrefix('common.municipality')}
                options={MUNICIPALITY_WITH_JA_VALUE_OPTIONS[supporter.prefecture] ?? []}
                value={supporter.municipality}
                onValueChange={val => {
                  updateSupporter(index, 'municipality', val);
                  saveData({ 
                    [getSupporterKeyOf.municipality(index + 1)]: val
                  });
                }}
              />

              <MultiTextField
                label={tWithoutPrefix('common.townStreetApartment')}
                maxLength={172}
                validators={[validation.createLengthValidator(172)]}
                value={supporter.townStreetApartment}
                onValueChange={val => updateSupporter(index, 'townStreetApartment', val)}
                error={supporterErrors[index].townStreetApartment}
                onErrorChange={val => updateSupporterError(index, 'townStreetApartment', val)}
                onBlur={() => {
                  saveData({ 
                    [getSupporterKeyOf.townStreetApartment(index + 1)]: supporter.townStreetApartment
                  });
                }}
              />

              <TextField
                label={tSupporter('telephoneNumber')}
                placeholder="1234567891234"
                maxLength={13}
                validators={[validation.createLengthValidator(13), validation.isNumeric]}
                restriction={inputNotes.halfwidthNumber}
                value={supporter.phoneNumber}
                onValueChange={val => updateSupporter(index, 'phoneNumber', val)}
                error={supporterErrors[index].phoneNumber}
                onErrorChange={val => updateSupporterError(index, 'phoneNumber', val)}
                onBlur={() => {
                  saveData({ 
                    [getSupporterKeyOf.phoneNumber(index + 1)]: supporter.phoneNumber
                  });
                }}
              />
              
              <TextField
                label={tSupporter('occupationPlaceOfEmp')}
                maxLength={172}
                validators={[validation.createLengthValidator(172)]}
                value={supporter.occupationAtWork}
                onValueChange={val => updateSupporter(index, 'occupationAtWork', val)}
                error={supporterErrors[index].occupationAtWork}
                onErrorChange={val => updateSupporterError(index, 'occupationAtWork', val)}
                onBlur={() => {
                  saveData({ 
                    [getSupporterKeyOf.occupationAtWork(index + 1)]: supporter.occupationAtWork
                  });
                }}
              />

              <TextField
                label={tSupporter('telephoneNumberPlaceOfEmp')}
                placeholder="1234567891234"
                maxLength={13}
                validators={[validation.createLengthValidator(13), validation.isNumeric]}
                restriction={inputNotes.halfwidthNumber}
                value={supporter.phoneNumberAtWork}
                onValueChange={val => updateSupporter(index, 'phoneNumberAtWork', val)}
                error={supporterErrors[index].phoneNumberAtWork}
                onErrorChange={val => updateSupporterError(index, 'phoneNumberAtWork', val)}
                onBlur={() => {
                  saveData({ 
                    [getSupporterKeyOf.phoneNumberAtWork(index + 1)]: supporter.phoneNumberAtWork
                  });
                }}
              />
               
              <TextField
                label={tSupporter('annualIncome')}
                placeholder="0"
                maxLength={14}
                validators={[validation.isNumeric, validation.createLengthValidator(14)]}
                restriction={inputNotes.halfwidthNumber}
                value={supporter.annualIncome}
                onValueChange={val => updateSupporter(index, 'annualIncome', val)}
                error={supporterErrors[index].annualIncome}
                onErrorChange={val => updateSupporterError(index, 'annualIncome', val)}
                onBlur={() => {
                  saveData({ 
                    [getSupporterKeyOf.annualIncome(index + 1)]: supporter.annualIncome
                  });
                }}
              /> 
            </Items30>
          </SupporterContainer>
        ))}
        </Items30>

        { supporters.length < MAX_SUPPORTERS &&
          <ActionButton onClick={onClickAddSupporter}>
            <PlusIcon />
            {tSupporter('addSupporter')}
          </ActionButton>
        }
      </LocalFieldGroup>

    </Section>
  );
};

export default MethodOfSupportToPaySection;