import { SelectOption } from "../../types/option";
import { Relationship_CB06, RelationshipInJapanese } from "../../types/visa/relationship";

const getRelationshipTKey = (key: string) => `relationship_CB06.${key}`;

//Ref: https://docs.google.com/spreadsheets/d/1DSH9psUEakHitz56tuTrRxzV0y07nI_KhafCmHlUjCI/edit?gid=1813510246#gid=1813510246
export const RELATIONSHIP_CB06_OPTIONS: SelectOption[] = [
    {
        "label": getRelationshipTKey(Relationship_CB06.Self),
        "value": Relationship_CB06.Self
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.Husband),
        "value": Relationship_CB06.Husband
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.Wife),
        "value": Relationship_CB06.Wife
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.Child),
        "value": Relationship_CB06.Child
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.Father),
        "value": Relationship_CB06.Father
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.Mother),
        "value": Relationship_CB06.Mother
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.OldBrother),
        "value": Relationship_CB06.OldBrother
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.YoungBrother),
        "value": Relationship_CB06.YoungBrother
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.OldSister),
        "value": Relationship_CB06.OldSister
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.YoungSister),
        "value": Relationship_CB06.YoungSister
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.Grandfather),
        "value": Relationship_CB06.Grandfather
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.Grandmother),
        "value": Relationship_CB06.Grandmother
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.Grandchild),
        "value": Relationship_CB06.Grandchild
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.FosterFather),
        "value": Relationship_CB06.FosterFather
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.FosterMother),
        "value": Relationship_CB06.FosterMother
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.AdoptedChild),
        "value": Relationship_CB06.AdoptedChild
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.FatherInLaw),
        "value": Relationship_CB06.FatherInLaw
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.MotherInLaw),
        "value": Relationship_CB06.MotherInLaw
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.ChildOfSpouse),
        "value": Relationship_CB06.ChildOfSpouse
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.Stepchild),
        "value": Relationship_CB06.Stepchild
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.Guarantor),
        "value": Relationship_CB06.Guarantor
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.Sibling),
        "value": Relationship_CB06.Sibling
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.UncleAunt),
        "value": Relationship_CB06.UncleAunt
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.EducationalInstitute),
        "value": Relationship_CB06.EducationalInstitute
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.Employer),
        "value": Relationship_CB06.Employer
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.FriendAcquaintance),
        "value": Relationship_CB06.FriendAcquaintance
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.RelativeOfFriendAcquaintance),
        "value": Relationship_CB06.RelativeOfFriendAcquaintance
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.BusinessConnectionPersonnelOfLocalEnterprise),
        "value": Relationship_CB06.BusinessConnectionPersonnelOfLocalEnterprise
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.RelativeOfBusinessConnectionPersonnelOfLocalEnterprise),
        "value": Relationship_CB06.RelativeOfBusinessConnectionPersonnelOfLocalEnterprise
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.Others),
        "value": Relationship_CB06.Others
    }
];

export const RELATIONSHIP_WITH_JA_VALUE_OPTIONS: SelectOption[] = [
    {
        "label": getRelationshipTKey(Relationship_CB06.Self),
        "value": RelationshipInJapanese.Self
    }, 
    {
        "label": getRelationshipTKey(Relationship_CB06.Husband),
        "value": RelationshipInJapanese.Husband
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.Wife),
        "value": RelationshipInJapanese.Wife
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.Child),
        "value": RelationshipInJapanese.Child
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.Father),
        "value": RelationshipInJapanese.Father
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.Mother),
        "value": RelationshipInJapanese.Mother
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.OldBrother),
        "value": RelationshipInJapanese.OldBrother
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.YoungBrother),
        "value": RelationshipInJapanese.YoungBrother
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.OldSister),
        "value": RelationshipInJapanese.OldSister
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.YoungSister),
        "value": RelationshipInJapanese.YoungSister
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.Grandfather),
        "value": RelationshipInJapanese.Grandfather
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.Grandmother),
        "value": RelationshipInJapanese.Grandmother
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.Grandchild),
        "value": RelationshipInJapanese.Grandchild
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.FosterFather),
        "value": RelationshipInJapanese.FosterFather
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.FosterMother),
        "value": RelationshipInJapanese.FosterMother
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.AdoptedChild),
        "value": RelationshipInJapanese.AdoptedChild
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.FatherInLaw),
        "value": RelationshipInJapanese.FatherInLaw
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.MotherInLaw),
        "value": RelationshipInJapanese.MotherInLaw
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.ChildOfSpouse),
        "value": RelationshipInJapanese.ChildOfSpouse
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.Stepchild),
        "value": RelationshipInJapanese.Stepchild
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.Guarantor),
        "value": RelationshipInJapanese.Guarantor
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.Sibling),
        "value": RelationshipInJapanese.Sibling
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.UncleAunt),
        "value": RelationshipInJapanese.UncleAunt
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.EducationalInstitute),
        "value": RelationshipInJapanese.EducationalInstitute
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.Employer),
        "value": RelationshipInJapanese.Employer
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.FriendAcquaintance),
        "value": RelationshipInJapanese.FriendAcquaintance
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.RelativeOfFriendAcquaintance),
        "value": RelationshipInJapanese.RelativeOfFriendAcquaintance
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.BusinessConnectionPersonnelOfLocalEnterprise),
        "value": RelationshipInJapanese.BusinessConnectionPersonnelOfLocalEnterprise
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.RelativeOfBusinessConnectionPersonnelOfLocalEnterprise),
        "value": RelationshipInJapanese.RelativeOfBusinessConnectionPersonnelOfLocalEnterprise
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.Others),
        "value": RelationshipInJapanese.Others
    }
]


//For some fields, the option "01" (i.e. "self") is not appropriate, so we hide it
export const RELATIONSHIP_CB06_OPTIONS_WITHOUT_SELF: SelectOption[] = 
    RELATIONSHIP_CB06_OPTIONS.filter((option) => option.value !== Relationship_CB06.Self);
export const RELATIONSHIP_WITH_JA_VALUE_OPTIONS_WITHOUT_SELF: SelectOption[] = 
    RELATIONSHIP_WITH_JA_VALUE_OPTIONS.filter((option) => option.value !== RelationshipInJapanese.Self);



//Ref: https://docs.google.com/spreadsheets/d/1DSH9psUEakHitz56tuTrRxzV0y07nI_KhafCmHlUjCI/edit?gid=308892565#gid=308892565
export const RELATIONSHIP_WITH_SUPPORTER_OPTIONS_FOR_STUDY_ABROAD: SelectOption[] = [
    {
        "label": getRelationshipTKey(Relationship_CB06.Wife),
        "value": RelationshipInJapanese.Wife
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.Husband),
        "value": RelationshipInJapanese.Husband
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.Father),
        "value": RelationshipInJapanese.Father
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.Mother),
        "value": RelationshipInJapanese.Mother
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.Grandfather),
        "value": RelationshipInJapanese.Grandfather
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.Grandmother),
        "value": RelationshipInJapanese.Grandmother
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.FosterFather),
        "value": RelationshipInJapanese.FosterFather
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.FosterMother),
        "value": RelationshipInJapanese.FosterMother
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.FriendAcquaintance),
        "value": RelationshipInJapanese.FriendAcquaintance
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.RelativeOfFriendAcquaintance),
        "value": RelationshipInJapanese.RelativeOfFriendAcquaintance
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.EducationalInstitute),
        "value": RelationshipInJapanese.EducationalInstitute
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.BusinessConnectionPersonnelOfLocalEnterprise),
        "value": RelationshipInJapanese.BusinessConnectionPersonnelOfLocalEnterprise
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.Sibling),
        "value": RelationshipInJapanese.Sibling
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.UncleAunt),
        "value": RelationshipInJapanese.UncleAunt
    },
    {
        "label": getRelationshipTKey(Relationship_CB06.Others),
        "value": RelationshipInJapanese.Others
    }
       
];