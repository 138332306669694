import { FunctionComponent, HTMLAttributes } from 'react';
import styled from 'styled-components';

interface RestrictionProps extends HTMLAttributes<HTMLDivElement> {
    content?: string;
    currentLength?: number;
    maxLength?: number;
}

interface LengthProps {
    invalid?: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 17px;
  color: #626161;
`;

const Content = styled.span`
  flex: 1;
`;

const Length = styled.span<LengthProps>`
  color: ${(props: LengthProps) => props.invalid ? '#E93232' : 'inherit'};
`;

const Restriction: FunctionComponent<RestrictionProps> = ({
    content,
    currentLength = 0,
    maxLength = 0,
}) => {
    return (
        <Container>
            <Content>{content}</Content>
            {maxLength > 0 && (
                <Length invalid={currentLength > maxLength}>
                    {currentLength}/{maxLength}
                </Length>
            )}
        </Container>
    );
};

export default Restriction;