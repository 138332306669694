import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "../../components/form/Button";
import RadioField from "../../components/compound/RadioField";
import styled from "styled-components";
import { CONTENT_MAX_WIDTH } from "../../constants/styles";
import Page from "../../components/layout/Page";
import { updateCurrentUser } from "../../apis/user";
import { Language } from "../../types/language";
import { getLanguages } from "../../apis/language";
import { updateUserSetting } from "../../apis/userSetting";
import { useDispatcher, useFieldPlaceholder } from "../../hooks";
import { useNavigate } from "react-router-dom";
import PageHeading from "../../components/text/PageHeading";
import SelectField from "../../components/compound/SelectField";
import { CURRENT_STATUS_OF_RESIDENCE_CB19_OPTIONS } from "../../constants/options";
import { StatusOfResidenceCode_CB19 } from "../../types/visa/statusOfResidence";
import BottomAlignedContainer from "../../components/layout/BottomAlignedContainer";

interface SetupPageProps {}

const LocalPage = styled(Page)`
  padding: 20px;
  gap: 30px;
`;

const ButtonContainer = styled(BottomAlignedContainer)`
  padding-top: 30px;
`;

const SetupPage: FunctionComponent<SetupPageProps> = () => {
  const { dispatcher } = useDispatcher();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const placeholder = useFieldPlaceholder();
  const [statusOfResidence, setStatusOfResidence] = useState<StatusOfResidenceCode_CB19>();
  const [preferredLanguage, setPreferredLanguage] = useState("");

  const onClick = async () => {
    dispatcher.startLoading();
    try {
      const user = await updateCurrentUser({ status_of_residence: statusOfResidence,});
      const languages: Language[] = await getLanguages();
      await updateUserSetting(user.user_setting.id, {
        language_id: languages.find(
          (language) => language.code === preferredLanguage
        )?.id,
      });
      i18n.changeLanguage(preferredLanguage);
      localStorage.setItem("lng", preferredLanguage);
      dispatcher.setSetupComplete(true);
      setTimeout(() => navigate("/onboarding"), 0);
    } catch {
      dispatcher.showWarningOnSnackbar(t("snackbar.failedToProceed"));
    } finally {
      dispatcher.stopLoading();
    }
  }

  return (
    <LocalPage>
      <PageHeading>{t("setupPage.setup")}</PageHeading>
      <SelectField
        label={t("setupPage.currentResidencyStatus")}
        placeholder={placeholder.select}
        options={CURRENT_STATUS_OF_RESIDENCE_CB19_OPTIONS}
        defaultValue={statusOfResidence}
        value={statusOfResidence}
        onValueChange={setStatusOfResidence}
      />

      <RadioField
        label={t("setupPage.yourLanguage")}
        options={[
          {
            label: t("setupPage.english"),
            value: "en",
          },
          {
            label: t("setupPage.japanese"),
            value: "ja",
          },
        ]}
        value={preferredLanguage}
        onValueChange={setPreferredLanguage}
      />

      <ButtonContainer>
        <Button
          disabled={!statusOfResidence || !preferredLanguage}
          onClick={onClick}
        >
          {t("setupPage.next")}
        </Button>
      </ButtonContainer>
    </LocalPage>
  );
};

export default SetupPage;
