import { ButtonHTMLAttributes, FunctionComponent } from 'react';
import styled from 'styled-components';
import { ReactComponent as LeftArrowIcon } from '../../assets/icon-left-arrow.svg';
import { useNavigate } from 'react-router-dom';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  jumpTo?: string;
  onClick?: () => void; 
};

const LocalButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #EDEDED;
  border: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 100px;
`;

const BackIcon = styled(LeftArrowIcon)`
  width: 14px;
  height: 14px;
  flex-shrink: 0;
`;

const BackButton: FunctionComponent<ButtonProps> = ({
  onClick,
  jumpTo,
  ...props
}) => {
  const navigate = useNavigate();

  return (
    <LocalButton 
      onClick={() => {
        onClick?.();
        jumpTo && navigate(jumpTo);
      }} 
      {...props} 
    >
      <BackIcon/>
    </LocalButton>
  );
};

export default BackButton;