import { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../components/form/Button";
import Page from "../../components/layout/Page";
import Text from "../../components/text/Text";
import { ReactComponent as PlusIcon } from "../../assets/icon-plus-thick.svg";
import Row from "../../components/layout/Row";
import { useTranslation } from "react-i18next";
import PageHeading from "../../components/text/PageHeading";
import Column from "../../components/layout/Column";
import CreateNewAppModal from "./CreateNewAppModal";
import { getVisaApplications } from "../../apis/visaApplication";
import { VisaApplication } from "../../types/visa/applicationData";
import { useDispatcher, useNextAction } from "../../hooks";
import { useNavigate } from "react-router-dom";
import PaymentPromptModal from "../../components/modal/PaymentPromptModal";
import {
  clearURLParameters,
  isEnumValIncludedInURLParams,
  sortByCreatedDates,
} from "../../utils/utils";
import UserAuthenticationModal from "./UserAuthenticationModal";
import { UserRegistrationApplication } from "../../types/userRegistration/data";
import { getLatestUserRegistrationApplication } from "../../apis/userRegistration";
import {
  getSubmissionDate,
  isUserRegistrationApplicationBeingProcessed,
  isUserRegistrationApplicationDenied,
} from "../../utils/userRegistrationHelper";
import { getCurrentUser } from "../../apis/user";
import { StatusOfResidenceCode_CB19 } from "../../types/visa/statusOfResidence";
import InlineMessage from "../../components/form/InlineMessage";
import ApplicationCard from "../../components/display/ApplicationCard";
import { QueryClient, useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/values/query";
import PromptCard from "../../components/display/PromptCard";
import PreviewModal from "../../components/modal/previewModal";
import { getVisaApplicationStatus } from "../../utils/visaApplicationHelper";
import { VisaApplicationStatus } from "../../types/visa/applicationStatus";
import { set } from "react-datepicker/dist/date_utils";
import BottomAlignedContainer from "../../components/layout/BottomAlignedContainer";

//This action is only used in the homepage to see if the payment modal should show or not, so not defined in the modal.
//The string value actually doesn't have a special meaning so it's okay to change later
//but since the backend uses it inside a welcome email, we have to be careful when changing it
enum PaymentModalAction {
  ShowModal = "shwpymntmdl",
}

const LocalPage = styled(Page)`
  padding: 20px;
  gap: 30px;
`;

const LocalButton = styled(Button)`
  width: auto;
  min-width: 114px;
  align-self: flex-start;
`;

const LocalRow = styled(Row)`
  justify-content: space-between;
`;

const LocalColumn = styled(Column)`
  gap: 15px;
`;

const SmallText = styled(Text)`
  font-size: 0.8rem;
  color: #999999;
`;

const ActionButton = styled(Button).attrs(() => ({
  variant: "inline",
}))`
  width: auto;
  min-width: 123px;
  align-self: flex-start;
`;

const PrivacyPolicyLink = styled.a`
  color: #0069ec;
`;

const PrivacyPolicyText = styled(Text)`
  font-size: 0.813rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const queryClient = new QueryClient();


const HomePage: FunctionComponent = () => {
  const { state, dispatcher } = useDispatcher();
  const { isAuthModalNextAction, consumeNextAction } = useNextAction();
  const navigate = useNavigate();
  const { t } = useTranslation("translation", { keyPrefix: "homepage" });
  const [visaApplications, setVisaApplications] = useState<VisaApplication[]>([]);
  const [userRegistrationApplication, setUserRegistrationApplication] = useState<UserRegistrationApplication>();
  const [currentStatusOfResidence, setCurrentStatusOfResidence] = useState<StatusOfResidenceCode_CB19>();
  const [paymentUrl, setPaymentUrl] = useState<string>("/");
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);
  const [showPaymentPromptModal, setShowPaymentPromptModal] = useState<boolean>(false);
  const [showUserAuthModal, setShowUserAuthModal] = useState<boolean>(false);
  const [hasUserPaid, setHasUserPaid] = useState<boolean>(false);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [isApplyingViaAPI, setIsApplyingViaAPI] = useState<boolean | null>(null);
  const [isApplyingWithScrivener, setIsApplyingWithScrivener] = useState<boolean | null>(null);
  const showNoApplicationMessage = visaApplications.length === 0;
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
  const [visaAppForPreview, setVisaAppForPreview] = useState<VisaApplication | null>(null);
  const showUserRegistrationBeingProcessedPrompt =
    isApplyingViaAPI &&
    isUserRegistrationApplicationBeingProcessed( 
      state.userRegistrationAppStatus
    );
  const showUserRegistrationDeniedPrompt =
    isApplyingViaAPI &&
    isUserRegistrationApplicationDenied(state.userRegistrationAppStatus);
  const showMessagesToUser =
    showUserRegistrationBeingProcessedPrompt ||
    showUserRegistrationDeniedPrompt ||
    !hasUserPaid;

  const onClickCard = (visaApplication: VisaApplication) => {
    const id = visaApplication.id;
    switch (getVisaApplicationStatus(visaApplication)) {
      case VisaApplicationStatus.Working:
      case VisaApplicationStatus.Insufficient:
        navigate(`/application/${id}/edit`);
        break;
      case VisaApplicationStatus.Applying:
      case VisaApplicationStatus.Approved:
      case VisaApplicationStatus.Denied:
        setShowPreviewModal(true);
        setVisaAppForPreview(visaApplication);
        break;  
    }
  };

  
  const { isPending: isUserPending, data: userData } = useQuery({
    queryKey: [QUERY_KEYS.USER],
    queryFn: getCurrentUser,
  });

  const { isPending: isVisaApplicationsPending, data: visaApplicationsData } = useQuery({
    queryKey: [QUERY_KEYS.VISA_APPLICATIONS],
    queryFn: getVisaApplications,
    enabled: !!userData
  });
  
  const { isPending: isUserRegistrationPending, data: userRegistrationApplicationData } = useQuery({
    queryKey: [QUERY_KEYS.USER_REGISTRATION_APPLICATION],
    queryFn: getLatestUserRegistrationApplication,
    enabled: !!userData
  });
  
  const isLoading = isVisaApplicationsPending || isUserPending || isUserRegistrationPending;
  const isLoaded = !isLoading && visaApplicationsData && userData && userRegistrationApplicationData;
  
  
  useEffect(() => {
    if (isLoaded) {
      setVisaApplications(sortByCreatedDates(visaApplicationsData));
      setCurrentStatusOfResidence(userData.status_of_residence);
      setUserRegistrationApplication(userRegistrationApplicationData);
      setPaymentUrl(userData.stripe_invoice_url);
      setIsApplyingViaAPI(userData.uses_myna);
      setIsApplyingWithScrivener(userData.uses_certified_administrative_procedures_legal_specialist);
      setIsDataLoaded(true);
  
      if (userData.is_paid) 
        setHasUserPaid(true);
  
      dispatcher.stopLoading();
    } 
    
    if (isLoading) {
      dispatcher.startLoading();
    }

  }, [isLoading, visaApplicationsData, userData, userRegistrationApplicationData]);
  

  useEffect(() => {
    if (isAuthModalNextAction()) {
      //Currently, only one action so no need to check the action type
      consumeNextAction();
      setShowUserAuthModal(true);
      return;
    }

    if (isEnumValIncludedInURLParams(PaymentModalAction)) {
      setShowPaymentPromptModal(true);
      clearURLParameters();
      return;
    }
  }, []);


  return (
    <>
      <LocalPage>
        {/* Heading + Create Button */}
        <LocalRow>
          <PageHeading>{t("applications")}</PageHeading>
          <LocalButton
            variant="secondary"
            onClick={() => setShowCreateAppModal(true)}
          >
            <PlusIcon style={{ marginRight: 10 }} />
            {t("create")}
          </LocalButton>
        </LocalRow>

        {/* Prompts to the user */}
        {isDataLoaded && showMessagesToUser && (
          <LocalColumn>
            {showNoApplicationMessage && (
              <InlineMessage>{t("noDocUploadedYet")}</InlineMessage>
            )}

            {/* User Auth Status prompt (Show while the user registaration application is still in process) */}
            {showUserRegistrationBeingProcessedPrompt && (
              <PromptCard>
                <Text>{t("userAuth.beingProcessed.description")}</Text>
                <ActionButton onClick={() => setShowUserAuthModal(true)}>
                  {t("userAuth.beingProcessed.checkResult")}
                </ActionButton>
                <SmallText>
                  {t("userAuth.beingProcessed.submitted")}{" "}
                  {userRegistrationApplication &&
                    getSubmissionDate(userRegistrationApplication)}
                </SmallText>
              </PromptCard>
            )}

            {/* User Auth Status prompt (Show only if the user registration is denied) */}
            {showUserRegistrationDeniedPrompt && (
              <PromptCard variant="warning">
                <Text>{t("userAuth.denied.description")}</Text>
                <ActionButton onClick={() => navigate("/onboarding")}>
                  {t("userAuth.denied.tryAgain")}
                </ActionButton>
                <SmallText>
                  {t("userAuth.denied.submitted")}{" "}
                  {userRegistrationApplication &&
                    getSubmissionDate(userRegistrationApplication)}
                </SmallText>
              </PromptCard>
            )}

            {/* Payment prompt */}
            {!hasUserPaid && visaApplications.length > 0 && (
              <PromptCard variant="warning">
                <Text>{t("payment.description")}</Text>
                <ActionButton onClick={() => setShowPaymentPromptModal(true)}>
                  {t("payment.viewDetails")}
                </ActionButton>
              </PromptCard>
            )}
          </LocalColumn>
        )}

        {/* Application Cards */}
        {visaApplications.length > 0 && (
          <LocalColumn>
            {
              visaApplications.map((visaApplication, i) => (
                  <ApplicationCard
                    key={`visa-application-card-${i}`}
                    visaApplication={visaApplication}
                    onClick={() => onClickCard(visaApplication)}
                  />
                )
            )}
          </LocalColumn>
        )}

        {/* Privacy Policy link */}
        <BottomAlignedContainer>
          <PrivacyPolicyText>
            <PrivacyPolicyLink 
              href="https://tokuty.jp/privacy/" 
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("privacyPolicy")}
            </PrivacyPolicyLink>
          </PrivacyPolicyText>
        </BottomAlignedContainer>
      </LocalPage>

      {showPaymentPromptModal && (
        <PaymentPromptModal
          paymentUrl={paymentUrl}
          onClose={() => setShowPaymentPromptModal(false)}
        />
      )}

      {showPreviewModal && visaAppForPreview && (
        <PreviewModal 
          visaApplication={visaAppForPreview} 
          onClickButton={() => {
            setShowPreviewModal(false);
            setVisaAppForPreview(null);
          }}  
          onClickBackIcon={() => {
            setShowPreviewModal(false);
            setVisaAppForPreview(null);
          }}          
        />
      )}

      {showCreateAppModal && isLoaded && (
        <CreateNewAppModal
          userRegistration={userRegistrationApplication!.riyousha_touroku}
          statusOfResidence={currentStatusOfResidence!}
          onClose={() => setShowCreateAppModal(false)}
        />
      )}

      {showUserAuthModal && (
        <UserAuthenticationModal
          onClose={() => setShowUserAuthModal(false)}
          onConfirmAuthStatus={() => {
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER_REGISTRATION_APPLICATION] });
          }}
        />
      )}
    </>
  );
};

export default HomePage;
