import { FunctionComponent } from "react";
import {
  getLabelFromMunicipalityInJapanese,
  getLabelFromPrefectureInJapanese,
} from "../../../../../../utils/visaFormDataHelpers/commonVisaFormDataHelper";
import { useTranslation } from "react-i18next";
import {
  Group,
  SectionHeading,
  SectionSubHeading,
} from "../../previewBodyCommonStyle";
import { FormN } from "../../../../../../types/visa/uncommonFormParts/formN/data";
import { DoesWorkInvolveInInfoProcessing } from "../../../../../../types/visa/uncommonFormParts/formN/value";
import PreviewList from "../../../PreviewList";

interface PlaceOfEmploymentSectionProps {
  data: FormN
}

export const BasicInformationSection: FunctionComponent<
  PlaceOfEmploymentSectionProps
> = ({ data }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'placeOfEmploymentSection' });
  const { t : tWithoutPrefix } = useTranslation();

  return (
    <section>
      <SectionHeading>{t("title")}</SectionHeading>
      <PreviewList
        items={[
          {
            label: t("nameOfOrganization"),
            value: data["place_of_employment_name"]
          },
          {
            label: t("nameOfBranchOffice"),
            value: data["place_of_employment_name_of_branch"]
          },
        ]}
      />

      <Group>
        <SectionSubHeading>{t("location")}</SectionSubHeading>
        <PreviewList
          items={[
            {
              label: t("prefecture"),
              value: getLabelFromPrefectureInJapanese(data["place_of_employment_prefecture"])
                ? tWithoutPrefix(`${getLabelFromPrefectureInJapanese(data["place_of_employment_prefecture"])}`)
                : ""
            },
            {
              label: t("municipality"),
              value: data["place_of_employment_prefecture"]
                ? tWithoutPrefix(`${
                  getLabelFromMunicipalityInJapanese(
                    data["place_of_employment_prefecture"],
                    data["place_of_employment_municipality"]
                  )}`)
                : ""
            },
            {
              label: t("townStreetApartment"),
              value: data["place_of_employment_street"],
            },
          ]}
        />
      </Group>

      <PreviewList
        items={[
          {
            label: t("phoneNumber"),
            value: data["place_of_employment_telephone_no"],
          },
          {
            label: t("doesYourWorkInvolveInfoProcessingServices"),
            value: (() => {
              switch (data["qualifications_for_information_processing"]) {
                case DoesWorkInvolveInInfoProcessing.Yes: return tWithoutPrefix("common.yes");
                case DoesWorkInvolveInInfoProcessing.No: return tWithoutPrefix("common.no");
              }
            })()

          },
          {
            label: t("nameOfQualificationExamForInfoProcessing"),
            value: data["name_of_the_qualification"],
            skip: data["name_of_the_qualification"] === DoesWorkInvolveInInfoProcessing.Yes,
          },
        ]}
      />
    </section>
  );
};

export default BasicInformationSection;
