import { FunctionComponent, useEffect, useState } from "react";
import Page from "../../components/layout/Page";
import Row from "../../components/layout/Row";
import Text from "../../components/text/Text";
import PageHeading from "../../components/text/PageHeading";
import Section from "../../components/layout/Section";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Button from "../../components/form/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatcher } from "../../hooks";
import { VisaApplication } from "../../types/visa/applicationData";
import { getVisaApplication, getVisaApplicationAsInvitee } from "../../apis/visaApplication";
import {
  getVisaApplicationStatus,
  checkVisaApplicationType,
  getVisaInfoTransKeys
} from "../../utils/visaApplicationHelper";
import BackButton from "../../components/navigation/BackButton";
import Column from "../../components/layout/Column";
import { VisaApplicationStatus } from "../../types/visa/applicationStatus";
import AppStatusLabel from "../../components/form/AppStatusLabel";
import VisaStatusConfirmationModal from "./VisaStatusConformationModal";
import { ReactComponent as AngleBracketIcon } from "../../assets/icon-angle-bracket-right-white.svg";
import { ReactComponent as ShareIcon } from "../../assets/icon-share.svg";
import { formatDateString, isEnumValIncludedInURLParams } from "../../utils/utils";
import PreviewModal from "../../components/modal/previewModal";
import { VisaApplicationType } from "../../types/visa/applicationType";
import dayjs from "dayjs";
import VisaMessageModal from "./VisaMessageModal";
import { VisaStatusConfirmationModalNextAction as VisaStatusConfirmationModalAction } from "../../types/nextActions";
import { VisaMessageModalNextAction as VisaMessageModalAction } from "../../types/nextActions";
import { getInvitationTokenFromURLParams, isSharedPageAccess } from "../../utils/invitationRelatedHelper";
import axios from "axios";
import UserInvitationModal from "../../components/modal/UserInvitationModal";

interface ApplicationReviewPageProps {}

const LocalPage = styled(Page)`
  padding: 20px;
  gap: 30px;
`;

const StatusHeadingWrapper = styled(Row)`
  justify-content: space-between;
`;

const ActionWrapper = styled(Column)`
  gap: 20px;
`;

const ShareButton = styled(Button).attrs(() => ({
  variant: 'inline',
}))`
  width: auto;
  min-width: 109px;
  gap: 10px;
`;

const ContentWrapper = styled(Section)`
  border-radius: 5px;
  padding: 30px 20px 30px 20px;
  gap: 30px;
`;

const DetailWrapper = styled(Column)`
  gap: 10px;
`;

const ResultText = styled(Text)`
  font-weight: 500;
`;

const SmallText = styled(Text)`
  font-size: 0.813rem;
  color: #626161;
`;

const ButtonsWrapper = styled(Column)`
  gap: 30px;
`;

const LocalButton = styled(Button)`
  width: auto;
`;

const DateText = styled(Text)`
  font-size: 0.8rem;
  color: #999999;
  white-space: "pre-wrap";
`;

const ForwardIcon = styled(AngleBracketIcon)`
  margin-left: 10px;
`;

const ApplicationReviewPage: FunctionComponent<
  ApplicationReviewPageProps
> = () => {
  const { dispatcher } = useDispatcher();
  const { t } = useTranslation('translation', { keyPrefix: 'applicationReviewPage' });
  const { t : tWithoutPrefix } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [visaApplication, setVisaApplication] = useState<VisaApplication>();
  const [visaApplicationType, setVisaApplicationType] =
    useState<VisaApplicationType>();
  const [visaApplicationStatus, setVisaApplicationStatus] =
    useState<VisaApplicationStatus>(VisaApplicationStatus.Applying);
  const [submittedAt, setSubmittedAt] = useState<string>("");
  const [isVisaToWorkWithOrg, setIsVisaToWorkWithOrg] = useState<boolean>(false); 
  const [have30MinNotPassed, setHave30MinNotPassed] = useState<boolean>(true);
  const [
      isVisaStatusConfirmationModalOpen,
      setIsVisaStatusConfirmationModalOpen,
    ] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showUserInvitationModal, setShowUserInvitationModal] = useState(false);
    
  const isThisSharedPageAccess = isSharedPageAccess();
  const invitationToken = getInvitationTokenFromURLParams();
  const [headingText, setHeadingText] = useState<string>("");
  const onClickViewApplication = () => setShowPreviewModal(true);
  const onClickCheckResult = () => setIsVisaStatusConfirmationModalOpen(true);
  const onClickviewMessage = () => setShowMessageModal(true);
  const onStatusFetched = (status: VisaApplicationStatus) => {
    setVisaApplicationStatus(status);
  };

  useEffect(() => {

    (async () => {
      try {
        dispatcher.startLoading();

        const visaApplication = 
          isThisSharedPageAccess
            ? await getVisaApplicationAsInvitee(id!, invitationToken!)
            : await getVisaApplication(id!)
            
        const visaApplicationType = checkVisaApplicationType(visaApplication);
        
        if (
          !!!visaApplication ||
          !!!visaApplicationType ||
          !!!visaApplication.last_submitted_visa_submission
        ) {
          throw new Error("Invalid or incomplete visa application");
        }

        setVisaApplication(visaApplication);
        setVisaApplicationType(visaApplicationType);
        setVisaApplicationStatus(getVisaApplicationStatus(visaApplication));

        switch(visaApplicationType) {
          case VisaApplicationType.ExtendGijinkokuVisaBySelf:
            setIsVisaToWorkWithOrg(true);
        }

        setHeadingText(t(`category.${getVisaInfoTransKeys(visaApplication).type}`));

        const submittedAt = dayjs(
          visaApplication.last_submitted_visa_submission.submitted_at
        );
        const now = dayjs();
        const diff = now.diff(submittedAt, "minute");
        setSubmittedAt(formatDateString(submittedAt.toString()));
        setHave30MinNotPassed(diff < 30);

        if (isEnumValIncludedInURLParams(VisaStatusConfirmationModalAction)) {
          setIsVisaStatusConfirmationModalOpen(true);
          return;
        }

        if (isEnumValIncludedInURLParams(VisaMessageModalAction)) {
         setShowMessageModal(true);
         return;
        }

      } catch (e) {
        if (axios.isAxiosError(e)) {
          switch (e.response?.status) {
            case 403:
              isThisSharedPageAccess && navigate("shared/invalid");
              return;
          }
        }
        navigate("/");
      } finally {
        dispatcher.stopLoading();
      }
    })();
  }, []);

  return (
    <>
      <LocalPage>
        { !isThisSharedPageAccess &&
          <ActionWrapper>
            <BackButton onClick={() => navigate("/")} />

            {/* We won't need this button for now but maybe in the future
            { isVisaToWorkWithOrg &&
              <ShareButton onClick={() => setShowUserInvitationModal(true)}>
                <ShareIcon/>
                {tWithoutPrefix("common.share")}
              </ShareButton>
            }
          */}
          </ActionWrapper>
        }

        <StatusHeadingWrapper>
          <PageHeading>{headingText}</PageHeading>
          {visaApplicationStatus && (
            <AppStatusLabel status={visaApplicationStatus} />
          )}
        </StatusHeadingWrapper>
        
        <ContentWrapper>
          {(() => {
            switch (visaApplicationStatus) {
              case VisaApplicationStatus.Applying:
                return (
                  isThisSharedPageAccess 
                    //For Invitee
                    ? <>
                        <DetailWrapper>
                          <ResultText>
                            {t("forInvitee.applying.description")}
                          </ResultText>
                        </DetailWrapper>
                        <ButtonsWrapper>
                          <LocalButton
                            variant="inline"
                            onClick={onClickViewApplication}
                          >
                            {t("viewApplication")}
                          </LocalButton>
                        </ButtonsWrapper>
                      </>

                    //For Applicant
                    : <>
                        <DetailWrapper>
                          <ResultText>
                            {t("applying.description1")}
                          </ResultText>
                          <Text>
                            {t("applying.description2")}
                          </Text>
                        </DetailWrapper>
                        <ButtonsWrapper>
                          <Column style={{ gap: 5 }}>
                            <LocalButton
                              disabled={have30MinNotPassed}
                              variant="primary"
                              onClick={onClickCheckResult}
                            >
                              {t("checkResult")}
                            </LocalButton>
                            {have30MinNotPassed && (
                              <SmallText>
                                {t(
                                  "applying.cannotBeChecked"
                                )}
                              </SmallText>
                            )}
                          </Column>
                          <LocalButton
                            variant="inline"
                            onClick={onClickViewApplication}
                          >
                            {t("viewApplication")}
                          </LocalButton>
                        </ButtonsWrapper>
                      </>
                );

              case VisaApplicationStatus.Approved:
                return (
                  isThisSharedPageAccess

                  //For Invitee
                  ? <>
                      <DetailWrapper>
                        <ResultText>
                          {t("forInvitee.approved.description")}
                        </ResultText>
                      </DetailWrapper>
                      <ButtonsWrapper>
                        <LocalButton
                          variant="inline"
                          onClick={onClickViewApplication}
                        >
                          {t("viewApplication")}
                        </LocalButton>
                      </ButtonsWrapper>
                    </>

                  //For Applicant
                  : <>
                    <DetailWrapper>
                      <ResultText>
                        {t("approved.description1")}
                      </ResultText>
                      <Text>
                        {t("approved.description2")}
                      </Text>
                      <Text>
                        {t("approved.description3")}
                      </Text>
                    </DetailWrapper>
                    <ButtonsWrapper>
                      <LocalButton
                        variant="inline"
                        onClick={onClickViewApplication}
                      >
                        {t("viewApplication")}
                      </LocalButton>
                    </ButtonsWrapper>
                  </>
                );

              case VisaApplicationStatus.Denied:
                return (
                  isThisSharedPageAccess

                  //For Invitee
                  ? <>
                      <DetailWrapper>
                        <ResultText>
                          {t("forInvitee.denied.description")}
                        </ResultText>
                      </DetailWrapper>
                      <ButtonsWrapper>
                        <LocalButton
                          variant="inline"
                          onClick={onClickViewApplication}
                        >
                          {t("viewApplication")}
                        </LocalButton>
                      </ButtonsWrapper>
                    </>

                  //For Applicant
                  : <>
                      <DetailWrapper>
                        <ResultText>
                          {t("denied.description1")}
                        </ResultText>
                        <Text>
                          {t("denied.description2")}
                        </Text>
                        <Text color="#626161">
                          {t("denied.description3")}
                        </Text>
                      </DetailWrapper>
                      <ButtonsWrapper>
                        <LocalButton
                          variant="primary"
                          onClick={onClickviewMessage}
                        >
                          {t("checkMessages")}
                          <ForwardIcon />
                        </LocalButton>
                        <LocalButton
                          variant="inline"
                          onClick={onClickViewApplication}
                        >
                          {t("viewApplication")}
                        </LocalButton>
                      </ButtonsWrapper>
                    </>
                );

              default:
                return null;
            }
          })()}
          {submittedAt && (
            <DateText>
              {`${t("submitted")} ${submittedAt}`}
            </DateText>
          )}
        </ContentWrapper>
      </LocalPage>

      {isVisaStatusConfirmationModalOpen && visaApplication && (
          <VisaStatusConfirmationModal
            visaApplication={visaApplication}
            onClose={() => setIsVisaStatusConfirmationModalOpen(false)}
            onStatusFetched={onStatusFetched}
          />
        )}

      {showMessageModal && visaApplication && (
        <VisaMessageModal
          visaApplicationId={visaApplication.id}
          onClose={() => setShowMessageModal(false)}
        />
      )}

      {showPreviewModal && visaApplication && (
        <PreviewModal
          visaApplication={visaApplication}
          title={tWithoutPrefix("modalPreview.forApplicationReviewPage.title")}
          warningText={
            isThisSharedPageAccess
              ? ""
              : tWithoutPrefix("modalPreview.forApplicationReviewPage.warning")
          }
          onClickBackIcon={() => setShowPreviewModal(false)}
          onClickButton={() => setShowPreviewModal(false)}
          buttonText={tWithoutPrefix("common.close")}
        />
      )}

      {/* For now, we don't need this modal but maybe in the future
      {showUserInvitationModal && visaApplication && (
        <UserInvitationModal
          visaApplicationId={visaApplication.id}
          isVisaApplicationSubmitted={true}
          onBack={() => setShowUserInvitationModal(false)}
        />
      )}
      */}
    </>
  );
};

export default ApplicationReviewPage;
