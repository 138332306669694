import dayjs from "dayjs";

export const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

//Don't use this for security purposes. The generated string is not cryptographically secure.
export const generateRandomString = () => Math.random().toString(32).substring(2);

export const formatDateString = (dateStr: string | undefined | null, isOnlyYearMonth: boolean = false): string => {
  if (!dateStr)
    return '';

  const format = isOnlyYearMonth ? "YYYY/MM" : "YYYY/MM/DD";
  return dayjs(dateStr).format(format);
}

export const formatDate = (date: Date, format: string): string => {
  format = format.replace(/yyyy/g, date.getFullYear().toString());
  format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
  format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2));
  format = format.replace(/HH/g, ('0' + date.getHours()).slice(-2));
  format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
  format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
  format = format.replace(/SSS/g, ('00' + date.getMilliseconds()).slice(-3));
  return format;
};

export const urlParameterExists = (paramName: string): boolean => {
  return window.location.search.includes(paramName);
}

export const extractURLParameters = (removeParamsFromUrl: boolean = true): (Record<string, string> | undefined) => {
  const [domain, params] =  window.location.href.split('?');
  const searchParams = new URLSearchParams(params);
  const dic: Record<string, string> = {};
  searchParams.forEach((value, name) => {
      dic[name] = value;
  });

  if (Object.keys(dic).length > 0) {
      if (removeParamsFromUrl)
        window.history.replaceState(null, '', domain);

      return dic;
  }
}

export const isEnumValIncluded = <T> (record: Record<string, T>, val: any): boolean => {
  const recordVals = Object.values(record);
  return recordVals.includes(val);
}

export const isEnumValIncludedInURLParams = <T> (record: Record<string, T>): boolean => {
  const urlParams = extractURLParameters(false);

  if (!urlParams)
    return false;

  const paramVals = Object.values(urlParams);
  const recordVals = Object.values(record);

  return recordVals.some(val => paramVals.includes(String(val)));
}

export const clearURLParameters = () => {
  const [domain, params] =  window.location.href.split('?');
  const searchParams = new URLSearchParams(params);

  if (searchParams.set.length > 0) {
    window.history.replaceState(null, '', domain);
  }
}

export const isValueEmpty = (val: any): boolean => {
  if (val == null)
    return true;

  //Empty array or object are considered empty
  if (typeof val === 'object')
    return Object.keys(val).length === 0;

  //0 is not considered as empty
  if (typeof val === 'number')
    return false;

  if (typeof val === 'boolean')
    return false;

  return val.trim() === '';
}

export const sortByCreatedDates = <T extends { created_at: string }>(arr: T[], sortType: "ASC" | "DEC" = "DEC"): T[] => {
  return arr.sort((a, b) => {
    const dateA = new Date(a.created_at).getTime();
    const dateB = new Date(b.created_at).getTime();

    return sortType === "ASC" ? dateA - dateB : dateB - dateA;
  });
}

export const toSafeLocaleString = (val: any): string => {
  if (typeof val === 'number')
    return val.toLocaleString();

  if (typeof val === 'string')
    return Number(val).toLocaleString();

  return '';
}

// Utility function to return a Date with a relative year offset
export const getLastDayWithYearOffset = (yearOffset: number): Date => {
  const date = new Date();

  date.setFullYear(date.getFullYear() + yearOffset);
  date.setMonth(11);
  date.setDate(31);

  return date;
};

export const getFirstDayOfCurrentYear = (): Date => {
  const date = new Date();
  date.setMonth(0);
  date.setDate(1);

  return date;
}

export const getFirstDayOfYearOffset = (yearOffset: number): Date => {
  const date = new Date();

  date.setFullYear(date.getFullYear() + yearOffset);
  date.setMonth(0);
  date.setDate(1);

  return date;
}

export const getDateWithDayOffset = (dayOffset: number): Date => {
  const date = new Date();
  
  date.setDate(date.getDate() + dayOffset);
  
  return date;
};