
import { PERIOD_OF_STAY_CB20_OPTIONS_FOR_DEPENDENT } from "../../constants/options";
import { FormR } from "../../types/visa/uncommonFormParts/formR/data";

export const getLabelFromPeriodOfStayCodeForDependent = (periodOfStayCode: string) => {
  return PERIOD_OF_STAY_CB20_OPTIONS_FOR_DEPENDENT.find(op => op.value === periodOfStayCode)?.label ?? '';
}

export const getEmploymentKeyOf = {
  typeOfWork: (index: number) => `other_activities_${index}_type_of_work` as keyof FormR,
  placeOfEmployment: (index: number) => `other_activities_${index}_name` as keyof FormR,
  nameOfBranch: (index: number) => `other_activities_${index}_name_of_branch` as keyof FormR,
  telephoneNumber: (index: number) => `other_activities_${index}_telephone_no` as keyof FormR,
  workTimePerWeek: (index: number) => `other_activities_${index}_work_time_per_week` as keyof FormR,
  salaryType: (index: number) => `other_activities_${index}_salary_monthly_or_daily` as keyof FormR,
  salaryYen: (index: number) => `other_activities_${index}_salary` as keyof FormR
}

export const getLengthOfEmployments = (data: FormR): number => {
  let length = 0;

  for (let i = 1; i <= 9; i++) 
  {
    const isThereValue = [
      data[getEmploymentKeyOf.typeOfWork(i)],
      data[getEmploymentKeyOf.placeOfEmployment(i)],
      data[getEmploymentKeyOf.nameOfBranch(i)],
      data[getEmploymentKeyOf.telephoneNumber(i)],
      data[getEmploymentKeyOf.workTimePerWeek(i)],
      data[getEmploymentKeyOf.salaryType(i)],
      data[getEmploymentKeyOf.salaryYen(i)]
    ].some(v => v != null);

    if (isThereValue) {
      length = i;
    }
  }

  return length;
}