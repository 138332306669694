import { FunctionComponent, HTMLAttributes, ReactNode } from "react";
import styled from "styled-components";

interface BottomAlignedContainerProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
}

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column-reverse;
  flex-grow: 1;
  align-items: center;
`;

const BottomAlignedContainer: FunctionComponent<BottomAlignedContainerProps> = ({
  children,
  ...props
}) => {
  return (
    <Container {...props}>{children}</Container>
  );
}

export default BottomAlignedContainer;